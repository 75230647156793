import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import DepthOfMarket from '../../containers/DepthOfMarket/DepthOfMarket'
import {
  getSecurityOrderDataById,
  getSecurityStaticDataById
} from '../../store/securities/selectors'

interface Props {
  securityId: number
  isAdmin: boolean
}

const popupElementId = 'depthPopupRoot'

export const DepthPopoutHost = (props: any) => {
  return <div id={popupElementId}></div>
}

const DepthPopout: FC<Props> = ({ securityId, isAdmin }) => {
  const { fin, manageWindows } = useOpenFin()
  const [externalWindow, setExternalWindow] = useState<any>(null)
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null
  )
  const securityOrderData = useSelector(getSecurityOrderDataById)(securityId)
  const securityStaticData = useSelector(getSecurityStaticDataById)(securityId)

  async function createOpenfinWindow() {
    let windowTop = 0
    let windowLeft = 0
    const previousBounds = localStorage.getItem(`depthPrevBounds-${securityId}`)
    if (previousBounds) {
      const parsed = JSON.parse(previousBounds)
      windowTop = parsed.top
      windowLeft = parsed.left
    }
    const winOption = {
      name: `Depth-${securityId}`,
      maxWidth: isAdmin ? 700 : 470,
      maxHeight: 174,
      defaultLeft: windowLeft,
      defaultTop: windowTop,
      url: '/depth',
      frame: false,
      saveWindowState: false,
      contextMenu: true,
      resizable: true,
      autoShow: true,
      showTaskbarIcon: true,
      cornerRounding: {
        height: 10,
        width: 10
      }
    }
    const win = await fin.Window.create(winOption)
    win.on('bounds-changing', async ({ left, top }: any) => {
      localStorage.setItem(
        `depthPrevBounds-${securityId}`,
        JSON.stringify({ left, top })
      )
    })
    return win
  }

  const val = useRef()

  useEffect(() => {
    val.current = externalWindow
  }, [externalWindow])

  const onUnload = () => {
    if (val.current) {
      const w = val.current as any
      w.close(true)
      setExternalWindow(null)
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      if (val.current) {
        const w = val.current as any
        w.close(true)
        setExternalWindow(null)
      }
    }
  }, [])

  useEffect(() => {
    if (fin) {
      const app = fin.desktop.Application.getCurrent()
      app.getChildWindows((children: any) => {
        if (
          !children.some((child: any) =>
            child.name.startsWith(`Depth-${securityId}`)
          )
        ) {
          renderWindow()
        }
        if (isAdmin) {
          manageWindows('Depth', 'resize')
        }
      })
    }
  }, [securityId, securityOrderData, securityStaticData])

  const renderWindow = () => {
    if (externalWindow) {
      // externalWindow.setAsForeground()
    } else {
      createOpenfinWindow()
        .then((extWindow) => {
          if (extWindow) {
            const webWindow = extWindow.getWebWindow()
            setContainerElement(
              webWindow.document.getElementById(popupElementId)
            )

            // Copy the app's styles into the new window
            const stylesheets = Array.from(document.styleSheets)
            stylesheets.forEach((stylesheet) => {
              /* tslint:disable */
              const css = stylesheet
              if (stylesheet.href) {
                const newStyleElement = document.createElement('link')
                newStyleElement.rel = 'stylesheet'
                newStyleElement.href = stylesheet.href
                webWindow.document.head.appendChild(newStyleElement)
              } else if (css && css.cssRules && css.cssRules.length > 0) {
                const newStyleElement = document.createElement('style')
                Array.from(css.cssRules).forEach((rule) => {
                  newStyleElement.appendChild(
                    document.createTextNode(rule.cssText)
                  )
                })
                webWindow.document.head.appendChild(newStyleElement)
              }
            })
            extWindow.on('close-requested', (e: any) => {
              extWindow.close(true)
              setExternalWindow(null)
            })
            extWindow.restore()
          }
          setExternalWindow(extWindow)

          return () => {
            if (extWindow) {
              extWindow.close(true)
              setExternalWindow(null)
            }
          }
        })
        /* eslint-disable no-console */
        .catch((err) => console.log(err))
    }
  }

  if (!containerElement) {
    return null
  }

  if (!externalWindow) {
    return null
  }
  // Render this component's children into the root element of the popout window
  // return ReactDOM.createPortal(props.children, containerElement)

  return ReactDOM.createPortal(
    <div>
      <DepthOfMarket
        gridIndex={0}
        key={securityId}
        securityId={securityId}
        popout={true}
      />
    </div>,
    containerElement
  )
}

export default DepthPopout
