import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import UserDashboardGrid from './Grid/UserDashboardGrid'

import styles from './UserDashboard.scss'

import {
  subscribeToUserDashboard,
  unsubscribeFromUserDashboard
} from '../../../store/admin/userDashboard/actions'

const UserDashboard: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(subscribeToUserDashboard())
    return () => {
      dispatch(unsubscribeFromUserDashboard())
    }
  }, [])

  return (
    <div className={fin ? styles.finWrapper : styles.userDashboardWrapper}>
      <UserDashboardGrid />
    </div>
  )
}

export default UserDashboard
