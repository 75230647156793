import cx from 'classnames'
import moment from 'moment'
import React, { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BidAskDiffType,
  BidAskDiffTypeStrings,
  GreaterOrLessThan,
  GreaterOrLessThanStrings
} from '../../store/alertFilter/types'
import { getBook, getDefaultBookId } from '../../store/books/selectors'
import {
  SeriesTypeFromString,
  StringFromSeriesType
} from '../../store/filter/helpers'
import {
  AllCountries,
  AllCouponTypes,
  AllRatings,
  AllRatingValues,
  AllSectors,
  AllSecurityTypes,
  AllSeries,
  Countries,
  CouponType,
  CouponTypeValues,
  CurrencyValues,
  defaultFilter,
  Filter,
  MaturitiesValues,
  Rating,
  Sectors,
  SecurityType,
  SecurityTypeValues,
  Series
} from '../../store/filter/types'
import {
  saveAdvancedFilter,
  setMyBook,
  setMyFirm,
  setSizeFilter,
  setUseAdvancedFilter
} from '../../store/securities/actions'
import {
  getAdvancedFilter,
  getMyBook,
  getMyFirm,
  getSize,
  getUseAdvancedFilter,
  getUseSize
} from '../../store/securities/selectors'
import {
  createNewWatchlistWithSecurityIds,
  resetNewWatchlistTransactionId
} from '../../store/upload/actions'
import DropdownRatings from '../DropdownRatings/DropdownRatings'
import GenericDropdownMenu from '../GenericDropdownMenu/GenericDropdownMenu'
import GenericDropdownMenuMulti from '../GenericDropdownMenu/GenericDropdownMenuMulti'
import NewWatchListInput from '../Upload/NewWatchListInput'
import styles from './AdvancedFilter.scss'
import SizeFilterAdvancedFilter from './SizeFilterAdvancedFilter'
export interface Props {
  active: boolean
  setActive: (b: boolean) => void
  gridIndex: number
  myOrdersOpen: boolean
}

const AdvancedFilterEditor: FC<Props> = ({ gridIndex, myOrdersOpen }) => {
  const useSizeChecked = useSelector(getUseSize)(gridIndex)
  const useAdvancedFilter = useSelector(getUseAdvancedFilter)(gridIndex)
  const thisBook = useSelector(getBook)
  const defaultBookId = useSelector(getDefaultBookId)
  const [displayNameModal, toggleModal] = useState(false)
  const [newWatchlistName, setNewWatchlistName] = useState(
    moment().format('M/DD/YY, h:mm a')
  )
  const [errorMessage, setErrorMessage] = useState('')
  let filter = useSelector(getAdvancedFilter)(gridIndex)
  if (!filter) {
    filter = defaultFilter
  }

  const myFirmChecked = useSelector(getMyFirm)(gridIndex)
  const [myFirmIsChecked, setMyFirmIsChecked] = useState(myFirmChecked)

  const myBookChecked = useSelector(getMyBook)(gridIndex)
  const [myBookIsChecked, setMyBookIsChecked] = useState(myBookChecked)

  const useSizeInitialVal = useSelector(getUseSize)(gridIndex)
  const [useSize, setUseSize] = useState(useSizeInitialVal)

  const sizeInitialVal = useSelector(getSize)(gridIndex)
  const [size, setSize] = useState(sizeInitialVal)

  const possibleSizes = ['200', '500', '1000', '2000', 'Custom']
  let selectedSizeValue = sizeInitialVal?.toString() ?? ''

  if (possibleSizes.indexOf(selectedSizeValue) < 0) {
    selectedSizeValue = 'Custom'
  }
  const [selectedSize, setSelectedSize] = useState(selectedSizeValue)

  const setSizeCallback = (sz: number) => {
    setSize(sz)
  }

  const setUseSizeCallback = (b: boolean) => {
    setUseSize(b)
  }

  const setSelectedSizeCallback = (st: string) => {
    if (st !== 'Custom') {
      setSize(Number(st))
    }
    setSelectedSize(st)
  }

  const dispatch = useDispatch()

  const [selectedSectors, setSelectedSectors] = useState(
    filter.sectors.map((s) => Sectors[s])
  )
  const [selectedMaturities, setSelectedMaturities] = useState(
    filter.maturities
  )
  const [selectedSecurityTypes, setSelectedSecurityTypes] = useState(
    filter.securityTypes.map((st) => SecurityTypeValues[st])
  )
  const [selectedCouponTypes, setSelectedCouponTypes] = useState(
    filter.couponTypes.map((ct) => CouponTypeValues[ct])
  )
  const [selectedRatings, setSelectedRatings] = useState(
    filter.ratings.map((r) => AllRatingValues[r])
  )
  const [selectedCountries, setSelectedCountries] = useState(
    filter.countries.map((c) => Countries[c])
  )
  const [selectedCurrencies, setSelectedCurrencies] = useState(
    filter.currencies.map((c) => c.toString())
  )
  const sectors = Object.values(Sectors).sort()
  const [yieldVal, setYieldVal] = useState(
    (filter.yield as any) !== null && (filter.yield as any) !== undefined
      ? filter.yield!.toString()
      : ''
  )
  const [bidAskDiffVal, setBidAskDiffVal] = useState(
    (filter.bidAskDiff as any) !== null &&
      (filter.bidAskDiff as any) !== undefined
      ? filter.bidAskDiff!.toString()
      : ''
  )
  const [priceVal, setPriceVal] = useState(
    (filter.price as any) !== null && (filter.price as any) !== undefined
      ? filter.price!.toString()
      : ''
  )

  const [seriesVal, setSeriesVal] = useState(
    StringFromSeriesType(filter.series)
  )

  const [priceGreaterLessThan, setPriceGreaterLessThan] = useState(
    GreaterOrLessThanStrings[filter.priceGreaterLessThan]
  )
  const [yieldGreaterLessThan, setYieldGreaterLessThan] = useState(
    GreaterOrLessThanStrings[filter.yieldGreaterLessThan]
  )

  const [bidAskDiffGreaterLessThan, setBidAskDiffGreaterLessThan] = useState(
    GreaterOrLessThanStrings[filter.bidAskDiffGreaterLessThan]
  )

  const [bidAskDiffType, setBidAskDiffType] = useState(
    BidAskDiffTypeStrings[filter.bidAskDiffType]
  )

  const yieldChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setYieldVal(evt.target.value)
  }

  const priceChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPriceVal(evt.target.value)
  }

  const bidAskDiffChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setBidAskDiffVal(evt.target.value)
  }

  const onCheckMyFirm = useCallback((e: React.ChangeEvent) => {
    const { checked } = e.target as HTMLInputElement
    // dispatch(setMyFirm(gridIndex, checked))
    setMyFirmIsChecked(checked)
  }, [])

  const onCheckMyBook = useCallback((e: React.ChangeEvent) => {
    const { checked } = e.target as HTMLInputElement
    setMyBookIsChecked(checked)
  }, [])

  const reset = () => {
    const f = {
      sectors: AllSectors,
      maturities: MaturitiesValues,
      securityTypes: AllSecurityTypes,
      couponTypes: AllCouponTypes,
      currencies: CurrencyValues,
      ratings: AllRatings,
      countries: AllCountries,
      securitiesAndTickers: [],
      price: undefined,
      priceGreaterLessThan: GreaterOrLessThan.GreaterThan,
      yield: undefined,
      yieldGreaterLessThan: GreaterOrLessThan.GreaterThan,
      bidAskDiff: undefined,
      bidAskDiffGreaterLessThan: GreaterOrLessThan.GreaterThan,
      bidAskDiffType: BidAskDiffType.Price,
      series: Series.All
    } as Filter

    setSelectedSectors(AllSectors.map((s) => Sectors[s]))
    setSelectedMaturities(MaturitiesValues)
    setSelectedSecurityTypes(
      AllSecurityTypes.map((st) => SecurityTypeValues[st])
    )
    setSelectedCouponTypes(AllCouponTypes.map((st) => CouponTypeValues[st]))
    setSelectedRatings(AllRatings.map((r) => AllRatingValues[r]))
    setSelectedCurrencies(CurrencyValues.map((c) => c.toString()))
    setSeriesVal(StringFromSeriesType(f.series))
    setSelectedCountries(f.countries.map((c) => Countries[c]))
    setYieldGreaterLessThan(
      GreaterOrLessThanStrings[GreaterOrLessThan.GreaterThan]
    )
    setYieldVal('')
    setPriceGreaterLessThan(
      GreaterOrLessThanStrings[GreaterOrLessThan.GreaterThan]
    )
    setPriceVal('')
    setBidAskDiffGreaterLessThan(
      GreaterOrLessThanStrings[GreaterOrLessThan.GreaterThan]
    )
    setBidAskDiffVal('')
    setMyFirmIsChecked(false)
    setMyBookIsChecked(false)
    setSelectedSize('200')
    setSize(200)
    setUseSize(false)

    dispatch(saveAdvancedFilter(gridIndex, f))

    dispatch(setSizeFilter(gridIndex, false, 200))
    dispatch(setUseAdvancedFilter(gridIndex, false))
    dispatch(setMyFirm(gridIndex, false))
    dispatch(setMyBook(gridIndex, false))
    // setActive(false)
  }

  const saveCurrentViewName = () => {
    onApplyClick()
    toggleModal(true)
  }

  const saveCurrentView = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (newWatchlistName === '') {
      setErrorMessage('Enter a watchlist name')
    } else {
      dispatch(resetNewWatchlistTransactionId(gridIndex))

      dispatch(
        createNewWatchlistWithSecurityIds(
          gridIndex,
          newWatchlistName,
          1,
          thisBook(defaultBookId),
          [],
          [],
          [],
          filter,
          myFirmChecked,
          useSizeChecked,
          size ? size : 200,
          useAdvancedFilter
        )
      )
      toggleModal(false)
      resetNameModal()
    }
  }

  const onApplyClick = () => {
    const sectorsToSave = selectedSectors.map((s) => {
      for (const [key, value] of Object.entries(Sectors)) {
        if (value === s) {
          return Number(key)
        }
      }
    })

    const maturitiesToSave = selectedMaturities

    const securityTypesToSave = selectedSecurityTypes.map((st) => {
      const key = st.replace(/ /g, '_')
      return SecurityType[key as keyof typeof SecurityType]
    })

    let couponTypesToSave = AllCouponTypes
    if (selectedCouponTypes.length > 0) {
      couponTypesToSave = selectedCouponTypes.map((ct) => {
        const key = ct.replace(/ /g, '_')
        return CouponType[key as keyof typeof CouponType]
      })
    } else {
      setSelectedCouponTypes(AllCouponTypes.map((st) => CouponTypeValues[st]))
    }

    const ratingToSave = selectedRatings.map((r) => {
      const key = r.replace('+', 'Plus').replace('-', 'Minus')
      return Rating[key as keyof typeof Rating]
    })

    const countriesToSave = selectedCountries.map((c) => {
      for (const country in Countries) {
        if (Countries[country] === c) {
          return country
        }
      }
    })
    const seriesToSave = SeriesTypeFromString(seriesVal)

    const currenciesToSave = selectedCurrencies
    const securitiesIdentifiersToSave = [] as string[]
    const f = {
      sectors: sectorsToSave,
      maturities: maturitiesToSave,
      securityTypes: securityTypesToSave,
      couponTypes: couponTypesToSave,
      currencies: currenciesToSave,
      ratings: ratingToSave,
      countries: countriesToSave,
      securitiesAndTickers: securitiesIdentifiersToSave,
      price: priceVal.length > 0 ? Number(priceVal) : undefined,
      priceGreaterLessThan:
        priceGreaterLessThan === '≥'
          ? GreaterOrLessThan.GreaterThan
          : GreaterOrLessThan.LessThan,
      yield: yieldVal.length > 0 ? Number(yieldVal) : undefined,
      yieldGreaterLessThan:
        yieldGreaterLessThan === '≥'
          ? GreaterOrLessThan.GreaterThan
          : GreaterOrLessThan.LessThan,
      bidAskDiff: bidAskDiffVal.length > 0 ? Number(bidAskDiffVal) : undefined,
      bidAskDiffGreaterLessThan:
        bidAskDiffGreaterLessThan === '≥'
          ? GreaterOrLessThan.GreaterThan
          : GreaterOrLessThan.LessThan,
      bidAskDiffType:
        bidAskDiffType === 'Price'
          ? BidAskDiffType.Price
          : BidAskDiffType.Spread,
      series: seriesToSave
    } as Filter
    dispatch(saveAdvancedFilter(gridIndex, f))
    dispatch(setMyFirm(gridIndex, myFirmIsChecked))
    dispatch(setMyBook(gridIndex, myBookIsChecked))
    if (useSize && size === 0) {
      setUseSize(false)
      dispatch(setSizeFilter(gridIndex, false, 200))
    } else {
      dispatch(setSizeFilter(gridIndex, useSize, size))
    }
    dispatch(setUseAdvancedFilter(gridIndex, true))
    // setActive(false)
  }

  const resetNameModal = () => {
    setNewWatchlistName(moment().format('M/DD/YY, h:mm a'))
    setErrorMessage('')
    toggleModal(false)
  }

  return (
    <div className={styles.AdvancedFilterEditorWrapper}>
      {displayNameModal && (
        <form className={styles.nameMenu} onSubmit={saveCurrentView}>
          <NewWatchListInput
            watchlistName={newWatchlistName}
            setWatchlistName={setNewWatchlistName}
          />
          {errorMessage !== '' && (
            <span className={styles.errorMessage}>
              <i className="fas fa-exclamation-triangle" />
              {errorMessage}
            </span>
          )}
          <footer>
            <button className={styles.save} type="submit">
              Save
            </button>
            <button className={styles.cancel} onClick={resetNameModal}>
              Cancel
            </button>
          </footer>
        </form>
      )}
      <div className={styles.AdvancedFilterLeftSide}>
        <div>
          <GenericDropdownMenuMulti
            placeholder="Sector"
            selectedItems={selectedSectors}
            setSelectedItems={setSelectedSectors}
            options={sectors}
            selectId={'FilterSector_' + gridIndex}
            openRight={true}
            className={
              selectedSectors.length !== AllSectors.length
                ? styles.notDefault
                : ''
            }
          />
          <GenericDropdownMenuMulti
            placeholder="Maturity"
            selectedItems={selectedMaturities}
            options={MaturitiesValues}
            setSelectedItems={setSelectedMaturities}
            selectId={'FilterMaturity_' + gridIndex}
            openRight={true}
            className={
              selectedMaturities.length !== MaturitiesValues.length
                ? styles.notDefault
                : ''
            }
          />
          <DropdownRatings
            placeholder="Rating"
            selectedItems={selectedRatings}
            options={AllRatingValues}
            setSelectedItems={setSelectedRatings}
            selectId={'FilterRating_' + gridIndex}
            openRight={true}
            className={
              selectedRatings.length !== AllRatings.length
                ? styles.notDefault
                : ''
            }
          />
          <GenericDropdownMenuMulti
            placeholder="Currency"
            selectedItems={selectedCurrencies}
            options={CurrencyValues}
            setSelectedItems={setSelectedCurrencies}
            selectId={'FilterCurrency_' + gridIndex}
            openRight={true}
            className={
              selectedCurrencies.length !== CurrencyValues.length
                ? styles.notDefault
                : ''
            }
          />
          <GenericDropdownMenuMulti
            placeholder="Country"
            selectedItems={selectedCountries}
            options={Object.values(Countries)}
            setSelectedItems={setSelectedCountries}
            selectId={'FilterCountry_' + gridIndex}
            openRight={true}
            className={
              selectedCountries.length !== Object.keys(Countries).length
                ? styles.notDefault
                : ''
            }
          />
          <GenericDropdownMenuMulti
            placeholder="Security Type"
            selectedItems={selectedSecurityTypes}
            options={SecurityTypeValues}
            setSelectedItems={setSelectedSecurityTypes}
            selectId={'FilterSecurityType_' + gridIndex}
            openRight={true}
            className={
              selectedSecurityTypes.length !== SecurityTypeValues.length
                ? styles.notDefault
                : ''
            }
          />
          <GenericDropdownMenuMulti
            placeholder="Coupon Type"
            selectedItems={selectedCouponTypes}
            options={CouponTypeValues}
            setSelectedItems={setSelectedCouponTypes}
            selectId={'FilterCouponType_' + gridIndex}
            openRight={true}
            className={
              selectedCouponTypes.length !== CouponTypeValues.length
                ? styles.notDefault
                : ''
            }
          />
          <GenericDropdownMenu
            selectedItem={seriesVal}
            setSelectedItem={setSeriesVal}
            options={AllSeries}
            selectId={'AdvancedFilterSeries'}
            openRight={true}
            className={seriesVal !== 'All Series' ? styles.notDefault : ''}
            placeholder={'Series'}
          />
          <div
            style={{ marginRight: 0 }}
            className={cx(
              'pretty p-switch p-fill m-auto',
              myFirmIsChecked && styles.notDefault,
              styles.myFirmWrapper
            )}
          >
            <input
              type="checkbox"
              name="checkbox-myfirm"
              onChange={onCheckMyFirm}
              checked={myFirmIsChecked}
            />
            <div className="state p-default">
              <label>My Firm</label>
            </div>
          </div>
          <div
            style={{ marginRight: 0 }}
            className={cx(
              'pretty p-switch p-fill m-auto',
              myBookIsChecked && styles.notDefault,
              styles.myFirmWrapper
            )}
          >
            <input
              type="checkbox"
              name="checkbox-mybook"
              onChange={onCheckMyBook}
              checked={myBookIsChecked}
            />
            <div className="state p-default">
              <label>My Book</label>
            </div>
          </div>
          <div className={cx(useSize && styles.notDefault, styles.size)}>
            <SizeFilterAdvancedFilter
              gridIndex={gridIndex}
              useSize={useSize}
              size={size}
              setSize={setSizeCallback}
              setUseSize={setUseSizeCallback}
              selectedSize={selectedSize}
              setSelectedSize={setSelectedSizeCallback}
            />
          </div>
        </div>
        <div className={styles.priceYieldStacked}>
          <div className={cx(styles.yield, yieldVal && styles.notDefault)}>
            <label className={styles.priceYieldLbl}>Yield</label>
            <GenericDropdownMenu
              selectedItem={yieldGreaterLessThan}
              setSelectedItem={setYieldGreaterLessThan}
              options={GreaterOrLessThanStrings}
              selectId={'AdvancedFilterSizeGreaterLessThan'}
              openRight={true}
              className=""
            />
            <input
              value={yieldVal}
              onChange={yieldChanged}
              className={styles.input}
            />
          </div>

          <div className={cx(styles.priceYield, priceVal && styles.notDefault)}>
            <label className={styles.priceYieldLbl}>Price</label>
            <GenericDropdownMenu
              selectedItem={priceGreaterLessThan}
              setSelectedItem={setPriceGreaterLessThan}
              options={GreaterOrLessThanStrings}
              selectId={'AdvancedFilterPriceGreaterLessThan'}
              openRight={true}
              className=""
            />
            <input
              value={priceVal}
              onChange={priceChanged}
              className={styles.input}
            />
          </div>
          <div
            className={cx(
              styles.bidAskDiff,
              bidAskDiffVal && styles.notDefault
            )}
          >
            <label className={styles.priceYieldLbl}>Bid Ask Diff</label>
            <GenericDropdownMenu
              selectedItem={bidAskDiffType}
              setSelectedItem={setBidAskDiffType}
              options={BidAskDiffTypeStrings}
              selectId={'AdvancedFilterBidAskDiffType'}
              openRight={true}
              className=""
            />
            <GenericDropdownMenu
              selectedItem={bidAskDiffGreaterLessThan}
              setSelectedItem={setBidAskDiffGreaterLessThan}
              options={GreaterOrLessThanStrings}
              selectId={'AdvancedFilterBidAskDiffGreaterLessThan'}
              openRight={true}
              className=""
            />
            <input
              value={bidAskDiffVal}
              onChange={bidAskDiffChanged}
              className={styles.input}
            />
          </div>
        </div>
        <div className={styles.apply}>
          <div>
            <button
              data-testid="advancedFilter-apply"
              className={styles.save}
              onClick={onApplyClick}
            >
              Apply
            </button>
          </div>
          <div>
            <button
              data-testid="advancedFilter-apply"
              className={styles.reset}
              onClick={reset}
            >
              Reset
            </button>
          </div>
          <div>
            <button
              data-testid="advancedFilter-save"
              className={styles.save}
              onClick={saveCurrentViewName}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      {myOrdersOpen && <div className={styles.myOrdersOpen} />}
    </div>
  )
}

export default AdvancedFilterEditor
