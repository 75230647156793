import { Action } from 'redux'

export interface MarketClosedAction extends Action {
  type: 'market.marketClosed'
  payload: {
    closed: boolean
  }
}

export const marketClosed = (closed: boolean): MarketClosedAction => ({
  type: 'market.marketClosed',
  payload: { closed }
})

export type MarketAction = MarketClosedAction
