/* eslint-disable */

import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import React, { FC, useEffect, useState } from 'react'
import { CandlestickResult, QueryResult } from '../../store/admin/query/types'

// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
require('highcharts/modules/broken-axis')(Highcharts)
// tslint:enable
interface Props {
  queryResult: QueryResult
}

const HcCandlestick: FC<Props> = (props: Props) => {
  const queryResult = props.queryResult
  const [opts, setOpts] = useState<any>(null)
  const [chartData, setChartData] = useState<any>(null)

  useEffect(() => {
    if (queryResult.data.length) {
      const result = queryResult.data.map((d) => {
        const singleResult = d.result.map((r) => {
          const cr = r as CandlestickResult
          return [cr.x, cr.open, cr.high, cr.low, cr.close]
        })
        const type = 'candlestick'
        return {
          data: singleResult,
          type
        }
      })
      setChartData(result)
    } else {
      setChartData(null)
    }
  }, [queryResult])

  useEffect(() => {
    const test = {
      title: {
        text: queryResult.queryTitle
      },
      xAxis: {
        type: 'datetime',
        breaks: queryResult.breaks
      },
      yAxis: {
        opposite: false
      },
      series: chartData,
      time: {
        useUTC: false
      },
      plotOptions: {
        series: {
          groupPadding: 0.35
        }
      },
      rangeSelector: {
        enabled: false
      }
    }
    setOpts(test)
  }, [chartData])

  return chartData ? (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={opts}
      />
    </div>
  ) : (
    <div></div>
  )
}
export default HcCandlestick
