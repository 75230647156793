import cx from 'classnames'
import React, { useCallback, useEffect, useRef } from 'react'
import { OrderRowInfo } from '../../store/upload/types'
import styles from './uploadDropDownMenu.scss'
import UploadTableRow from './UploadTableRow'

interface Props {
  addOrUpdateRows: (newRow: OrderRowInfo) => void
  pasteRows: (index: number, event: React.ClipboardEvent) => void
  rows: OrderRowInfo[]
  dropdownState: string
  validations: any
  gridIndex?: number
  rowToFocus?: number
}

const UploadTable: React.FC<Props> = ({
  rows,
  addOrUpdateRows,
  pasteRows,
  dropdownState,
  validations,
  gridIndex,
  rowToFocus = -1
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const displayTableRows = useCallback(() => {
    return dropdownState === 'invalidUpload'
      ? validations.map((rowInfo: any, index: number) => (
          <UploadTableRow
            key={index}
            index={index}
            rowInfo={rowInfo}
            dropdownState={dropdownState}
          />
        ))
      : rows.map((rowInfo, index) => {
          const r = rowToFocus === index ? ref : undefined
          return (
            <UploadTableRow
              key={index}
              index={index}
              addOrUpdateRows={addOrUpdateRows}
              rowInfo={rowInfo}
              pasteRows={pasteRows}
              dropdownState={dropdownState}
              gridIndex={gridIndex}
              ref={r}
            />
          )
        })
  }, [rows, validations, dropdownState, rowToFocus])
  useEffect(() => {
    if (rowToFocus < 0) return
    ref.current?.focus()
  }, [ref.current, rowToFocus])
  return (
    <div
      id="upload-orders"
      className={cx(
        styles.uploadTable,
        dropdownState === 'invalidUpload',
        styles.uploadTableInvalid
      )}
    >
      {dropdownState === 'invalidUpload' && (
        <div className={styles.invalidUploadError}>
          Error: The following markets were not added
        </div>
      )}
      <table className={styles.uploadTableHeader}>
        {dropdownState !== 'invalidUpload' && (
          <thead>
            <tr>
              <th className={cx(styles.topLabel, styles.tickerHeader)}>
                Ticker / ISIN / CUSIP
              </th>
              <th className={styles.topLabel} colSpan={3}>
                Bid <span>(optional)</span>
              </th>
              <th className={cx(styles.topLabel, styles.noBorder)} colSpan={3}>
                Ofr <span>(optional)</span>
              </th>
            </tr>
            <tr>
              <th className={styles.rowLabel}></th>
              <th className={styles.rowLabel}>Amt</th>
              <th className={styles.rowLabel}>Price</th>
              <th className={styles.rowLabel}>Spread</th>
              <th className={styles.rowLabel}>Spread</th>
              <th className={styles.rowLabel}>Price</th>
              <th className={cx(styles.rowLabel, styles.noBorder)}>Amt</th>
            </tr>
          </thead>
        )}
        <tbody>{displayTableRows()}</tbody>
      </table>
    </div>
  )
}

export default UploadTable
