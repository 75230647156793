import { datesAreOnSameDay } from '../../helpers/utils'
import { createStagedOrdersFromSecuritySearch } from '../searchSecurities/helpers'
import { StagedOrder } from '../stagedOrders/types'
import { OrderRowInfo, OrdersWithSecurityIds } from './types'

export const getListOfStagedOrdersIfSameDay = (
  listOFDaysSelected: Date[],
  listOfMyOrders: UploadOrderByDatePicker[],
  watchlistSecurities: number[] | undefined
) => {
  const stagedOrders: StagedOrder[] = []

  listOFDaysSelected.forEach((daySelected: Date) => {
    let filteredOrders = listOfMyOrders.filter((myOrder) =>
      datesAreOnSameDay(myOrder.submitTime, daySelected)
    )

    if (watchlistSecurities && watchlistSecurities?.length > 0) {
      filteredOrders = filteredOrders.filter((myOrder) =>
        watchlistSecurities.includes(myOrder.securityId)
      )
    }

    if (filteredOrders) {
      filteredOrders.forEach((myOrder) => {
        if (myOrder) {
          stagedOrders.push({
            orderType: myOrder.orderType,
            price: myOrder.price,
            spread: myOrder.spread,
            securityId: myOrder.securityId,
            size: myOrder.size,
            isSpreadOrder: false,
            allOrNone: myOrder.allOrNone,
            individualMin: myOrder.individualMin,
            custId: 0,
            tob: myOrder.tob
          })
        }
      })
    }
  })
  return stagedOrders
}

export interface UploadOrderByDatePicker extends StagedOrder {
  submitTime: Date
}

export const areLevelsEmpty = (orderInfo: Omit<OrderRowInfo, 'identifier'>) =>
  orderInfo.bidAmt === '' &&
  orderInfo.bidPrice === '' &&
  orderInfo.bidSprd === '' &&
  orderInfo.ofrAmt === '' &&
  orderInfo.ofrPrice === '' &&
  orderInfo.ofrSprd === ''

export const reduceRowsToRemoveEmptyRows = (orders: OrderRowInfo[]) => {
  let whereToSlice: number = orders.length

  orders.forEach((order, i) => {
    if (order.identifier !== '' || !areLevelsEmpty(order)) {
      whereToSlice = i + 1
    }
  })

  // return empty arr if there are no identifiers
  return orders.length === whereToSlice
    ? []
    : [...orders.slice(0, whereToSlice)]
}

export const isRowEmpty = (orderInfo: OrderRowInfo) =>
  orderInfo.identifier === '' &&
  orderInfo.bidAmt === '' &&
  orderInfo.bidPrice === '' &&
  orderInfo.bidSprd === '' &&
  orderInfo.ofrAmt === '' &&
  orderInfo.ofrPrice === '' &&
  orderInfo.ofrSprd === ''

export const getFailures = (ordersWithSecurityIds: OrdersWithSecurityIds) =>
  ordersWithSecurityIds
    .filter(
      ({ securityIds, orderInfo }) =>
        (securityIds.length === 0 && !isRowEmpty(orderInfo)) ||
        (securityIds.length > 1 && !areLevelsEmpty(orderInfo))
    )
    .reduce(
      (acc, data, i) => [
        ...acc,
        {
          index: data.orderInfo.index,
          identifier: data.orderInfo.identifier,
          orderRowInfo: data.orderInfo,
          errorIndex: i
        }
      ],
      []
    )

export const getOrdersToStage = (
  ordersWithSecurityIds: OrdersWithSecurityIds,
  bookId: number
) =>
  ordersWithSecurityIds
    .reduce(
      (acc, { securityIds, orderInfo }) =>
        !areLevelsEmpty(orderInfo)
          ? [...acc, { securityIds, orderInfo }]
          : [...acc],
      []
    )
    .map(({ securityIds, orderInfo }) =>
      createStagedOrdersFromSecuritySearch(securityIds, orderInfo, bookId)
    )
    .reduce((acc, stagedOrders) => [...acc, ...stagedOrders], [])

export const getSecuritiesToAdd = (
  ordersWithSecurityIds: OrdersWithSecurityIds
) =>
  ordersWithSecurityIds
    .filter(
      ({ securityIds, orderInfo }) =>
        (securityIds.length > 0 && areLevelsEmpty(orderInfo)) ||
        securityIds.length === 1
    )
    .reduce((acc, { securityIds }) => [...acc, ...securityIds], [])

export const getDateWithoutWeekend = (numberOfDays: number) => {
  const result: Date[] = []
  const date = new Date()

  while (result.length < numberOfDays) {
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      const currentDate = new Date(date)
      result.push(currentDate)
    }
    date.setDate(date.getDate() - 1)
  }

  return result
}
