import cx from 'classnames'
import React, { ChangeEvent, ComponentType, useCallback } from 'react'
import {noop} from 'rxjs'
import Checked from './checked'
import Normal from './normal'

import styles from './index.scss'

export interface Props {
  onChange?: (e: React.ChangeEvent) => void
  locator: string
  fillColor?: string
  fillColorHover?: string
  checked?: boolean
  disabled?: boolean
  name: string
  value: string
}
export interface RadioProps {
  className: string
  fillColor: string
}

const Radio: React.FC<Props> = ({
  children,
  locator,
  /* eslint-disable no-empty-function */
  onChange = noop,
  fillColor = '#43A980',
  fillColorHover,
  name,
  value,
  disabled = false,
  checked = false
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!disabled) {
        onChange(event)
      }
    },
    [disabled, onChange]
  )

  const createCheckbox = (Component: ComponentType<RadioProps>) => (
    <>
      <Component className={styles.checked} fillColor={fillColor} />
      <Component
        className={styles.checkedHover}
        fillColor={fillColorHover || fillColor}
      />
    </>
  )

  return (
    <span className={cx(styles.wrapper, disabled && styles.disabled)}>
      <label className={styles.label} data-testid={locator}>
        <span className={styles.boxContainer}>
          <input
            type="radio"
            className={styles.checkbox}
            name={name}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            checked={checked}
          />
          <Normal />
          {checked && createCheckbox(Checked)}
        </span>
        <span className={styles.text}>{children}</span>
      </label>
    </span>
  )
}

export default Radio
