import { combineEpics, Epic, ofType } from 'redux-observable'
import { map, mergeMap } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { setWebSettings } from './actions'

const getWebSettingsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('settings.handleWindowSettings'),
    mergeMap(() => {
      return getHub().invoke('GetWebSettings').pipe(map(setWebSettings))
    })
  )

export default combineEpics(getWebSettingsEpic)
