import React from 'react'
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './loading.scss'

const Loading = () => (
  <div className={styles.loading}>
    <FontAwesomeIcon icon={faSpinner} spin /> Loading...
  </div>
)

export default Loading
