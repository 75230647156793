import React, { FC, useState } from 'react'

import styles from './expandableComponent.scss'

interface Props {
  title: (
    isExpanded: boolean,
    setExpanded: (expanded: boolean) => void
  ) => JSX.Element
  isInitiallyExpanded?: boolean
}

const ExpandableComponent: FC<Props> = props => {
  const { title, isInitiallyExpanded = true, children } = props
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded)
  const [isFirstCall, setFirstCall] = useState(true)
  let classNameContent
  if (isFirstCall) {
    classNameContent = isInitiallyExpanded
      ? styles.expandable
      : styles.collapsed
  } else {
    classNameContent = isExpanded ? styles.expandable : styles.collapsed
  }

  return (
    <div
      className={styles.expandableComponents}
      data-testid="expandable-content"
    >
      {title(isExpanded, expanded => {
        setFirstCall(false)
        setIsExpanded(expanded)
      })}
      <div data-testid="child-container" className={`${styles.expandContent} ${classNameContent}`}>
        {children && children}
      </div>
    </div>
  )
}

export default ExpandableComponent
