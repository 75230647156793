import { createSelector } from 'reselect'
import { namespace } from '.'
import { CombineOrderAndStaticData } from './helpers'
import { SecurityOrderData, SecurityStaticData, State } from './reducer'

type T = typeof namespace

type HasNsKey = {
  [key in T]: State
}
export const getState = <H extends HasNsKey>(state: H) => state[namespace]

export const getGridData = createSelector(
  [getState],
  (state) => (gridIndex: number) =>
    state.gridData[gridIndex] || {
      currentPage: undefined,
      pages: {},
      pending: false,
      error: false
    }
)

export const isPending = createSelector(
  [getGridData],
  (gridData) => (gridIndex: number) => gridData(gridIndex).pending
)

export const hasError = createSelector(
  [getGridData],
  (gridData) => (gridIndex: number) => gridData(gridIndex).error
)

const getSecuritiesOrderDataInfoCache = createSelector(
  [getState],
  (state) => state.securitiesOrderDataInfoCache
)

const getSecuritiesStaticDataInfoCache = createSelector(
  [getState],
  (state) => state.securitiesStaticDataInfoCache
)

export const getSecurityOrderDataById = createSelector(
  [getSecuritiesOrderDataInfoCache],
  (securitiesInfoCache) => (securityId: number) =>
    securitiesInfoCache[securityId] as SecurityOrderData | undefined
)

export const getSecurityStaticDataById = createSelector(
  [getSecuritiesStaticDataInfoCache],
  (securitiesInfoCache) => (securityId: number) =>
    securitiesInfoCache[securityId] as SecurityStaticData | undefined
)

export const getCompleteSecurityById = createSelector(
  [getSecuritiesOrderDataInfoCache, getSecuritiesStaticDataInfoCache],
  (securitiesOrderDataInfoCache, securitiesStaticDataInfoCache) =>
    (securityId: number) =>
      CombineOrderAndStaticData(
        securitiesOrderDataInfoCache[securityId],
        securitiesStaticDataInfoCache[securityId]
      )
)

export const getGridCount = createSelector(
  [getState],
  (state) => () => Object.keys(state.gridDefinitions).length
)

export const getWatchlistId = createSelector(
  [getState],
  (state) => (gridIndex: number) =>
    state.gridDefinitions[gridIndex]?.watchlistId
)

export const getIssuerFilter = createSelector(
  [getState],
  (state) => (gridIndex: number) =>
    state.gridDefinitions[gridIndex]?.issuerFilter
)

export const getQueryFilter = createSelector(
  [getState],
  (state) => (gridIndex: number) =>
    state.gridDefinitions[gridIndex]?.queryFilter
)

export const getAdvancedFilter = createSelector(
  [getState],
  (state) => (gridIndex: number) => {
    return state.gridDefinitions[gridIndex]?.filter
  }
)

export const getSecuritiesFilter = createSelector(
  [getState],
  (state) => (gridIndex: number) =>
    state.gridDefinitions[gridIndex]?.securitiesFilter
)

export const getIsMine = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.gridDefinitions[gridIndex]?.isMine
)

export const getShowLive = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.gridDefinitions[gridIndex]?.showLive
)

export const getMyFirm = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.gridDefinitions[gridIndex]?.myFirm
)

export const getMyBook = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.gridDefinitions[gridIndex]?.myBook
)

export const getUseSize = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.gridDefinitions[gridIndex]?.useSize
)

export const getUseAdvancedFilter = createSelector(
  [getState],
  (state) => (gridIndex: number) =>
    state.gridDefinitions[gridIndex]?.useAdvancedFilter
)

export const getSize = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.gridDefinitions[gridIndex]?.size
)

export const getQuoteReliability = createSelector(
  [getState],
  (state) => (gridIndex: number) =>
    state.gridDefinitions[gridIndex]?.quoteReliability
)

export const getCurrentPage = createSelector(
  [getGridData],
  (gridData) => (gridIndex: number) => gridData(gridIndex).currentPage
)

export const isPageLoaded = createSelector(
  [getGridData],
  (gridData) => (gridIndex: number, page: number) =>
    gridData(gridIndex).pages[page]?.status === 'loaded'
)

const getPages = createSelector(
  [getGridData],
  (gridData) => (gridIndex: number) => gridData(gridIndex).pages
)

export const getSortToTop = createSelector(
  [getState],
  (state) => (gridIndex: number) =>
    state.gridDefinitions[gridIndex]?.sortToTop ?? []
)

export const getDisplayedSecurityIds = createSelector(
  [getPages, isPageLoaded],
  (getPagesForGrid, pageIsLoaded) => (gridIndex: number) => {
    return Object.entries(getPagesForGrid(gridIndex))
      .filter(([page]) => pageIsLoaded(gridIndex, Number(page)))
      .map(([, { securityIds }]) => securityIds)
      .reduce((acc, securityId) => [...acc, ...securityId], [])
  }
)

export const getSecuritiesForPage = createSelector(
  [
    getPages,
    isPageLoaded,
    getSecuritiesOrderDataInfoCache,
    getSecuritiesStaticDataInfoCache
  ],
  (getPagesForGrid, pageIsLoaded, cache, staticCache) =>
    (gridIndex: number, page: number) =>
      pageIsLoaded(gridIndex, page)
        ? getPagesForGrid(gridIndex)[page].securityIds.map((securityId) =>
            CombineOrderAndStaticData(
              cache[securityId],
              staticCache[securityId]
            )
          )
        : []
)

export const getGridIndices = createSelector([getState], (state) =>
  Object.keys(state.gridDefinitions)
    .map((gridIndex) => Number(gridIndex))
    .sort(
      (i1, i2) =>
        state.gridDefinitions[i1].order - state.gridDefinitions[i2].order
    )
)

export const getGridIndicesWithWatchlist = createSelector(
  [getState],
  (state) => (watchlistId: number) =>
    Object.keys(state.gridDefinitions)
      .map((gridIndex) => Number(gridIndex))
      .filter(
        (gridIndex) =>
          state.gridDefinitions[gridIndex].watchlistId === watchlistId
      )
)

export const getSecuritiesForAllPages = createSelector(
  [getPages],
  (getPagesForGrid) => (gridIndex: number) => {
    return Object.entries(getPagesForGrid(gridIndex))
      .map(([, { securityIds }]) => securityIds)
      .reduce((acc, securityId) => [...acc, ...securityId], [])
  }
)
