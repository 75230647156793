import cx from 'classnames'

import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetRowIdFunc,
  GridApi
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../../../app/openFinContext'
import { aggressAttemptFetch } from '../../../../store/admin/aggressAttempt/actions'
import {
  getAggressAttemptError,
  getAggressAttempts
} from '../../../../store/admin/aggressAttempt/selectors'
import { AggressAttempt } from '../../../../store/admin/aggressAttempt/types'
import { aggressAttemptsColumnModelChanged } from '../../../../store/settings/actions'
import { getAggressAttemptsColumnOrder } from '../../../../store/settings/selectors'
import Checkbox from '../../../Checkbox'
import { applyColumnsOrder } from '../../helpers'
import styles from '../AggressAttempts.scss'
import { columnDefinitions } from './columnDefs'

import { selectSecurity } from '../../../../store/depthOfMarket/actions'
import { getQuoteReliability } from '../../../../store/securities/selectors'

export const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  resizable: true,
  suppressMovable: false,
  wrapText: true,
  menuTabs: ['columnsMenuTab' as const],
  suppressMenu: false
}

const finDefaultColumns = {
  ...defaultColumnDefinitions,
  resizable: true,
  suppressMenu: true
}
export interface Props {
  setSelectedTab?: (st: string) => void
}

const getAggressAttemptsRowId: GetRowIdFunc<AggressAttempt> = ({ data }) =>
  `${data.id}`
const AggressAttemptsGrid: FC<Props> = ({ setSelectedTab }) => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const aggressAttempts = useSelector(getAggressAttempts)
  const aggressAttemptsError = useSelector(getAggressAttemptError)
  const columnsOrder = useSelector(getAggressAttemptsColumnOrder)
  const quoteReliability = useSelector(getQuoteReliability)(0)

  const [traded, setTraded] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(true)

  useEffect(() => {
    dispatch(aggressAttemptFetch())
  }, [])

  const [columnDefs, setColumnDefs] = useState<
    ColDef[] | ColGroupDef[] | undefined
  >(undefined)

  //
  // Set Initial Columns Def
  useEffect(() => {
    setColumnDefs(applyColumnsOrder(columnsOrder, columnDefinitions))
  }, [columnsOrder?.join(',')])

  const [dataInitialized, setDataInitialized] = useState(false)

  const onDataInitialized = () => {
    setDataInitialized(true)
  }

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const sortModel = [{ colId: 'takerOrderId', sort: 'desc' } as const]
        columnApi.applyColumnState({ state: sortModel })
      }
    },
    []
  )

  const handleColumnChange = useCallback(() => {
    if (dataInitialized) {
      const displayed = gridApi?.columnApi
        .getAllDisplayedColumns()
        .map((col) => {
          return col.getColId()
        })
      if (displayed) {
        dispatch(aggressAttemptsColumnModelChanged(displayed))
      }
    }
  }, [dataInitialized])

  if (aggressAttemptsError) {
    return <div>{aggressAttemptsError}</div>
  }

  const rowStyle = (params: any) => {
    if (params.data.status === 'Pending') {
      return { backgroundColor: 'yellow' }
    } else if (params.data.status.indexOf('Rejected') > -1) {
      return { backgroundColor: '#98DBFF' }
    } else if (
      params.data.status.indexOf('Timed Out') > -1 ||
      params.data.status.indexOf('Expired') > -1
    ) {
      return { backgroundColor: '#fdf43a' }
    } else if (
      params.data.status.indexOf('price off market') > -1 ||
      params.data.didNotMatchReason.indexOf('passive=') > -1
    ) {
      return { backgroundColor: '#ff8900' }
    } else if (params.data.status !== 'Traded') {
      return { backgroundColor: '#ff8080' }
    } else if (params.data.status === 'Traded') {
      return { backgroundColor: '#e5e5e5' }
    }
  }

  useEffect(() => {
    if (!aggressAttempts) {
      return
    }
    if (traded && failed) {
      gridApi?.api.setRowData(aggressAttempts)
    } else if (traded) {
      gridApi?.api.setRowData(
        aggressAttempts.filter((d) => d.status === 'Traded')
      )
    } else if (failed) {
      gridApi?.api.setRowData(
        aggressAttempts.filter((d) => d.status !== 'Traded')
      )
    } else {
      gridApi?.api.setRowData([])
    }
  }, [traded, failed, aggressAttempts])

  const onDoubleClick = (row: any) => {
    dispatch(selectSecurity(0, row.data.securityId, quoteReliability))
    setSelectedTab!('Watchlist')
  }

  return (
    <>
      <div
        className={fin ? styles.finAttemptsContainer : styles.attemptsContainer}
      >
        <div className={cx(styles.checkboxes, fin && styles.finCheckboxes)}>
          <Checkbox
            locator={`aggressAttempt-traded`}
            checked={traded}
            onChange={() => {
              setTraded(!traded)
            }}
            disabled={false}
          >
            Traded
          </Checkbox>
          <Checkbox
            locator={`aggressAttempt-failed`}
            checked={failed}
            onChange={() => {
              setFailed(!failed)
            }}
            disabled={false}
          >
            Failed
          </Checkbox>
        </div>
        <div className="ag-theme-balham aggress-attempts">
          <AgGridReact<AggressAttempt>
            columnDefs={columnDefs}
            overlayNoRowsTemplate="No aggress attempts found."
            overlayLoadingTemplate="Loading Aggress Attempts…"
            maintainColumnOrder={true}
            onColumnMoved={handleColumnChange}
            onDisplayedColumnsChanged={handleColumnChange}
            defaultColDef={fin ? finDefaultColumns : defaultColumnDefinitions}
            onFirstDataRendered={onDataInitialized}
            onGridReady={onGridReady}
            suppressDragLeaveHidesColumns={true}
            onRowDoubleClicked={onDoubleClick}
            rowClass="aggress-attempts-grid-row"
            getRowId={getAggressAttemptsRowId}
            getRowStyle={rowStyle}
            rowHeight={90}
            scrollbarWidth={14}
          />
        </div>
      </div>
    </>
  )
}

export default AggressAttemptsGrid
