import React, { FC, MouseEvent, useCallback } from 'react'
import {useIsInPopup} from "../../containers/popoutContext";
import { highlight } from '../../helpers/formatting'
import styles from './SecuritySearchResultIssuer.scss'

export interface Props {
  issuerSymbol: string
  numberOfSecurities: number
  search: string
  actionLabel: string
  actionOnClick: (issuerSymbol: string) => void
  isDomPopout?: boolean
}

const SecuritySearchResultIssuer: FC<Props> = ({
  issuerSymbol,
  numberOfSecurities,
  search,
  actionLabel,
  actionOnClick,
  isDomPopout
}) => {
  const handleOnShowAllClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      actionOnClick(issuerSymbol)
    },
    [actionOnClick]
  )

  return (
    <div className={styles.issuer} data-testid={`security-search-result-issuer-${issuerSymbol}`}>
      <span>
        {highlight(search, issuerSymbol)} ({numberOfSecurities})
      </span>
      {!isDomPopout && (
        <button className={styles.showAllButton} onClick={handleOnShowAllClick}>
          {actionLabel}
        </button>
      )}
    </div>
  )
}

const PopupAwareSecurityResultIssuer: FC<Props> = (props) => {
  // use value from the provider if not provided on props
  const {isInPopup} = useIsInPopup()
  return <SecuritySearchResultIssuer {...props} isDomPopout={props.isDomPopout ?? isInPopup} />
}
export default PopupAwareSecurityResultIssuer
