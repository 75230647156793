import { BenchmarkDataAction } from './actions'
import BenchmarkData from './types'

export interface State {
  benchmarkData: BenchmarkData | undefined
  error: boolean
}

export const initialState: State = {
  benchmarkData: undefined,
  error: false
}

export default (state = initialState, action: BenchmarkDataAction): State => {
  switch (action.type) {
    case 'benchmark.getBenchmarkData':
      return { ...state, error: false }
    case 'benchmark.getBenchmarkDataSuccess':
      return {
        ...state,
        benchmarkData: action.payload,
        error: false
      }
    case 'benchmark.getBenchmarkDataFailure':
      return { ...state, error: true }
    default:
      return state
  }
}
