import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useOpenFin } from '../../../app/openFinContext'
import { getAdminWatchlists } from '../../../store/watchList/selectors'
import { WatchList } from '../../../store/watchList/types'
import Grid from './Grid/Grid'
import WatchlistEditor from './Editors/WatchlistEditor'

import styles from './WatchlistManager.scss'
import openfinStyles from '../../../containers/Openfin/Admin/openfinAdmin.scss'

import {
  deleteWatchlist,
  fetchAdminWatchListsAction
} from '../../../store/watchList/actions'
import WatchListManagerHeader from './WatchlistManagerHeader'

export type Resettable = {
  reset: () => void
}

const WatchlistManager = () => {
  const [selectedWatchlist, setSelectedWatchlist] = useState<
    WatchList | undefined
  >()
  const [version, setVersion] = useState(0)
  const { fin } = useOpenFin()
  const containerStyle = fin
    ? openfinStyles.finWrapper
    : styles.watchlistAdminContainer

  const dispatch = useDispatch()
  const watchlists = useSelector(getAdminWatchlists)

  const resetHandle = useRef<Resettable>(null)

  const deleteSelectedWatchlist = useCallback(() => {
    if (selectedWatchlist?.id && selectedWatchlist.canEdit) {
      dispatch(deleteWatchlist(selectedWatchlist.id))
    }
  }, [selectedWatchlist])

  const onSearch = useCallback(() => {
    resetHandle.current?.reset()
  }, [resetHandle.current])

  useEffect(() => {
    dispatch(fetchAdminWatchListsAction())
  }, [])

  useEffect(
    () => {
      setVersion((v) => ++v)
      const updatedWatchlist = watchlists?.find(
        (watchlist) => watchlist.id === selectedWatchlist?.id
      )
      if (watchlists && selectedWatchlist && !updatedWatchlist) {
        setSelectedWatchlist(watchlists[0])
      }
      if (updatedWatchlist && updatedWatchlist !== selectedWatchlist) {
        setSelectedWatchlist(updatedWatchlist)
      }
    },
    /*  We deliberately only want to update this when watchlists changes to
        force a redraw of the editor and to reset selectedWatchList if the old/stale
        watchlist has been deleted or replaced.
    */
    [watchlists]
  )

  return (
    <div className={containerStyle}>
      <div className={styles.content}>
        <WatchListManagerHeader onSearch={onSearch} />
        <Grid
          watchlists={watchlists}
          selectWatchlist={setSelectedWatchlist}
          selectedWatchlist={selectedWatchlist}
          deleteSelectedWatchlist={deleteSelectedWatchlist}
          ref={resetHandle}
        />
        <WatchlistEditor
          watchlist={selectedWatchlist}
          deleteSelectedWatchlist={deleteSelectedWatchlist}
          key={`wle-${selectedWatchlist?.id}-${version}`}
        />
      </div>
    </div>
  )
}

export default WatchlistManager
