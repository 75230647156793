import React, { FC } from 'react'

interface Props {
  isExpanded: boolean
}

const ExpandableComponentIcon: FC<Props> = ({ isExpanded }) => {
  const icnClass = !isExpanded ? 'fa-angle-double-down' : 'fa-angle-double-up'
  return <i className={`fas ${icnClass}`} />
}

export default ExpandableComponentIcon
