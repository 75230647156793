import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import copy from '../../assets/images/copy.svg'
import Button from '../Button/Button'
import ButtonWithFeedback from '../ButtonWithFeedback/ButtonWithFeedback'
import styles from './uploadDropDownMenu.scss'

interface Props {
  clearAllRows: () => void
  errorMessage: string
  dropdownState: string
  toggleNewWatchlist: () => void
  cancel: () => void
  copyData: () => void
  title: string
}

const UploadMenuHeader: React.FC<Props> = ({
  clearAllRows,
  errorMessage,
  dropdownState,
  toggleNewWatchlist,
  cancel,
  copyData,
  title
}) => {
  return (
    <div className={styles.uploadMenuHeader}>
      <div className={styles.uploadMenuHeaderLeft}>
        {dropdownState === 'upload' && (
          <span>
            <Button onClick={toggleNewWatchlist}>Create new watchlist</Button>
          </span>
        )}
        {dropdownState === 'newWatchlist' && (
          <span>Enter Information Below</span>
        )}
        {dropdownState === 'invalidUpload' && (
          <span className={styles.uploadMenuHeaderInvalid}>
            Unable to Submit Orders
          </span>
        )}
      </div>
      {errorMessage !== '' && (
        <div className={styles.errorMessage} data-testid="manualUploadError">
          {errorMessage}
        </div>
      )}
      <div className={styles.uploadMenuHeaderRight}>
        {dropdownState !== 'invalidUpload' && (
          <span className={styles.uploadMenuClear} onClick={clearAllRows}>
            Clear All
          </span>
        )}
        {dropdownState === 'invalidUpload' ? (
          <span style={{ marginRight: '5px', cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faTimes} onClick={cancel} />
          </span>
        ) : (
          <ButtonWithFeedback
            contentUpdate="COPIED"
            onClick={copyData}
            timerToReturnToFirstState={3000}
            title={title}
          >
            <img src={copy} alt="copy icon" />
          </ButtonWithFeedback>
        )}
      </div>
    </div>
  )
}

export default UploadMenuHeader
