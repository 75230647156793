import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import {
  clearPassiveOrders,
  fetchPassiveOrders,
  setViewAllPassiveOrdersAction
} from '../../store/passiveOrders/actions'
import { getViewAllPassiveOrders } from '../../store/passiveOrders/selectors'
import { getIsAdmin } from '../../store/webSettings/selectors'
import Checkbox from '../Checkbox'
import PassiveOrdersGrid from './Grid/PassiveOrdersGrid'
import styles from './PassiveOrders.scss'

const PassiveOrders: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const admin = useSelector(getIsAdmin)
  useEffect(() => {
    dispatch(fetchPassiveOrders())
  }, [])

  const viewAll = useSelector(getViewAllPassiveOrders)

  const checkChanged = () => {
    dispatch(setViewAllPassiveOrdersAction(!viewAll))
    dispatch(clearPassiveOrders())
  }

  return (
    <div className={fin ? styles.finWrapper : styles.wrapper}>
      {admin && (
        <div className={styles.topMenu}>
          <Checkbox
            checkboxClass={styles.cb}
            locator={`test`}
            checked={viewAll}
            onChange={checkChanged}
            disabled={false}
          >
            Show all orders from users I have access to see
          </Checkbox>
        </div>
      )}
      <PassiveOrdersGrid />
    </div>
  )
}

export default PassiveOrders
