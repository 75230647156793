import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import { getExternalOrderStatsPending } from '../../../store/admin/externalOrderStats/selectors'
import {
  clearExternalLiquidityStats,
  externalLiquidityStatsFetch
} from '../../../store/admin/externalLiquidityStats/actions'
import { getVenues } from '../../../store/admin/venues/selectors'
import QuerySearchBondsInput from '../../Query/QuerySecuritySearch/SearchBondsInput'
import { venueFetch } from '../../../store/admin/venues/actions'
import styles from '../HistoricalUserData/HistoricalData.scss'
import Select from 'react-select'
import ExternalLiquidityStatsGrid from './Grid/ExternalLiquidityStatsGrid'

const ExternalLiquidityStats: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const pending = useSelector(getExternalOrderStatsPending)

  const venues = useSelector(getVenues)

  const [venueOptionsState, setVenueOptionsState] = useState<
    Array<{ label: string; value: string }>
  >([])

  const [venuesState, setVenuesState] = useState<
    Array<{ label: string; value: string }>
  >([])

  useEffect(() => {
    dispatch(venueFetch())
  }, [])

  useEffect(() => {
    const options: Array<{ label: string; value: string }> = []
    if (venues) {
      for (const u of venues) {
        options.push({ label: u.name, value: u.id.toString() })
      }
    }
    setVenueOptionsState(options)
  }, [venues])
  const runQuery = () => {
    const ids = venuesState.map((v) => Number(v.value))
    dispatch(clearExternalLiquidityStats())
    dispatch(externalLiquidityStatsFetch(ids, search))
  }

  return (
    <div className={cx(fin ? styles.finDataWrapper : styles.tickerWrapper)}>
      <div>
        <Select
          className={styles.Select}
          options={venueOptionsState}
          isMulti
          isClearable={true}
          value={venuesState}
          onChange={(event) => {
            if (event) {
              const selVenues = []
              for (const e of event) {
                selVenues.push(e)
              }
              setVenuesState(selVenues)
            }
          }}
        />

        <QuerySearchBondsInput search={search} setSearch={setSearch} />
        <button
          className={cx(styles.btn, pending && styles.disabled)}
          onClick={runQuery}
          disabled={pending}
        >
          Refresh
        </button>
        <span className={styles.liquidityTitle}>
          External Liquidity Statistics
        </span>
      </div>
      <ExternalLiquidityStatsGrid />
    </div>
  )
}

export default ExternalLiquidityStats
