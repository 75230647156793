import { Action } from 'redux'
import { OrderFields } from '../../helpers/hooks/useManageMyOrders'
import { CheckedOrder } from '../checkedOrders/types'
import { OrderType } from '../order/types'
import { Security } from '../securities/reducer'
import { StagedOrder } from './types'

export interface AddOrUpdateStagedOrdersAction extends Action {
  type: 'stagedOrders.addOrUpdateStagedOrders'
  payload: {
    stagedOrders: StagedOrder[]
    cancelLiveOrders: boolean
  }
}

export const addOrUpdateStagedOrders = (
  stagedOrders: StagedOrder[],
  cancelLiveOrders = false
): AddOrUpdateStagedOrdersAction => ({
  type: 'stagedOrders.addOrUpdateStagedOrders',
  payload: { stagedOrders, cancelLiveOrders }
})

export interface RemoveStagedOrderAction extends Action {
  type: 'stagedOrders.removeStagedOrder'
  payload: { securityId: Security['id']; orderType: OrderType }
}

export const removeStagedOrder = (stagedOrderSecurityIdAndType: {
  securityId: Security['id']
  orderType: OrderType
}): RemoveStagedOrderAction => ({
  type: 'stagedOrders.removeStagedOrder',
  payload: stagedOrderSecurityIdAndType
})

export interface RemoveStagedOrdersAction extends Action {
  type: 'stagedOrders.removeStagedOrders'
  payload: CheckedOrder[]
}

export const removeStagedOrders = (
  stagedOrderSecurityIdAndType: CheckedOrder[]
): RemoveStagedOrdersAction => ({
  type: 'stagedOrders.removeStagedOrders',
  payload: stagedOrderSecurityIdAndType
})

export interface ClearCheckedStagedOrdersAction extends Action {
  type: 'stagedOrders.clearCheckedStagedOrdersAction'
}

export const clearCheckedStagedOrders = (): ClearCheckedStagedOrdersAction => ({
  type: 'stagedOrders.clearCheckedStagedOrdersAction'
})

export interface SetFocusOnOrderAction extends Action {
  type: 'stagedOrders.setFocusOnOrder'
  payload: { securityId: Security['id']; orderType: OrderType } | undefined
}

export const setFocusOnOrder = (
  order:
    | {
        securityId: Security['id']
        orderType: OrderType
      }
    | undefined
): SetFocusOnOrderAction => ({
  type: 'stagedOrders.setFocusOnOrder',
  payload: order
})

export interface SetTempOrderFieldAction extends Action {
  type: 'stagedOrders.setTempOrderFieldValue'
  securityId: Security['id']
  orderType: OrderType
  field: OrderFields
  payload: string
}

export const setTempOrderFieldValue = (
  securityId: Security['id'],
  orderType: OrderType,
  field: OrderFields,
  tempValue: string
) =>
  ({
    type: 'stagedOrders.setTempOrderFieldValue',
    securityId,
    orderType,
    field,
    payload: tempValue
  } as const)

export type StagedOrdersAction =
  | AddOrUpdateStagedOrdersAction
  | RemoveStagedOrderAction
  | RemoveStagedOrdersAction
  | ClearCheckedStagedOrdersAction
  | SetFocusOnOrderAction
  | SetTempOrderFieldAction
