import { ColDef } from '@ag-grid-community/core'
import { dateFormatter } from '../../../../helpers/formatting'

export const CRITERIA = 'criteria'
export const DATE_TIME_RESTRICTED = 'dateTimeRestricted'
export const RESTRICTED_BY = 'restrictedBy'
export const RATIONALE = 'rationale'
export const RESTRICT_UNTIL = 'restrictUntil'

export const customizableColumns: ColDef[] = [
  {
    colId: CRITERIA,
    field: CRITERIA,
    headerName: 'Criteria',
    width: 95,
    checkboxSelection: true,
    resizable: true
  },
  {
    colId: DATE_TIME_RESTRICTED,
    field: DATE_TIME_RESTRICTED,
    headerName: 'Time Restricted',
    valueFormatter: dateFormatter,
    width: 125
  },
  {
    colId: RESTRICTED_BY,
    field: RESTRICTED_BY,
    headerName: 'Restricted By',
    width: 150
  },
  {
    colId: RATIONALE,
    field: RATIONALE,
    headerName: 'Rationale',
    width: 300,
    resizable: true
  },
  {
    colId: RESTRICT_UNTIL,
    field: RESTRICT_UNTIL,
    headerName: 'Restrict Until',
    valueFormatter: dateFormatter,
    width: 125
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
