import { WatchList, WatchlistPermission } from './types'

export const getPermissionForResponse = (
  response: number
): WatchlistPermission => (response === 0 ? 'private' : 'myFirm')

export const getResponseForPermission = (permission: WatchlistPermission) =>
  permission === 'private' ? 0 : 1

export const watchlistDetailsFromResponse = <T extends WatchList>(
  response: any
): T => ({
  ...response,
  permission: getPermissionForResponse(response.permission)
})
