import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import '@openfonts/lato_latin'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom'
import * as Sentry from '@sentry/react'

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-043954 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( MTS Markets International, Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( BondsPro )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( BondsPro )_need_to_be_licensed___( BondsPro )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 9 August 2024 )____[v2]_MTcyMzE1ODAwMDAwMA==a57682f25c4f48c22c60286b72ec6be0'
)

import { OpenFinContext } from './app/openFinContext'
import './assets/scss/variables.scss'
import config from './config'
import { initHub } from './helpers/hub'
import OktaWrapper from './routing/OktaWrapper'
import getStore from './store'

// AG Grid modules
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { CsvExportModule } from '@ag-grid-community/csv-export'

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  InfiniteRowModelModule,
  MenuModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  ClipboardModule,
  SetFilterModule
])

const store = getStore()
if (process.env.NODE_ENV === 'development') {
  ;(window as any).__REDUX_STORE__ = store
}
initHub(store)
export const fin = (window as any).fin

Sentry.init({
  dsn: 'https://5d88b9bcf3b767b9f0e88827c59e379d@o4506503129989120.ingest.sentry.io/4506507821580288',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      workerUrl: '/worker.min.js'
    })
  ],
  tracePropagationTargets: [config.api.url],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack }) => (
      <>
        <h2>An error occurred:</h2>
        <pre className="error-boundary">
          {error.toString()}
          {componentStack}
        </pre>
      </>
    )}
  >
    <Provider store={store}>
      <OpenFinContext.Provider value={fin}>
        <BrowserRouter>
          <OktaWrapper />
        </BrowserRouter>
      </OpenFinContext.Provider>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('app')
)
