import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import {
  restrictedTradesFetch,
  unsubscribeFromRestrictedTrades
} from '../../../store/admin/restrictedTrades/actions'
import RestrictedTradesGrid from './Grid/RestrictedTradesGrid'
import styles from './RestrictedTrades.scss'

export interface Props {
  selectedTab: string
}

const RestrictedTrades: FC<Props> = (props) => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const isPermanent = props.selectedTab === 'permanent'

  useEffect(() => {
    dispatch(restrictedTradesFetch())
    return () => {
      dispatch(unsubscribeFromRestrictedTrades())
    }
  }, [])

  return (
    <React.Fragment>
      <div
        className={fin ? styles.finParamsWrapper : styles.systemParamWrapper}
      >
        <RestrictedTradesGrid isPermanent={isPermanent} />
      </div>
    </React.Fragment>
  )
}

export default RestrictedTrades
