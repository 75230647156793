import { Action } from 'redux'
import BenchmarkData from './types'

export interface GetBenchmarkDataAction extends Action {
  type: 'benchmark.getBenchmarkData'
}

export const getBenchmarkData = (): GetBenchmarkDataAction => ({
  type: 'benchmark.getBenchmarkData'
})

export interface GetBenchmarkDataFailureAction extends Action {
  type: 'benchmark.getBenchmarkDataFailure'
  payload: any
  error: boolean
}

export const getBenchmarkDataFailure = (
  error: any
): GetBenchmarkDataFailureAction => ({
  type: 'benchmark.getBenchmarkDataFailure',
  payload: error,
  error: true
})

export interface GetBenchmarkDataSuccessAction extends Action {
  type: 'benchmark.getBenchmarkDataSuccess'
  payload: BenchmarkData
}

export const getBenchmarkDataSuccess = (
  benchmarkData: BenchmarkData
): GetBenchmarkDataSuccessAction => ({
  type: 'benchmark.getBenchmarkDataSuccess',
  payload: benchmarkData
})

export type BenchmarkDataAction =
  | GetBenchmarkDataAction
  | GetBenchmarkDataSuccessAction
  | GetBenchmarkDataFailureAction
