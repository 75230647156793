import {
  faSpinner,
  faFloppyDisk,
  faTrashAlt
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { defaultFilter } from '../../../../store/filter/types'
import {
  clearFailedSymbolsAndCusipsFromWatchlistDefinitionUpdate,
  updateWatchlistDefinition
} from '../../../../store/watchList/actions'
import { getFailedUpdateSymbols } from '../../../../store/watchList/selectors'
import { WatchList } from '../../../../store/watchList/types'
import Modal from '../../../GenericModal/Modal'
import NewWatchListInput from '../../../Upload/NewWatchListInput'
import AdvancedFilterEditor from './AdvancedFilterEditor'

import styles from '../WatchlistManager.scss'
import SymbolAndCusipEditor from './SymbolAndCusipEditor'

type Props = {
  watchlist?: WatchList
  deleteSelectedWatchlist: () => void
}

type ReducerCanEdit = Pick<WatchList, 'name' | 'filter' | 'symbolsAndCusips'>

const getDefaultWatchlist = () => {
  return {
    name: 'No watchlist selected',
    filter: { ...defaultFilter },
    symbolsAndCusips: [],
    isChanged: false
  }
}

type UpdateWatchlistActions = UpdateActions<ReducerCanEdit>

type InitAction = {
  type: 'init'
  payload: WatchList | undefined
}

const createActionForProperty = <Prop extends keyof ReducerCanEdit>(
  prop: Prop,
  payload: ReducerCanEdit[Prop]
) => {
  return {
    type: `update_${prop}` as const,
    payload
  } as const
}

const initWatchlist = (
  watchlist: WatchList | undefined
): ReducerCanEdit & { isChanged: boolean } => {
  if (watchlist) return { ...watchlist, isChanged: false }
  return getDefaultWatchlist()
}

type EditingWatchlist = ReturnType<typeof initWatchlist>
const watchlistReducer = <W extends EditingWatchlist>(
  watchlist: W,
  action: UpdateWatchlistActions | InitAction
) => {
  switch (action.type) {
    case 'update_name':
      return { ...watchlist, name: action.payload, isChanged: true }
    case 'update_filter':
      return { ...watchlist, filter: action.payload, isChanged: true }
    case 'update_symbolsAndCusips':
      return { ...watchlist, symbolsAndCusips: action.payload, isChanged: true }
    case 'init':
      return initWatchlist(action.payload)
  }
  return watchlist
}

const WatchlistEditor = ({ watchlist, deleteSelectedWatchlist }: Props) => {
  // version used to force a total rerender on new info received
  const [watchlistVersion, setWatchlistVersion] = useState(0)
  const [processing, setProcessing] = useState(false)

  const dispatch = useDispatch()
  const invalidIdentifiers = useSelector(getFailedUpdateSymbols)(
    watchlist?.id || -1
  )

  const invalidMsg = useMemo(() => {
    if (!invalidIdentifiers?.length) return ''
    if (invalidIdentifiers.length === 1) {
      return `${invalidIdentifiers.length} security was not recognized`
    }
    return `${invalidIdentifiers.length} securities were not recognized`
  }, [invalidIdentifiers])

  const [editingWatchlist, localDispatch] = useReducer(
    watchlistReducer,
    initWatchlist(watchlist)
  )

  const setWatchlistName = useCallback((newName) => {
    localDispatch({
      type: 'update_name',
      payload: newName
    })
  }, [])

  const saveWatchlist = useCallback(() => {
    if (!watchlist) return
    setProcessing(true)
    const { isChanged, ...changes } = editingWatchlist
    const updatedWatchlist = { ...watchlist, ...changes }
    dispatch(updateWatchlistDefinition(updatedWatchlist))
  }, [editingWatchlist])

  const dismissErrors = useCallback(() => {
    if (watchlist?.id) {
      dispatch(
        clearFailedSymbolsAndCusipsFromWatchlistDefinitionUpdate(watchlist.id)
      )
    }
  }, [watchlist?.id])

  useEffect(() => {
    if (processing) {
      setProcessing(false)
      setWatchlistVersion((version) => ++version)
      localDispatch({
        type: 'init',
        payload: watchlist
      })
    }

    // processing left out of deps to catch a change in WL after processing set
  }, [watchlist])

  if (watchlist && !watchlist.canEdit) {
    return (
      <div className={styles.editor}>
        You can't edit {watchlist.name} right now.
      </div>
    )
  }

  return (
    <div className={cx(styles.editor, { [styles.disabled]: !watchlist })}>
      <NewWatchListInput
        watchlistName={editingWatchlist.name || 'No Watchlist selected'}
        setWatchlistName={setWatchlistName}
        className={styles.watchlistName}
      />
      {watchlist && (
        <>
          <AdvancedFilterEditor
            key={'afe' + watchlistVersion}
            onEditFilter={(filter) => {
              localDispatch(createActionForProperty('filter', filter))
            }}
            filter={watchlist?.filter}
          />
          <SymbolAndCusipEditor
            key={'sace' + watchlistVersion}
            symbolsAndCusips={watchlist?.symbolsAndCusips ?? []}
            setSymbolsAndCusips={(symbols) => {
              localDispatch(
                createActionForProperty('symbolsAndCusips', symbols)
              )
            }}
          />
          <footer>
            {processing ? (
              <div>
                <FontAwesomeIcon icon={faSpinner} spin /> Processing...
              </div>
            ) : (
              <button
                id="wle-save-changes"
                data-testid="wle-save-changes"
                disabled={!watchlist || !editingWatchlist.isChanged}
                onClick={saveWatchlist}
              >
                <FontAwesomeIcon icon={faFloppyDisk} /> Save
              </button>
            )}
            <button
              id="wle-delete-watchlist"
              data-testid="wle-delete-watchlist"
              onClick={deleteSelectedWatchlist}
            >
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </button>
          </footer>
        </>
      )}
      {invalidMsg && (
        <Modal customWrapperStyles={styles.modal}>
          <div>
            <h3>{invalidMsg}</h3>
            <ul>
              {invalidIdentifiers?.map((symbol, i) => (
                <li key={i}>{symbol}</li>
              ))}
            </ul>
            <button data-testid="wle-dismiss-errors" onClick={dismissErrors}>
              Got it!
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default WatchlistEditor
