import cx from 'classnames'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../components/Checkbox'
import { getWatchlistId } from '../../../store/securities/selectors'
import {
  checkOrUncheckSecurities,
  setLastChecked
} from '../../../store/watchList/actions'
import {
  getLastChecked,
  securityIsChecked
} from '../../../store/watchList/selectors'
import { CellProps, useSecurityStaticData } from './helpers'

const SecurityCheckboxCell = (gridIndex: number) => ({
  data,
  api
}: CellProps) => {
  const dispatch = useDispatch()
  const security = useSecurityStaticData(data)
  const gridApi = api
  const watchlistId = useSelector(getWatchlistId)(gridIndex)
  const lastChecked = useSelector(getLastChecked)(watchlistId ?? 0)
  const isChecked = useSelector(securityIsChecked)
  const checked = security ? isChecked(security.id, watchlistId) : false

  const onClick = (e: React.MouseEvent) => {
    if (e.shiftKey) {
      const items: number[] = []
      let startReached = false
      let endReached = false
      gridApi.forEachNode((node) => {
        if (!startReached) {
          if (!node.data) {
            return
          }
          if (node.data.id === security.id || node.data.id === lastChecked) {
            startReached = true
            if (node.data) {
              items.push(node.data.id)
            }
          }
        } else {
          if (!endReached) {
            if (node.data.id === security.id || node.data.id === lastChecked) {
              endReached = true
            }
            if (node.data) {
              items.push(node.data.id)
            }
          }
        }
      })
      let singleItemChecked = false
      singleItemChecked = isChecked(security.id, watchlistId)

      if (singleItemChecked) {
        dispatch(checkOrUncheckSecurities(watchlistId ?? 0, items, false))
      } else {
        // dispatch(resetCheckedSecurities())
        dispatch(checkOrUncheckSecurities(watchlistId ?? 0, items, true))
      }
    } else {
      const singleItemChecked = isChecked(security.id, watchlistId)

      const items = []
      items.push(security.id)

      if (singleItemChecked) {
        dispatch(checkOrUncheckSecurities(watchlistId ?? 0, items, false))
      } else {
        // dispatch(resetCheckedSecurities())
        dispatch(checkOrUncheckSecurities(watchlistId ?? 0, items, true))
      }
    }
    dispatch(setLastChecked(watchlistId ?? 0, security ? security.id : 0))
  }

  if (!security) return <> </>

  return (
    <div className={cx(!checked && 'checked-security')}>
      {/* disable checkbox but make it appear like it's clickable */}
      {/* this is handled in BondList - onChange here will break unselect one from selected range functionality */}
      <Checkbox
        onClick={onClick}
        locator="select-security"
        disabled={false}
        suppressDisabledStyling={true}
        fillColor="#43A980"
        checked={checked}
      />
    </div>
  )
}

export default SecurityCheckboxCell
