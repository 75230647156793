import { Reducer } from 'react'
import { Action, AnyAction } from 'redux'
import { concat, Observable } from 'rxjs'
import { reduce } from 'rxjs/operators'
import { WatchList } from './watchList/types'

export const addOrUpdateElement = <T>(
  array: T[],
  element: T,
  matches: (el1: T, el2: T) => boolean
) => {
  const elementIndex = array.findIndex((el) => matches(el, element))
  if (elementIndex === -1) {
    return [...array, element]
  }
  return [
    ...array.slice(0, elementIndex),
    element,
    ...array.slice(elementIndex + 1, array.length)
  ]
}

export const removeElement = <T>(array: T[], matches: (el1: T) => boolean) =>
  array.filter((el) => !matches(el))

export const uniq = <T>(arr: T[]) => [...new Set(arr)]

export const getAllOrNoneStatus = <T>(
  array: T[],
  isChecked: (element: T, watchlistId?: WatchList['id']) => boolean,
  watchlistId?: WatchList['id']
) => {
  if (!array) {
    return 'none'
  }
  const { all, none } = array.reduce(
    (acc, element) => {
      if (!acc.all && !acc.none) {
        return acc // Just an optimization
      }

      let checked = null
      if (watchlistId) {
        checked = isChecked(element, watchlistId)
      } else {
        checked = isChecked(element)
      }

      return {
        all: acc.all && checked,
        none: checked ? false : acc.none
      }
    },
    { all: true, none: true }
  )

  // Note that we check for “none” first because we want to return “none”
  // if no checked orders, although technically ”all” would be correct.
  return none ? 'none' : all ? 'all' : 'some'
}

export const composeReducers = <S = any, A extends Action<any> = AnyAction>(
  reducer: Reducer<S, A>,
  ...[nextReducer, ...otherReducers]: Array<Reducer<S, A>>
): Reducer<S, A> => (state, action) =>
  nextReducer
    ? composeReducers(nextReducer, ...otherReducers)(
        reducer(state, action),
        action
      )
    : reducer(state, action)

const chunk = <T>(arr: T[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )

export const splitCallByChunks = <T, U>(
  params: T[],
  chunkSize: number,
  fn: (params: T[]) => Observable<U[]>
) => concat(...chunk(params, chunkSize).map(fn))

export const splitCallByChunksAndAggregateResult = <T, U>(
  params: T[],
  chunkSize: number,
  fn: (params: T[]) => Observable<U[]>
) =>
  splitCallByChunks(params, chunkSize, fn).pipe(
    reduce((acc, result) => [...acc, ...result], [])
  )
