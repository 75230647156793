import React from 'react'
import { CheckboxProps } from '.'

const Checkbox: React.FC<CheckboxProps> = ({ className, fillColor }) => (
  <span className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 14 14"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={fillColor}
          d="M1.867 0h10.266C13.164 0 14 .836 14 1.867v10.266C14 13.164 13.164 14 12.133 14H1.867A1.867 1.867 0 0 1 0 12.133V1.867C0 .836.836 0 1.867 0z"
        />
        <path d="M-4.667-3.733h22.4v22.4h-22.4z" />
        <path
          fill="#FFF"
          d="M11.061 3.033a.612.612 0 0 1 .827-.126.546.546 0 0 1 .133.787l-5.532 7.273a.612.612 0 0 1-.858.103l-3.55-2.81a.545.545 0 0 1-.078-.794.613.613 0 0 1 .835-.073L5.9 9.817l5.16-6.784z"
        />
      </g>
    </svg>
  </span>
)

export default Checkbox
