export interface Config {
  /** Config for back-end connection. */
  api: {
    /** URL of SignalR hub. */
    url: string
    /** Delay (ms) before trying to reconnect when connection is lost */
    reconnectDelay: number
    /** Timeout (ms) for token refresh before it expires */
    refreshTokenTimeout: number
  }
  /** Config for Okta SSO. */
  okta: {
    /** Okta issuer. */
    issuer: string
    /** Okta redirect URI after login. */
    redirectUri: string
    /** Okta client ID. */
    clientId: string
    /** Deactivate Okta? (For automated tests) */
    deactivate: boolean
    /** User Id (if Okta is deactivated) */
    userId?: number
  }
}

const nodeEnv = process.env.NODE_ENV
const formatEnvironment = (env: string | undefined) => {
  switch (env) {
    case 'development':
      return 'DEV'
    case 'test':
      return 'TEST'
    case 'demo':
      return 'DEMO'
    default:
      return null
  }
}
export const formattedEnvironment = formatEnvironment(nodeEnv)

const getUserId = () => Number(localStorage.getItem('userId'))

function loadConfig(): Config {
  if (nodeEnv === 'development' || nodeEnv === 'test') {
    require('../config/local')
    const baseConfig = (window as any).config
    return {
      api: {
        ...baseConfig.api,
        url: process.env.API_URL || baseConfig.api.url
      },
      okta: {
        ...baseConfig.okta,
        tokenManager: { storage: 'sessionStorage' },
        issuer: process.env.OKTA_ISSUER || baseConfig.okta.issuer,
        redirectUri:
          process.env.OKTA_REDIRECT_URI || baseConfig.okta.redirectUri,
        clientId: process.env.OKTA_CLIENT_ID || baseConfig.okta.clientId,
        deactivate: process.env.DEACTIVATE_OKTA
          ? process.env.DEACTIVATE_OKTA === '1'
          : baseConfig.okta.deactivate,
        userId: getUserId()
      }
    }
  } else {
    const baseConfig = (window as any).config
    return {
      ...baseConfig,
      okta: {
        ...baseConfig.okta,
        tokenManager: { storage: 'sessionStorage' },
        userId: getUserId()
      }
    }
  }
}

const config = loadConfig()

export const actionsToListenToForLocalStorage = [
  'securities.setWatchListId',
  'securities.setIssuerFilter',
  'securities.setSecuritiesFilter',
  'securities.setIsMine',
  'securities.setShowLive',
  'securities.setMyFirm',
  'securities.saveAdvancedFilter',
  'securities.setUseAdvancedFilter',
  'securities.setQuoteReliability',
  'securities.setSizeFilter',
  'securities.createGrid',
  'securities.removeGrid',
  'grid.updateColumnsOrder',
  'grid.updateDisplayedColumns',
  'depthofmarket.popout',
  'depthofmarket.closePopout',
  'depthofmarket.closeAllPopouts',
  'depthofmarket.updatePopouts',
  'order.setMyOrdersOpen',
  // 'alerts.addTradingNowAlerts',
  // 'alerts.addCounteredToppedAlerts',
  'alerts.muteAlert',
  'alerts.unmuteAlert',
  'settings.aggressAttemptsColumnModelChanged',
  'settings.externalOrderColumnModelChanged',
  'settings.externalLiquidityColumnModelChanged',
  'settings.historicalDataColumnModelChanged'
  // 'activity.toggle-visibility'
]

export const stateValueForLocalStorage = [
  'securities.gridDefinitions',
  'grid.columnsOrder',
  'grid.displayedColumns',
  'depthofmarket.popoutSecurityIds',
  'order.myOrdersIsOpen',
  'alerts.tradingNowAlerts',
  'alerts.counteredToppedAlerts',
  'alerts.mutedAlerts',
  'activity.visible'
]

export default config
