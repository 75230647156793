import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useResizeObserver from 'use-resize-observer'
import { useOpenFin } from '../../../app/openFinContext'
import notification from '../../../assets/sounds/notification.wav'
import { confirmOrder, rejectOrder } from '../../../store/lastLook/actions'
import { addLogItem } from '../../../store/log/actions'
import { getUserOrOperatorOrderById } from '../../../store/order/selectors'
import { getSecurityStaticDataById } from '../../../store/securities/selectors'
import {
  addOrUpdateStagedOrders,
  removeStagedOrder
} from '../../../store/stagedOrders/actions'
import { getStagedOrderBySecurityForOrderType } from '../../../store/stagedOrders/selectors'
import { getUserPreferences } from '../../../store/userPreferences/selectors'
import { showTsySetting } from '../../../store/webSettings/selectors'
import { removeLastLookFromWindows } from '../../../store/windows/actions'
import { LastLookWindowInfo } from '../../../store/windows/types'
import { useSecurityModalInfo } from '../AggressorModal/helpers'
import LastLookModalFooter from '../LastLookModalFooter/LastLookModalFooter'
import styles from '../modal.scss'
import HeaderModal from '../ModalHeader/ModalHeader'
import ModalSecurityInformationWrapper from '../ModalSecurityInformationWrapper'
import SpotCrossTypes from '../types'
import LastLookModalContent from './LastLookModalContent'

interface Props {
  windowInfo: LastLookWindowInfo
  index: number
  isActiveWindow: boolean
  handleMinimize: (index: number) => void
}

const LastLookModal: FC<Props> = ({
  windowInfo,
  index = 0,
  isActiveWindow = true,
  handleMinimize
}) => {
  const [logWritten, setLogWritten] = useState<boolean>(false)
  const showTsy = useSelector(showTsySetting)
  const [size, setSize] = useState(0)
  const securityModalInfo = useSecurityModalInfo(windowInfo.orderId, size)
  const getSecurity = useSelector(getSecurityStaticDataById)
  const getOrder = useSelector(getUserOrOperatorOrderById)
  const getStagedOrder = useSelector(getStagedOrderBySecurityForOrderType)
  const userPreferences = useSelector(getUserPreferences)
  const dispatch = useDispatch()
  const [canCross, setCanCross] = useState(false)
  const { ref, height } = useResizeObserver<HTMLDivElement>()
  const { fin } = useOpenFin(`LastLook-${windowInfo.orderId}`, height)

  const security =
    securityModalInfo && getSecurity(securityModalInfo.securityId)

  const order = securityModalInfo && getOrder(windowInfo.orderId)

  useEffect(() => {
    // on load, select autospot/cross
    switch (securityModalInfo?.takerSpotHedge) {
      case SpotCrossTypes.PreferToHedge:
        securityModalInfo.spotHedgePreference === SpotCrossTypes.PreferToHedge
          ? setCanCross(true)
          : setCanCross(false)
        break
      case SpotCrossTypes.MustHedge:
        setCanCross(true)
        break
      case SpotCrossTypes.Spot:
        setCanCross(false)
        break
      default:
        setCanCross(false)
    }
  }, [securityModalInfo?.takerSpotHedge])

  useEffect(() => {
    if (!userPreferences.muteNotifications) {
      const notificationAudio = new Audio(notification)
      // tslint:disable-next-line: no-floating-promises
      notificationAudio.play()
    }
  }, [])

  useEffect(() => {
    if (!logWritten && securityModalInfo) {
      const st = `Last look popup for ${security?.boardLabel} - ${
        order?.type
      } ${String(tradeAmt)} @ ${securityModalInfo?.price}`
      dispatch(addLogItem(st))
      setLogWritten(true)
    }
  }, [securityModalInfo])

  useEffect(() => {
    setSize(order?.aggressorOrder?.size || 0)
  }, [order?.aggressorOrder?.size])

  if (!security || !securityModalInfo || !order) {
    return null
  }

  const aggressorOrder = order.aggressorOrder
  const orderStatus = aggressorOrder?.status
  const tradeAmt = aggressorOrder!.size

  const handleCloseModal = () => {
    dispatch(removeLastLookFromWindows(windowInfo))
  }

  const handleConfirmButtonClick = (tradeAmount?: number) => () => {
    if (order?.id) {
      dispatch(confirmOrder(order.id, canCross))

      const stagedOrder = getStagedOrder(order.securityId, order.type)
      if (stagedOrder && tradeAmount && tradeAmount !== stagedOrder.size) {
        dispatch(
          addOrUpdateStagedOrders([
            {
              orderType: stagedOrder.orderType,
              securityId: stagedOrder.securityId,
              price: stagedOrder.price,
              isSpreadOrder: false,
              spread: stagedOrder.spread,
              size: stagedOrder.size - tradeAmount,
              allOrNone: stagedOrder.allOrNone,
              individualMin: stagedOrder.individualMin,
              custId: stagedOrder.custId,
              tob: stagedOrder.tob
            }
          ])
        )
      } else {
        dispatch(
          removeStagedOrder({
            securityId: order.securityId,
            orderType: order.type
          })
        )
      }
    } else {
      console.error('orderId not found')
    }
  }

  const handleRejectButtonClick = () => {
    if (order?.id) {
      dispatch(rejectOrder(order.id))
    } else {
      console.error('orderId not found')
    }
  }

  const fontClassName =
    order.type === 'buy' ? styles.buyColor : styles.sellColor

  const expandedOrCollapsed = isActiveWindow
    ? styles.expandableModal
    : styles.collapsedModal

  const disableCrossButton =
    order.id.startsWith('E') ||
    !securityModalInfo.benchmarkPrice ||
    securityModalInfo.takerSpotHedge === SpotCrossTypes.Spot ||
    orderStatus === 'accepted' ||
    orderStatus === 'rejected' ||
    orderStatus === 'cancelled'

  const disableSpotButton =
    orderStatus === 'accepted' ||
    orderStatus === 'rejected' ||
    orderStatus === 'cancelled' ||
    securityModalInfo.takerSpotHedge === SpotCrossTypes.MustHedge

  return (
    <div
      id="modalWrapper"
      className={styles.modalWrapper}
      ref={fin ? ref : null}
    >
      <section className={styles.modalMainLastLook}>
        <HeaderModal
          type={order.type}
          security={security}
          securityModalInfo={securityModalInfo}
          styles={styles}
          isLastLook={false}
          index={index}
          isActiveWindow={isActiveWindow}
          handleMinimize={handleMinimize}
          orderStatusComp={aggressorOrder?.status}
        />

        <div className={`${styles.contentExpandable} ${expandedOrCollapsed}`}>
          <LastLookModalContent
            order={order}
            securityModalInfo={securityModalInfo}
            isLastLook={true}
            price={securityModalInfo.price}
            styles={styles}
            tradeAmt={String(tradeAmt)}
            type={securityModalInfo.type}
            handleCloseModal={handleCloseModal}
            handleRejectButtonClick={handleRejectButtonClick}
            handleConfirmButtonClick={handleConfirmButtonClick}
            orderStatusComp={orderStatus}
            currency={security.currency}
          />

          {showTsy && (
            <div className={styles.lastLookPriceHedgeContainer}>
              <div className={styles.leftSide}>
                <div className={styles.title}>Price/hedge options:</div>
                <div>choose your preference</div>
              </div>
              <div className={styles.rightSide}>
                <button
                  type="button"
                  className={cx(
                    styles.tooltip,
                    styles.priceHedgeOption,
                    !canCross && styles.selectedButton
                  )}
                  onClick={() => setCanCross(false)}
                  disabled={disableSpotButton}
                >
                  Auto-Spot
                  {disableSpotButton && (
                    <span className={styles.tooltiptext}>Not Available</span>
                  )}
                </button>

                <button
                  type="button"
                  className={cx(
                    styles.tooltip,
                    styles.priceHedgeOption,
                    canCross && styles.selectedButton
                  )}
                  onClick={() => setCanCross(true)}
                  disabled={disableCrossButton}
                >
                  Cross
                  {disableCrossButton && (
                    <span className={styles.tooltiptext}>Not Available</span>
                  )}
                </button>
              </div>
            </div>
          )}

          <ModalSecurityInformationWrapper
            securityModalInfo={securityModalInfo}
            security={security}
          />

          <LastLookModalFooter
            fontClassName={fontClassName}
            orderStatusComp={orderStatus}
            size={order.size}
            type={order.type}
            tradeAmt={Number(tradeAmt)}
            actionCloseModal={handleCloseModal}
            handleConfirmButtonClick={handleConfirmButtonClick}
            handleRejectButtonClick={handleRejectButtonClick}
            benchmarkName={security.benchmarkName}
            hedgeNotional={securityModalInfo.hedgeNotional}
            benchPrice={securityModalInfo.benchmarkPrice}
            canCross={canCross}
          />
        </div>
      </section>
    </div>
  )
}

export default LastLookModal
