interface WindowConfig {
  windowHeight: number
  windowWidth: number
  windowTop: number
  windowLeft: number
  isAdminWindow: boolean
}

interface OpenfinConfig {
  [key: string]: WindowConfig
}
export const openfinConfig: OpenfinConfig = {
  ActivityBlotter: {
    windowHeight: 500,
    windowWidth: 500,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: false
  },
  ActivityLog: {
    windowHeight: 300,
    windowWidth: 750,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: false
  },
  AlertFilterEditor: {
    windowHeight: 650,
    windowWidth: 420,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: false
  },
  Benchmark: {
    windowHeight: 95,
    windowWidth: 800,
    windowTop: 0,
    windowLeft: screen.width / 4,
    isAdminWindow: false
  },
  PassiveOrders: {
    windowHeight: 333,
    windowWidth: 1030,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: false
  },
  Settings: {
    windowHeight: 500,
    windowWidth: 340,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: false
  },
  // ADMIN WINDOWS
  ActivityTicker: {
    windowHeight: 300,
    windowWidth: 1100,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  AggressAttempts: {
    windowHeight: 333,
    windowWidth: 1030,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  BBMISIN: {
    windowHeight: 610,
    windowWidth: 750,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  ExternalLiquidityStats: {
    windowHeight: 530,
    windowWidth: 1030,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  ExternalOrderStats: {
    windowHeight: 530,
    windowWidth: 1030,
    windowTop: 10,
    windowLeft: 0,
    isAdminWindow: true
  },
  HistoricalUserData: {
    windowHeight: 530,
    windowWidth: 1030,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  ManagementReports: {
    windowHeight: 355,
    windowWidth: 415,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  ManageWatchlists: {
    windowHeight: 333,
    windowWidth: 1030,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  QuerySelector: {
    windowHeight: 600,
    windowWidth: 740,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  QueueStats: {
    windowHeight: 225,
    windowWidth: 735,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  RestrictedTrading: {
    windowHeight: 300,
    windowWidth: 700,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  RestrictedTradingPermanent: {
    windowHeight: 300,
    windowWidth: 700,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  SystemParameters: {
    windowHeight: 600,
    windowWidth: 1080,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  Tiers: {
    windowHeight: 550,
    windowWidth: 550,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  TradeBlotter: {
    windowHeight: 300,
    windowWidth: 900,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  UserDashboard: {
    windowHeight: 275,
    windowWidth: 900,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  },
  WatchlistManager: {
    windowHeight: 550,
    windowWidth: 550,
    windowTop: 10,
    windowLeft: 10,
    isAdminWindow: true
  }
}
