import cx from 'classnames'
import React from 'react'
import { formatCurrency } from '../../../helpers/formatting'
import { OrderType } from '../../../store/order/types'
import styles from '../bondListStyle.scss'
import { CellProps, useSecurityOrderData } from './helpers'

const BestPriceCell =
  (orderType: OrderType) =>
  ({ data }: CellProps) => {
    const security = useSecurityOrderData(data)
    if (!security) return <> </>

    const order = orderType === 'buy' ? security.bestBid : security.bestOffer
    const displayPrice = order?.displayPrice
    const testId = data.id + (orderType === 'buy' ? '-bestbid' : '-bestofr')
    return (
      <span
        className={cx(styles.bestOrder, order?.myFirm && styles.myOrder)}
        data-testid={testId}
        data-testid-value={data.id + '-' + displayPrice}
      >
        {displayPrice ? formatCurrency(displayPrice, data?.currency) : '-'}
      </span>
    )
  }

export default BestPriceCell
