import cx from 'classnames'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import { createOrUpdateAlertFilter } from '../../store/alertFilter/actions'
import { getAlertFilter } from '../../store/alertFilter/selectors'
import {
  AndOr,
  AndOrStrings,
  BidAskDiffType,
  GreaterOrLessThan,
  GreaterOrLessThanStrings,
  PriceType
} from '../../store/alertFilter/types'
import {
  AllCountries,
  AllCouponTypes,
  AllRatings,
  AllRatingValues,
  AllSectors,
  AllSecurityTypes,
  CouponType,
  CouponTypeValues,
  Currency,
  CurrencyValues,
  Filter,
  MaturitiesValues,
  Rating,
  Sectors,
  SecurityType,
  SecurityTypeValues,
  Series
} from '../../store/filter/types'
import { getWatchList } from '../../store/watchList/selectors'
import Checkbox from '../Checkbox'
import ComponentFooter from '../ComponentFooter/ComponentFooter'
import DropdownRatings from '../DropdownRatings/DropdownRatings'
import GenericDropdownMenu from '../GenericDropdownMenu/GenericDropdownMenu'
import GenericDropdownMenuMulti from '../GenericDropdownMenu/GenericDropdownMenuMulti'
import styles from './AlertFilter.scss'

export interface Props {
  filterId: number
  onClose(): void
}

interface AlertPropertyCounts {
  identifiers: string
  lists: string
  filters: string
  sector: string
  maturity: string
  secType: string
  couponType: string
  ratings: string
  currency: string
}

const defaultFilter = {
  id: 0,
  name: '',
  watchlists: [],
  alertWhenBidTopped: false,
  alertWhenBidCountered: false,
  alertWhenNewBid: false,
  alertWhenNewOffer: false,
  alertWhenOfferCountered: false,
  alertWhenOfferCut: false,
  alertWhenTradingNow: false,
  alertPrice: 0,
  alertPriceType: PriceType.Spread,
  alertSize: 0,
  muted: false,
  priceSizeAndOr: AndOr.And,
  alertWhenPriceGreaterOrLessThan: GreaterOrLessThan.GreaterThan,
  alertWhenSizeGreaterOrLessThan: GreaterOrLessThan.GreaterThan,
  usePriceSizeCriteria: false,
  filter: {
    sectors: AllSectors,
    maturities: MaturitiesValues,
    couponTypes: AllCouponTypes,
    securityTypes: AllSecurityTypes,
    currencies: CurrencyValues as Currency[],
    countries: AllCountries,
    ratings: AllRatings,
    securitiesAndTickers: [],
    price: undefined,
    priceGreaterLessThan: GreaterOrLessThan.GreaterThan,
    yield: undefined,
    yieldGreaterLessThan: GreaterOrLessThan.GreaterThan,
    bidAskDiff: undefined,
    bidAskDiffGreaterLessThan: GreaterOrLessThan.GreaterThan,
    bidAskDiffType: BidAskDiffType.Price,
    series: Series.All
  },
  includesMineWatchlist: false,
  all: false
}
const createPropertyCount = (count: number) => {
  if (count) {
    return ` (${count})`
  }
  return ''
}

const createFilterPropertyCount = (selectedList: any[], valuesList: any[]) => {
  if (selectedList.length < valuesList.length) {
    return createPropertyCount(selectedList.length)
  }
  return ''
}

const AlertFilterEdit: FC<Props> = ({ filterId, onClose }) => {
  const [bondListOpen, setBondListOpen] = useState(false)
  const [watchlistOpen, setWatchlistListOpen] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const watchlists = useSelector(getWatchList) ?? []
  const alertFilter = useSelector(getAlertFilter)(filterId) ?? defaultFilter

  const reset = () => {
    setAlertWhenBidTopped(false)
    setAlertWhenNewBid(false)
    setPriceHits('')
    setSelectedSectors(AllSectors.map((s) => Sectors[s]))
    setSelectedMaturities(MaturitiesValues)
    setSelectedSecurityTypes(
      AllSecurityTypes.map((st) => SecurityTypeValues[st])
    )
    setSelectedCouponTypes(AllCouponTypes.map((st) => CouponTypeValues[st]))
    setAllBondsChecked(false)
    setSelectedRatings(AllRatings.map((r) => AllRatingValues[r]))
    setSelectedCurrencies(CurrencyValues.map((c) => c.toString()))
    setAlertWhenBidTopped(false)
    setAlertWhenOfferCut(false)
    setAlertWhenTradingNow(false)
    setAlertWhenNewBid(false)
    setAlertWhenNewOffer(false)
    setAlertWhenBidCountered(false)
    setAlertWhenOfferCountered(false)
    setAndIfChecked(false)
    setSizeHits('')
    setSizeGreaterOrLessThan(GreaterOrLessThanStrings[0])
    setPriceGreaterOrLessThan(GreaterOrLessThanStrings[0])
    setPriceType(PriceType[PriceType.Price])
    setPriceSizeAndOrValue(AndOrStrings[AndOr.And])
    setSelectedWatchlists([])
    setSecuritiesIdentifiers('')
  }

  const [alertFilterData, setAlertFilterData] = useState(alertFilter)
  useEffect(() => {
    if (JSON.stringify(alertFilter) !== JSON.stringify(alertFilterData)) {
      setAlertFilterData(alertFilter)
      const af = alertFilter
      setAlertWhenBidTopped(af.alertWhenBidTopped)
      setAlertWhenNewBid(af.alertWhenNewBid)
      setPriceHits(
        af.alertPrice ? (af.alertPrice !== 0 ? af.alertPrice : '') : ''
      )
      setSelectedSectors(af.filter.sectors.map((s) => Sectors[s]))
      setRuleName(af.name)
      setSelectedMaturities(af.filter.maturities)
      setSelectedSecurityTypes(
        af.filter.securityTypes.map((st) => SecurityTypeValues[st])
      )
      setSelectedCouponTypes(
        af.filter.couponTypes.map((st) => CouponTypeValues[st])
      )
      setAllBondsChecked(af.all)
      setSelectedRatings(af.filter.ratings.map((r) => AllRatingValues[r]))
      setSelectedCurrencies(af.filter.currencies.map((c) => c.toString()))
      setAlertWhenBidTopped(af.alertWhenBidTopped)
      setAlertWhenOfferCut(af.alertWhenOfferCut)
      setAlertWhenTradingNow(af.alertWhenTradingNow)
      setAlertWhenNewBid(af.alertWhenNewBid)
      setAlertWhenNewOffer(af.alertWhenNewOffer)
      setAlertWhenBidCountered(af.alertWhenBidCountered)
      setAlertWhenOfferCountered(af.alertWhenOfferCountered)
      setAndIfChecked(af.usePriceSizeCriteria)
      const displaySz =
        af.alertSize !== undefined
          ? af.alertSize !== 0
            ? af.alertSize
            : ''
          : ''
      setSizeHits(displaySz)
      af.watchlists.forEach((wl) => {
        const watchlistItem = watchlists.find((obj) => obj.id === wl)
        if (watchlistItem) {
          initiallySelectedWatchlists.push(watchlistItem.name)
        }
      })
      const newlySelectedWatchlists = [] as string[]
      af.watchlists.forEach((wl) => {
        const watchlistItem = watchlists.find((obj) => obj.id === wl)
        if (watchlistItem) {
          newlySelectedWatchlists.push(watchlistItem.name)
        }
      })
      if (af.includesMineWatchlist) {
        newlySelectedWatchlists.push('Mine')
      }
      setSelectedWatchlists(newlySelectedWatchlists)
      setSecuritiesIdentifiers(af.filter.securitiesAndTickers.join('\n'))
      setSizeGreaterOrLessThan(
        GreaterOrLessThanStrings[af.alertWhenSizeGreaterOrLessThan]
      )
      setPriceGreaterOrLessThan(
        GreaterOrLessThanStrings[af.alertWhenPriceGreaterOrLessThan]
      )
      setPriceType(PriceType[af.alertPriceType])
      setPriceSizeAndOrValue(AndOrStrings[af.priceSizeAndOr])
    }
  })

  const initiallySelectedWatchlists = [] as string[]

  alertFilter.watchlists.forEach((wl) => {
    const watchlistItem = watchlists.find((obj) => obj.id === wl)
    if (watchlistItem) {
      initiallySelectedWatchlists.push(watchlistItem.name)
    }
  })
  if (alertFilter.includesMineWatchlist) {
    initiallySelectedWatchlists.push('Mine')
  }

  const [selectedWatchlists, setSelectedWatchlists] = useState(
    initiallySelectedWatchlists
  )

  const dispatch = useDispatch()

  const toggleBondListOpen = useCallback(() => {
    setBondListOpen((value) => !value)
    setWatchlistListOpen(false)
    setFiltersOpen(false)
  }, [])
  const toggleWatchlistOpen = useCallback(() => {
    setWatchlistListOpen((value) => !value)
    setBondListOpen(false)
    setFiltersOpen(false)
  }, [])
  const toggleFiltersOpen = useCallback(() => {
    setFiltersOpen((value) => !value)
    setBondListOpen(false)
    setWatchlistListOpen(false)
  }, [])
  const sectors = Object.values(Sectors)
  const [ruleName, setRuleName] = useState(alertFilter.name)
  const [selectedSectors, setSelectedSectors] = useState(
    alertFilter.filter.sectors.map((s) => Sectors[s])
  )
  const [selectedMaturities, setSelectedMaturities] = useState(
    alertFilter.filter.maturities
  )
  const [selectedSecurityTypes, setSelectedSecurityTypes] = useState(
    alertFilter.filter.securityTypes.map((st) => SecurityTypeValues[st])
  )
  const [selectedCouponTypes, setSelectedCouponTypes] = useState(
    alertFilter.filter.couponTypes.map((ct) => CouponTypeValues[ct])
  )
  const [allBondsChecked, setAllBondsChecked] = useState(alertFilter.all)
  const [selectedRatings, setSelectedRatings] = useState(
    alertFilter.filter.ratings.map((r) => AllRatingValues[r])
  )
  const [selectedCurrencies, setSelectedCurrencies] = useState(
    alertFilter.filter.currencies.map((c) => c.toString())
  )
  const [securitiesIdentifiers, setSecuritiesIdentifiers] = useState(
    alertFilter.filter.securitiesAndTickers.join('\n')
  )
  const [alertWhenBidTopped, setAlertWhenBidTopped] = useState(
    alertFilter.alertWhenBidTopped
  )
  const [alertWhenOfferCut, setAlertWhenOfferCut] = useState(
    alertFilter.alertWhenOfferCut
  )
  const [alertWhenTradingNow, setAlertWhenTradingNow] = useState(
    alertFilter.alertWhenTradingNow
  )
  const [alertWhenNewBid, setAlertWhenNewBid] = useState(
    alertFilter.alertWhenNewBid
  )
  const [alertWhenNewOffer, setAlertWhenNewOffer] = useState(
    alertFilter.alertWhenNewOffer
  )
  const [alertWhenBidCountered, setAlertWhenBidCountered] = useState(
    alertFilter.alertWhenBidCountered
  )
  const [alertWhenOfferCountered, setAlertWhenOfferCountered] = useState(
    alertFilter.alertWhenOfferCountered
  )
  const [andIfChecked, setAndIfChecked] = useState(
    alertFilter.usePriceSizeCriteria
  )

  const [priceGreaterOrLessThan, setPriceGreaterOrLessThan] = useState(
    GreaterOrLessThanStrings[alertFilter.alertWhenPriceGreaterOrLessThan]
  )

  const [sizeGreaterOrLessThan, setSizeGreaterOrLessThan] = useState(
    GreaterOrLessThanStrings[alertFilter.alertWhenSizeGreaterOrLessThan]
  )

  const [priceSizeAndOrValue, setPriceSizeAndOrValue] = useState(
    AndOrStrings[alertFilter.priceSizeAndOr]
  )
  const [priceType, setPriceType] = useState(
    PriceType[alertFilter.alertPriceType]
  )

  const displaySize =
    alertFilter.alertSize !== undefined
      ? alertFilter.alertSize !== 0
        ? alertFilter.alertSize
        : ''
      : ''
  const [sizeHits, setSizeHits] = useState(displaySize)
  const displayPrice =
    alertFilter.alertPrice !== undefined
      ? alertFilter.alertPrice !== 0
        ? alertFilter.alertPrice
        : ''
      : ''
  const [priceHits, setPriceHits] = useState(displayPrice)

  const alertPropertyCounts = useMemo<AlertPropertyCounts>(() => {
    const identifiers = createPropertyCount(
      securitiesIdentifiers ? securitiesIdentifiers.split('\n').length : 0
    )
    const lists = createPropertyCount(selectedWatchlists.length)

    const sector = createFilterPropertyCount(selectedSectors, sectors)
    const maturity = createFilterPropertyCount(
      selectedMaturities,
      MaturitiesValues
    )
    const secType = createFilterPropertyCount(
      selectedSecurityTypes,
      SecurityTypeValues
    )
    const couponType = createFilterPropertyCount(
      selectedCouponTypes,
      CouponTypeValues
    )
    const ratings = createFilterPropertyCount(selectedRatings, AllRatingValues)
    const currency = createFilterPropertyCount(
      selectedCurrencies,
      CurrencyValues
    )
    // add up how many filters have counts > 0
    const filterCount = [
      sector,
      maturity,
      secType,
      couponType,
      ratings,
      currency
    ].reduce((prev, displayCount) => (displayCount.length ? prev + 1 : prev), 0)

    const filters = createPropertyCount(filterCount)

    return {
      identifiers,
      lists,
      sector,
      maturity,
      secType,
      couponType,
      ratings,
      currency,
      filters
    }
  }, [
    securitiesIdentifiers,
    selectedWatchlists,
    selectedSectors,
    selectedMaturities,
    selectedSecurityTypes,
    selectedCouponTypes,
    selectedRatings,
    selectedCurrencies
  ])

  const alertPropertyStyle = useCallback(
    (property: keyof typeof alertPropertyCounts) => {
      return alertPropertyCounts[property] ? styles.notDefault : ''
    },
    [alertPropertyCounts]
  )

  const updateSecuritiesIdentifiers = (
    evt: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSecuritiesIdentifiers(evt.target.value)
  }

  const updateRuleName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setRuleName(evt.target.value)
  }

  const allBondsCheckChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setAllBondsChecked(evt.target.checked)
  }

  const alertWhenOfferCutChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAlertWhenOfferCut(evt.target.checked)
  }

  const alertWhenBidToppedChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAlertWhenBidTopped(evt.target.checked)
  }

  const alertWhenNewBidChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setAlertWhenNewBid(evt.target.checked)
  }

  const alertWhenNewOfferChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAlertWhenNewOffer(evt.target.checked)
  }

  const andIfCheckedChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setAndIfChecked(evt.target.checked)
  }

  const alertWhenBidCounteredChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAlertWhenBidCountered(evt.target.checked)
  }

  const alertWhenOfferCounteredChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAlertWhenOfferCountered(evt.target.checked)
  }

  const alertWhenTradingNowChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAlertWhenTradingNow(evt.target.checked)
  }

  const sizeHitsChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSizeHits(evt.target.value)
  }

  const priceHitsChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPriceHits(evt.target.value)
  }

  // todo: save this filter
  const apply = () => {
    // convert selectedSectors to Sector enum
    const sectorsToSave = selectedSectors.map((s) => {
      for (const [key, value] of Object.entries(Sectors)) {
        if (value === s) {
          return Number(key)
        }
      }
    })

    const maturitiesToSave = selectedMaturities

    const securityTypesToSave = selectedSecurityTypes.map((st) => {
      const key = st.replace(/ /g, '_')
      return SecurityType[key as keyof typeof SecurityType]
    })
    let couponTypesToSave = AllCouponTypes
    if (selectedCouponTypes.length > 0) {
      couponTypesToSave = selectedCouponTypes.map((ct) => {
        const key = ct.replace(/ /g, '_')
        return CouponType[key as keyof typeof CouponType]
      })
    } else {
      setSelectedCouponTypes(AllCouponTypes.map((st) => CouponTypeValues[st]))
    }

    const ratingToSave = selectedRatings.map((r) => {
      const key = r.replace('+', 'Plus').replace('-', 'Minus')
      return Rating[key as keyof typeof Rating]
    })

    const currenciesToSave = selectedCurrencies

    const securitiesIdentifiersToSave = securitiesIdentifiers
      .split('\n')
      .filter((element) => element !== '')

    const name = ruleName
    const filter = {
      sectors: sectorsToSave,
      maturities: maturitiesToSave,
      securityTypes: securityTypesToSave,
      couponTypes: couponTypesToSave,
      currencies: currenciesToSave,
      ratings: ratingToSave,
      securitiesAndTickers: securitiesIdentifiersToSave,
      countries: AllCountries
    } as Filter

    const watchlistsToSave = [] as number[]
    selectedWatchlists.forEach((wl) => {
      const watchlistItem = watchlists.find((obj) => obj.name === wl)
      if (watchlistItem) {
        watchlistsToSave.push(watchlistItem.id as number)
      }
    })

    const alrtFilter = {
      filter,
      watchlists: watchlistsToSave, // todo
      name,
      alertWhenBidTopped,
      alertWhenOfferCut,
      alertWhenNewBid,
      alertWhenNewOffer,
      alertWhenTradingNow,
      alertWhenBidCountered,
      alertWhenOfferCountered,
      alertSize: Number(sizeHits),
      muted: alertFilter?.muted ?? false,
      usePriceSizeCriteria: andIfChecked,
      priceSizeAndOr: priceSizeAndOrValue === 'And' ? AndOr.And : AndOr.Or,
      alertWhenPriceGreaterOrLessThan:
        priceGreaterOrLessThan === '≥'
          ? GreaterOrLessThan.GreaterThan
          : GreaterOrLessThan.LessThan,
      alertWhenSizeGreaterOrLessThan:
        sizeGreaterOrLessThan === '≥'
          ? GreaterOrLessThan.GreaterThan
          : GreaterOrLessThan.LessThan,
      alertPrice: Number(priceHits),
      alertPriceType: PriceType[priceType as keyof typeof PriceType],
      id: alertFilterData.id,
      includesMineWatchlist: selectedWatchlists.indexOf('Mine') > -1,
      all: allBondsChecked
    }

    if (
      (alrtFilter.alertWhenNewBid || alrtFilter.alertWhenNewOffer) &&
      alrtFilter.all
    ) {
      setErrorText(
        'Error: Cannot select All Bonds for a New Bid or New Offer alert'
      )
    } else {
      dispatch(createOrUpdateAlertFilter(alrtFilter))
      onClose()
    }
  }

  const bondListClassname =
    'fas ' + (bondListOpen ? 'fa-angle-double-down' : 'fa-angle-double-up')
  const watchlistClassname =
    'fas ' + (watchlistOpen ? 'fa-angle-double-down' : 'fa-angle-double-up')
  const filtersClassname =
    'fas ' + (filtersOpen ? 'fa-angle-double-down' : 'fa-angle-double-up')

  return (
    <div className={styles.FormOuterWrapper}>
      <div className={styles.RuleEdit}>
        <div className={styles.RuleName}>
          <div className={styles.RuleNameLeft}>
            <label htmlFor="ruleName">NAME</label>
          </div>
          <div className={styles.RuleNameRight}>
            <span className={styles.ResetIcon} onClick={reset}>
              <i className="fas fa-redo fa-flip-horizontal"></i>
            </span>
            <label className={styles.Reset} onClick={reset}>
              RESET
            </label>
          </div>
        </div>
        <input
          className={styles.NameInput}
          placeholder="Name"
          value={ruleName}
          onChange={updateRuleName}
          name="ruleName"
          id="ruleName"
        />
        <div className={styles.ApplyAlertUsingHeader}>
          <div className={styles.ApplyAlertUsingHeaderLeft}>
            <label>APPLY ALERT USING:</label>
          </div>
          <div className={styles.ApplyAlertUsingHeaderRight}>
            <Checkbox
              locator={'allBondsCheck'}
              checked={allBondsChecked}
              onChange={allBondsCheckChanged}
              disabled={false}
            >
              ALL BONDS
            </Checkbox>
          </div>
        </div>
        <div>
          <div
            className={cx(
              styles.FilterMethod,
              alertPropertyStyle('identifiers')
            )}
          >
            <div
              className={styles.FilterMethodHeader}
              onClick={toggleBondListOpen}
            >
              <span className={styles.AlertFilterMethodText}>
                TYPE / PASTE BONDS MANUALLY{alertPropertyCounts.identifiers}
              </span>

              <i className={bondListClassname}></i>
            </div>

            {bondListOpen && (
              <div className={styles.BondList}>
                <p>SECURITY</p>
                <textarea
                  placeholder="Enter Ticker, CUSIP, or ISIN"
                  rows={5}
                  value={securitiesIdentifiers}
                  onChange={updateSecuritiesIdentifiers}
                ></textarea>
              </div>
            )}
          </div>
          <div className={cx(styles.FilterMethod, alertPropertyStyle('lists'))}>
            <div
              className={styles.FilterMethodHeader}
              onClick={toggleWatchlistOpen}
            >
              <span className={styles.AlertFilterMethodText}>
                LISTS{alertPropertyCounts.lists}
              </span>
              <i className={watchlistClassname}></i>
            </div>
            {watchlistOpen && (
              <div className={styles.Watchlists}>
                {/* <AlertFilterWatchlistDropdown filters={filter} />*/}
                <GenericDropdownMenuMulti
                  placeholder="Watchlists"
                  setSelectedItems={setSelectedWatchlists}
                  options={['Mine'].concat(watchlists.map((wl) => wl.name))}
                  selectedItems={selectedWatchlists}
                  selectId={'AlertWatchlists'}
                  openRight={true}
                  className=""
                />
              </div>
            )}
          </div>
          <div
            className={cx(styles.FilterMethod, alertPropertyStyle('filters'))}
          >
            <div
              className={styles.FilterMethodHeader}
              onClick={toggleFiltersOpen}
            >
              <span className={styles.AlertFilterMethodText}>
                FILTERS{`${alertPropertyCounts.filters}`}
              </span>
              <i className={filtersClassname}></i>
            </div>

            {filtersOpen && (
              <div>
                <GenericDropdownMenuMulti
                  placeholder={`Sectors${alertPropertyCounts.sector}`}
                  selectedItems={selectedSectors}
                  setSelectedItems={setSelectedSectors}
                  options={sectors}
                  selectId={'AlertSector'}
                  openRight={true}
                  className={alertPropertyStyle('sector')}
                />
                <GenericDropdownMenuMulti
                  placeholder={`Maturity${alertPropertyCounts.maturity}`}
                  selectedItems={selectedMaturities}
                  options={MaturitiesValues}
                  setSelectedItems={setSelectedMaturities}
                  selectId={'AlertMaturity'}
                  openRight={true}
                  className={alertPropertyStyle('maturity')}
                />
                <GenericDropdownMenuMulti
                  placeholder={`Security Type${alertPropertyCounts.secType}`}
                  selectedItems={selectedSecurityTypes}
                  options={SecurityTypeValues}
                  setSelectedItems={setSelectedSecurityTypes}
                  selectId={'AlertSecurityType'}
                  openRight={false}
                  className={alertPropertyStyle('secType')}
                />
                <GenericDropdownMenuMulti
                  placeholder={`Coupon Type${alertPropertyCounts.couponType}`}
                  selectedItems={selectedCouponTypes}
                  options={CouponTypeValues}
                  setSelectedItems={setSelectedCouponTypes}
                  selectId={'AlertCouponType'}
                  openRight={false}
                  className={alertPropertyStyle('couponType')}
                />
                <DropdownRatings
                  placeholder={`Ratings${alertPropertyCounts.ratings}`}
                  selectedItems={selectedRatings}
                  options={AllRatingValues}
                  setSelectedItems={setSelectedRatings}
                  selectId={'AlertRating'}
                  openRight={true}
                  className={alertPropertyStyle('ratings')}
                />
                <GenericDropdownMenuMulti
                  placeholder={`Currency${alertPropertyCounts.currency}`}
                  selectedItems={selectedCurrencies}
                  options={CurrencyValues}
                  setSelectedItems={setSelectedCurrencies}
                  selectId={'AlertCurrency'}
                  openRight={true}
                  className={alertPropertyStyle('currency')}
                />
              </div>
            )}
          </div>
          <div>
            <div className={styles.AlertMeWhen}>
              <label>ALERT ME WHEN:</label>
            </div>
            <table className={styles.AlertMeWhenTable}>
              <tbody>
                <tr>
                  <WebOnlyCheckboxCell
                    alertWhenNewBidOffer={alertWhenNewBid}
                    alertWhenNewBidOfferChanged={alertWhenNewBidChanged}
                    labelText={'New Bid'}
                  />
                  <td>
                    <Checkbox
                      locator={'myBidTopped'}
                      checked={alertWhenBidTopped}
                      onChange={alertWhenBidToppedChanged}
                      disabled={false}
                    >
                      My Bid Topped
                    </Checkbox>
                  </td>
                  <td>
                    <Checkbox
                      locator={'myBidCountered'}
                      checked={alertWhenBidCountered}
                      onChange={alertWhenBidCounteredChanged}
                      disabled={false}
                    >
                      My Bid Countered
                    </Checkbox>
                  </td>
                </tr>
                <tr>
                  <WebOnlyCheckboxCell
                    alertWhenNewBidOffer={alertWhenNewOffer}
                    alertWhenNewBidOfferChanged={alertWhenNewOfferChanged}
                    labelText={'New Offer'}
                  />
                  <td>
                    <Checkbox
                      locator={'myOfferCut'}
                      checked={alertWhenOfferCut}
                      onChange={alertWhenOfferCutChanged}
                      disabled={false}
                    >
                      My Offer Cut
                    </Checkbox>
                  </td>
                  <td>
                    <Checkbox
                      locator={'myOfferCountered'}
                      checked={alertWhenOfferCountered}
                      onChange={alertWhenOfferCounteredChanged}
                      disabled={false}
                    >
                      My Offer Countered
                    </Checkbox>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      locator={'tradingNow'}
                      checked={alertWhenTradingNow}
                      onChange={alertWhenTradingNowChanged}
                      disabled={false}
                    >
                      Trading on BondsPro
                    </Checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.AndIf}>
            <Checkbox
              locator={'andIf'}
              checked={andIfChecked}
              onChange={andIfCheckedChanged}
              disabled={false}
            >
              AND IF:
            </Checkbox>
          </div>
          <div>
            Size (k)
            <GenericDropdownMenu
              selectedItem={sizeGreaterOrLessThan}
              setSelectedItem={setSizeGreaterOrLessThan}
              options={GreaterOrLessThanStrings}
              selectId={'AlertFilterSizeGreaterLessThan'}
              openRight={true}
              className=""
            />
            <input
              value={sizeHits}
              onChange={sizeHitsChanged}
              className={styles.sizePriceInput}
            />
            <GenericDropdownMenu
              selectedItem={priceSizeAndOrValue}
              setSelectedItem={setPriceSizeAndOrValue}
              options={AndOrStrings}
              selectId={'AlertFilterAndOr'}
              openRight={false}
              className=""
            />
            <GenericDropdownMenu
              selectedItem={priceType}
              setSelectedItem={setPriceType}
              options={Object.keys(PriceType).filter((item) =>
                isNaN(Number(item))
              )}
              selectId={'AlertFilterAndOr'}
              openRight={false}
              className=""
            />
            <GenericDropdownMenu
              selectedItem={priceGreaterOrLessThan}
              setSelectedItem={setPriceGreaterOrLessThan}
              options={GreaterOrLessThanStrings}
              selectId={'AlertFilterPriceGreaterLessThan'}
              openRight={false}
              className=""
            />
            <input
              value={priceHits}
              onChange={priceHitsChanged}
              className={styles.sizePriceInput}
            />
          </div>
        </div>
      </div>
      <ComponentFooter
        onCancelClick={onClose}
        onApplyClick={apply}
        errorText={errorText}
      />
    </div>
  )
}

interface CheckBoxCellProps {
  alertWhenNewBidOffer: boolean
  alertWhenNewBidOfferChanged: (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => void
  labelText: string
}
const WebOnlyCheckboxCell: FC<CheckBoxCellProps> = ({
  alertWhenNewBidOffer,
  alertWhenNewBidOfferChanged,
  labelText
}) => {
  const { fin } = useOpenFin()
  if (fin) return <></>
  return (
    <td>
      <Checkbox
        locator={labelText}
        checked={alertWhenNewBidOffer}
        onChange={alertWhenNewBidOfferChanged}
      >
        {labelText}
      </Checkbox>
    </td>
  )
}

export default AlertFilterEdit
