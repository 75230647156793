import { ColumnApi, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getPassiveOrders,
  getPassiveordersPending
} from '../../../store/passiveOrders/selectors'
import { PassiveOrder } from '../../../store/passiveOrders/types'
import { getIsAdmin } from '../../../store/webSettings/selectors'
import styles from '../PassiveOrders.scss'
import { adminColumnDefinitions, columnDefinitions } from './columnDefs'

import { useOpenFin } from '../../../app/openFinContext'

export const defaultColumnDefinitions = {
  resizable: true,
  filter: true,
  filterParams: {
    buttons: ['clear']
  },
  sortable: true,
  suppressMovable: false
}

let ig = false
let hy = false
let live = false

const PassiveOrdersGrid: FC = () => {
  const { fin } = useOpenFin()
  const loading = useSelector(getPassiveordersPending)
  const admin = useSelector(getIsAdmin)
  const gridRef = useRef(null)

  const [igState, setIG] = useState<boolean>(false)
  const [hyState, setHY] = useState<boolean>(false)
  const [liveState, setLive] = useState<boolean>(false)
  const [passiveOrdersRowData, setPassiveOrdersRowData] = useState<
    PassiveOrder[]
  >([])
  const passiveOrders = useSelector(getPassiveOrders)
  useEffect(() => {
    if (passiveOrdersRowData) {
      setPassiveOrdersRowData(passiveOrders)
    } else {
      setPassiveOrdersRowData([])
    }
  }, [passiveOrders])

  useEffect(() => {
    if (fin) {
      defaultColumnDefinitions.suppressMovable = true
    }
  }, [])

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const externalFilterChanged = useCallback(() => {
    // @ts-ignore
    gridRef.current.api.onFilterChanged()
  }, [])

  const isExternalFilterPresent = () => {
    return ig || hy || live
  }

  const igChanged = () => {
    if (hy) {
      setHY(!hy)
      hy = !hy
    }
    setIG(!ig)
    ig = !ig
    externalFilterChanged()
  }

  const hyChanged = () => {
    if (ig) {
      setIG(!ig)
      ig = !ig
    }
    setHY(!hy)
    hy = !hy
    externalFilterChanged()
  }

  const liveChanged = () => {
    setLive(!live)
    live = !live
    externalFilterChanged()
  }

  const doesExternalFilterPass = (node: any) => {
    if (node.data) {
      if (ig && !node.data.isIG) {
        return false
      }
      if (hy && node.data.isIG) {
        return false
      }
      if (live && !node.data.isLive) {
        return false
      }
      return true
    }
    return true
  }

  useEffect(() => {
    if (gridApi) {
      if (loading) {
        setTimeout(() => {
          gridApi.api.showLoadingOverlay()
        })
      } else {
        if (!passiveOrders || passiveOrders.length === 0) {
          gridApi.api.showNoRowsOverlay()
        } else {
          gridApi.api.hideOverlay()
        }
      }
    }
  }, [loading])

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
    },
    []
  )

  const rowStyle = (params: any) => {
    if (!params.data.isLive) {
      return { backgroundColor: '#e5e5e5' }
    }
  }

  return (
    <div className="ag-theme-balham aggress-attempts">
      <div className={styles.topMenu}>
        <span className={styles.topMenuSpan}>
          <input type="checkbox" checked={igState} onChange={igChanged} />
          <span onClick={igChanged}>IG</span>
        </span>
        <span className={styles.topMenuSpan}>
          <input type="checkbox" checked={hyState} onChange={hyChanged} />
          <span onClick={hyChanged}>HY</span>
        </span>
        <span className={styles.topMenuSpan}>
          <input type="checkbox" checked={liveState} onChange={liveChanged} />
          <span onClick={liveChanged}>Live</span>
        </span>
      </div>
      <div className={styles.gridWrapper}>
        <AgGridReact
          ref={gridRef}
          rowData={passiveOrdersRowData}
          columnDefs={admin ? adminColumnDefinitions : columnDefinitions}
          defaultColDef={defaultColumnDefinitions}
          overlayNoRowsTemplate="No orders found."
          overlayLoadingTemplate="Loading passive orders..."
          alwaysShowVerticalScroll={true}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          suppressDragLeaveHidesColumns={true}
          rowClass="aggress-attempts-grid-row"
          rowSelection={'single'}
          onGridReady={onGridReady}
          getRowStyle={rowStyle}
          enableCellTextSelection={true}
          ensureDomOrder={true}
        />
      </div>
    </div>
  )
}

export default PassiveOrdersGrid
