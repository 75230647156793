import { ValidationResult } from '../../store/order/types'
import { OrderRowInfo } from '../../store/upload/types'

export const initialRowInfo: OrderRowInfo = {
  index: 0,
  identifier: '',
  bidAmt: '',
  bidPrice: '',
  bidSprd: '',
  ofrAmt: '',
  ofrPrice: '',
  ofrSprd: '',
  bidAon: false,
  ofrAon: false
}

// this will give the rows their 'correct' indices
export const displayInitialTableRows = (
  numRows: number,
  startIndex: number = 0
) =>
  Array(numRows)
    .fill(initialRowInfo)
    .map((row, index) => ({ ...row, index: index + startIndex }))

export const pasteAndConvertAnArray = (content: string) => {
  const result = []
  if (content.includes('\n')) {
    const multiLineArray = content.split('\n')

    multiLineArray.forEach((element) => {
      if (element) {
        result.push(element.split('\t'))
      }
    })
  } else if (content.includes('\t')) {
    result.push(content.split('\t'))
  }
  return result
}

const trimPrice = (price: any) => price?.replace('$', '').trim() || ''

const simpleValueParse = (value: string | undefined) => value?.trim() || ''

const allOrNoneParse = (value: string | undefined) =>
  value?.toLowerCase().endsWith('a') || false

export const orderIsValidForIdentifier = (identifier: string) => {
  const digits = /\d+/
  return digits.test(identifier)
}

export const createArrayUploadOrder = (
  pastValue: string,
  indexInsert: number
): OrderRowInfo[] =>
  pasteAndConvertAnArray(pastValue)
    .filter((content) => content[0].trim() !== headersToCopy[0])
    .map((content, index) => {
      const identifier = simpleValueParse(content[0])
      const canCreateOrder = orderIsValidForIdentifier(identifier)

      return {
        index: indexInsert + index,
        identifier,
        bidAmt: canCreateOrder ? simpleValueParse(content[1]) : '',
        bidAon: canCreateOrder ? allOrNoneParse(content[1]) : false,
        bidPrice: canCreateOrder ? trimPrice(content[2]) : '',
        bidSprd: canCreateOrder ? simpleValueParse(content[3]) : '',
        ofrSprd: canCreateOrder ? simpleValueParse(content[4]) : '',
        ofrPrice: canCreateOrder ? trimPrice(content[5]) : '',
        ofrAmt: canCreateOrder ? simpleValueParse(content[6]) : '',
        ofrAon: canCreateOrder ? allOrNoneParse(content[6]) : false
      }
    })

export const headersToCopy = [
  'Ticker / ISIN / CUSIP',
  'Bid Amt',
  'Bid Price',
  'Bid Spread',
  'Ofr Spread',
  'Ofr Price',
  'Ofr Amt'
]

export const reduceDataToCopy = (data: OrderRowInfo[]) => {
  const clipboardString: string = data
    .map((row) =>
      [
        row.identifier,
        row.bidAmt,
        row.bidPrice,
        row.bidSprd,
        row.ofrSprd,
        row.ofrPrice,
        row.ofrAmt
      ].join('\t')
    )
    .join('\t\n')
  return headersToCopy.join('\t') + '\n' + clipboardString
}

export const invalidDataToCopy = (data: ValidationResult[]) => {
  return data
    .map((row) =>
      [row.isin, row.orderType === 'buy' ? 'Bid' : 'Ofr'].join('\t')
    )
    .join('\t\n')
}
