import React, { FC } from 'react'
import { OrderStatus } from '../../../store/order/types'

import styles from './modalActionsButtons.scss'

export interface Props {
  orderStatusComp?: OrderStatus | undefined
  onModalClosed?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onButtonClicked?: (e: React.MouseEvent<HTMLButtonElement>) => void
  fontClassName?: string
  type?: string
  disabled?: boolean
}

const ModalActionsButtons: FC<Props> = (props) => {
  const {
    orderStatusComp,
    onModalClosed,
    onButtonClicked,
    fontClassName,
    type,
    disabled
  } = props
  let buttonsComponents
  switch (orderStatusComp) {
    case 'accepted':
      buttonsComponents = (
        <>
          <button
            className={`${styles.btnCloseAlone} ${styles.btnCloseAloneSuccess} `}
            data-testid="aggressor-close"
            onClick={onModalClosed}
          >
            Close
          </button>
        </>
      )
      break
    case 'waitingForConfirmation':
      buttonsComponents = (
        <>
          <button
            className={`${styles.btnCloseAlone} ${styles.btnCloseAlonePending} `}
            data-testid="aggressor-cancel"
            onClick={onButtonClicked}
          >
            Cancel Attempt
          </button>
        </>
      )
      break
    case 'error':
    case 'cancelled':
      buttonsComponents = (
        <div className={styles.buttonsComponents}>
          <button
            className={styles.btnClose}
            data-testid="aggressor-close"
            onClick={onModalClosed}
            type="button"
          >
            Close
          </button>
          <button
            className={`${styles.btnSellBuy} ${fontClassName}`}
            data-testid="aggressor-status"
            type="submit"
            disabled={disabled}
          >
            Resubmit
          </button>
        </div>
      )
      break
    case 'rejected':
      buttonsComponents = (
        <>
          <button
            className={`${styles.btnCloseAlone} ${styles.btnCloseAloneRejected} `}
            data-testid="aggressor-close"
            onClick={onModalClosed}
          >
            Close
          </button>
        </>
      )
      break
    case 'notAvailable':
      buttonsComponents = (
        <>
          <button
            className={styles.btnCloseAlone}
            data-testid="aggressor-close"
            onClick={onModalClosed}
          >
            Close
          </button>
        </>
      )
      break
    default:
      buttonsComponents = (
        <>
          <button
            data-testid="aggressor-close"
            className={styles.btnClose}
            type="button"
            onClick={onModalClosed}
          >
            Close
          </button>
          <button
            data-testid="aggressor-btnsellbuy"
            type="submit"
            className={`${styles.btnSellBuy} ${fontClassName}`}
            disabled={disabled}
          >
            {type}
          </button>
        </>
      )
      break
  }

  return buttonsComponents
}

export default ModalActionsButtons
