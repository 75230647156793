import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, merge, of, timer } from 'rxjs'
import {
  catchError,
  map,
  mapTo,
  mergeMap,
  mergeMapTo,
  switchMap,
  tap
} from 'rxjs/operators'
import config from '../../config'
import { getNow } from '../../helpers/auth'
import { getHub } from '../../helpers/hub'
import { fetchAuthToken, redirectToLogin } from '../auth/actions'
import { getAuthToken } from '../auth/selectors'
import { beginHeartbeating } from '../heartbeat/actions'
import {
  fetchUserPermissions,
  fetchUserPreferences
} from '../userPreferences/actions'
import {
  adjustServerTimeDelaySuccess,
  AdjustServerTimeDelaySuccessAction,
  connectFailure,
  connectSuccess,
  logError,
  LogErrorAction,
  LogServerMetadataAction,
  WsConnectAction
} from './actions'

const connectEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('ws.connect'),
    mergeMap((action: WsConnectAction) => {
      const state = state$.value
      const authToken = getAuthToken(state)
      return getHub()
        .start(config.api.url, authToken ?? '')
        .pipe(
          switchMap(() =>
            of(
              connectSuccess(),
              fetchUserPreferences(),
              fetchUserPermissions(),
              beginHeartbeating()
            )
          ),
          catchError((err) => {
            if (err?.message === 'Unauthorized') {
              return of(
                connectFailure(err),
                logError('Invalid token, redirecting to login…'),
                redirectToLogin()
              )
            }
            console.log(err)
            return merge(
              of(connectFailure(err), logError(err)),
              timer(config.api.reconnectDelay).pipe(mapTo(action))
            )
          })
        )
    })
  )

const adjustServerTimeDelayEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('ws.adjust-server-time'),
    mergeMap(() => {
      const startTime = getNow()
      return getHub()
        .invoke<string>('GetTime')
        .pipe(
          map((time) => {
            const endTime = getNow()
            const serverTime = new Date(time).valueOf()
            const delay = serverTime - (endTime + startTime) / 2
            return adjustServerTimeDelaySuccess(delay)
          })
        )
    }),
    catchError((err) => of(logError(err)))
  )

const refreshTokenWhenWeKnowServerTimeDelay: Epic = (action$) =>
  action$.pipe(
    ofType<AdjustServerTimeDelaySuccessAction>('ws.adjust-server-time-success'),
    mapTo(fetchAuthToken(true))
  )

const logErrorsEpic: Epic = (action$) =>
  action$.pipe(
    ofType<LogErrorAction>('ws.log-error'),
    // tslint:disable-next-line: no-console
    tap((action) => console.error(action.payload)),
    mergeMapTo(EMPTY)
  )

const logServerMetadataEpic: Epic = (action$) =>
  action$.pipe(
    ofType<LogServerMetadataAction>('ws.log-server-metadata'),
    mergeMap(() => getHub().invoke('GetServerMetadata')),
    // eslint-disable-line no-console
    tap((metadata) => console.log('Server metadata:', metadata)),
    mergeMapTo(EMPTY)
  )

export default combineEpics(
  connectEpic,
  adjustServerTimeDelayEpic,
  logErrorsEpic,
  logServerMetadataEpic,
  refreshTokenWhenWeKnowServerTimeDelay
)
