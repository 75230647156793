import cx from 'classnames'
import React, { FC, useState } from 'react'
import { useOpenFin } from '../../../app/openFinContext'
import EditableTiers from './EditableTiers'
import LiveTiers from './LiveTiers'
import styles from './Tiers.scss'

const Tiers: FC = () => {
  const { fin } = useOpenFin()
  const [editableSelected, setEditableSelected] = useState<boolean>(true)

  return (
    <>
      <div className={fin && styles.finWindow}>
        <div className={styles.tierDiv}>
          <span
            className={cx(
              editableSelected && styles.tabSelected,
              styles.tiersTab,
              fin && styles.finTiersTab
            )}
            onClick={() => setEditableSelected(true)}
          >
            Editable Tiers
          </span>
          <span
            className={cx(
              !editableSelected && styles.tabSelected,
              styles.tiersTab,
              fin && styles.finTiersTab
            )}
            onClick={() => setEditableSelected(false)}
          >
            Live Tiers
          </span>
        </div>
        {editableSelected && <EditableTiers />}
        {!editableSelected && <LiveTiers />}
      </div>
    </>
  )
}

export default Tiers
