import React, { FC } from 'react'
import Checkbox from '../Checkbox'
import styles from './checkBoxLabelAndInput.scss'

interface Props {
  checked: boolean
  onChangeCheckBox: (e: any) => void
  labelCheckBox: string
  isNumber: boolean
  inputValue: string
  onChangeInput: (e: any) => void
  otherContent?: string
  disabled?: boolean
  testid: string
}

const CheckBoxLabelAndInput: FC<Props> = (props: Props) => {
  const {
    checked,
    inputValue,
    onChangeCheckBox,
    labelCheckBox,
    isNumber,
    onChangeInput: onchangeInput,
    otherContent,
    disabled,
    testid
  } = props

  const locator = labelCheckBox.toLowerCase().replace(/ /g, '-')

  return (
    <div className={styles.checkBoxAndInput}>
      <Checkbox
        locator={locator}
        onChange={onChangeCheckBox}
        fillColor="#43A980"
        checked={checked}
        disabled={disabled}
      >
        {labelCheckBox}
      </Checkbox>
      <input
        className={styles.inputPreference}
        type={isNumber ? 'number' : 'text'}
        value={inputValue}
        onChange={onchangeInput}
        disabled={disabled || !checked}
        required={checked}
        data-testid={testid + '-input'}
      />{' '}
      {otherContent}
    </div>
  )
}

export default CheckBoxLabelAndInput
