import cx from 'classnames'
import {
  OrderFields,
  OrderManager
} from '../../../helpers/hooks/useManageMyOrders'
import { Security } from '../../../store/securities/reducer'
import styles from '../bondListStyle.scss'
import React from 'react'

interface OrderFieldParams {
  manager: OrderManager
  field: OrderFields
  security: Security | undefined
}

export const MyOrderRenderer = ({
  manager,
  field,
  security
}: OrderFieldParams) => {
  const styleConditions = manager.getStyleConditions(security?.id ?? 0, field)
  const classNames = cx(styles.item, {
    [styles.hasError]: styleConditions.hasError,
    [styles.isPending]: styleConditions.isPending,
    [styles.hasFocus]: styleConditions.hasFocus
  })

  const displayValue = manager.getInitialFieldValueForSecurity(
    security?.id ?? -1,
    field
  )
  return (
    <span
      className={classNames}
      data-testid={`my${manager.orderTypeName}${field}-${security?.isin ?? ''}`}
    >
      {displayValue}
    </span>
  )
}
