import { ColDef, ColGroupDef, RowClassParams } from '@ag-grid-community/core'
import { columnDefinitions } from '../../containers/BondList/columnDefs'
import { Security } from '../../store/securities/reducer'

export function isEqual<T, K extends keyof T>(a: T, b: T): boolean {
  if (a === b) return true
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
    return a === b
  }
  if (!a || !b) {
    return false
  }
  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false
  return keys.every((k) => isEqual(a[k as K], b[k as K]))
}

export const applyColumnsOrder = (
  gridIndex: number,
  columnsOrder: string[] | undefined
) => {
  const colDef = columnDefinitions(gridIndex)
  const columnDefs = []
  let bidPrice = true
  let bidSpread = true
  let offerPrice = true
  let offerSpread = true

  if (columnsOrder && columnsOrder.includes('myBidSize')) {
    bidPrice = columnsOrder.includes('bestBidPrice')
    bidSpread = columnsOrder.includes('bestBidSpread')
    offerPrice = columnsOrder.includes('bestOfferPrice')
    offerSpread = columnsOrder.includes('bestOfferSpread')

    // TODO: filter by ColDef vs ColDefGroup to make all of this more clear
    for (const c of columnsOrder) {
      // pinnedRightColumns is an array with a single ColGroupDef, which does not have a colId
      // we cast to this for TS, though a missing colId is harmless here
      const col = colDef.find((column) => (column as ColDef).colId === c)

      if (col) {
        col.hide = false
        columnDefs.push(Object.assign({}, col))
      }
    }
    for (const c of colDef) {
      const col = columnDefs.find(
        (column) => (column as ColDef).colId === (c as ColDef).colId
      )
      if ((c as ColGroupDef).groupId) {
        // we are mutating the children, not really mapping them (could be forEach here)
        ;(c as ColGroupDef).children.map((child: ColDef) => {
          if (
            (child.colId === 'myBidPrice' && bidPrice) ||
            (child.colId === 'myBidSpread' && bidSpread) ||
            (child.colId === 'myOfferSpread' && offerSpread) ||
            (child.colId === 'myOfferPrice' && offerPrice)
          ) {
            child.hide = false
          }
          return child
        })
        columnDefs.push(Object.assign({}, c))
      } else if (!col) {
        c.hide = true
        columnDefs.push(Object.assign({}, c))
      }
    }
  } else {
    if (columnsOrder) {
      for (const c of columnsOrder) {
        const col = colDef.find((column) => (column as ColDef).colId === c)

        if (col) {
          col.hide = false
          columnDefs.push(Object.assign({}, col))
        }
      }
      for (const c of colDef) {
        const col = columnDefs.find(
          (column) => (column as ColDef).colId === (c as ColDef).colId
        )
        if ((c as ColGroupDef).groupId) {
          columnDefs.push(Object.assign({}, c))
        } else if (!col) {
          c.hide = true
          columnDefs.push(Object.assign({}, c))
        }
      }
    }
    return columnsOrder ? columnDefs : colDef
  }
  return columnDefs
}

export const lastRowBorder = ({ node, api }: RowClassParams<Security>) => {
  if (node.rowIndex === api.getDisplayedRowCount() - 1) {
    return { borderBottom: '2px solid #f1f1f1' }
  }
}
