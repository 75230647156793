import { SecurityOrderData } from '../securities/reducer'
import { OrderType, TobParameters } from './types'

const sizeMax = 20000

export const errorMessages = {
  INVALID_BID_PRICE_OR_SIZE: 'Invalid bid price or size.',
  INVALID_OFFER_PRICE_OR_SIZE: 'Invalid offer price or size.',
  BID_PRICE_GREATER_THAN_OFFER_PRICE:
    'Crossing or locking markets is not allowed. Bid price must be lower than offer price.',
  BID_SPREAD_LOWER_THAN_OFFER_SPREAD:
    'Crossing or locking markets is not allowed. Bid price must be lower than offer price.',
  OFFER_SPREAD_GREATER_THAN_BID_SPREAD:
    'Crossing or locking markets is not allowed. Offer price must be greater than bid price.',
  OFFER_PRICE_LOWER_THAN_BID_PRICE:
    'Crossing or locking markets is not allowed. Offer price must be greater than bid price.',
  BID_SIZE_EXCEEDS_MAX: (size: number, max: number) =>
    `Bid size of ${size} exceeds the system-wide maximum of ${max}`,
  OFFER_SIZE_EXCEEDS_MAX: (size: number, max: number) =>
    `Order size of ${size} exceeds the system-wide maximum of ${max}`,
  BID_SIZE_LOWER_THAN_TRADER_PREFERENCE:
    'Bid size must be greater than min size.',
  OFFER_SIZE_LOWER_THAN_TRADER_PREFERENCE:
    'Offer size must be greater than min size.',
  SIZE_LOWER_THAN_INDIVIDUAL_MIN: 'Size must be greater than min size.',
  TOB_ORDER_WITHOUT_LIMIT_PRICE: 'TOB order without limit price.',
  TOB_ORDER_WITHOUT_FLOOR_PRICE: 'TOB order without floor price.',
  TOB_ORDER_LIMIT_PRICE_LOWER_THAN_FLOOR_PRICE:
    'TOB limit price must be greater than floor price.',
  TOB_ORDER_LIMIT_PRICE_GREATER_THAN_FLOOR_PRICE:
    'TOB limit price must be lower than floor price.',
  TOB_ORDER_LIMIT_SPREAD_LOWER_THAN_FLOOR_SPREAD:
    'TOB limit spread must be greater than floor spread.',
  TOB_ORDER_LIMIT_SPREAD_GREATER_THAN_FLOOR_SPREAD:
    'TOB limit spread must be less than floor spread.'
}

export const validate = (
  security: SecurityOrderData,
  orderType: OrderType,
  price: number,
  isSpread: boolean,
  size: number,
  individualMin: number | undefined, // todo: update so that individualmin is never undefined
  traderPrefMinimum: number,
  tob: TobParameters | null
) => {
  if (tob) {
    if (tob.floorPrice && !tob.limitPrice) {
      return errorMessages.TOB_ORDER_WITHOUT_LIMIT_PRICE
    }
    if (!tob.floorPrice && tob.limitPrice) {
      return errorMessages.TOB_ORDER_WITHOUT_FLOOR_PRICE
    }
    if (tob.floorPrice && tob.limitPrice) {
      if (orderType === 'buy') {
        if (isSpread) {
          if (tob.floorPrice < tob.limitPrice) {
            return errorMessages.TOB_ORDER_LIMIT_SPREAD_GREATER_THAN_FLOOR_SPREAD
          }
        } else {
          if (tob.floorPrice > tob.limitPrice) {
            return errorMessages.TOB_ORDER_LIMIT_PRICE_LOWER_THAN_FLOOR_PRICE
          }
        }
      } else {
        if (isSpread) {
          if (tob.floorPrice > tob.limitPrice) {
            return errorMessages.TOB_ORDER_LIMIT_SPREAD_LOWER_THAN_FLOOR_SPREAD
          }
        } else {
          if (tob.floorPrice < tob.limitPrice) {
            return errorMessages.TOB_ORDER_LIMIT_PRICE_GREATER_THAN_FLOOR_PRICE
          }
        }
      }
      price = tob.floorPrice
    }
  }
  if ((!isSpread && price < 0) || size < 0 || size % 1 !== 0 || isNaN(price)) {
    // Negative price or size
    return orderType === 'buy'
      ? errorMessages.INVALID_BID_PRICE_OR_SIZE
      : errorMessages.INVALID_OFFER_PRICE_OR_SIZE
  }

  if (size > sizeMax) {
    // Too high size
    if (orderType === 'buy') {
      return errorMessages.BID_SIZE_EXCEEDS_MAX(size, sizeMax)
    } else {
      return errorMessages.OFFER_SIZE_EXCEEDS_MAX(size, sizeMax)
    }
  }

  if (!individualMin && size < traderPrefMinimum) {
    return orderType === 'buy'
      ? errorMessages.BID_SIZE_LOWER_THAN_TRADER_PREFERENCE
      : errorMessages.OFFER_SIZE_LOWER_THAN_TRADER_PREFERENCE
  }

  if (individualMin && size < individualMin) {
    return errorMessages.SIZE_LOWER_THAN_INDIVIDUAL_MIN
  }

  const otherOrder =
    orderType === 'buy' ? security?.bestOffer : security?.bestBid
  if (otherOrder) {
    // Too high price
    if (!isSpread) {
      if (orderType === 'buy' && price >= otherOrder.price) {
        return errorMessages.BID_PRICE_GREATER_THAN_OFFER_PRICE
      } else if (orderType === 'sell' && price <= otherOrder.price) {
        return errorMessages.OFFER_PRICE_LOWER_THAN_BID_PRICE
      }
    } else {
      if (
        orderType === 'buy' &&
        otherOrder.spread !== undefined &&
        price <= otherOrder.spread
      ) {
        return errorMessages.BID_SPREAD_LOWER_THAN_OFFER_SPREAD
      } else if (
        orderType === 'sell' &&
        otherOrder.spread !== undefined &&
        price >= otherOrder.spread
      ) {
        return errorMessages.OFFER_SPREAD_GREATER_THAN_BID_SPREAD
      }
    }
  }
}

const errorMsg = {
  INVALID_PRICE_OR_SIZE: `Invalid bid/offer price or size.`,
  BID_PRICE_GREATER_THAN_OFFER_PRICE:
    'Crossing or locking markets is not allowed. Bid price must be lower than offer price.',
  OFFER_PRICE_LOWER_THAN_BID_PRICE:
    'Crossing or locking markets is not allowed. Offer price must be greater than bid price.',
  BID_SPREAD_LESS_THAN_OFFER_SPREAD:
    'Crossing or locking markets is not allowed. Bid spread must be greater than offer spread.',
  OFFER_SPREAD_GREATER_THAN_BID_SPREAD:
    'Crossing or locking markets is not allowed. Offer spread must be less than bid spread.'
}

export const validateOrder = (
  orderType: OrderType,
  bIsSpread: boolean,
  oIsSpread: boolean,
  bidPrc?: number,
  ofrPrc?: number,
  bidSz?: number,
  ofrSz?: number
) => {
  if ((bidSz && bidSz % 1 !== 0) || (ofrSz && ofrSz % 1 !== 0)) {
    return errorMsg.INVALID_PRICE_OR_SIZE
  }

  if ((bidSz && bidSz > sizeMax) || (ofrSz && ofrSz > sizeMax)) {
    const bidSzErr =
      bidSz && bidSz > sizeMax ? `Bid size of ${bidSz}` : undefined
    const ofrSzErr =
      ofrSz && ofrSz > sizeMax ? `Offer size of ${ofrSz}` : undefined

    if (bidSzErr && ofrSzErr) {
      return `${bidSzErr} and ${ofrSzErr} exceeds system-wide maximum of ${sizeMax}`
    }

    return bidSzErr
      ? `${bidSzErr} exceeds system-wide maximum of ${sizeMax}`
      : `${ofrSzErr} exceeds system-wide maximum of ${sizeMax}`
  }

  if (
    orderType === 'buy' &&
    bidPrc &&
    ofrPrc &&
    bIsSpread &&
    oIsSpread &&
    bidPrc <= ofrPrc
  ) {
    return errorMsg.BID_SPREAD_LESS_THAN_OFFER_SPREAD
  }

  if (
    orderType === 'sell' &&
    bidPrc &&
    ofrPrc &&
    bIsSpread &&
    oIsSpread &&
    ofrPrc >= bidPrc
  ) {
    return errorMsg.OFFER_SPREAD_GREATER_THAN_BID_SPREAD
  }

  if (
    orderType === 'buy' &&
    bidPrc &&
    ofrPrc &&
    !bIsSpread &&
    !oIsSpread &&
    bidPrc >= ofrPrc
  ) {
    return errorMsg.BID_PRICE_GREATER_THAN_OFFER_PRICE
  }

  if (
    orderType === 'sell' &&
    bidPrc &&
    ofrPrc &&
    !bIsSpread &&
    !oIsSpread &&
    ofrPrc <= bidPrc
  ) {
    return errorMsg.OFFER_PRICE_LOWER_THAN_BID_PRICE
  }
}
