import cx from 'classnames'
import React from 'react'
import HitLiftButton from '../../../components/HitLiftButton/HitLiftButton'
import { OrderType } from '../../../store/order/types'
import styles from '../bondListStyle.scss'
import { CellProps, useSecurityOrderData } from './helpers'

const HitLiftCell = (orderType: OrderType) => ({ data }: CellProps) => {
  const security = useSecurityOrderData(data)
  if (!security) return <> </>

  return (
    <span className={cx(styles.bestOrder)}>
      <HitLiftButton
        security={security}
        type={orderType === 'buy' ? 'hit' : 'lift'}
      />
    </span>
  )
}

export default HitLiftCell
