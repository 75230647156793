import { writeLogItems } from './actions'

export const removeRecords = (
  remove: number[],
  rec: Record<number, string>
) => {
  const newRec: Record<number, string> = {}
  for (const key in rec) {
    if (rec.hasOwnProperty(key)) {
      const numKey = Number(key)
      if (remove.indexOf(numKey) < 0) {
        newRec[key] = rec[key]
      }
    }
  }
  return newRec
}

export const addRecord = (
  id: number,
  log: string,
  rec: Record<number, string>
) => {
  const newRec: Record<number, string> = {}
  for (const key in rec) {
    if (rec.hasOwnProperty(key)) {
      const numKey = Number(key)
      newRec[numKey] = rec[key]
    }
  }
  newRec[id] = log
  return newRec
}

export const addRecords = (
  id: number,
  log: string[],
  rec: Record<number, string>
) => {
  const newRec: Record<number, string> = {}
  for (const key in rec) {
    if (rec.hasOwnProperty(key)) {
      const numKey = Number(key)
      newRec[numKey] = rec[key]
    }
  }
  for (const st of log) {
    newRec[id] = st
    id++
  }

  return newRec
}

export const initLog = () => {
  setInterval(() => {
    writeLogItems()
  }, 5000)
}
