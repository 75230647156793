import cx from 'classnames'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import {
  getBook,
  getBooks,
  getDefaultBookId
} from '../../store/books/selectors'
import { defaultFilter } from '../../store/filter/types'
import { addOrUpdateElement } from '../../store/helpers'
import { getOrderValidations } from '../../store/order/selectors'
import { setIsMine } from '../../store/securities/actions'
import { getIsMine } from '../../store/securities/selectors'
import {
  createNewWatchlistWithIdentifiers,
  resetInvalidIdentifiers,
  resetNewWatchlistTransactionId,
  resetUploadOrdersServerError,
  toggleDropwdownState,
  uploadOrders
} from '../../store/upload/actions'
import {
  isRowEmpty,
  reduceRowsToRemoveEmptyRows
} from '../../store/upload/helpers'
import {
  getDropdownState,
  getInvalidIdentifiers,
  getState as getUploadState
} from '../../store/upload/selectors'
import { OrderRowInfo } from '../../store/upload/types'
import { getResponseForPermission } from '../../store/watchList/helpers'
import { getDetailsForCurrentWatchlist } from '../../store/watchList/selectors'
import { WatchlistPermission } from '../../store/watchList/types'
import {
  createArrayUploadOrder,
  displayInitialTableRows,
  headersToCopy,
  initialRowInfo,
  invalidDataToCopy,
  orderIsValidForIdentifier,
  reduceDataToCopy
} from './helpers'
import NewWatchListInput from './NewWatchListInput'
import styles from './uploadDropDownMenu.scss'
import UploadMenuFooter from './UploadMenuFooter'
import UploadMenuHeader from './UploadMenuHeader'
import UploadTable from './UploadTable'

interface Props {
  gridIndex: number
  myOrdersOpen: boolean
  toggleMyOrders: () => void
}

const dateFormat = 'M/DD/YY, h:mm a'
const UploadDropDownMenu: React.FC<Props> = ({
  gridIndex,
  myOrdersOpen,
  toggleMyOrders
}) => {
  const { fin } = useOpenFin()
  const [processing, setProcessing] = useState(false)

  const [rows, setRows] = useState<OrderRowInfo[]>(displayInitialTableRows(18))
  const [rowToFocus, setRowToFocus] = useState(0)
  const [watchlistName, setWatchlistName] = useState(dayjs().format(dateFormat))

  const books = useSelector(getBooks)
  const thisBook = useSelector(getBook)
  const defaultBookId = useSelector(getDefaultBookId)
  const permissions = ['My Firm', 'Private']
  const [book, setBook] = useState(defaultBookId)
  const [permission, setPermission] = useState(permissions[0])
  const dispatch = useDispatch()
  const isMine = useSelector(getIsMine)(gridIndex)
  const invalidIdentifiers = useSelector(getInvalidIdentifiers)
  const [errorMessage, setErrorMessage] = useState('')
  const validations = useSelector(getOrderValidations)
  const dropdownState = useSelector(getDropdownState)(gridIndex)
  const { serverErrorMessage } = useSelector(getUploadState)

  const currentWatchlist = useSelector(getDetailsForCurrentWatchlist)(gridIndex)
  const invalidOrderMsg = "Can't create orders for Tickers."

  useEffect(() => {
    if (validations.length > 1) {
      dispatch(toggleDropwdownState(gridIndex, 'invalidUpload'))
    }
  }, [validations])

  useEffect(() => {
    if (errorMessage === invalidOrderMsg) return

    const separator = serverErrorMessage.length > 0 ? '\n' : ''
    if (serverErrorMessage) {
      setProcessing(false)
    }
    if (invalidIdentifiers) {
      setProcessing(false)
      setErrorMessage(
        invalidIdentifiers.length > 1
          ? `${serverErrorMessage}${separator}(${invalidIdentifiers.length}) securities were not recognized`
          : `${serverErrorMessage}${separator}(${invalidIdentifiers.length}) security was not recognized`
      )
    } else {
      if (!rows.every(isRowEmpty) && !serverErrorMessage) {
        setErrorMessage('')
      } else if (serverErrorMessage) {
        setErrorMessage(serverErrorMessage)
      }
    }
  }, [
    errorMessage,
    invalidIdentifiers,
    setErrorMessage,
    rows,
    serverErrorMessage
  ])

  useEffect(() => {
    setBook(defaultBookId)
  }, [defaultBookId])

  useEffect(() => {
    if (errorMessage === invalidOrderMsg) return
    switch (dropdownState) {
      case 'upload':
        if (
          !invalidIdentifiers?.length &&
          currentWatchlist?.symbolsAndCusips?.length
        ) {
          const newRows = createArrayUploadOrder(
            currentWatchlist.symbolsAndCusips.join('\n'),
            0
          )
          const extraRows =
            newRows.length < 18
              ? displayInitialTableRows(18 - newRows.length, newRows.length)
              : displayInitialTableRows(1, newRows.length)
          setRows(newRows.concat(extraRows))
          setRowToFocus(newRows.length)
          setWatchlistName(currentWatchlist?.name ?? dayjs().format(dateFormat))
        }
        break
      case 'newWatchlist':
        setWatchlistName(dayjs().format(dateFormat))
      /* falls through */
      case 'closed':
        setProcessing(false)
        clearAllRows()
        break
    }
  }, [
    dropdownState,
    currentWatchlist,
    invalidOrderMsg,
    errorMessage
    /* intentionally left out of dependency list bc it triggers too often */
    /* invalidIdentifiers*/
  ])

  useEffect(() => {
    if (invalidIdentifiers) {
      const invalidIds = invalidIdentifiers.map((ids) => ids.index)
      // const errorOrders = rows.filter(row => invalidIds.includes(row.index))
      // TP - 11/3/2020 fix. If watchlist upload render issues are fixed, revert this.
      const errorOrders = invalidIdentifiers.map((id) => id.orderRowInfo)

      setRowToFocus(-1)
      if (invalidIds.length < 18) {
        const newRows = errorOrders
          .concat(displayInitialTableRows(18 - invalidIds.length))
          .map((row, i) => ({ ...row, index: i }))
        setRows(newRows)
      } else {
        setRows(errorOrders)
      }
    }
  }, [invalidIdentifiers])

  const convertRows = () => {
    return rows.map((r) => {
      return {
        index: r.index,
        identifier: r.identifier,
        bidAmt: r.bidAmt?.replace('a', ''),
        bidPrice: r.bidPrice,
        bidSprd: r.bidSprd,
        ofrAmt: r.ofrAmt?.replace('a', ''),
        ofrPrice: r.ofrPrice,
        ofrSprd: r.ofrSprd,
        bidAon: r.bidAmt?.toLowerCase().endsWith('a'),
        ofrAon: r.ofrAmt?.toLowerCase().endsWith('a')
      }
    })
  }

  const isRowMatching = (rowOne: OrderRowInfo, rowTwo: OrderRowInfo) =>
    rowOne.index === rowTwo.index

  const addOrUpdateRows = useCallback(
    (newRow: OrderRowInfo) => {
      let newRows: OrderRowInfo[] = []
      if (newRow.index + 1 === rows.length) {
        // add an additional (empty) row AND update the newRow received
        newRows = addOrUpdateElement(rows, newRow, isRowMatching)
        const additionalRow = { ...initialRowInfo, index: rows.length }
        setRows([...newRows, additionalRow])
        setRowToFocus(newRows.length + 1)
      } else {
        // update the newRow received
        newRows = addOrUpdateElement(rows, newRow, isRowMatching)
        setRows(newRows)
      }
    },
    [rows]
  )

  const clearAllRows = () => {
    const clearRows = displayInitialTableRows(18)
    setRows(clearRows)
    setErrorMessage('')
    dispatch(resetInvalidIdentifiers())
    dispatch(resetUploadOrdersServerError())
    setRowToFocus(-1)
  }

  const pasteRows = (index: number, e: React.ClipboardEvent) => {
    const newRows = createArrayUploadOrder(
      e.clipboardData.getData('text'),
      index
    )
    const newPasteRows: OrderRowInfo[] = [
      ...rows.slice(0, index),
      ...newRows,
      ...rows.slice(index)
    ].map((elem, index2) => {
      elem.index = index2
      return elem
    })
    setRows(newPasteRows)
    setRowToFocus(-1)
  }

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      const splitForm: any = (e.target as any).dataset.testidValue.split('-')
      e.preventDefault()
      setRowToFocus(Number(splitForm[1]) + 1)
    }
  }

  const checkForInvalidOrders = (orders: OrderRowInfo[]) => {
    for (const order of orders) {
      const { index, identifier, ...orderWithoutIds } = order
      const valuesToString = Object.values(orderWithoutIds)
        .map((value) => value || '')
        .join('')
      if (!orderIsValidForIdentifier(identifier) && valuesToString.length > 0) {
        return true
      }
    }
    return false
  }

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      setProcessing(true)
      dispatch(resetInvalidIdentifiers())
      dispatch(resetNewWatchlistTransactionId(gridIndex))
      dispatch(resetUploadOrdersServerError())
      const convertedRows = convertRows()
      const reducedOrders = reduceRowsToRemoveEmptyRows(convertedRows)
      const identifiers = reducedOrders.map((order) => order.identifier)
      const hasInvalidOrders = checkForInvalidOrders(reducedOrders)
      if (hasInvalidOrders) setProcessing(false)
      if (dropdownState === 'newWatchlist') {
        if (watchlistName === '') {
          setErrorMessage('Enter a watchlist name')
          setProcessing(false)
        } else if (hasInvalidOrders) {
          setErrorMessage(invalidOrderMsg)
          setProcessing(false)
          return
        }
        {
          dispatch(
            createNewWatchlistWithIdentifiers(
              gridIndex,
              watchlistName,
              identifiers,
              getResponseForPermission(
                permission.toLowerCase() as WatchlistPermission
              ),
              thisBook(book),
              reducedOrders,
              defaultFilter,
              false,
              false,
              200,
              false
            )
          )
        }
      }

      if (dropdownState === 'upload') {
        if (!hasInvalidOrders) {
          if (identifiers.length !== 0) {
            dispatch(uploadOrders(gridIndex, identifiers, rows, thisBook(book)))
          }
          if (!identifiers.length && currentWatchlist?.name === watchlistName) {
            setErrorMessage('Enter a valid Ticker/CUSIP/ISIN')
            setProcessing(false)
          }
        } else {
          setErrorMessage(invalidOrderMsg)
        }
      }

      if (hasInvalidOrders) return

      if (!myOrdersOpen) {
        toggleMyOrders()
      }

      if (isMine) {
        setTimeout(() => {
          dispatch(setIsMine(gridIndex, true))
        }, 100)
      }

      const clearRows = displayInitialTableRows(18)
      setRows(clearRows)
      setRowToFocus(0)
      setErrorMessage('')
    },
    [
      myOrdersOpen,
      rows,
      setRows,
      isMine,
      dropdownState,
      book,
      permission,
      watchlistName,
      currentWatchlist
    ]
  )

  const onCancel = () => {
    dispatch(toggleDropwdownState(gridIndex, 'closed'))
  }

  const onToggleNewWatchlist = () => {
    dispatch(toggleDropwdownState(gridIndex, 'newWatchlist'))
  }

  const title = rows.every((row) => isRowEmpty(row))
    ? 'Copy Headers'
    : 'Copy Data'

  const copyData = useCallback(() => {
    const areRowsEmpty = rows.every((row) => isRowEmpty(row))
    const dummyElement = document.createElement('textarea')
    document.body.appendChild(dummyElement)
    dummyElement.value =
      validations.length > 0
        ? invalidDataToCopy(validations)
        : areRowsEmpty
        ? headersToCopy.join('\t')
        : reduceDataToCopy(reduceRowsToRemoveEmptyRows(rows))

    dummyElement.select()
    // tslint:disable-next-line: no-floating-promises
    navigator.clipboard.writeText(dummyElement.value)
    document.body.removeChild(dummyElement)
  }, [rows, validations])

  return (
    <>
      {['upload', 'newWatchlist', 'invalidUpload'].includes(dropdownState) && (
        <div className={styles.dropdown}>
          <form
            data-testid="watchlist-upload-form"
            className={cx(
              styles.contentDropDownMenu,
              fin && styles.finDropDownMenu,
              dropdownState === 'invalidUpload' &&
                styles.invalidUploadMenuHeight
            )}
            onSubmit={onSubmit}
            onKeyDown={handleEnter}
          >
            {dropdownState === 'newWatchlist' ? (
              <NewWatchListInput
                watchlistName={watchlistName}
                setWatchlistName={setWatchlistName}
              />
            ) : (
              <></>
            )}
            <UploadMenuHeader
              clearAllRows={clearAllRows}
              errorMessage={errorMessage}
              dropdownState={dropdownState}
              toggleNewWatchlist={onToggleNewWatchlist}
              cancel={onCancel}
              copyData={copyData}
              title={title}
            />
            <UploadTable
              addOrUpdateRows={addOrUpdateRows}
              rows={rows}
              pasteRows={pasteRows}
              dropdownState={dropdownState}
              validations={validations}
              gridIndex={gridIndex}
              rowToFocus={rowToFocus}
            />
            <UploadMenuFooter
              gridIndex={gridIndex}
              cancel={onCancel}
              books={books}
              permissions={permissions}
              setBook={setBook}
              setPermission={setPermission}
              processing={processing}
              dropdownState={dropdownState}
              copy={copyData}
            />
          </form>
        </div>
      )}
    </>
  )
}

export default UploadDropDownMenu
