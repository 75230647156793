import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getActivitiesGroups } from '../../store/activity/selectors'
import { Activity } from '../../store/activity/types'
import {
  subscribeToAlerts,
  unsubscribeFromAlerts
} from '../../store/alerts/actions'

import { getOrderById } from '../../store/order/selectors'
import { getUserPreferences } from '../../store/userPreferences/selectors'

import {
  addEventListener,
  clear,
  create,
  getAll,
  IndicatorColor,
  NotificationActionEvent,
  NotificationsCountChanged
} from 'openfin-notifications'

import { popoutSecurity } from '../../store/depthOfMarket/actions'
import { addLogItem } from '../../store/log/actions'
import TradeConfirm from './TradeConfirm/TradeConfirm'

const ActivityNotifications: FC = () => {
  const userPreferences = useSelector(getUserPreferences)
  const [tradeConfirmId, setTradeConfirmId] = useState<number | null>(null)
  const getOrder = useSelector(getOrderById)
  const [alerts, setAlerts] = useState<any[]>([])
  const activitiesByGroup = useSelector(getActivitiesGroups)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(subscribeToAlerts())
    return () => {
      dispatch(unsubscribeFromAlerts())
    }
  }, [])

  useEffect(() => {
    addEventListener(
      'notifications-count-changed',
      (event: NotificationsCountChanged) => {
        if (event.count > 300) {
          const alertContainer: any[] = []
          getAll()
            .then((notifications) => {
              notifications.map((notification) => {
                if (notification.category === 'alert') {
                  alertContainer.push(notification)
                }
                if (alertContainer.length > 300) {
                  const cutdown = alertContainer.slice(0, -300)
                  cutdown.map((cutAlerts) => {
                    clear(cutAlerts.id).catch((err) =>
                      dispatch(addLogItem(err))
                    )
                  })
                }
              })
            })
            .catch((err) =>
              dispatch(addLogItem(`Error getting all notifications, ${err}`))
            )
        }
      }
    )
  }, [])

  useEffect(() => {
    // 'Activities change'
    activitiesByGroup.today.map((activity) => {
      if (activity.type !== 'alert') {
        const existingNotifications = localStorage.getItem(`notificationIds`)
        if (existingNotifications) {
          const existing = existingNotifications.split(',')
          if (!existing.includes(activity.id)) {
            // tslint:disable-next-line: no-floating-promises
            generateOpenfinNotification(activity)
            existing.push(activity.id)
            localStorage.setItem('notificationIds', existing.toString())
          }
        } else {
          // tslint:disable-next-line: no-floating-promises
          generateOpenfinNotification(activity)
          localStorage.setItem('notificationIds', activity.id)
        }
      } else {
        if (activity.alertType !== 'newOrder') {
          const displayedAlerts = sessionStorage.getItem('displayedAlerts')
          if (displayedAlerts) {
            const displayed = displayedAlerts.split(',')
            if (!displayed.includes(activity.id)) {
              setAlerts((activities) => [...activities, activity])
              displayed.push(activity.id)
              sessionStorage.setItem('displayedAlerts', displayed.toString())
            }
          } else {
            setAlerts((activities) => [...activities, activity])
            sessionStorage.setItem('displayedAlerts', activity.id)
          }
        }
      }
    })
  }, [activitiesByGroup.today])

  useEffect(() => {
    if (alerts.length > 0) {
      const timerFunc = setTimeout(() => {
        const [first, ...rest] = alerts
        setAlerts(rest)
        generateOpenfinNotification(first)
      }, 1000)

      return () => clearTimeout(timerFunc)
    }
  }, [alerts])

  // TODO: remove event listeners
  addEventListener('notification-action', (event: NotificationActionEvent) => {
    const { result } = event

    switch (result.task) {
      case 'view-details':
        const id = result.id.length === 6 ? result.id.substring(1) : result.id
        handleTradeConfirmClick(id)
        break
      case 'popout-alertdepth':
        dispatch(popoutSecurity(result.id))
        break
      case 'popout-depth':
        const order = getOrder(result.id)
        if (order) {
          dispatch(popoutSecurity(order.securityId))
        }
        break
    }
  })

  const handleTradeConfirmClick = useCallback((id) => {
    setTradeConfirmId(id === null ? id : Number(id.replace('O', '')))
  }, [])

  const generateOpenfinNotification = (finActivities: Activity) => {
    const getButtons = (finActivity: Activity) => {
      const buttons = []
      if (finActivity.type === 'trade') {
        buttons.push({
          cta: true,
          title: 'Details',
          onClick: {
            task: 'view-details',
            id: finActivity.id
          }
        })
      }
      if (!finActivity.isPending && !finActivity.hasAggressorActions) {
        buttons.push({
          cta: true,
          title: 'Market',
          onClick: {
            task: 'popout-depth',
            id: finActivity.id
          }
        })
      }

      return buttons
    }
    switch (finActivities.type) {
      case 'pendingTrade':
        if (!userPreferences.lastLookInPopup) {
          // tslint:disable-next-line: no-floating-promises
          create({
            indicator: {
              color:
                finActivities.cardColor === 'blue'
                  ? IndicatorColor.BLUE
                  : IndicatorColor.RED,
              text: finActivities.title
            },
            priority: 4,
            title: finActivities.description?.toString() ?? '',
            body: `${finActivities.badgeText}`,
            sticky: 'sticky',
            category: finActivities.type,
            id: finActivities.id,
            date: finActivities.date,
            expires: finActivities.expiration,
            buttons: getButtons(finActivities)
          })
        }
        break
      case 'trade':
      case 'tradeAttempt':
        // tslint:disable-next-line: no-floating-promises
        create({
          indicator: {
            color:
              finActivities.cardColor === 'blue'
                ? IndicatorColor.BLUE
                : IndicatorColor.RED,
            text: finActivities.title
          },
          priority: 3,
          title: finActivities.description?.toString() ?? '',
          body: `${finActivities.badgeText} ${
            finActivities.type === 'trade' ? finActivities.badgeInfo : ''
          }`,
          sticky: 'sticky',
          category: finActivities.type,
          id: finActivities.id,
          date: finActivities.date,
          expires: finActivities.expiration,
          buttons: getButtons(finActivities)
        })
        break
      case 'alert':
        // tslint:disable-next-line: no-floating-promises
        create({
          indicator: {
            color: IndicatorColor.YELLOW,
            text:
              finActivities.formattedTitle?.toString() ?? finActivities.title
          },
          priority: 2,
          title: '',
          sticky:
            finActivities.alertType === 'tradingNow' ? 'sticky' : 'transient',
          body: finActivities.description?.toString() ?? '',
          category: finActivities.type,
          id: finActivities.id,
          buttons: [
            {
              cta: true,
              title: 'Market',
              onClick: {
                task: 'popout-alertdepth',
                id: finActivities.securityId
              }
            }
          ]
        })
        break
    }
  }

  return (
    <>
      {tradeConfirmId && (
        <TradeConfirm
          isOpen={true}
          tradeConfirmId={tradeConfirmId}
          handleTradeConfirmClick={handleTradeConfirmClick}
        />
      )}
    </>
  )
}

export default memo(ActivityNotifications)
