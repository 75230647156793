import React, { FC, MouseEvent, useCallback } from 'react'
import { useTimeout } from '../../helpers/hooks'
import Message from '../Message/Message'

const ForceReloadMessage: FC = () => {
  const refresh = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    location.reload()
  }, [])

  const remainingSeconds = useTimeout(() => {
    location.reload()
  }, 10)

  const remainingMessage =
    remainingSeconds > 1
      ? `in ${remainingSeconds} seconds`
      : remainingSeconds === 1
      ? 'in one second'
      : 'now'

  return (
    <Message key="reload" type="error">
      Inconsistent data was received from the server. It may be because the
      server was restarted, or because the connection was closed. The page will
      be refreshed {remainingMessage}…{' '}
      <a href="#" onClick={refresh}>
        Refresh now
      </a>
      .
    </Message>
  )
}

export default ForceReloadMessage
