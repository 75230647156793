import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, of } from 'rxjs'
import { catchError, map, mergeMap, mergeMapTo } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { createOrderFromResponse } from '../order/helpers'
import { Order, OrderResponse } from '../order/types'
import { fetchSecuritiesByIds, RemoveGridAction } from '../securities/actions'
import { getSecurityStaticDataById } from '../securities/selectors'
import { QuoteReliability } from '../securities/types'
import { logError } from '../ws/actions'
import {
  addOrUpdateDepthOfMarket,
  FetchDepthAction,
  ForceCancelAction,
  forceCancelFailure,
  forceCancelSuccess,
  loadSecurityFailure,
  MarkAsRestrictedAction,
  markAsRestrictedFailure,
  markAsRestrictedSuccess,
  NewSubscriptionAction,
  RemoveSubscriptionAction,
  resetDOMForGrid,
  setDepthOrders
} from './actions'

export const SECURITIES_BUFFER_DELAY = 100

const getDepthOfMarketForSecurity = (
  securityId: number,
  quoteReliability: QuoteReliability,
  gridIndex: number,
  popout: boolean
) => {
  return getHub()
    .invoke<OrderResponse[]>(
      'SubscribeDepth',
      securityId,
      quoteReliability,
      gridIndex,
      popout
    )
    .pipe(
      map((orderResponses) =>
        orderResponses.map((o: OrderResponse) => createOrderFromResponse(o))
      )
    )
}

const getDepthOfMarket = () => {
  return getHub()
    .stream<Record<number, OrderResponse[]>>('GetDepth')
    .pipe(
      map((orderResponses: Record<number, OrderResponse[]>) => {
        const retval: Record<number, Order[]> = {}
        for (const gridIndex in orderResponses) {
          if (orderResponses.hasOwnProperty(gridIndex)) {
            retval[gridIndex] = orderResponses[
              gridIndex
            ].map((o: OrderResponse) => createOrderFromResponse(o))
          }
        }
        return retval
      })
    )
}

/* const getDepthOfMarketForSecurity = (
  securityId: number,
  quoteReliability: QuoteReliability
) => {
  return getHub()
    .stream<OrderResponse[]>('GetOrderBook', securityId, quoteReliability)
    .pipe(
      map((orderResponses) =>
        orderResponses.map((o: OrderResponse) => createOrderFromResponse(o))
      )
    )
}*/

/* const subscribeToDepthOfMarketEpic: Epic = (action$) => {
  return action$.pipe(
    ofType<SubscribeToDepthOfMarketAction>(
      'depthofmarket.subscribeToDepthOfMarket'
    ),
    mergeMap((action) => {
      const {
        gridIndex,
        securityId,
        isPopout,
        quoteReliability
      } = action.payload
      return getDepthOfMarketForSecurity(securityId, quoteReliability).pipe(
        map((orders) =>
          addOrUpdateDepthOfMarket(gridIndex, securityId, orders, orders)
        ),
        takeUntil(
          action$.pipe(
            ofType('depthofmarket.unsubscribeFromDepthOfMarket'),
            filter(
              (cancelAction: UnsubscribeFromDepthOfMarketAction) =>
                cancelAction.payload.securityId === securityId &&
                cancelAction.payload.isPopout === isPopout
            ),
            take(1)
          )
        ),
        catchError((err) =>
          of(loadSecurityFailure(err, securityId), logError(err))
        )
      )
    })
  )
}*/

const unsubscribeFromDepthOfMarketEpic: Epic = (action$) => {
  return action$.pipe(
    ofType<RemoveSubscriptionAction>('depthofmarket.removeSubscription'),
    mergeMap((action) => {
      const { gridIndex, securityId, popout } = action.payload
      return getHub()
        .invoke<OrderResponse[]>(
          'UnsubscribeDepth',
          gridIndex,
          securityId,
          popout
        )
        .pipe(mergeMapTo(EMPTY))
    })
  )
}

const getDepthEpic: Epic = (action$) => {
  return action$.pipe(
    ofType<FetchDepthAction>('depthofmarket.fetchDepth'),
    mergeMap((action) => {
      return getDepthOfMarket().pipe(
        map((records) => setDepthOrders(records)),
        catchError((err) => of(logError(err)))
      )
    })
  )
}

const subscribeToDepthOfMarketEpic: Epic = (action$) => {
  return action$.pipe(
    ofType<NewSubscriptionAction>('depthofmarket.newSubscription'),
    mergeMap((action) => {
      const { gridIndex, securityId, quoteReliability, popout } = action.payload
      return getDepthOfMarketForSecurity(
        securityId,
        quoteReliability,
        gridIndex,
        popout
      ).pipe(
        map((orders) =>
          addOrUpdateDepthOfMarket(gridIndex, securityId, orders, orders)
        ),
        catchError((err) =>
          of(loadSecurityFailure(err, securityId), logError(err))
        )
      )
    })
  )
}

/* const fetchSecuritiesWhensubscribingToDepthOfMarketEpic: Epic = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType<SubscribeToDepthOfMarketAction>(
      'depthofmarket.subscribeToDepthOfMarket'
    ),
    mergeMap((action) => {
      const { securityId } = action.payload
      const getSecurity = getSecurityStaticDataById(state$.value)

      return getSecurity(securityId) === undefined
        ? of(fetchSecuritiesByIds([securityId]))
        : EMPTY
    })
  )
}*/

const fetchSecuritiesWhensubscribingToDepthOfMarketEpic: Epic = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType<NewSubscriptionAction>('depthofmarket.newSubscription'),
    mergeMap((action) => {
      const { securityId } = action.payload
      const getSecurity = getSecurityStaticDataById(state$.value)

      return getSecurity(securityId) === undefined
        ? of(fetchSecuritiesByIds([securityId]))
        : EMPTY
    })
  )
}

const resetDOMForGridEpic: Epic = (action$) =>
  action$.pipe(
    ofType<RemoveGridAction>('securities.removeGrid'),
    map((action) => resetDOMForGrid(action.payload.gridIndex))
  )

const markAsRestrictedEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<MarkAsRestrictedAction>('depthofmarket.markAsRestricted'),
    mergeMap((action) => {
      const { id } = action.payload
      return getHub()
        .invoke('MarkAsRestricted', id)
        .pipe(
          map((errors: any) => markAsRestrictedSuccess(errors)),
          catchError((err) => of(markAsRestrictedFailure(err), logError(err)))
        )
    })
  )

const forceCancelEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<ForceCancelAction>('depthofmarket.forceCancel'),
    mergeMap((action) => {
      const { id } = action.payload
      return getHub()
        .invoke('ForceCancel', id)
        .pipe(
          map((errors: any) => forceCancelSuccess(errors)),
          catchError((err) => of(forceCancelFailure(err), logError(err)))
        )
    })
  )

export default combineEpics(
  subscribeToDepthOfMarketEpic,
  resetDOMForGridEpic,
  fetchSecuritiesWhensubscribingToDepthOfMarketEpic,
  unsubscribeFromDepthOfMarketEpic,
  getDepthEpic,
  markAsRestrictedEpic,
  forceCancelEpic
)
