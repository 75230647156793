import cx from 'classnames'
import React from 'react'
import { OrderType } from '../../../store/order/types'
import styles from '../bondListStyle.scss'
import { CellProps, useSecurityOrderData } from './helpers'

const BestSizeCell = (orderType: OrderType) => ({ data }: CellProps) => {
  const security = useSecurityOrderData(data)
  // const bestOrderIsMine = useSelector(isBestOrderMine)
  if (!security) return <> </>

  // const isMine = bestOrderIsMine(security.id, orderType)
  const order = orderType === 'buy' ? security.bestBid : security.bestOffer
  const size = order?.size
  let sizeStr = size?.toString()
  if (sizeStr) {
    sizeStr += order?.allOrNone ? 'a' : ''
  }
  const testId = data.id + (orderType === 'buy' ? '-bidsz' : '-ofrsz')

  return (
    <span
      className={cx(styles.bestOrder, order?.myFirm && styles.myOrder)}
      data-testid={testId}
    >
      {sizeStr || '-'}
    </span>
  )
}

export default BestSizeCell
