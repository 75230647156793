import { ColumnApi, GetRowIdFunc, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import { showMenuPartialColumn } from '../../containers/BondList/columnDefs'
import OrderTooltip from '../../containers/BondList/OrderTooltip'
import SecurityDetailsModalLazy from '../../containers/DepthOfMarket/DetailsModal/SecurityDetailsModalLazy'

import { getMarketClosed } from '../../store/market/selectors'
import { getOrdersFetched } from '../../store/order/selectors'
import { fetchSecurities, setCurrentPage } from '../../store/securities/actions'
import { SECURITY_PAGE_SIZE } from '../../store/securities/helpers'
import { Security } from '../../store/securities/reducer'
import {
  getCurrentPage,
  getSecuritiesForPage,
  getSortToTop,
  getWatchlistId,
  hasError,
  isPending
} from '../../store/securities/selectors'

import styles from './grid.scss'

import { lastRowBorder } from './helpers'

import { useGridControls } from './useGridControls'

export interface Props {
  gridIndex: number
  rowStyle?: any
  onSelectionChanged: (...args: any) => void
  onRowDoubleClicked: (...args: any) => void
  myOrdersOpen: boolean
  canEditWatchlist: boolean
}

export const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  menuTabs: [],
  suppressMovable: false,
  suppressNavigable: true,
  ...showMenuPartialColumn
}

const finColumnDefinitions = {
  ...defaultColumnDefinitions,
  suppressMovable: true
}

const sortFunction = (row1: Security, row2: Security) => {
  const row1Best = row1.bestBid?.myFirm || row1.bestOffer?.myFirm
  const row2Best = row2.bestBid?.myFirm || row2.bestOffer?.myFirm
  if (row1Best && !row2Best) {
    return -1
  }
  if (row2Best && !row1Best) {
    return 1
  }

  if (row1.issuerSymbol > row2.issuerSymbol) {
    return 1
  } else if (row1.issuerSymbol < row2.issuerSymbol) {
    return -1
  }

  if (row1.maturityDate > row2.maturityDate) {
    return 1
  } else if (row1.maturityDate < row2.maturityDate) {
    return -1
  }

  if (row1.coupon > row2.coupon) {
    return 1
  } else if (row1.coupon < row2.coupon) {
    return -1
  } else {
    return 0
  }
}

const getRowId: GetRowIdFunc<Security> = ({ data }) => {
  return `${data.id}`
}

const components = {
  orderTooltip: OrderTooltip
}

const MineGrid: React.FC<Props> = ({
  gridIndex,
  onSelectionChanged,
  onRowDoubleClicked,
  canEditWatchlist
}) => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const loadingSecurities = useSelector(isPending)(gridIndex)
  const error = useSelector(hasError)(gridIndex)
  const currentPage = useSelector(getCurrentPage)(gridIndex)
  const securitiesForPage = useSelector(getSecuritiesForPage)
  const marketClosed = useSelector(getMarketClosed)
  const watchlistId = useSelector(getWatchlistId)(gridIndex)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const [rowDataState, setRowDataState] = useState<Security[] | undefined>(
    undefined
  )
  const [selectedDetailSecurityId, setSelectedDetailSecurityId] =
    useState<number>(0)

  const defaultColDefsToUse =
    fin && gridIndex > 0 ? finColumnDefinitions : defaultColumnDefinitions
  const rowStyle = {
    borderTop: 0,
    borderBottom: 'none',
    background: marketClosed ? '#F6F6F6' : ''
  }
  const securities =
    currentPage !== undefined ? securitiesForPage(gridIndex, currentPage) : []

  const {
    handleColumnChange,
    getContextMenuItems,
    columnDefs,
    handleGridRowClick,
    handleGridRowDoubleClick,
    gridContext
  } = useGridControls({
    gridApi,
    gridIndex,
    canEditWatchlist,
    setSelectedDetailSecurityId,
    currentPage,
    onClickGridRow: onSelectionChanged,
    onDoubleClickGridRow: onRowDoubleClicked
  })

  useEffect(() => {
    rowStyle.background = marketClosed ? '#F6F6F6' : ''
    gridApi?.api.redrawRows()
  }, [marketClosed])

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
    },
    []
  )

  const ordersAreFetched = useSelector(getOrdersFetched)
  useEffect(() => {
    if (ordersAreFetched) {
      dispatch(fetchSecurities(gridIndex, 0))
      dispatch(setCurrentPage(gridIndex, 0))
    }
  }, [watchlistId, ordersAreFetched, currentPage])

  const sortToTop = useSelector(getSortToTop)(gridIndex)
  let rowData = useSelector(getSecuritiesForPage)(gridIndex, 0)
  if (rowData.length !== rowDataState?.length) {
    const sortedToTopTemp = []
    const rowDataTemp = []
    for (const s of rowData) {
      let sorted = false
      for (const stt of sortToTop) {
        if (s.id === stt) {
          sortedToTopTemp.push(s)
          sorted = true
          break
        }
      }
      if (!sorted) {
        rowDataTemp.push(s)
      }
    }
    const sortedToTop = sortedToTopTemp.sort(sortFunction)
    rowData = rowDataTemp.sort(sortFunction)
    setRowDataState(sortedToTop.concat(rowData))
    // TP - MineGridRefreshBug - without this the grid refreshes everytime rowData changes e.g. the top of stack price changes
  }

  useEffect(() => {
    if (gridApi) {
      if (loadingSecurities && currentPage === 0) {
        gridApi.api.showLoadingOverlay()
      } else if (
        securities.length === 0 &&
        currentPage === 0 &&
        rowData.length === 0
      ) {
        gridApi.api.showNoRowsOverlay()
      } else {
        gridApi.api.hideOverlay()
      }
    }
  }, [loadingSecurities, securities, currentPage, gridApi, rowData])

  if (!ordersAreFetched || !columnDefs) {
    // When MINE is checked, we wait for the orders to be fetched before fetching
    // securities for the first time.
    return null
  }

  return (
    <React.Fragment>
      <div
        className={`ag-theme-balham ${styles.gridStyle}`}
        data-testid={`securities-grid-${gridIndex}`}
      >
        <AgGridReact
          getContextMenuItems={getContextMenuItems}
          suppressRowTransform={true}
          suppressColumnVirtualisation={true}
          cacheBlockSize={SECURITY_PAGE_SIZE}
          columnDefs={columnDefs}
          defaultColDef={defaultColDefsToUse}
          suppressScrollOnNewData={true}
          components={components}
          groupHeaderHeight={0}
          headerHeight={20}
          rowHeight={20}
          rowStyle={rowStyle}
          rowData={rowDataState}
          rowSelection="multiple"
          suppressRowDeselection={true}
          onRowClicked={handleGridRowClick}
          onRowDoubleClicked={handleGridRowDoubleClick}
          onGridReady={onGridReady}
          getRowId={getRowId}
          enableCellTextSelection={true}
          singleClickEdit={true}
          suppressNoRowsOverlay={!gridApi}
          suppressMenuHide={true}
          overlayNoRowsTemplate={
            error
              ? 'An error occurred during securities update.'
              : 'No security found for current filters and watchlist.'
          }
          overlayLoadingTemplate="Loading securities…"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          getRowStyle={lastRowBorder}
          onColumnMoved={handleColumnChange}
          onDisplayedColumnsChanged={handleColumnChange}
          tooltipShowDelay={0}
          enterNavigatesVerticallyAfterEdit={true}
          context={gridContext}
        />
      </div>
      {!!selectedDetailSecurityId && (
        <SecurityDetailsModalLazy
          securityId={selectedDetailSecurityId}
          isOpen={!!selectedDetailSecurityId}
          toggleIsOpen={() => setSelectedDetailSecurityId(0)}
        />
      )}
    </React.Fragment>
  )
}

export default MineGrid
