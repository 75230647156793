import { ColDef } from '@ag-grid-community/core'
import { diffDateFormatter } from '../../../../helpers/formatting'

export const QUEUESIZE = 'queueSize'
export const COMPONENTNAME = 'componentName'
export const LASTEDITTIME = 'lastEditTime'

export const customizableColumns: ColDef[] = [
  {
    colId: QUEUESIZE,
    field: QUEUESIZE,
    headerName: 'QueueSize',
    width: 100,
    suppressAutoSize: true,
    sortable: true
  },
  {
    colId: COMPONENTNAME,
    field: COMPONENTNAME,
    headerName: 'ComponentName',
    width: 400,
    suppressAutoSize: true,
    sortable: true
  },
  {
    colId: LASTEDITTIME,
    field: LASTEDITTIME,
    headerName: 'LastEditTime',
    width: 200,
    suppressAutoSize: true,
    valueFormatter: diffDateFormatter,
    sortable: true
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
