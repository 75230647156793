// import { addOrUpdateElement } from '../helpers'
import { Security } from '../securities/reducer'
import { AlertsAction } from './actions'
import {
  CancelOrderAlert,
  CounteredToppedAlert,
  NewOrderAlert,
  TradingNowAlert
} from './types'

export interface State {
  /* tradingNowAlerts: TradingNowAlert[]
  counteredToppedAlerts: CounteredToppedAlert[]
  newOrderAlerts: NewOrderAlert[]
  cancelOrderAlerts: CancelOrderAlert[]*/
  tradingNowAlerts: Record<number, TradingNowAlert>
  counteredToppedAlerts: Record<string, CounteredToppedAlert>
  newOrderAlerts: Record<string, NewOrderAlert>
  cancelOrderAlerts: Record<string, CancelOrderAlert>
  mutedAlerts: {
    tradingNow: Array<Security['id']>
    countered: Array<Security['id']>
    topped: Array<Security['id']>
    newOrder: Array<Security['id']>
    cancelOrder: Array<Security['id']>
  }
}

const initialState: State = {
  /* tradingNowAlerts: [],
  counteredToppedAlerts: [],
  newOrderAlerts: [],
  cancelOrderAlerts: [],*/
  tradingNowAlerts: {},
  counteredToppedAlerts: {},
  newOrderAlerts: {},
  cancelOrderAlerts: {},
  mutedAlerts: {
    tradingNow: [],
    countered: [],
    topped: [],
    newOrder: [],
    cancelOrder: []
  }
}

/* const addOrUpdateAlert = (
  alerts:
    | TradingNowAlert[]
    | CounteredToppedAlert[]
    | NewOrderAlert[]
    | CancelOrderAlert[],
  alert:
    | TradingNowAlert
    | CounteredToppedAlert
    | NewOrderAlert
    | CancelOrderAlert
) => addOrUpdateElement(alerts, alert, (a1, a2) => a1.id === a2.id)*/

const reducer = (state = initialState, action: AlertsAction) => {
  switch (action.type) {
    case 'alerts.addTradingNowAlerts':
      const newTradingNow = { ...state.tradingNowAlerts }
      action.payload.tradingNowAlerts.forEach((a) => (newTradingNow[a.id] = a))
      return {
        ...state,
        tradingNowAlerts: newTradingNow
        /* tradingNowAlerts: action.payload.tradingNowAlerts.reduce(
          addOrUpdateAlert,
          state.tradingNowAlerts
        )*/
      }

    case 'alerts.addCounteredToppedAlerts':
      const newCounteredTopped = { ...state.counteredToppedAlerts }
      action.payload.counteredToppedAlerts.forEach(
        (a) => (newCounteredTopped[a.id] = a)
      )
      return {
        ...state,
        counteredToppedAlerts: newCounteredTopped
        /* counteredToppedAlerts: action.payload.counteredToppedAlerts.reduce(
          addOrUpdateAlert,
          state.counteredToppedAlerts
        )*/
      }
    case 'alerts.addNewOrderAlerts':
      const newAlerts = { ...state.newOrderAlerts }
      action.payload.newOrderAlerts.forEach((a) => (newAlerts[a.id] = a))

      // cut the amount of alerts kept in redux to the most recent 300
      const limitedResults = Object.fromEntries(
        Object.entries(newAlerts).splice(-300)
      )

      return {
        ...state,
        newOrderAlerts: limitedResults
        /* newOrderAlerts: action.payload.newOrderAlerts.reduce(
          addOrUpdateAlert,
          state.newOrderAlerts
        )*/
      }

    case 'alerts.addCancelOrderAlerts':
      const newCancelOrders = { ...state.cancelOrderAlerts }
      action.payload.cancelOrderAlerts.forEach(
        (a) => (newCancelOrders[a.id] = a)
      )
      return {
        ...state,
        cancelOrderAlerts: newCancelOrders
        /* cancelOrderAlerts: action.payload.cancelOrderAlerts.reduce(
          addOrUpdateAlert,
          state.cancelOrderAlerts
        )*/
      }
    case 'alerts.muteAlert':
      return {
        ...state,
        mutedAlerts: {
          ...state.mutedAlerts,
          [action.payload.alertType]: [
            ...state.mutedAlerts[action.payload.alertType],
            action.payload.securityId
          ]
        }
      }

    case 'alerts.unmuteAlert':
      return {
        ...state,
        mutedAlerts: {
          ...state.mutedAlerts,
          [action.payload.type]: state.mutedAlerts[action.payload.type].filter(
            (id) => id !== action.payload.securityId
          )
        }
      }

    default:
      return state
  }
}

export default reducer
