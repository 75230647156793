import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import moment from 'moment'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../../../app/openFinContext'
import { externalLiquidityColumnModelChanged } from '../../../../store/settings/actions'
import { getExternalLiquidityColumnOrder } from '../../../../store/settings/selectors'
import SearchInput from '../../../SearchInput/SearchInput'
import { applyColumnsOrder } from '../../helpers'
import styles from '../../HistoricalUserData/HistoricalData.scss'
import { columnDefinitions } from './columnDefs'
import {
  getExternalLiquidityStats,
  getExternalLiquidityStatsError,
  getExternalLiquidityStatsPending
} from '../../../../store/admin/externalLiquidityStats/selectors'

export const defaultColumnDefinitions = {
  resizable: true,
  filter: true,
  filterParams: {
    buttons: ['clear']
  },
  sortable: true,
  suppressMovable: false
}

const ExternalLiquidityStatsGrid: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const pending = useSelector(getExternalLiquidityStatsPending)
  const error = useSelector(getExternalLiquidityStatsError)
  const externalLiquidityStats = useSelector(getExternalLiquidityStats)
  const [dataInitialized, setDataInitialized] = useState(false)

  const onDataInitialized = () => {
    setDataInitialized(true)
  }
  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const columnsOrder = useSelector(getExternalLiquidityColumnOrder)
  const [columnDefs, setColumnDefs] = useState<
    ColDef[] | ColGroupDef[] | undefined
  >(undefined)

  useEffect(() => {
    if (fin) {
      defaultColumnDefinitions.suppressMovable = true
    }
  }, [])
  //
  // Set Initial Columns Def
  useEffect(() => {
    setColumnDefs(applyColumnsOrder(columnsOrder, columnDefinitions))
  }, [columnsOrder?.join(',')])

  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
    },
    []
  )

  useEffect(() => {
    if (gridApi) {
      if (pending) {
        gridApi.api.showLoadingOverlay()
      } else if (error) {
        gridApi.api.showNoRowsOverlay()
      } else {
        if (!externalLiquidityStats || externalLiquidityStats.length === 0) {
          gridApi.api.showNoRowsOverlay()
        } else {
          gridApi.api.hideOverlay()
        }
      }
    }
  }, [pending, error, externalLiquidityStats, gridApi])

  const onBtExport = useCallback(() => {
    if (gridApi) {
      const time = moment(new Date())
      gridApi.api.exportDataAsCsv({
        fileName: `External Liquidity Stats ${time.format(
          'YYYY-MM-DD HH_mm_ss'
        )}`
      })
    }
  }, [gridApi])

  const handleColumnChange = useCallback(() => {
    if (dataInitialized) {
      const displayed = gridApi?.columnApi
        .getAllDisplayedColumns()
        .map((col) => {
          return col.getColId()
        })
      if (displayed) {
        dispatch(externalLiquidityColumnModelChanged(displayed))
      }
    }
  }, [dataInitialized])

  return (
    <div className={styles.dataGridWrapper}>
      <div className={styles.dataSearchWrapper}>
        {!pending && !error && (
          <SearchInput
            value={search}
            onChange={handleSearchChange}
            onClear={onClear}
            placeholder="Filter data"
            className="tiers-search"
          />
        )}
      </div>
      {!pending && !error && externalLiquidityStats && (
        <button
          className={styles.exportBtn}
          onClick={onBtExport}
          style={{ marginBottom: '5px', fontWeight: 'bold' }}
        >
          Export to Csv
        </button>
      )}
      <div
        className={cx(
          'ag-theme-balham activity-log',
          fin ? styles.finWrapper : styles.tickerWrapper
        )}
      >
        <AgGridReact
          rowData={externalLiquidityStats}
          columnDefs={columnDefs}
          defaultColDef={defaultColumnDefinitions}
          quickFilterText={search}
          overlayNoRowsTemplate="No external liquidity data."
          overlayLoadingTemplate="Loading external liquidity data. This may take up to several minutes depending on server load."
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          onColumnMoved={handleColumnChange}
          onDisplayedColumnsChanged={handleColumnChange}
          onFirstDataRendered={onDataInitialized}
          rowClass="activity-log-grid-row"
          rowSelection={'single'}
          onGridReady={onGridReady}
          pagination={true}
          groupIncludeTotalFooter={true}
        />
      </div>
    </div>
  )
}

export default ExternalLiquidityStatsGrid
