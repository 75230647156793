import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'

import styles from './ActivityTicker.scss'

import {
  activityTickerFetch,
  unsubscribeFromTicker
} from '../../../store/admin/activityTicker/actions'
import ActivityTickerGrid from './Grid/ActivityTickerGrid'

const ActivityTicker: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(activityTickerFetch())
    return () => {
      dispatch(unsubscribeFromTicker())
    }
  }, [])

  return (
    <React.Fragment>
      <div className={fin ? styles.finTickerWrapper : styles.tickerWrapper}>
        <ActivityTickerGrid />
      </div>
    </React.Fragment>
  )
}

export default ActivityTicker
