import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons'
import {
  faChalkboardUser,
  faCircleInfo,
  faCookieBite,
  faDesktop,
  faEnvelope,
  faMemoCircleInfo,
  faQuestionCircle as questionCircle,
  faShieldCheck
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useOpenFin } from '../../app/openFinContext'
import SSIPDF from '../../assets/pdfs/SSI.pdf'
import UserPDF from '../../assets/pdfs/UserGuide.pdf'
import styles from '../Header/header.scss'

const HelpMenu: FC = () => {
  const { fin } = useOpenFin()
  const [toggleHelpMenu, setToggleHelpMenu] = useState(false)

  const getMenu = () => {
    if (!fin || (fin && toggleHelpMenu)) {
      return (
        <div
          className={cx(
            styles.dropdownWrapper,
            fin && styles.finDropdownWrapper
          )}
        >
          <ul>
            <li>
              <div>Any questions:</div>
              <span style={{ display: 'flex' }}>
                <a href="mailto:help@bonds.com">help@bonds.com</a>
                <span style={{ padding: '1rem 0' }}>|</span>
                <a href="tel:2122574062">(212) 257-4062</a>
              </span>
            </li>
            <li className={styles.alignIcon}>
              <FontAwesomeIcon icon={faChalkboardUser} />
              <a href={UserPDF} download="bonds.com_userGuide.pdf">
                Self Guided Tour PDF
              </a>
            </li>
            <li className={styles.alignIcon}>
              <FontAwesomeIcon icon={faMemoCircleInfo} />
              <a href={SSIPDF} download="bonds.com_ssi.pdf">
                Settlement Instructions (SSI)
              </a>
            </li>
            <li className={styles.alignIcon}>
              <FontAwesomeIcon icon={faDesktop} />
              <a href="https://www.bonds.com/" target="_blank" rel="noreferrer">
                Corporate website
              </a>
            </li>
            <li className={styles.alignIcon}>
              <FontAwesomeIcon icon={faCookieBite} />
              <a
                href="https://bonds.com/cookie-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Cookie Policy
              </a>
            </li>
            <li className={styles.alignIcon}>
              <FontAwesomeIcon icon={faShieldCheck} />
              <a
                href="https://bonds.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li className={styles.alignIcon}>
              <FontAwesomeIcon icon={faEnvelope} />
              <a href="mailto:hello@bonds.com">Request Trading Rules</a>
            </li>
            <li className={styles.alignIcon}>
              <FontAwesomeIcon icon={faCircleInfo} />
              <a
                href="https://bonds.com/regulatory-information/"
                target="_blank"
                rel="noreferrer"
              >
                More regulatory info
              </a>
            </li>
          </ul>
        </div>
      )
    }
  }

  return (
    <>
      <div
        className={cx(styles.dropdownContainer)}
        style={{ marginLeft: '0.5em' }}
        data-testid="helpMenu"
        data-tip="Help"
        data-for="helpMenu"
      >
        <span
          className={cx(fin ? styles.finTitle : styles.dropdownTitle)}
          onClick={() => (fin ? setToggleHelpMenu(!toggleHelpMenu) : null)}
        >
          <FontAwesomeIcon icon={fin ? questionCircle : faQuestionCircle} />
        </span>
        {getMenu()}
      </div>
      <ReactTooltip
        id="helpMenu"
        backgroundColor="#e9edf1"
        textColor="#656C78"
        effect="solid"
        className="iconTooltip"
        place="left"
      >
        Help
      </ReactTooltip>
    </>
  )
}

export default HelpMenu
