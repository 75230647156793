import React, { Component, FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newSubscription } from '../../../store/depthOfMarket/actions'
import { getHoveredRows } from '../../../store/depthOfMarket/selectors'
import { OrderType } from '../../../store/order/types'
import { Security } from '../../../store/securities/reducer'
import { getQuoteReliability } from '../../../store/securities/selectors'
import styles from './stackSize.scss'

export interface Props {
  value: Security['id']
  orderType: OrderType
  gridIndex: number
}

const StackSizeTooltip: FC<Props> = (props) => {
  const dispatch = useDispatch()
  const quoteReliability = useSelector(getQuoteReliability)(props.gridIndex)
  const [stackSize, setStackSize] = useState('')
  useEffect(() => {
    dispatch(
      newSubscription(props.gridIndex, props.value, quoteReliability, false)
    )
  }, [props.value, quoteReliability])
  const rows = useSelector(getHoveredRows)(props.gridIndex, props.value)

  useEffect(() => {
    let stackAccumulator = 0
    rows.map((row) => {
      const bidOfferSide =
        props.orderType === 'buy' ? row.bidSize : row.offerSize
      if (bidOfferSide) stackAccumulator += bidOfferSide
    })
    stackAccumulator !== 0 && setStackSize(stackAccumulator.toLocaleString())
  }, [rows])

  return !stackSize ? null : (
    <div className={styles.stackSizeTooltip}>{stackSize}</div>
  )
}

class StackSizeTooltipWrapper extends Component<Props> {
  // Ag-grid needs this method 😒

  getReactContainerClasses() {
    return [styles.stackSizeTooltipWrapper]
  }

  render() {
    return <StackSizeTooltip {...this.props} />
  }
}

export default StackSizeTooltipWrapper
