import { setUser } from '@sentry/react'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, merge, of, throwError, timer } from 'rxjs'
import { catchError, mapTo, mergeMap, mergeMapTo } from 'rxjs/operators'
import config from '../../config'
import { getDelayBeforeExpiration, getNow, parseJwt } from '../../helpers/auth'
import { getHub } from '../../helpers/hub'
import { forceReload, logError } from '../ws/actions'
import { getServerTimeDelayMs } from '../ws/selectors'
import {
  AuthAction,
  fetchAuthToken,
  FetchAuthTokenAction,
  fetchAuthTokenFailure,
  fetchAuthTokenSuccess
} from './actions'
import { getOktaAuth } from './selectors'

const getAuthTokenEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('auth.fetch-auth-token'),
    mergeMap((action: FetchAuthTokenAction) => {
      const { isRefresh } = action.payload

      const auth = getOktaAuth(state$.value)

      const authToken = auth?.getAccessToken()

      if (!authToken && auth) {
        // tslint:disable-next-line: no-floating-promises
        auth.signInWithRedirect()
        return throwError('No access token')
      }

      const delay = getServerTimeDelayMs(state$.value) || 0
      const expiresIn = getDelayBeforeExpiration(authToken!) - delay
      const refreshIn = Math.max(
        expiresIn - config.api.refreshTokenTimeout,
        // It happens that we want to refresh the token but Okta SDK judges that the token
        // is still valid, so returns the same as before. In this case, we don’t ask for a
        // new refresh immediately, but we wait a second.
        1000
      )
      // tslint:disable-next-line: no-console
      console.log(
        `Auth token refresh at ${new Date(
          getNow() + refreshIn
        ).toLocaleTimeString()}`
      )

      const callApiToRefreshToken$ = isRefresh
        ? getHub().invoke('RefreshToken', authToken).pipe(mergeMapTo(EMPTY))
        : EMPTY
      const scheduleNextTokenRefresh$ = timer(refreshIn).pipe(
        mapTo(fetchAuthToken(true))
      )

      const parsedToken = parseJwt(authToken!)
      const userName = parsedToken.sub
      const userId = parsedToken.userId
      if (
        state$.value.auth.userId !== undefined &&
        state$.value.auth.userId !== userId
      ) {
        // tslint:disable-next-line: no-console
        console.log('Invalid user in Okta token. Refreshing page.')
        return of(forceReload())
      } else {
        setUser({ id: userId, username: userName, ip_address: '{{auto}}' })
      }
      return merge(
        callApiToRefreshToken$,
        scheduleNextTokenRefresh$,
        of(fetchAuthTokenSuccess(authToken!, userName, userId))
      )
    }),
    catchError((err) => of(fetchAuthTokenFailure(err), logError(err)))
  )

const redirectToLoginEpic: Epic<AuthAction> = (action$, state$) =>
  action$.pipe(
    ofType('auth.redirect-to-login'),
    mergeMap(() => of(getOktaAuth(state$.value)?.signInWithRedirect())),
    mergeMapTo(EMPTY)
  )

export default combineEpics(getAuthTokenEpic, redirectToLoginEpic)
