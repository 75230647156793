import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'
import { getBenchmarkDataFailure, getBenchmarkDataSuccess } from './actions'
import BenchmarkData from './types'

const benchmarkEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('benchmark.getBenchmarkData'),
    mergeMap(() => {
      return getHub()
        .invoke<BenchmarkData>('GetBenchmarkData')
        .pipe(
          map((benchmarkData: BenchmarkData) =>
            getBenchmarkDataSuccess(benchmarkData)
          ),
          catchError((err) => of(getBenchmarkDataFailure(err), logError(err)))
        )
    })
  )

export default combineEpics(benchmarkEpic)
