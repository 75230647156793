import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { secondsToMinutes } from '../../helpers/formatting'
import { getTotalSecondsBeforeExpiration } from '../../helpers/orders'
import styles from './countdownCircle.scss'
import {
  getLineHeight,
  setStrokeColor,
  setStrokeDasharray,
  setWidthHeightStyle
} from './helper'

interface Props {
  valueSize: 'small' | 'default' | 'big'
  valueColor: string
  isPlaying: boolean
  timerInit: number
  valueCircle: any
  strokeWidth: number
  totalSecondsBeforeExpiration: {
    expiration?: Date
    submitTime?: Date
    serverTimeDelayMs?: number
  }
}

const CountdownCircle: FC<Props> = ({
  valueSize,
  valueColor,
  isPlaying,
  timerInit,
  valueCircle,
  strokeWidth,
  totalSecondsBeforeExpiration
}) => {
  let secondsLeft = Math.floor(
    getTotalSecondsBeforeExpiration(
      totalSecondsBeforeExpiration.expiration,
      totalSecondsBeforeExpiration.submitTime,
      totalSecondsBeforeExpiration.serverTimeDelayMs
    ).secondsLeft
  )
  const lineHeight = getLineHeight(valueCircle.r)
  const strokeColor = setStrokeColor(valueColor)
  const widthHeightStyle = setWidthHeightStyle(valueCircle.r)
  const strokeDasharray = setStrokeDasharray(valueCircle.r, strokeWidth)

  const [seconds, setSecondes] = useState<number>(secondsLeft)

  let animationDuration
  if (isPlaying) {
    animationDuration = {
      animationDuration: `${timerInit}s`
    }
  }

  let fontSizeTimer: string
  let animationStyle: any

  if (valueSize === 'default') {
    fontSizeTimer = '1em'
    animationStyle = styles.strokeCountdownDefault
  } else if (valueSize === 'small') {
    fontSizeTimer = '.8em'
    animationStyle = styles.strokeCountdownSmall
  } else {
    fontSizeTimer = '1.6em'
    animationStyle = styles.strokeCountdownBig
  }

  const renderTime = (timer: number) => (
    <div
      style={{
        color: valueColor,
        fontSize: `${fontSizeTimer}`
      }}
    >
      {timer === 0 ? '0:00' : secondsToMinutes(timer)}
    </div>
  )

  useEffect(() => {
    if (isPlaying) {
      const intervalCountdown = setInterval(() => {
        secondsLeft = Math.ceil(
          getTotalSecondsBeforeExpiration(
            totalSecondsBeforeExpiration.expiration,
            totalSecondsBeforeExpiration.submitTime,
            totalSecondsBeforeExpiration.serverTimeDelayMs
          ).secondsLeft
        )

        setSecondes(secondsLeft)
      }, 1000)
      return () => clearInterval(intervalCountdown)
    }
  }, [seconds, isPlaying, secondsLeft])

  return (
    <div className={styles.countdown} style={widthHeightStyle}>
      <div className={styles.countdownNumber} style={lineHeight}>
        {renderTime(seconds)}
      </div>
      <svg className={styles.svg} style={widthHeightStyle}>
        <circle
          className={styles.strokeBackground}
          style={strokeDasharray}
          r={valueCircle.r}
          cx={valueCircle.cx}
          cy={valueCircle.cy}
        ></circle>
        <circle
          className={cx(styles.strokeCountdown, animationStyle)}
          style={{
            ...animationDuration,
            ...strokeColor,
            ...strokeDasharray
          }}
          r={valueCircle.r}
          cx={valueCircle.cx}
          cy={valueCircle.cy}
        ></circle>
      </svg>
    </div>
  )
}

export default CountdownCircle
