import React, { FC } from 'react'
import styles from './Badge.scss'

export interface Props {
  color: 'red' | 'yellow' | 'green'
}

const Badge: FC<Props> = ({ color, children }) => {
  return <span className={styles[`badge-${color}`]}>{children}</span>
}

export default Badge
