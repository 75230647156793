import {
  ColumnApi,
  GetRowIdFunc,
  GridApi,
  IGetRowsParams
} from '@ag-grid-community/core'

import { AgGridReact } from '@ag-grid-community/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import StackSizeTooltipWrapper from '../../containers/BondList/cells/StackSize'
import {
  MARKET_VOLUME,
  showMenuPartialColumn
} from '../../containers/BondList/columnDefs'
import OrderTooltip from '../../containers/BondList/OrderTooltip'
import SecurityDetailsModalLazy from '../../containers/DepthOfMarket/DetailsModal/SecurityDetailsModalLazy'

import { getMarketClosed } from '../../store/market/selectors'
import {
  fetchSecurities,
  setCurrentPage,
  setWatchListId
} from '../../store/securities/actions'
import {
  DYNAMIC_WATCHLISTS,
  SECURITY_PAGE_SIZE
} from '../../store/securities/helpers'
import { Security } from '../../store/securities/reducer'
import {
  getCurrentPage,
  getSecuritiesForPage,
  getWatchlistId,
  hasError,
  isPageLoaded,
  isPending
} from '../../store/securities/selectors'
import { getWatchList } from '../../store/watchList/selectors'
import { getIsAdmin } from '../../store/webSettings/selectors'

import styles from './grid.scss'

import { flatten } from '../../helpers/utils'
import { lastRowBorder } from './helpers'

import { useOpenFin } from '../../app/openFinContext'
import { useGridControls } from './useGridControls'

export interface Props {
  gridIndex: number
  rowStyle?: any
  onSelectionChanged: (securityId?: number) => void
  onRowDoubleClicked: (...args: any) => void
  canEditWatchlist: boolean
  watchlistName: string | undefined
}

type RecordsCache = Record<number, Security[]>

const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  menuTabs: [],
  ...showMenuPartialColumn,
  suppressMovable: false,
  suppressNavigable: true
}

const finColumnDefinitions = {
  ...defaultColumnDefinitions,
  suppressMovable: true
}

const components = {
  orderTooltip: OrderTooltip,
  stackSizeTooltip: StackSizeTooltipWrapper
}

const getRowId: GetRowIdFunc<Security> = ({ data }) => {
  return `${data.id}`
}

const Grid: React.FC<Props> = ({
  gridIndex,
  onSelectionChanged,
  onRowDoubleClicked,
  canEditWatchlist,
  watchlistName
}) => {
  const { fin } = useOpenFin()
  const gridRef = useRef<AgGridReact<Security>>(null)
  const isAdmin = useSelector(getIsAdmin)

  const dispatch = useDispatch()
  const loadingSecurities = useSelector(isPending)(gridIndex)
  const error = useSelector(hasError)(gridIndex)
  const currentPage = useSelector(getCurrentPage)(gridIndex)
  const pageIsLoaded = useSelector(isPageLoaded)
  const securitiesForPage = useSelector(getSecuritiesForPage)
  const marketClosed = useSelector(getMarketClosed)
  const watchlists = useSelector(getWatchList)
  const watchlistIdSelected = useSelector(getWatchlistId)(gridIndex)

  //
  // useState Hooks
  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const [selectedDetailSecurityId, setSelectedDetailSecurityId] =
    useState<number>(0)

  const [dataInitialized, setDataInitialized] = useState(false)

  const [waitingForRowsForParams, setWaitingForRowsForParams] = useState<
    IGetRowsParams | undefined
  >(undefined)

  //
  // Const Declarations
  const defaultColDefsToUse =
    fin && gridIndex > 0 ? finColumnDefinitions : defaultColumnDefinitions
  const rowStyle = {
    borderTop: 0,
    borderBottom: 'none',
    background: marketClosed ? '#F6F6F6' : '',
    /* AG Grid is not applying rowStyle to first row.
       https://github.com/ag-grid/ag-grid/issues/6993
       Workaround for now
    */
    height: '20px'
  }

  const securities =
    currentPage !== undefined ? securitiesForPage(gridIndex, currentPage) : []

  const pageLoaded =
    waitingForRowsForParams === undefined ||
    pageIsLoaded(
      gridIndex,
      waitingForRowsForParams.startRow / SECURITY_PAGE_SIZE
    )

  // do we need this? could we just use the presence or absence of gridApi?
  const onDataInitialized = () => {
    setDataInitialized(true)
  }

  const {
    handleColumnChange,
    getContextMenuItems,
    columnDefs,
    handleGridRowClick,
    handleGridRowDoubleClick,
    gridContext
  } = useGridControls({
    gridApi: dataInitialized && gridApi ? gridApi : undefined,
    gridIndex,
    canEditWatchlist,
    setSelectedDetailSecurityId,
    currentPage,
    onClickGridRow: onSelectionChanged,
    onDoubleClickGridRow: onRowDoubleClicked
  })

  // IG/HY Watchlist hooks
  useEffect(() => {
    if (
      watchlistIdSelected &&
      watchlistName &&
      DYNAMIC_WATCHLISTS.includes(watchlistName)
    ) {
      // fetch updated watchlist data --this is causing too many rerenders
      dispatch(setWatchListId(gridIndex, watchlistIdSelected))
    }
  }, [watchlists, watchlistName])

  // //
  // // Handle watchlist id change
  useEffect(() => {
    if (gridApi) {
      if (
        watchlistIdSelected &&
        watchlistName &&
        DYNAMIC_WATCHLISTS.includes(watchlistName)
      ) {
        gridApi.columnApi.setColumnVisible(MARKET_VOLUME, true)
      } else {
        gridApi.columnApi.setColumnVisible(MARKET_VOLUME, false)
      }
    }
  }, [gridApi?.columnApi, watchlistIdSelected, watchlistName])

  // End IG/HY Watchlist hooks

  //
  // UseEffect Hooks
  useEffect(() => {
    rowStyle.background = marketClosed ? '#F6F6F6' : ''
    gridApi?.api.redrawRows()
  }, [marketClosed])

  // ------------------ grid only, not in minegrid ------------------------ //
  useEffect(() => {
    if (waitingForRowsForParams && pageLoaded) {
      const lastRow =
        securities.length === SECURITY_PAGE_SIZE
          ? undefined
          : waitingForRowsForParams.startRow + securities.length

      const rowsThisBlock = securities.map((security) => flatten(security))

      waitingForRowsForParams.successCallback(rowsThisBlock, lastRow)

      setWaitingForRowsForParams(undefined)
      if (!currentPage && securities?.length) {
        onSelectionChanged(rowsThisBlock[0]?.id)
      }
    } else if (
      pageLoaded &&
      !!gridApi &&
      securities?.length &&
      !waitingForRowsForParams
    ) {
      const rowsThisBlock = securities.map((security) =>
        flatten(security)
      ) as Security[]
      gridApi.api.forEachNode((node) => {
        // can't see it, don't need to update it
        const idx = node.rowIndex ?? 0
        if (
          idx > gridApi.api.getLastDisplayedRow() + 20 ||
          idx < gridApi.api.getFirstDisplayedRow() - 20
        ) {
          return
        }
        const updatedRow = rowsThisBlock.find((row) => row.id === node.data?.id)
        if (updatedRow && updatedRow !== node.data) {
          node.setData(updatedRow)
        }
      })
    }
  }, [waitingForRowsForParams, gridApi, pageLoaded, securities, currentPage])

  // TODO: rework to just store the selectors, etc. in refs so we can get at the current state
  // from within getRows
  const cacheRef = useRef<RecordsCache>({})
  const requestRef = useRef<IGetRowsParams>()

  const createGetRows = useCallback(() => {
    // we can't get at latest redux state directly, so we wrap the success callback
    // so we can (sort of) understand what the redux state is from within getRows
    const recordsCache = cacheRef.current
    const successCallbackWrapper = (
      successCallback: (rowsThisBlock: Security[], lastRow?: number) => void,
      page: number
    ) => {
      return (rowsThisBlock: Security[], lastRow?: number) => {
        recordsCache[page] = rowsThisBlock
        successCallback(rowsThisBlock, lastRow)
        requestRef.current = undefined
      }
    }
    // this is the getRows function here
    return (params: IGetRowsParams) => {
      const page = params.startRow / SECURITY_PAGE_SIZE
      // we don't yet have the rows, but we won't have the success callback
      // for the last call to be able to call it
      if (requestRef.current) {
        if (params.startRow < 0) return // we aren't making a new call
        requestRef.current.failCallback()
        setWaitingForRowsForParams(undefined)
        requestRef.current = undefined
      }

      if (page >= 0) {
        const cachedSecurities = recordsCache[page]
        if (cachedSecurities?.length) {
          // don't need last row here--we're scrolling up
          params.successCallback(cachedSecurities)
        } else {
          dispatch(fetchSecurities(gridIndex, page))
          dispatch(setCurrentPage(gridIndex, page))
          const newParams = {
            ...params,
            successCallback: successCallbackWrapper(
              params.successCallback,
              page
            )
          }
          requestRef.current = newParams
          setWaitingForRowsForParams(newParams)
        }
      }
    }
  }, [currentPage, cacheRef, requestRef])

  useEffect(() => {
    if (waitingForRowsForParams) {
      waitingForRowsForParams.failCallback()
      setWaitingForRowsForParams(undefined)
    }
  }, [error])

  // force reload when the entire data source is invalidated
  useEffect(() => {
    if (currentPage === undefined && gridApi) {
      const datasource = { getRows: createGetRows() }
      requestRef.current = undefined
      cacheRef.current = {}
      gridApi.api.setDatasource(datasource)
    }
  }, [gridApi, currentPage, createGetRows])

  // ---------------------------------------------------------------------- //

  //
  // useCallback Hooks
  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
    },
    []
  )

  useEffect(() => {
    // Overlay is not displayed by default with infinite scroll
    // https://www.ag-grid.com/javascript-grid-infinite-scrolling/#overlays
    if (gridApi) {
      if (loadingSecurities && currentPage === 0) {
        gridApi.api.showLoadingOverlay()
      } else if (securities.length === 0 && currentPage === 0) {
        gridApi.api.showNoRowsOverlay()
      } else {
        gridApi.api.hideOverlay()
      }
    }
  }, [loadingSecurities, securities, currentPage, gridApi])

  if (!columnDefs) {
    return null
  }

  return (
    <React.Fragment>
      <div
        className={`ag-theme-balham ${styles.gridStyle}`}
        data-testid={`securities-grid-${gridIndex}`}
      >
        <AgGridReact
          ref={gridRef}
          getContextMenuItems={getContextMenuItems}
          rowModelType="infinite"
          suppressRowTransform={true}
          suppressColumnVirtualisation={true}
          cacheBlockSize={SECURITY_PAGE_SIZE}
          maxBlocksInCache={2}
          columnDefs={columnDefs}
          defaultColDef={defaultColDefsToUse}
          suppressScrollOnNewData={true}
          components={components}
          groupHeaderHeight={0}
          headerHeight={20}
          rowHeight={20}
          rowStyle={rowStyle}
          rowSelection="multiple"
          suppressRowDeselection={true}
          onRowClicked={handleGridRowClick}
          onRowDoubleClicked={handleGridRowDoubleClick}
          onGridReady={onGridReady}
          onFirstDataRendered={onDataInitialized}
          getRowId={getRowId}
          enableCellTextSelection={true}
          singleClickEdit={true}
          overlayNoRowsTemplate={
            error
              ? isAdmin && gridIndex > 0
                ? 'Watchlist not visible to active user'
                : 'An error occurred during securities update.'
              : 'No security found for current filters and watchlist.'
          }
          overlayLoadingTemplate="Loading securities…"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          getRowStyle={lastRowBorder}
          onColumnMoved={handleColumnChange}
          onDisplayedColumnsChanged={handleColumnChange}
          tooltipShowDelay={0}
          tooltipMouseTrack={true}
          suppressMenuHide={true}
          maxConcurrentDatasourceRequests={-1}
          enterNavigatesVerticallyAfterEdit={true}
          context={gridContext}
        />
      </div>
      {!!selectedDetailSecurityId && (
        <SecurityDetailsModalLazy
          securityId={selectedDetailSecurityId}
          isOpen={!!selectedDetailSecurityId}
          toggleIsOpen={() => setSelectedDetailSecurityId(0)}
        />
      )}
    </React.Fragment>
  )
}
export default Grid
