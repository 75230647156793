import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { queueStatsFetch } from '../../../store/admin/queueStats/actions'
import QueueStatsGrid from './Grid/QueueStatsGrid'

const QueueStats: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(queueStatsFetch())
  }, [])
  return <QueueStatsGrid />
}
export default QueueStats
