import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { faPencil, faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ReactModal from 'react-modal'
import { revertTier } from '../../../../store/admin/tiers/actions'
import {
  getEditableTiers,
  getIsEditable
} from '../../../../store/admin/tiers/selectors'
import { getCustomers } from '../../../../store/customers/selectors'
import { Customer } from '../../../../store/customers/types'
import { getUsers } from '../../../../store/users/selectors'
import TiersModal from '../TiersModal'

import styles from '../Tiers.scss'

const EditDeleteTiers = (props: any) => {
  const dispatch = useDispatch()
  const editOrDelete = props.colDef.colId
  const isEditable = useSelector(getIsEditable)
  const customers = useSelector(getCustomers)
  const users = useSelector(getUsers)
  const tiers = useSelector(getEditableTiers)
  const [displayModal, toggleModal] = useState(false)
  const [displayFromUser, setDisplayFromUser] = useState(false)
  const [displayFromGrid, setDisplayFromGrid] = useState(false)
  const selectedRow = props.data.id
  const [selectedCustomer] = useState<Customer | undefined>(undefined)
  const [showConfirmation, setConfirmation] = useState(false)

  const customer = displayFromGrid
    ? customers?.find(
        (c) => tiers?.find((t) => t.id === selectedRow)?.custId === c.id
      )
    : displayFromUser
    ? undefined
    : selectedCustomer

  const user = displayFromGrid
    ? users?.find(
        (u) => tiers?.find((t) => t.id === selectedRow)?.userId === u.id
      )
    : undefined

  const handleShowModal = useCallback(
    (fromGrid: boolean, fromUser: boolean) => {
      setDisplayFromGrid(fromGrid)
      setDisplayFromUser(fromUser)
      toggleModal(!displayModal)
    },
    [displayModal, displayFromUser, displayFromGrid, selectedRow]
  )

  const handleConfirmation = () => {
    setConfirmation(!showConfirmation)
  }

  const confirmDelete = () => {
    dispatch(revertTier(props.data.id))
    handleConfirmation()
  }

  const showPencilOrX = () => {
    return editOrDelete === 'edit' ? (
      <FontAwesomeIcon
        icon={faPencil}
        style={{ cursor: 'pointer' }}
        onClick={() => handleShowModal(true, false)}
      />
    ) : (
      <FontAwesomeIcon
        icon={faTimes}
        style={{ cursor: 'pointer' }}
        onClick={handleConfirmation}
      />
    )
  }

  return (
    <div>
      {showPencilOrX()}
      {displayModal && (
        <TiersModal
          closeFunc={() => handleShowModal(false, false)}
          displayModal={displayModal}
          customer={customer}
          user={user}
          editable={isEditable}
        />
      )}

      <ReactModal
        className={styles.wrapperModal}
        overlayClassName={styles.overlay}
        isOpen={showConfirmation}
        style={{
          content: {
            inset: '33%',
            margin: '0 auto',
            bottom: '150px',
            width: '400px',
            height: '125px'
          }
        }}
      >
        <div className={styles.confirmContent}>
          This will delete all tiers configured for this customer. Proceed with
          deleting?
        </div>
        <div className={styles.footer}>
          <button onClick={handleConfirmation}>No</button>
          <button onClick={confirmDelete} className={styles.confirm}>
            Yes
          </button>
        </div>
      </ReactModal>
    </div>
  )
}

EditDeleteTiers.displayName = 'EditDeleteTiers'
export default EditDeleteTiers
