import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { GetRowIdParams } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { useOpenFin } from '../../../app/openFinContext'
import { activityLogFetch } from '../../../store/activityLog/actions'
import {
  getActivityLog,
  getActivityLogError
} from '../../../store/activityLog/selectors'
import { Activity } from '../../../store/activityLog/types'
import SearchInput from '../../SearchInput/SearchInput'
import { columnDefinitions } from './columnDefs'

interface Props {
  onToggle?: (value: boolean) => void
}

const getActivityLogRowId = ({ data }: GetRowIdParams<Activity>) => {
  return `${data.matId ?? ''}`
}

const ActivityLogGrid: FC<Props> = ({ onToggle }) => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const activityLog = useSelector(getActivityLog)
  const activityLogError = useSelector(getActivityLogError)
  const [search, setSearch] = useState('')
  const modalStyle = fin ? 'finModal' : 'webModal'

  useEffect(() => {
    dispatch(activityLogFetch())
  }, [])

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  if (activityLogError) {
    return <div>{activityLogError}</div>
  }

  return (
    <>
      <div className="activity-log-actions-row">
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          onClear={onClear}
          placeholder="Search Activity"
          className="activity-log-search"
        />
        {onToggle && (
          <i
            className="fas fa-times activity-log-close"
            onClick={() => onToggle(false)}
          />
        )}
      </div>
      <div className={`ag-theme-balham activity-log ${modalStyle}`}>
        <AgGridReact<Activity>
          rowData={activityLog}
          columnDefs={columnDefinitions}
          quickFilterText={search}
          overlayNoRowsTemplate="No activity found."
          overlayLoadingTemplate="Loading activity…"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          rowClass="activity-log-grid-row"
          getRowId={getActivityLogRowId}
        />
      </div>
    </>
  )
}

export default ActivityLogGrid
