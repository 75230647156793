import { setUser } from '@sentry/react'
import cx from 'classnames'
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  faBell,
  faBellSlash,
  faGear,
  faList,
  faMagnifyingGlass,
  faOctagon
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ReactTooltip from 'react-tooltip'
import { useOpenFin } from '../../app/openFinContext'
import bondsLogo from '../../assets/images/logo_white_blue.png'
import { formattedEnvironment } from '../../config'
import PopoutOpener from '../../containers/Openfin/LastLookOpener'
import { toggleActivityPanel } from '../../store/activity/actions'
import { getActivityPanelVisible } from '../../store/activity/selectors'
import {
  getIsSignedIn,
  getOktaAuth,
  getUserName
} from '../../store/auth/selectors'
import { getMarketClosed } from '../../store/market/selectors'
import { cancelAllWatchListOrdersAction } from '../../store/watchList/actions'
import AlertFilter from '../AlertFilter/AlertFilter'
import BenchmarkDataContainer from '../BenchmarkData/BenchmarkData'
import HelpMenu from '../HelpMenu/HelpMenu'
import UserSelector from '../UserSelector/UserSelector'
import AdminTabSelect from './AdminTabSelect'
import styles from './header.scss'

export interface Props {
  handleShowSettings: () => void
  setSelectedTab: (st: string | null) => void
  isAdmin: boolean
}

const Header: FC<Props> = ({ handleShowSettings, setSelectedTab, isAdmin }) => {
  const { fin } = useOpenFin()
  const isSignedIn = useSelector(getIsSignedIn)
  const userName = useSelector(getUserName)
  const activityPanelVisible = useSelector(getActivityPanelVisible)
  const marketClosed = useSelector(getMarketClosed)
  const auth = useSelector(getOktaAuth)

  const dispatch = useDispatch()

  const handleSignout = useCallback(() => {
    auth?.signOut({ postLogoutRedirectUri: null })
    setUser(null)

    // For now we don’t clear the settings at sign out. Maybe it will be
    // required for security reasons.
    // dispatch(clearSettings())
  }, [auth])
  const toggleActivity = useCallback(() => {
    dispatch(toggleActivityPanel())
  }, [])

  if (fin) {
    return <PopoutOpener />
  }
  const cancelAllOrders = () => {
    dispatch(cancelAllWatchListOrdersAction(0, undefined))
  }

  return (
    <div className={styles.header}>
      <h1>
        <a
          href="http://www.bonds.com"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.logo}
        >
          <img alt="bonds.com" src={bondsLogo} style={{ height: '4.5rem' }} />
        </a>
      </h1>
      {!marketClosed && !fin && !isAdmin && isSignedIn && (
        <BenchmarkDataContainer />
      )}
      {marketClosed && (
        <div className={styles.marketStatus}>ALL MARKETS CLOSED</div>
      )}
      {isAdmin && (
        <React.Fragment>
          <UserSelector />
        </React.Fragment>
      )}
      <span className={styles.marketStatus}>{formattedEnvironment}</span>
      <PopoutOpener />
      {isSignedIn && (
        <div className={styles.rightSection}>
          {!fin && (
            <div className={styles.alertButton}>
              <span
                className={cx(styles.adminButton, styles.watchlistButton)}
                onClick={() => setSelectedTab('Watchlist')}
                data-tip="Price Panel"
                data-for="Price Panel"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </span>
              <ReactTooltip
                id="Price Panel"
                backgroundColor="#e9edf1"
                textColor="#656C78"
                effect="solid"
                className="iconTooltip"
                place="bottom"
              >
                Price Panel
              </ReactTooltip>
            </div>
          )}
          {!fin && (
            <div className={styles.alertButton}>
              <span
                className={cx(styles.adminButton, styles.watchlistButton)}
                onClick={() => setSelectedTab('PassiveOrders')}
                data-tip="Passive Orders"
                data-for="Passive Orders"
              >
                <FontAwesomeIcon icon={faList} />
              </span>
              <ReactTooltip
                id="Passive Orders"
                backgroundColor="#e9edf1"
                textColor="#656C78"
                effect="solid"
                className="iconTooltip"
                place="bottom"
              >
                Passive Orders
              </ReactTooltip>
            </div>
          )}
          <span data-testid="email" className={styles.userName}>
            {userName}
          </span>

          <button data-testid="logout" onClick={handleSignout}>
            Sign out
          </button>

          <AlertFilter />

          <button
            className={styles.activityButton}
            onClick={toggleActivity}
            data-tip={
              activityPanelVisible
                ? 'Hide Activity Panel'
                : 'Show Activity Panel'
            }
            data-for={
              activityPanelVisible ? 'hideActivityPanel' : 'showActivityPanel'
            }
          >
            {activityPanelVisible ? (
              <FontAwesomeIcon icon={faBellSlash} />
            ) : (
              <FontAwesomeIcon icon={faBell} />
            )}
          </button>
          <ReactTooltip
            id={
              activityPanelVisible ? 'hideActivityPanel' : 'showActivityPanel'
            }
            backgroundColor="#e9edf1"
            textColor="#656C78"
            effect="solid"
            className="iconTooltip"
          >
            {activityPanelVisible
              ? 'Hide Activity Panel'
              : 'Show Activity Panel'}
          </ReactTooltip>

          <button
            className={styles.settingsButton}
            onClick={handleShowSettings}
            data-testid="settingsbutton"
            data-tip="Trader Preferences"
            data-for="traderPreferences"
          >
            <FontAwesomeIcon icon={faGear} swapOpacity={true} />
          </button>
          <ReactTooltip
            id="traderPreferences"
            backgroundColor="#e9edf1"
            textColor="#656C78"
            effect="solid"
            className="iconTooltip"
          >
            Trader Preferences
          </ReactTooltip>

          <HelpMenu />

          <button
            className={styles.cancelAllButton}
            onClick={cancelAllOrders}
            data-testid="cancelAllOrders"
            data-tip="Cancel All Orders"
            data-for="cancelAllOrders"
          >
            <FontAwesomeIcon icon={faOctagon} />
          </button>
          <ReactTooltip
            id="cancelAllOrders"
            backgroundColor="#e9edf1"
            textColor="#656C78"
            effect="solid"
            className="iconTooltip"
          >
            Cancel All Orders
          </ReactTooltip>
          {isAdmin && <AdminTabSelect setSelectedTab={setSelectedTab} />}
        </div>
      )}
    </div>
  )
}

export default Header
