import { ColDef } from '@ag-grid-community/core'
import EditDeleteTiers from './EditDeleteTiers'

export const TIER_ID = 'id'
export const EVENT_TIME = 'eventTime'
export const CUSTOMER = 'customer'
export const USER = 'user'
export const TIERS = 'tiers'
export const EDIT = 'edit'
export const DELETE = 'delete'

export const customizableColumns: ColDef[] = [
  {
    colId: TIER_ID,
    field: TIER_ID,
    headerName: 'Tier Id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true
  },
  {
    colId: CUSTOMER,
    field: CUSTOMER,
    headerName: 'Customer',
    width: 200,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    wrapText: true
  },
  {
    colId: USER,
    field: USER,
    headerName: 'User',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true
  },
  {
    colId: TIERS,
    field: TIERS,
    headerName: 'Tiers',
    width: 50,
    suppressAutoSize: true,
    sortable: true,
    resizable: true
  },
  {
    colId: EDIT,
    headerName: 'Edit',
    width: 45,
    suppressAutoSize: true,
    sortable: true,
    cellRenderer: EditDeleteTiers,
    resizable: true
  },
  {
    colId: DELETE,
    headerName: 'Delete',
    width: 45,
    suppressAutoSize: true,
    sortable: true,
    cellRenderer: EditDeleteTiers,
    resizable: true
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
