import React, { FC } from 'react'
import styles from './Message.scss'

export interface Props {
  type?: 'error' | 'information'
}

const Message: FC<Props> = ({ children, type }) => {
  const className = type === 'error' ? styles.errorMessage : styles.infoMessage
  return <p className={className}>{children}</p>
}

Message.defaultProps = {
  type: 'information'
}

export default Message
