import { ColDef } from '@ag-grid-community/core'
import { dateFormatter, dateFormatter2 } from '../../../helpers/formatting'
import PriceSpreadCell from './PriceSpreadCell'

export const ORDER_TIME = 'orderTime'
export const SECURITY = 'security'
export const ORDER_TYPE = 'orderType'
export const PRICE = 'price'
export const SPREAD = 'spread'
export const COMPLETEDAMOUNT = 'completedAmount'
export const INITIALAMOUNT = 'initialAmount'
export const REMAININGAMOUNT = 'remainingAmount'
export const CANCEL_TIME = 'cancelTime'
export const TRADER = 'trader'
export const OPERATOR = 'operatorName'
export const ORDERID = 'id'
export const CUSTOMER = 'customer'
export const ACCTTYPE = 'acctType'

export const customizableColumns: ColDef[] = [
  {
    colId: ORDER_TIME,
    field: ORDER_TIME,
    headerName: 'Time',
    valueFormatter: dateFormatter,
    cellStyle: { border: '1px solid #e4e5e6' },
    width: 150,
    suppressSizeToFit: true
  },
  {
    colId: SECURITY,
    field: SECURITY,
    headerName: 'Security',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true
  },
  {
    colId: ORDER_TYPE,
    field: ORDER_TYPE,
    headerName: 'Order Type',
    cellStyle: { border: '1px solid #e4e5e6' },
    width: 100,
    valueFormatter: ({ value }) => (value === 0 ? 'Bid' : 'Offer'),
    tooltipField: ORDER_TYPE
  },
  {
    colId: PRICE,
    field: PRICE,
    headerName: 'Price',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true,
    width: 100,
    cellRenderer: PriceSpreadCell
  },
  {
    colId: ORDERID,
    field: ORDERID,
    headerName: 'Order Id',
    cellStyle: { border: '1px solid #e4e5e6' },
    sort: 'desc',
    width: 100
  },
  {
    colId: SPREAD,
    field: SPREAD,
    headerName: 'Spread',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true,
    width: 100,
    cellRenderer: PriceSpreadCell
  },
  {
    colId: COMPLETEDAMOUNT,
    field: COMPLETEDAMOUNT,
    headerName: 'Completed Amouint',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true,
    width: 100
  },
  {
    colId: INITIALAMOUNT,
    field: INITIALAMOUNT,
    headerName: 'Initial Amount',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true,
    width: 100
  },
  {
    colId: REMAININGAMOUNT,
    field: REMAININGAMOUNT,
    headerName: 'Remaining Amount',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true,
    width: 100
  },

  {
    colId: CANCEL_TIME,
    field: CANCEL_TIME,
    headerName: 'Cancel Time',
    cellStyle: { border: '1px solid #e4e5e6' },
    valueFormatter: dateFormatter,
    suppressSizeToFit: true
  },

  {
    colId: TRADER,
    field: TRADER,
    headerName: 'Trader',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true
  },

  {
    colId: OPERATOR,
    field: OPERATOR,
    headerName: 'Operator',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true
  }
]

export const adminColumns: ColDef[] = [
  {
    colId: ORDER_TIME,
    field: ORDER_TIME,
    headerName: 'Time',
    valueFormatter: dateFormatter,
    cellStyle: { border: '1px solid #e4e5e6' },
    width: 150,
    suppressSizeToFit: true
  },
  {
    colId: SECURITY,
    field: SECURITY,
    headerName: 'Security',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true
  },
  {
    colId: ORDER_TYPE,
    field: ORDER_TYPE,
    headerName: 'Order Type',
    cellStyle: { border: '1px solid #e4e5e6' },
    width: 100,
    valueFormatter: ({ value }) => (value === 0 ? 'Bid' : 'Offer'),
    tooltipField: ORDER_TYPE
  },
  {
    colId: PRICE,
    field: PRICE,
    headerName: 'Price',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true,
    width: 100,
    cellRenderer: PriceSpreadCell
  },
  {
    colId: ORDERID,
    field: ORDERID,
    headerName: 'Order Id',
    cellStyle: { border: '1px solid #e4e5e6' },
    sort: 'desc',
    width: 100
  },
  {
    colId: SPREAD,
    field: SPREAD,
    headerName: 'Spread',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true,
    width: 100,
    cellRenderer: PriceSpreadCell
  },
  {
    colId: COMPLETEDAMOUNT,
    field: COMPLETEDAMOUNT,
    headerName: 'Completed Amouint',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true,
    width: 100
  },
  {
    colId: INITIALAMOUNT,
    field: INITIALAMOUNT,
    headerName: 'Initial Amount',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true,
    width: 100
  },
  {
    colId: REMAININGAMOUNT,
    field: REMAININGAMOUNT,
    headerName: 'Remaining Amount',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true,
    width: 100
  },

  {
    colId: CANCEL_TIME,
    field: CANCEL_TIME,
    headerName: 'Cancel Time',
    cellStyle: { border: '1px solid #e4e5e6' },
    valueFormatter: dateFormatter2,
    suppressSizeToFit: true
  },

  {
    colId: TRADER,
    field: TRADER,
    headerName: 'Trader',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true
  },

  {
    colId: OPERATOR,
    field: OPERATOR,
    headerName: 'Operator',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true
  },
  {
    colId: CUSTOMER,
    field: CUSTOMER,
    headerName: 'Customer',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true
  },
  {
    colId: ACCTTYPE,
    field: ACCTTYPE,
    headerName: 'Acct Type',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
export const adminColumnDefinitions: ColDef[] = [...adminColumns]
