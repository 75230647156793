import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import openfinStyles from '../../../containers/Openfin/Admin/openfinAdmin.scss'
import { saveBBMISINs } from '../../../store/admin/bbmisin/actions'
import { StringToBBMISINList } from '../../../store/admin/bbmisin/helpers'
import { getBBMISINErrors } from '../../../store/admin/bbmisin/selectors'

import styles from './BBMISIN.scss'

const BBMISIN: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const serverErrors = useSelector(getBBMISINErrors)
  const [bbmIsinData, setBbmIsinData] = useState<string>('')
  const [frontEndError, setFrontEndError] = useState<string>('')
  const [backEndError, setBackEndError] = useState('')
  const containerStyle = fin ? openfinStyles.finWrapper : styles.bbmIsinWrapper

  const updateBbmIsinData = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBbmIsinData(evt.target.value)
  }

  useEffect(() => {
    setBackEndError(serverErrors)
  }, [serverErrors])

  const uploadData = () => {
    const { bbmIsinList, errors } = StringToBBMISINList(bbmIsinData)
    if (bbmIsinList.length > 0) {
      dispatch(saveBBMISINs(bbmIsinList))
    }
    setFrontEndError(errors.join('\n'))
  }

  return (
    <React.Fragment>
      <div className={containerStyle}>
        <div className={fin && styles.headerWrapper}>
          <button className={styles.uploadButton} onClick={uploadData}>
            Upload
          </button>
        </div>

        <textarea
          className={styles.textArea}
          placeholder="Cusip/ISIN BBMISIN"
          rows={40}
          cols={100}
          value={bbmIsinData}
          onChange={updateBbmIsinData}
        ></textarea>
      </div>
      <div className={styles.errorSection}>
        <div className={styles.frontEndErrorSection}>
          <label>{frontEndError}</label>
        </div>
        <div className={styles.backEndErrorSection}>
          <label>{backEndError}</label>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BBMISIN
