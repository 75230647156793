import cx from 'classnames'
import React, { FC } from 'react'
import ReactModal from 'react-modal'

import { useDispatch } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import { useToggleWithCancelCallback } from '../../helpers/hooks'
import { tradeBlotterFetchCancel } from '../../store/activityTradeBlotter/actions'
import Button from '../Button/Button'
import styles from './ActivityTradeBlotter.scss'
import ActivityTradeBlotterGrid from './Grid/ActivityTradeBlotterGrid'

const ActivityTradeBlotter: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()

  const [displayModal, toggleModal] = useToggleWithCancelCallback(false, () => {
    dispatch(tradeBlotterFetchCancel())
  })

  return (
    <>
      {displayModal && (
        <ReactModal
          isOpen={displayModal}
          onRequestClose={() => toggleModal(false)}
          style={{
            content: {
              margin: '0 auto',
              bottom: '150px',
              width: '80%'
            }
          }}
        >
          <ActivityTradeBlotterGrid onToggle={toggleModal} />
        </ReactModal>
      )}
      <Button
        onClick={() => toggleModal(true)}
        className={cx(fin && styles.finButton, styles.toggleButton)}
      >
        My Trade Blotter
      </Button>
    </>
  )
}

export default ActivityTradeBlotter
