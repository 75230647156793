import React, { FC, useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'

import {
  faExclamationTriangle,
  faSpinner,
  faTimes
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  tradeBlotterFetch,
  tradeBlotterFetchCancel
} from '../../../store/activityTradeBlotter/actions'
import {
  getActivityTradeBlotter,
  getActivityTradeBlotterError
} from '../../../store/activityTradeBlotter/selectors'

import './TradeConfirm.scss'

interface Props {
  tradeConfirmId: number
  handleTradeConfirmClick: (id: string | null) => void
  isOpen: boolean
}

const TradeConfirm: FC<Props> = ({
  tradeConfirmId,
  handleTradeConfirmClick,
  isOpen
}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [tradeConfirmText, setTradeConfirmText] = useState(null)

  const dispatch = useDispatch()
  const tradeData = useSelector(getActivityTradeBlotter)
  const tradeDataError = useSelector(getActivityTradeBlotterError)

  useEffect(() => {
    dispatch(tradeBlotterFetch())
  }, [])

  useEffect(() => {
    if (tradeDataError) {
      setError(true)
      dispatch(tradeBlotterFetchCancel())
      setLoading(false)
    }

    if (tradeData && tradeConfirmId) {
      const tradeConfirmData = tradeData.find(
        (trade) => trade.orderId === Number(tradeConfirmId)
      )
      if (tradeConfirmData) {
        setTradeConfirmText(tradeConfirmData.confirmText)
      }
      setLoading(false)
    }
  }, [tradeData, tradeDataError])

  const onTradeConfirmClick = useCallback(() => {
    dispatch(tradeBlotterFetchCancel())
    handleTradeConfirmClick(null)
  }, [])

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onTradeConfirmClick}
      shouldReturnFocusAfterClose={false}
      style={{
        content: {
          margin: '0 auto',
          bottom: '150px',
          width: '515px'
        }
      }}
    >
      <div className="dFlex">
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onTradeConfirmClick}
          className="text-right"
        />
      </div>
      <div>
        {loading && (
          <div className="loading-error-container">
            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
          </div>
        )}

        {((!loading && error) || (!loading && !tradeConfirmText)) && (
          <div className="loading-error-container">
            <FontAwesomeIcon icon={faExclamationTriangle} /> An unexpected error
            occurred.
          </div>
        )}

        {tradeConfirmText && (
          <pre className="confirm-text">{tradeConfirmText}</pre>
        )}
      </div>
    </ReactModal>
  )
}

export default TradeConfirm
