import { faPencil } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../containers/BondList/bondListStyle.scss'
import {
  useSecurityStaticData,
  useStagedAndLiveOrders
} from '../../containers/BondList/cells/helpers'
import ErrorComponent from '../../containers/DepthOfMarket/Error'
import { updateOrCancelOrder } from '../../containers/DepthOfMarket/Form/helpers'
import { ConvertUSTPrice } from '../../helpers/formatting'
import { getBooks } from '../../store/books/selectors'
import { getPendingUserOrderForSecurity } from '../../store/order/selectors'
import { OrderType } from '../../store/order/types'
import { validate } from '../../store/order/validation'
import { showTOBSetting } from '../../store/webSettings/selectors'
import FilterPill from '../Activity/ActivityHeader/FilterPill'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import ComponentFooter from '../ComponentFooter/ComponentFooter'
import Modal from '../GenericModal/Modal'

export interface Props {
  data: any
  orderType: OrderType
}

const AdvancedOrderEditor: FC<Props> = ({ data, orderType }) => {
  const type = orderType === 'buy' ? 'Bid' : 'Offer'
  const dispatch = useDispatch()
  const security = useSecurityStaticData(data)
  const { stagedOrder, liveOrder } = useStagedAndLiveOrders(
    security?.id,
    orderType
  )

  const getOrderForSecurity = useSelector(getPendingUserOrderForSecurity)
  const books = useSelector(getBooks)
  const showTOB = useSelector(showTOBSetting)

  const pills = [
    {
      id: 'general',
      label: 'Order Entry'
    }
  ]
  if (showTOB) {
    pills.push({
      id: 'tob',
      label: 'Top of Book'
    })
  }

  // Note: values are strings because they’re used in inputs.
  const [selectedTab, setSelectedTab] = useState<string>('general')
  const [totalSize, setTotalSize] = useState<string>('')
  const [minSize, setMinSize] = useState<string>('')
  const [price, setPrice] = useState<string>('')
  const [spread, setSpread] = useState<string>('')
  const [allOrNone, setAllOrNone] = useState(false)
  const [isTob, setIsTOB] = useState(false)
  const [book, setBook] = useState('')
  const [disableMinSize, setDisableMinSize] = useState(false)
  const [disablePrice, setDisablePrice] = useState(false)
  const [disableSpread, setDisableSpread] = useState(false)
  const [disableBook, setDisableBook] = useState(false)
  const [tobLimitPrice, setTOBLimitPrice] = useState<string>('')
  const [tobLimitSpread, setTOBLimitSpread] = useState<string>('')
  const [tobFloorPrice, setTOBFloorPrice] = useState<string>('')
  const [tobFloorSpread, setTOBFloorSpread] = useState<string>('')
  const [disableTOBPrice, setDisableTOBPrice] = useState(false)
  const [disableTOBSpread, setDisableTOBSpread] = useState(false)

  const [isSpread, setIsSpread] = useState(false)
  const [errorText, setError] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(!modalOpen)
  }

  useEffect(() => {
    setTotalSize(
      liveOrder
        ? String(liveOrder.size)
        : stagedOrder
        ? String(stagedOrder.size)
        : ''
    )

    setPrice(
      liveOrder
        ? String(liveOrder.price)
        : stagedOrder &&
          stagedOrder.price !== undefined &&
          !stagedOrder.tob?.tob
        ? String(stagedOrder.price)
        : ''
    )
    setSpread(
      liveOrder && liveOrder.spread !== undefined
        ? String(liveOrder.spread)
        : stagedOrder &&
          stagedOrder.spread !== undefined &&
          !stagedOrder.tob?.tob
        ? String(stagedOrder.spread)
        : ''
    )
    setIsSpread(liveOrder ? liveOrder.isSpreadOrder : isSpread)

    setAllOrNone(liveOrder ? liveOrder.allOrNone : false)

    if (liveOrder) {
      setDisableBook(true)
      liveOrder.isSpreadOrder ? setDisablePrice(true) : setDisableSpread(true)
      if (liveOrder.tob?.tob) {
        // setDisableTOB(false)
        setDisableSpread(true)
        setDisablePrice(true)
        setIsTOB(true)
        liveOrder.isSpreadOrder
          ? setDisableTOBPrice(true)
          : setDisableTOBSpread(true)
        setTOBLimitPrice(
          liveOrder.isSpreadOrder ? '' : String(liveOrder.tob.limitPrice) ?? ''
        )
        setTOBLimitSpread(
          liveOrder.isSpreadOrder ? String(liveOrder.tob.limitPrice) ?? '' : ''
        )
      } else {
        setDisableTOBPrice(true)
        setDisableTOBSpread(true)
      }

      liveOrder.allOrNone
        ? setMinSize(totalSize)
        : setMinSize(String(liveOrder.individualMin))
    } else {
      setDisableBook(false)
      setDisablePrice(false)
      setDisableSpread(false)
      setMinSize('')
    }
  }, [modalOpen, stagedOrder, liveOrder])

  const checkPrice = (priceStr: string) => {
    return isNaN(Number(priceStr)) && security?.product === 'PrinUSGovtOutright'
      ? ConvertUSTPrice(priceStr)
      : Number(priceStr)
  }

  const handleSubmitHitOrBid = useCallback(() => {
    const priceStr = isTob ? tobLimitPrice : price
    const validatePrice = isTob ? tobFloorPrice : price
    const spreadStr = isTob ? tobFloorSpread : spread
    if ((priceStr || spreadStr) && totalSize && security) {
      const num = isSpread ? spreadStr : checkPrice(priceStr)
      const validateNum = isSpread ? spreadStr : checkPrice(validatePrice)
      const floorPrice = isSpread ? tobFloorSpread : checkPrice(tobFloorPrice)
      const limitPrice = isSpread ? tobLimitSpread : checkPrice(tobLimitPrice)
      const tobParams = {
        tob: isTob,
        limitPrice: isTob ? Number(limitPrice) : null,
        floorPrice: isTob ? Number(floorPrice) : null
      }
      const error =
        security &&
        validate(
          security,
          orderType,
          Number(validateNum),
          isSpread,
          Number(totalSize),
          Number(minSize),
          0,
          tobParams
        )
      if (error) {
        setError(error)
      } else {
        setError('')
        const order = getOrderForSecurity(security.id, orderType)
        const { action } = updateOrCancelOrder(
          security,
          order,
          orderType,
          Number(num),
          isSpread,
          Number(totalSize),
          allOrNone,
          Number(minSize),
          Number(book),
          tobParams
        )

        if (action) {
          dispatch(action)
        }
        setModalOpen(false)
      }
    } else if (!totalSize) {
      setError('Enter a size')
    } else if (!priceStr || !spreadStr) {
      setError('Enter price or spread')
    }
  }, [
    security,
    price,
    spread,
    totalSize,
    minSize,
    allOrNone,
    book,
    isTob,
    tobLimitPrice,
    tobLimitSpread,
    tobFloorPrice,
    tobFloorSpread
  ])

  const handleTotalSzChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTotalSize(event.currentTarget.value)
      if (allOrNone) {
        setDisableMinSize(false)
        setMinSize(event.currentTarget.value)
        setDisableMinSize(true)
      }
    },
    [allOrNone, totalSize]
  )
  const handleMinSzChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMinSize(event.currentTarget.value)
    },
    []
  )
  const handlePriceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (liveOrder === undefined) {
        setIsSpread(false)
        setSpread('')
      }
      if (liveOrder === undefined || !liveOrder.isSpreadOrder) {
        setIsSpread(false)
        setPrice(event.currentTarget.value)
        if (event.currentTarget.value) {
          setTobDisables(false)
        } else {
          unsetAllDisables()
        }
      }
    },
    [liveOrder, isSpread, price, spread]
  )
  const handleSpreadChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (liveOrder === undefined) {
        setIsSpread(true)
        setPrice('')
      }
      if (liveOrder === undefined || liveOrder.isSpreadOrder) {
        setIsSpread(true)
        setSpread(event.currentTarget.value)
        if (event.currentTarget.value) {
          setTobDisables(false)
        } else {
          unsetAllDisables()
        }
      }
    },
    [liveOrder, isSpread, price, spread]
  )

  const handleTOBLimitPriceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (liveOrder === undefined) {
        setIsSpread(false)
        setTOBLimitSpread('')
        setTOBFloorSpread('')
      }
      if (liveOrder === undefined || !liveOrder.isSpreadOrder) {
        setIsSpread(false)
        setTOBLimitPrice(event.currentTarget.value)
        if (event.currentTarget.value) {
          setTobDisables(true)
        } else {
          unsetAllDisables()
        }
      }
    },
    [
      liveOrder,
      isSpread,
      tobLimitPrice,
      tobLimitSpread,
      tobFloorPrice,
      tobFloorSpread
    ]
  )

  const handleTOBFloorPriceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (liveOrder === undefined) {
        setIsSpread(false)
        setTOBLimitSpread('')
        setTOBFloorSpread('')
      }
      if (liveOrder === undefined || !liveOrder.isSpreadOrder) {
        setIsSpread(false)
        setTOBFloorPrice(event.currentTarget.value)
        if (event.currentTarget.value) {
          setTobDisables(true)
        } else {
          unsetAllDisables()
        }
      }
    },
    [
      liveOrder,
      isSpread,
      tobLimitPrice,
      tobLimitSpread,
      tobFloorPrice,
      tobFloorSpread
    ]
  )

  const unsetAllDisables = useCallback(() => {
    setIsTOB(false)
    setDisablePrice(false)
    setDisableSpread(false)
    setDisableTOBPrice(false)
    setDisableTOBSpread(false)
  }, [
    liveOrder,
    isSpread,
    tobLimitPrice,
    tobLimitSpread,
    tobFloorPrice,
    tobFloorSpread,
    price,
    spread
  ])

  const setTobDisables = useCallback(
    (tob: boolean) => {
      if (tob) {
        setIsTOB(true)
        setDisablePrice(true)
        setDisableSpread(true)
        setDisableTOBPrice(false)
        setDisableTOBSpread(false)
      } else {
        setIsTOB(false)
        setDisablePrice(false)
        setDisableSpread(false)
        setDisableTOBPrice(true)
        setDisableTOBSpread(true)
      }
    },
    [
      liveOrder,
      isSpread,
      tobLimitPrice,
      tobLimitSpread,
      tobFloorPrice,
      tobFloorSpread,
      price,
      spread
    ]
  )

  const handleTOBFloorSpreadChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (liveOrder === undefined) {
        setIsSpread(true)
        setTOBLimitPrice('')
        setTOBFloorPrice('')
      }
      if (liveOrder === undefined || liveOrder.isSpreadOrder) {
        setIsSpread(true)
        setTOBFloorSpread(event.currentTarget.value)
        if (event.currentTarget.value) {
          setTobDisables(true)
        } else {
          unsetAllDisables()
        }
      }
    },
    [
      liveOrder,
      isSpread,
      tobLimitPrice,
      tobLimitSpread,
      tobFloorPrice,
      tobFloorSpread
    ]
  )

  const handleTOBLimitSpreadChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (liveOrder === undefined) {
        setIsSpread(true)
        setTOBLimitPrice('')
        setTOBFloorPrice('')
      }
      if (liveOrder === undefined || liveOrder.isSpreadOrder) {
        setIsSpread(true)
        setTOBLimitSpread(event.currentTarget.value)
        if (event.currentTarget.value) {
          setTobDisables(true)
        } else {
          unsetAllDisables()
        }
      }
    },
    [
      liveOrder,
      isSpread,
      tobLimitPrice,
      tobLimitSpread,
      tobFloorPrice,
      tobFloorSpread
    ]
  )

  const handleAllOrNone = () => {
    setAllOrNone(!allOrNone)
  }

  useEffect(() => {
    if (allOrNone) {
      setMinSize(totalSize)
      setDisableMinSize(true)
    } else {
      setDisableMinSize(false)
    }
  }, [allOrNone])

  const clearForm = () => {
    setModalOpen(false)
    setPrice('')
    setSpread('')
    setTotalSize('')
    setMinSize('')
    setAllOrNone(false)
    setDisableSpread(false)
    setDisablePrice(false)
    setError('')
  }

  return (
    <div className="my-orders-edit">
      <FontAwesomeIcon icon={faPencil} onClick={openModal} />
      {modalOpen && (
        <Modal customWrapperStyles={styles.customHeight}>
          <div>
            <ErrorComponent
              error={errorText}
              autoDismissDelay={3000}
              setError={() => setError('')}
            />
            <ComponentHeader
              title={`${type}: ${data.boardLabel} | ${
                data.cusip ? data.cusip : data.isin
              }`}
            />

            {data.benchmarkSecurity && (
              <div className={styles.benchmark}>
                Benchmark:{' '}
                {`${data.benchmarkSecurity} \u00A0|\u00A0 ${
                  data.benchmarkCusip ? data.benchmarkCusip : data.benchmarkISIN
                }`}
              </div>
            )}
            {/* tabs */}
            <div className={styles.pillwrapper}>
              {pills.map(({ id, label }) => (
                <FilterPill
                  key={id}
                  onClick={() => setSelectedTab(id)}
                  active={id === selectedTab}
                >
                  {label}
                </FilterPill>
              ))}
            </div>
            <main>
              {selectedTab === 'general' && (
                <React.Fragment>
                  <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      Spread:
                      <input
                        className={styles.advanced}
                        value={spread}
                        onChange={handleSpreadChange}
                        disabled={disableSpread}
                      />
                    </label>
                  </div>
                  <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      Total Size:
                      <input
                        className={styles.advanced}
                        onChange={handleTotalSzChange}
                        value={totalSize}
                      />
                    </label>
                  </div>
                  <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      Price:
                      <input
                        className={styles.advanced}
                        value={price}
                        onChange={handlePriceChange}
                        disabled={disablePrice}
                      />
                    </label>
                  </div>
                  <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      Min Size:
                      <input
                        value={minSize}
                        onChange={handleMinSzChange}
                        disabled={disableMinSize}
                        className={styles.advanced}
                      />
                    </label>
                  </div>

                  <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      Book:
                      <select
                        className={styles.books}
                        value={book}
                        onChange={(e) => setBook(e.currentTarget.value)}
                        disabled={disableBook}
                      >
                        {books?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                  <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      All or None:
                      <input
                        type="checkbox"
                        name="checkbox-aon"
                        onChange={handleAllOrNone}
                        checked={allOrNone}
                        className={styles.checkbox}
                      />
                    </label>
                  </div>
                </React.Fragment>
              )}
              {selectedTab === 'tob' && (
                <React.Fragment>
                  <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      Limit Price:
                      <input
                        className={styles.advanced}
                        value={tobLimitPrice}
                        onChange={handleTOBLimitPriceChange}
                        disabled={disableTOBPrice}
                      />
                    </label>
                  </div>

                  <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      Limit Spread:
                      <input
                        className={styles.advanced}
                        value={tobLimitSpread}
                        onChange={handleTOBLimitSpreadChange}
                        disabled={disableTOBSpread}
                      />
                    </label>
                  </div>
                  <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      Floor Price:
                      <input
                        className={styles.advanced}
                        value={tobFloorPrice}
                        onChange={handleTOBFloorPriceChange}
                        disabled={disableTOBPrice}
                      />
                    </label>
                  </div>

                  <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      Floor Spread:
                      <input
                        className={styles.advanced}
                        value={tobFloorSpread}
                        onChange={handleTOBFloorSpreadChange}
                        disabled={disableTOBSpread}
                      />
                    </label>
                  </div>
                  {/* <div className={styles.field}>
                    <label className={styles.advancedLabel}>
                      TOB:
                      <input
                        type="checkbox"
                        name="checkbox-aon"
                        onChange={handleTOB}
                        checked={isTob}
                        className={styles.checkbox}
                        disabled={disableTOB}
                      />
                    </label>
              </div>*/}
                </React.Fragment>
              )}
            </main>

            <ComponentFooter
              onCancelClick={clearForm}
              onApplyClick={handleSubmitHitOrBid}
              submitText={type}
              errorText={errorText}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default AdvancedOrderEditor
