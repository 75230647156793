import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import {
  faBars,
  faCompressAlt,
  faTimes,
  faUser,
  faWindowMinimize,
  faWindowRestore
} from '@fortawesome/pro-regular-svg-icons'
import { faOctagon } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hide as hideNotifCenter } from 'openfin-notifications'
import { useOpenFin } from '../../../app/openFinContext'
import bondsLogo from '../../../assets/images/bonds_logo_black.png'
import ComponentFooter from '../../../components/ComponentFooter/ComponentFooter'
import UserSelector from '../../../components/UserSelector/UserSelector'
import { formattedEnvironment } from '../../../config'
import { getUserName } from '../../../store/auth/selectors'
import { closeAllPopouts } from '../../../store/depthOfMarket/actions'
import { getMarketClosed } from '../../../store/market/selectors'
import { getOpenfinParameters } from '../../../store/userPreferences/selectors'
import { cancelAllWatchListOrdersAction } from '../../../store/watchList/actions'
import { getIsAdmin } from '../../../store/webSettings/selectors'
import styles from './titlebar.scss'

export interface Props {
  toggleMenu: (menu: string) => void
}

// TODO: move admin menus into titlebar
const Titlebar: FC<Props> = ({ toggleMenu }) => {
  const {
    fin,
    autoLaunch,
    createOpenfinWindow,
    manageWindows,
    removeFrame,
    restoreWindows
  } = useOpenFin()
  const [showQuitConfirmation, setQuitConfirmation] = useState(false)

  const isAdmin = useSelector(getIsAdmin)
  const dispatch = useDispatch()
  const userName = useSelector(getUserName)
  const marketClosed = useSelector(getMarketClosed)

  const mainWindow = fin.desktop.Window.getCurrent()
  const finApp = fin?.Application.getCurrentSync()
  const startupParameters = useSelector(getOpenfinParameters)
  const [interval, setStateInterval] = useState<NodeJS.Timeout | undefined>(
    undefined
  )

  useEffect(() => {
    // remove default frame
    removeFrame()

    // on start, bypass the renderWindowCheck in order to launch windows from previous session
    const openWindows = localStorage.getItem(`openfinOpenWindows`)?.split(',')
    if (openWindows) {
      try {
        openWindows.forEach((name: string) => {
          switch (true) {
            // don't render windows still using popout host
            case name.includes('UserEditor'):
            case name.includes('Depth'):
            case name.includes('Watchlist'):
              break
            default:
              createOpenfinWindow(name)
          }
        })
      } catch (error) {
        console.error('Error opening windows: ' + error)
      }
    }

    finApp.on('run-requested', () => {
      restoreWindows()
    })
  }, [])

  useEffect(() => {
    autoLaunch(startupParameters, interval, setStateInterval)
  }, [JSON.stringify(startupParameters)])

  const handleQuitConfirmation = () => {
    setQuitConfirmation(!showQuitConfirmation)
  }

  const closeAll = () => {
    dispatch(closeAllPopouts())
    manageWindows('Depth', 'quit')
  }

  const onMinimizeClick = () => {
    mainWindow.minimize()
    manageWindows(null, 'minimize')
  }

  const onShowClick = () => {
    manageWindows(null, 'show')
  }

  const onQuitClick = () => {
    cancelAllOrders()
    mainWindow.hide()
    manageWindows(null, 'hide')
    hideNotifCenter()
    setQuitConfirmation(false)
  }

  const cancelAllOrders = () => {
    dispatch(cancelAllWatchListOrdersAction(0, undefined))
  }

  return (
    <div className={styles.titlebarContainer}>
      <ReactModal
        className={styles.wrapperModal}
        overlayClassName={styles.overlay}
        isOpen={showQuitConfirmation}
      >
        <div className={styles.confirmContent}>Close BondsPro?</div>
        <ComponentFooter
          onCancelClick={handleQuitConfirmation}
          onApplyClick={onQuitClick}
          submitText="YES"
        />
      </ReactModal>

      {!isAdmin && (
        <div className={styles.appTitle}>
          <img alt="bonds.com" src={bondsLogo} />
        </div>
      )}

      {marketClosed && (
        <div className={styles.marketStatus}>ALL MARKETS CLOSED</div>
      )}

      {!marketClosed && isAdmin && (
        <div className={styles.selectorContainer}>
          <UserSelector />
        </div>
      )}
      <span className={styles.marketStatus}>{formattedEnvironment}</span>

      <div className={styles.actions}>
        <span data-testid="email" className={styles.userName}>
          {userName}
        </span>
        <div
          className={cx(styles.icon, styles.cancelIcon)}
          onClick={cancelAllOrders}
          title="Cancel All Orders"
        >
          <FontAwesomeIcon icon={faOctagon} />
        </div>
        {isAdmin && (
          <div
            className={styles.icon}
            onClick={() => toggleMenu('Admin')}
            title="Admin Options"
          >
            <FontAwesomeIcon icon={faUser} />
          </div>
        )}
        <div
          className={cx(styles.icon, styles.menu)}
          onClick={() => toggleMenu('Client')}
          title="Menu"
        >
          <FontAwesomeIcon icon={faBars} />
        </div>

        <div
          className={styles.icon}
          onClick={onShowClick}
          title="Show All Windows"
        >
          <FontAwesomeIcon icon={faWindowRestore} />
        </div>

        <div
          className={styles.icon}
          onClick={closeAll}
          title="Close Depth Windows"
        >
          <FontAwesomeIcon icon={faCompressAlt} />
        </div>

        <div
          className={styles.icon}
          onClick={onMinimizeClick}
          title="Minimize All Windows"
        >
          <FontAwesomeIcon icon={faWindowMinimize} />
        </div>

        <div
          className={styles.icon}
          onClick={handleQuitConfirmation}
          title="Quit"
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  )
}

export default Titlebar
