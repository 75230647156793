import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

const getState = (state: { [namespace]: State }) => state[namespace]

export const getStateValuesToSave = createSelector(
  [getState],
  (state) => state.stateValuesToSave
)

export const getActionTypesToTriggerSaving = createSelector(
  [getState],
  (state) => state.actionTypesToTriggerSaving
)

export const areSettingsLoaded = createSelector(
  [getState],
  (state) => state.areSettingsLoaded
)

export const getAggressAttemptsColumnOrder = createSelector(
  [getState],
  (state) => state.aggressAttemptSettings?.columnDefinitions
)

export const getExternalOrderColumnOrder = createSelector(
  [getState],
  (state) => state.externalOrderSettings?.columnDefinitions
)

export const getExternalLiquidityColumnOrder = createSelector(
  [getState],
  (state) => state.externalLiquiditySettings?.columnDefinitions
)

export const getHistoricalDataColumnOrder = createSelector(
  [getState],
  (state) => state.historicalDataSettings?.columnDefinitions
)
