import { ColDef } from '@ag-grid-community/core'

export const EVENT_TIME = 'eventTime'
export const NAME = 'name'
export const DESCRIPTION = 'description'
export const VALUE = 'value'

export const customizableColumns: ColDef[] = [
  {
    colId: NAME,
    field: NAME,
    headerName: 'Name',
    width: 600,
    suppressAutoSize: true,
    sortable: true
  },
  {
    colId: DESCRIPTION,
    field: DESCRIPTION,
    headerName: 'Description',
    width: 100,
    suppressAutoSize: true,
    sortable: true
  },
  {
    colId: VALUE,
    field: VALUE,
    headerName: 'Value',
    width: 300,
    suppressAutoSize: true,
    sortable: true
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
