import React, { FC } from 'react'
import styles from './index.scss'

interface Props {
  onClick: () => void
  active?: boolean
}

const FilterPill: FC<Props> = ({ active, onClick, children }) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.pill} ${active ? styles.active : ''}`}
    >
      {children}
    </div>
  )
}

export default FilterPill
