import moment from 'moment'
import { ManagementReport } from './types'

export const addOrUpdateManagementReport = (
  stateManagementReports: Record<string, ManagementReport>,
  managementReportToAdd: ManagementReport
) => {
  const newManagementReports: Record<string, ManagementReport> = {
    ...stateManagementReports
  }
  newManagementReports[
    moment.parseZone(managementReportToAdd.reportDate).format('MM/DD/yyyy')
  ] = managementReportToAdd
  return newManagementReports
}
