import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import AggressorModal from '../../components/Modal/AggressorModal/AggressorModal'
import {
  getActiveAggressorWindow,
  getAggressorWindows
} from '../../store/windows/selectors'
import { AggressorWindowInfo } from '../../store/windows/types'

export interface Props {
  windowInfo: AggressorWindowInfo
  index: number
  handleMinimize: (index: number) => void
}

const popupElementId = 'aggressorPopupRoot'

export const AggressorPopoutHost = (props: any) => {
  return <div id={popupElementId}></div>
}

const AggressorPopout: FC<Props> = ({ windowInfo, handleMinimize, index }) => {
  const { fin } = useOpenFin()
  const [externalWindow, setExternalWindow] = useState<any>(null)
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null
  )
  const aggressorWindows = useSelector(getAggressorWindows)
  const activeModalIndex = useSelector(getActiveAggressorWindow)
  async function createOpenfinWindow() {
    let windowTop = 0
    let windowLeft = 0
    const previousBounds = localStorage.getItem(`aggressorPrevBounds`)
    if (previousBounds) {
      const parsed = JSON.parse(previousBounds)
      windowTop = parsed.top
      windowLeft = parsed.left
    }

    const winOption = {
      name: `Aggressor-${windowInfo.transactionId}`,
      minHeight: 71,
      defaultWidth: 342,
      defaultHeight: 420,
      defaultTop: windowTop,
      defaultLeft: windowLeft,
      url: '/aggressor',
      frame: false,
      saveWindowState: true,
      showTaskbarIcon: true,
      cornerRounding: {
        height: 10,
        width: 10
      },
      resizable: false
    }
    const win = await fin.Window.create(winOption)
    win.on('bounds-changing', async ({ left, top }: any) => {
      localStorage.setItem(`aggressorPrevBounds`, JSON.stringify({ left, top }))
    })
    return win
  }

  const val = useRef()

  useEffect(() => {
    val.current = externalWindow
  }, [externalWindow])

  const onUnload = () => {
    if (val.current) {
      const w = val.current as any
      w.close(true)
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      if (val.current) {
        const w = val.current as any
        w.close(true)
      }
    }
  }, [])

  useEffect(() => {
    if (fin) {
      renderWindow()
    }
  }, [aggressorWindows])

  const renderWindow = () => {
    if (externalWindow) {
      // externalWindow.setAsForeground()
    } else {
      createOpenfinWindow()
        .then((extWindow) => {
          if (extWindow) {
            const webWindow = extWindow.getWebWindow()
            setContainerElement(
              webWindow.document.getElementById(popupElementId)
            )

            // Copy the app's styles into the new window
            const stylesheets = Array.from(document.styleSheets)
            stylesheets.forEach((stylesheet) => {
              /* tslint:disable */
              const css = stylesheet
              if (stylesheet.href) {
                const newStyleElement = document.createElement('link')
                newStyleElement.rel = 'stylesheet'
                newStyleElement.href = stylesheet.href
                webWindow.document.head.appendChild(newStyleElement)
              } else if (css && css.cssRules && css.cssRules.length > 0) {
                const newStyleElement = document.createElement('style')
                Array.from(css.cssRules).forEach((rule) => {
                  newStyleElement.appendChild(
                    document.createTextNode(rule.cssText)
                  )
                })
                webWindow.document.head.appendChild(newStyleElement)
              }
            })
            extWindow.on('close-requested', (e: any) => {
              extWindow.close(true)
              setExternalWindow(null)
            })
          }
          setExternalWindow(extWindow)

          return () => {
            if (extWindow) {
              extWindow.close(true)
              setExternalWindow(null)
            }
          }
        })
        /* eslint-disable no-console */
        .catch((err) => console.log(err))
    }
  }

  if (!containerElement) {
    return null
  }

  if (!externalWindow) {
    return null
  }
  // Render this component's children into the root element of the popout window
  // return ReactDOM.createPortal(props.children, containerElement)

  return ReactDOM.createPortal(
    <div>
      <AggressorModal
        key={windowInfo.initialOrderId + windowInfo.transactionId}
        windowInfo={windowInfo}
        isActiveWindow={activeModalIndex === index}
        handleMinimize={handleMinimize}
        index={index}
      />
    </div>,
    containerElement
  )
}

export default AggressorPopout
