import React, { FC } from 'react'
import QueryBuilder, { RuleGroupType } from 'react-querybuilder'
import { fields } from '../../../store/admin/tiers/types'
import styles from './Tiers.scss'

interface Props {
  initialQuery: RuleGroupType | undefined
  setQuery: (query: RuleGroupType) => void
}

const TypeSelector: FC<Props> = (props: Props) => {
  const initialQuery = props.initialQuery
  const setQuery = props.setQuery
  return (
    <div className={styles.queryWrapper}>
      <QueryBuilder
        fields={fields}
        onQueryChange={setQuery}
        query={initialQuery}
      />
    </div>
  )
}

export default TypeSelector
