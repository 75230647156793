import cx from 'classnames'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { Item, Menu, useContextMenu } from 'react-contexify'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import DepthCancelButton from '../../../components/HitLiftButton/DepthCancelButton'
import HitLiftButton from '../../../components/HitLiftButton/HitLiftButton'
import { formatCurrency } from '../../../helpers/formatting'
import {
  forceCancel,
  markAsRestricted
} from '../../../store/depthOfMarket/actions'
import { DepthOfMarketRow } from '../../../store/depthOfMarket/types'
import { isOrderMine } from '../../../store/order/selectors'
import { Order, OrderType } from '../../../store/order/types'
import { SecurityOrderData } from '../../../store/securities/reducer'
import { QuoteReliability } from '../../../store/securities/types'
import {
  getCanForceCancel,
  getCanMarkRestricted
} from '../../../store/webSettings/selectors'
import {
  BEST_BID_PRICE,
  BEST_BID_SPREAD,
  BEST_OFFER_PRICE,
  BEST_OFFER_SPREAD
} from '../../BondList/columnDefs'
import styles from './table.scss'

import 'react-contexify/dist/ReactContexify.css'

interface Props {
  security: SecurityOrderData
  rows: DepthOfMarketRow[]
  displayedColumns: string[]
  isAdmin: boolean
  isPopout: boolean
  currency: string
}

const createOrderData = (
  id: string,
  securityId: number,
  size: number,
  isSpreadOrder: boolean,
  price: number,
  markedPrice: number,
  displayPrice: string,
  qr: QuoteReliability,
  myFirm: boolean,
  canAggress: boolean,
  type: OrderType,
  allOrNone: boolean,
  spread: number | undefined,
  displayName: string | null
): Order => {
  return {
    id,
    securityId,
    size,
    isSpreadOrder,
    price,
    markedPrice,
    displayPrice,
    spread,
    qr,
    myFirm,
    canAggress,
    type,
    status: 'pending',
    submitTime: new Date(), // FIXME
    allOrNone,
    custId: 0,
    corpOrderId: null,
    displayName,
    individualMin: 0,
    tob: { tob: false, limitPrice: 0, floorPrice: 0 }
  }
}

const Table: React.FC<Props> = ({
  rows,
  security,
  displayedColumns,
  isAdmin,
  isPopout,
  currency
}) => {
  const dispatch = useDispatch()
  const orderIsMine = useSelector(isOrderMine)
  const canMarkRestricted = useSelector(getCanMarkRestricted)
  const canForceCancel = useSelector(getCanForceCancel)

  const { show } = useContextMenu()

  const displayMenu = (e: any, id: any, status: any) => {
    if (
      isAdmin &&
      id.startsWith('E') &&
      ((canMarkRestricted && status === 'pending') || canForceCancel)
    ) {
      show(e, {
        id: `menu-${id}`
      })
    }
  }

  const showMarkRestrictedItem = useCallback(
    (id: string, status: string) => {
      if (canMarkRestricted && id.startsWith('E') && status === 'pending') {
        return (
          <Item onClick={() => dispatch(markAsRestricted(id))}>
            Mark Restricted
          </Item>
        )
      }
    },
    [dispatch]
  )
  const showForceCancelItem = useCallback(
    (id: string) => {
      if (canForceCancel && id.startsWith('E')) {
        return (
          <Item onClick={() => dispatch(forceCancel(id))}>Force Cancel</Item>
        )
      }
    },
    [dispatch]
  )

  const { bidRows, offerRows } = useMemo<{
    bidRows: ReactElement[]
    offerRows: ReactElement[]
  }>(() => {
    // this would be more clear/concise without the "no shadowed variables" linting rule
    const br: ReactElement[] = []
    const or: ReactElement[] = []
    rows.forEach(
      (
        {
          offerId,
          offerSize,
          offerPrice,
          offerDisplayPrice,
          offerSpread,
          offerAggress,
          offerQr,
          offerIsSpread,
          offerIsMyFirm,
          offerAon,
          offerDisplayName,
          offerStatus,
          bidId,
          bidSize,
          bidPrice,
          bidDisplayPrice,
          bidSpread,
          bidAggress,
          bidQr,
          bidIsSpread,
          bidIsMyFirm,
          bidAon,
          bidDisplayName,
          bidStatus
        },
        i
      ) => {
        const bidIsMine = bidId && orderIsMine(bidId)
        let bidMenu = <></>
        let bidButton = <> </>
        if (bidSize && bidPrice && bidId) {
          if (bidIsMine) {
            bidButton = <DepthCancelButton id={bidId} />
          } else if (!bidIsMyFirm) {
            const bestBid = createOrderData(
              bidId,
              security.id,
              bidSize,
              bidIsSpread,
              bidPrice,
              bidPrice,
              bidDisplayPrice,
              Number(bidQr),
              bidIsMyFirm,
              !!bidAggress,
              'buy',
              bidAon,
              bidSpread,
              bidDisplayName
            )
            bidButton = (
              <HitLiftButton
                security={{
                  ...security,
                  bestBid
                }}
                type="hit"
              />
            )
          }
          bidMenu = (
            <Menu id={`menu-${bidId}`}>
              {showMarkRestrictedItem(bidId, bidStatus!)}
              {showForceCancelItem(bidId)}
            </Menu>
          )
          br.push(
            <div key={i} className={styles.row}>
              {bidMenu}
              <span
                className={cx(
                  styles.bid,
                  bidIsMyFirm && styles.myOrder,
                  !isPopout && styles.widerMargin,
                  isAdmin && styles.adminBid,
                  isAdmin && bidStatus === 'notAvailable' && styles.isRestricted
                )}
                onContextMenu={(e) => displayMenu(e, bidId, bidStatus)}
              >
                {isAdmin && (
                  <span className={styles.displayName}>
                    <span data-tip={bidDisplayName} data-for={bidId}>
                      {bidDisplayName}
                    </span>
                    <ReactTooltip
                      id={bidId}
                      backgroundColor="#e9edf1"
                      textColor="#656C78"
                      effect="solid"
                      className="iconTooltip"
                    >
                      {bidDisplayName}
                    </ReactTooltip>
                  </span>
                )}
                <span className={cx(styles.hitLift)}>{bidButton}</span>
                <span className={cx(styles.size)}>
                  {bidSize && bidSize + (bidAon ? 'a' : '')}
                </span>
                {displayedColumns.includes(BEST_BID_PRICE) && (
                  <span className={cx(styles.price)}>
                    {formatCurrency(bidDisplayPrice, currency)}
                  </span>
                )}
                {displayedColumns.includes(BEST_BID_SPREAD) && (
                  <span className={cx(styles.spread)}>
                    {bidSpread
                      ? bidSpread > 0
                        ? '+' + bidSpread.toLocaleString()
                        : bidSpread
                      : ''}
                  </span>
                )}
              </span>
            </div>
          )
        }

        const offerIsMine = offerId && orderIsMine(offerId)
        let offerMenu = <></>
        let offerButton = <> </>
        if (offerSize && offerPrice && offerId) {
          if (offerIsMine) {
            offerButton = <DepthCancelButton id={offerId} />
          } else if (!offerIsMyFirm) {
            const bestOffer = createOrderData(
              offerId,
              security.id,
              offerSize,
              offerIsSpread,
              offerPrice,
              offerPrice,
              offerDisplayPrice,
              Number(offerQr),
              offerIsMyFirm,
              !!offerAggress,
              'sell',
              offerAon,
              offerSpread,
              offerDisplayName
            )
            offerButton = (
              <HitLiftButton
                security={{
                  ...security,
                  bestOffer
                }}
                type="lift"
              />
            )
          }
          offerMenu = (
            <Menu id={`menu-${offerId}`}>
              {showMarkRestrictedItem(offerId, offerStatus!)}
              {showForceCancelItem(offerId)}
            </Menu>
          )
          or.push(
            <div key={i} className={styles.row}>
              {offerMenu}
              <span
                className={cx(
                  styles.offer,
                  offerIsMyFirm && styles.myOrder,
                  isAdmin &&
                    offerStatus === 'notAvailable' &&
                    styles.isRestricted
                )}
                onContextMenu={(e) => displayMenu(e, offerId, offerStatus)}
              >
                {displayedColumns.includes(BEST_OFFER_SPREAD) && (
                  <span className={cx(styles.spread)}>
                    {offerSpread
                      ? offerSpread > 0
                        ? '+' + offerSpread.toLocaleString()
                        : offerSpread
                      : ''}
                  </span>
                )}
                {displayedColumns.includes(BEST_OFFER_PRICE) && (
                  <span className={cx(styles.price)}>
                    {formatCurrency(offerDisplayPrice, currency)}
                  </span>
                )}

                <span className={cx(styles.size)}>
                  {offerSize && offerSize + (offerAon ? 'a' : '')}
                </span>
                <span className={styles.hitLift}>{offerButton}</span>
                {isAdmin && (
                  <span className={styles.displayName}>
                    <span data-tip={offerDisplayName} data-for={offerId}>
                      {offerDisplayName}
                    </span>
                    <ReactTooltip
                      id={offerId}
                      backgroundColor="#e9edf1"
                      textColor="#656C78"
                      effect="solid"
                      className="iconTooltip"
                    >
                      {offerDisplayName}
                    </ReactTooltip>
                  </span>
                )}
              </span>
            </div>
          )
        }
      }
    )
    return { bidRows: br, offerRows: or }
  }, [rows])

  return (
    <div className={styles.tableGrid}>
      <div
        className={cx(styles.table, isAdmin && !isPopout, styles.adminTable)}
      >
        {bidRows}
      </div>
      <div
        className={cx(styles.table, isAdmin && !isPopout, styles.adminTable)}
      >
        {offerRows}
      </div>
    </div>
  )
}

export default Table
