import { faTimes } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getSelectedRow } from '../../../store/admin/adminTradeBlotter/selectors'
import Modal from '../../GenericModal/Modal'
import styles from './AdminTradeBlotter.scss'
interface Props {
  closeFunc: () => void
  paramId: string
}
const TradeBlotterModal: FC<Props> = (props: Props) => {
  const closeFunc = props.closeFunc
  const selectedRow = useSelector(getSelectedRow)(props.paramId)
  if (!selectedRow) return null

  return (
    <Modal customWrapperStyles={styles.customDimensions}>
      <button
        data-qa="close"
        type="button"
        className={styles.close}
        onClick={closeFunc}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className={styles.confirmText}>{selectedRow.confirmText}</div>
    </Modal>
  )
}
export default TradeBlotterModal
