import { createSelector } from 'reselect'
import { getSearchResults } from '../../../store/admin/query/selectors'
import { SecuritySearchResult } from '../../../store/searchSecurities/types'

export interface IssuerSecurities {
  issuer: string
  securities: SecuritySearchResult[]
}

const groupSecuritiesByIssuer = (securities: SecuritySearchResult[]) =>
  Object.entries(
    securities.reduce<Record<string, SecuritySearchResult[]>>(
      (acc, security) => ({
        ...acc,
        [security.issuerSymbol]: [
          ...(acc[security.issuerSymbol] || []),
          security
        ]
      }),
      {}
    )
  ).map<IssuerSecurities>(([issuer, issuerSecurities]) => ({
    issuer,
    securities: issuerSecurities
  }))

export const getSearchResultsByIssuer = createSelector(
  [getSearchResults],
  (securities) => groupSecuritiesByIssuer(securities)
)
