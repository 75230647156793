import { Action } from 'redux'
import { Order } from '../order/types'
import { Security } from '../securities/reducer'
import {
  AggressorWindowInfo,
  LastLookWindowInfo,
  SecurityModalInfo
} from './types'

export interface OpenAggressorWindowAction extends Action {
  type: 'windows.openAggressorWindow'
  payload: {
    initialOrderId: Order['id']
    securityId: Security['id']
  }
}

export const openAggressorWindow = (
  initialOrderId: Order['id'],
  securityId: Security['id']
): OpenAggressorWindowAction => ({
  type: 'windows.openAggressorWindow',
  payload: {
    initialOrderId,
    securityId
  }
})

export interface FetchDataForSecurityModalAction extends Action {
  type: 'windows.fetchDataForSecurityModal'
  payload: {
    orderId: Order['id']
    size: number
  }
}

export const fetchDataForSecurityModal = (
  orderId: Order['id'],
  size: number
): FetchDataForSecurityModalAction => ({
  type: 'windows.fetchDataForSecurityModal',
  payload: { orderId, size }
})

export interface UnsubscribeFetchDataForSecurityModalAction extends Action {
  type: 'windows.unsubscribeFetchDataForSecurityModal'
  payload: {
    orderId: Order['id']
  }
}

export const unsubscribeFetchDataForSecurityModal = (
  orderId: Order['id']
): UnsubscribeFetchDataForSecurityModalAction => ({
  type: 'windows.unsubscribeFetchDataForSecurityModal',
  payload: { orderId }
})

export interface UpdateDataForSecurityModalAction extends Action {
  type: 'windows.updateDataForSecurityModal'
  payload: {
    initialOrderId: Order['id']
    securityModalInfo: SecurityModalInfo
  }
}

export const updateDataForSecurityModal = (
  initialOrderId: Order['id'],
  securityModalInfo: SecurityModalInfo
): UpdateDataForSecurityModalAction => ({
  type: 'windows.updateDataForSecurityModal',
  payload: { initialOrderId, securityModalInfo }
})

export interface AddAggressorWindowAction extends Action {
  type: 'windows.addAggressorWindow'
  payload: AggressorWindowInfo
}

export const addAggressorWindow = (
  windowInfo: AggressorWindowInfo
): AddAggressorWindowAction => ({
  type: 'windows.addAggressorWindow',
  payload: windowInfo
})

export interface AddLastLookWindowAction extends Action {
  type: 'windows.addLastLookWindow'
  payload: LastLookWindowInfo
}

export const addLastLookWindow = (
  windowInfo: LastLookWindowInfo
): AddLastLookWindowAction => ({
  type: 'windows.addLastLookWindow',
  payload: windowInfo
})

export interface RemoveAggressorFromWindows extends Action {
  type: 'windows.removeAggressorWindow'
  payload: AggressorWindowInfo
}

export const removeAggressorWindow = (windowInfo: AggressorWindowInfo) => ({
  type: 'windows.removeAggressorWindow',
  payload: windowInfo
})

export interface RemoveLastLookFromWindows extends Action {
  type: 'windows.removeLastLookFromWindows'
  payload: LastLookWindowInfo
}

export const removeLastLookFromWindows = (window: LastLookWindowInfo) => ({
  type: 'windows.removeLastLookFromWindows',
  payload: window
})

export interface ForegroundAggressorWindowAtIndexAction extends Action {
  type: 'windows.foregroundAggressorWindowAtIndex'
  payload: number | undefined
}

export const foregroundAggressorWindowAtIndex = (
  index: number | undefined
): ForegroundAggressorWindowAtIndexAction => ({
  type: 'windows.foregroundAggressorWindowAtIndex',
  payload: index
})

export interface ForegroundAggressorWindowAction extends Action {
  type: 'windows.foregroundAggressorWindow'
  payload: Order['id']
}

export const foregroundAggressorWindow = (orderId: Order['id']) => ({
  type: 'windows.foregroundAggressorWindow',
  payload: orderId
})

export interface ForegroundLastLookWindowAtIndexAction extends Action {
  type: 'windows.foregroundLastLookWindowAtIndex'
  payload: number | undefined
}

export const foregroundLastLookWindowAtIndex = (
  index: number | undefined
): ForegroundLastLookWindowAtIndexAction => ({
  type: 'windows.foregroundLastLookWindowAtIndex',
  payload: index
})

export interface ForegroundLastLookWindowAction extends Action {
  type: 'windows.foregroundLastLookWindow'
  payload: Order['id']
}

export const foregroundLastLookWindow = (orderId: string) => ({
  type: 'windows.foregroundLastLookWindow',
  payload: orderId
})

export interface SetWindowFront extends Action {
  type: 'windows.setWindowFront'
  payload: string
}

export const setWindowFront = (windowFront: string): SetWindowFront => ({
  type: 'windows.setWindowFront',
  payload: windowFront
})

export type WindowsAction =
  | RemoveAggressorFromWindows
  | RemoveLastLookFromWindows
  | ForegroundAggressorWindowAction
  | ForegroundAggressorWindowAtIndexAction
  | ForegroundLastLookWindowAction
  | ForegroundLastLookWindowAtIndexAction
  | FetchDataForSecurityModalAction
  | UnsubscribeFetchDataForSecurityModalAction
  | UpdateDataForSecurityModalAction
  | AddAggressorWindowAction
  | AddLastLookWindowAction
  | SetWindowFront
