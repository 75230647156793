import React, { FC, useCallback } from 'react'
import ButtonWithFeedback from '../ButtonWithFeedback/ButtonWithFeedback'
import styles from './ComponentFooter.scss'

interface FooterButtonsProps {
  onCancelClick: () => void
  onApplyClick: () => void
  submitText?: string
  errorText?: string
}

const ComponentFooter: FC<FooterButtonsProps> = ({
  onCancelClick,
  onApplyClick,
  submitText = 'Save',
  errorText
}) => {
  const showSavingTime = 500
  const showSaving = useCallback(() => {
    const timer = setTimeout(onApplyClick, showSavingTime)
    return () => clearTimeout(timer)
  }, [onApplyClick])

  return (
    <>
      {errorText && (
        <div className={styles.errorTextWrapper}>
          <label className={styles.errorText}>{errorText}</label>
        </div>
      )}
      <div className={styles.footerButtons}>
        <button className={styles.cancel} onClick={onCancelClick}>
          CANCEL
        </button>
        <ButtonWithFeedback
          className={styles.save}
          onClick={showSaving}
          contentUpdate="SUBMITTING"
          timerToReturnToFirstState={showSavingTime}
        >
          {submitText}
        </ButtonWithFeedback>
      </div>
    </>
  )
}

export default ComponentFooter
