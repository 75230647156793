import { Action } from 'redux'
import { UserPermissions, UserPreferences } from './type'

export interface FetchUserPreferencesAction extends Action {
  type: 'userPreferences.fetchUserPreferences'
}

export const fetchUserPreferences = (): FetchUserPreferencesAction => ({
  type: 'userPreferences.fetchUserPreferences'
})

export interface FetchUserPreferencesFailureAction extends Action {
  type: 'userPreferences.fetchUserPreferencesFailure'
  payload: any
  error: boolean
}

export const fetchUserPreferencesFailure = (
  error: any
): FetchUserPreferencesFailureAction => ({
  type: 'userPreferences.fetchUserPreferencesFailure',
  payload: error,
  error: true
})

export interface FetchUserPreferencesSuccessAction extends Action {
  type: 'userPreferences.fetchUserPreferencesSuccess'
  payload: UserPreferences
}

export const fetchUserPreferencesSuccess = (
  userPreferences: UserPreferences
): FetchUserPreferencesSuccessAction => ({
  type: 'userPreferences.fetchUserPreferencesSuccess',
  payload: userPreferences
})

export interface SetUserPreferencesAction extends Action {
  type: 'userPreferences.setUserPreferences'
  payload: UserPreferences
}

export const setUserPreferences = (
  userPreferences: UserPreferences
): SetUserPreferencesAction => ({
  type: 'userPreferences.setUserPreferences',
  payload: userPreferences
})

export interface SetUserPreferencesFailureAction extends Action {
  type: 'userPreferences.setUserPreferencesFailure'
  payload: any
  error: boolean
}

export const setUserPreferencesFailure = (
  error: any
): SetUserPreferencesFailureAction => ({
  type: 'userPreferences.setUserPreferencesFailure',
  payload: error,
  error: true
})

export interface SetUserPreferencesSuccessAction extends Action {
  type: 'userPreferences.setUserPreferencesSuccess'
  payload: {
    userPreferences: UserPreferences
    transactionId: number | undefined
  }
}

export const setUserPreferencesSuccess = (
  userPreferences: UserPreferences,
  transactionId?: number
): SetUserPreferencesSuccessAction => ({
  type: 'userPreferences.setUserPreferencesSuccess',
  payload: { userPreferences, transactionId }
})

export interface FetchUserPermissionsAction extends Action {
  type: 'userPreferences.fetchUserPermissions'
}

export const fetchUserPermissions = (): FetchUserPermissionsAction => ({
  type: 'userPreferences.fetchUserPermissions'
})

export interface FetchUserPermissionsFailureAction extends Action {
  type: 'userPreferences.fetchUserPermissionsFailure'
  payload: any
  error: boolean
}

export const fetchUserPermissionsFailure = (
  error: any
): FetchUserPermissionsFailureAction => ({
  type: 'userPreferences.fetchUserPermissionsFailure',
  payload: error,
  error: true
})

export interface FetchUserPermissionsSuccessAction extends Action {
  type: 'userPreferences.fetchUserPermissionsSuccess'
  payload: UserPermissions
}

export const fetchUserPermissionsSuccess = (
  userPermissions: UserPermissions
): FetchUserPermissionsSuccessAction => ({
  type: 'userPreferences.fetchUserPermissionsSuccess',
  payload: userPermissions
})

export type UserPreferencesAction =
  | FetchUserPreferencesAction
  | FetchUserPreferencesSuccessAction
  | FetchUserPreferencesFailureAction
  | SetUserPreferencesAction
  | SetUserPreferencesFailureAction
  | SetUserPreferencesSuccessAction
  | FetchUserPermissionsAction
  | FetchUserPermissionsFailureAction
  | FetchUserPermissionsSuccessAction
