import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getLastLookWindows } from '../../store/windows/selectors'
import { SecurityModalInfo } from '../../store/windows/types'
import Popout from './LastLookPopout'

const PopoutOpener: FC = () => {
  const lastLookWindows = useSelector(getLastLookWindows)

  const getPopout = () => {
    const props = {
      title: 'Last Look'
    }
    return lastLookWindows.length > 0 ? (
      <div>
        {lastLookWindows.map((windowInfo: SecurityModalInfo, i: number) => (
          <Popout
            {...props}
            windowInfo={windowInfo}
            index={i}
            key={windowInfo.orderId}
          />
        ))}
      </div>
    ) : null
  }
  return <div>{getPopout()}</div>
}

export default PopoutOpener
