import React from 'react'

const DeleteLarge = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
    viewBox="0 0 9 12"
  >
    <g fill="#656C78">
      <path d="M7.6 3.183l-.293 7.042c-.006.138-.128.255-.267.255H1.92c-.139 0-.261-.117-.267-.255L1.36 3.183c-.008-.22-.195-.392-.416-.382-.22.008-.392.195-.382.416l.293 7.041c.024.566.499 1.022 1.066 1.022h5.12c.567 0 1.043-.455 1.066-1.022L8.4 3.217c.01-.221-.161-.408-.382-.416-.221-.01-.408.162-.416.382z" />
      <path d="M4.16 3.84v5.44c0 .177.143.32.32.32.177 0 .32-.143.32-.32V3.84c0-.177-.143-.32-.32-.32-.177 0-.32.143-.32.32zM2.4 3.85l.16 5.44c.006.176.152.315.33.31.176-.006.315-.152.31-.33l-.16-5.44c-.006-.176-.152-.315-.33-.31-.176.006-.315.153-.31.33zM5.92 3.83l-.16 5.44c-.005.177.134.324.31.33.178.005.324-.134.33-.31l.16-5.44c.005-.178-.134-.324-.31-.33-.178-.005-.324.134-.33.31zM.48 2.24h8c.22 0 .4-.18.4-.4 0-.22-.18-.4-.4-.4h-8c-.22 0-.4.18-.4.4 0 .22.18.4.4.4z" />
      <path d="M3.264 1.71l.167-.583c.036-.125.198-.247.328-.247H5.2c.13 0 .292.122.328.247l.167.583.768-.22-.166-.583C6.164.439 5.688.08 5.201.08H3.76c-.488 0-.963.359-1.097.827l-.166.583.768.22z" />
    </g>
  </svg>
)

export default DeleteLarge
