import React from 'react'
import { CheckboxProps } from '.'

const PartialCheckbox: React.FC<CheckboxProps> = ({ className, fillColor }) => (
  <span className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 14 14"
    >
      <g
        transform="matrix(1.4, 0, 0, 1.4, 0, 0)"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M1.33333333,0 L8.66666667,0 C9.40304633,0 10,0.596953667 10,1.33333333 L10,8.66666667 C10,9.40304633 9.40304633,10 8.66666667,10 L1.33333333,10 C0.596953667,10 0,9.40304633 0,8.66666667 L0,1.33333333 C0,0.596953667 0.596953667,0 1.33333333,0 Z"
          fill={fillColor}
        ></path>
        <path
          d="M8,4.6 L2,4.6 C1.7790861,4.6 1.6,4.82385763 1.6,5.1 C1.6,5.37614237 1.7790861,5.6 2,5.6 L8,5.6 C8.2209139,5.6 8.4,5.37614237 8.4,5.1 C8.4,4.82385763 8.2209139,4.6 8,4.6 Z"
          fill="#FFF"
        ></path>
      </g>
    </svg>
  </span>
)

export default PartialCheckbox
