import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import { getQueries } from '../../store/admin/query/actions'
import { getClickhouseQueries } from '../../store/admin/query/selectors'
import styles from './query.scss'
import QueryList from './QueryList'

const QuerySelector: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getQueries())
  }, [])

  const clickhouseQueries = useSelector(getClickhouseQueries)
  return (
    <div className={fin ? styles.FinQueryWrapper : styles.QueryWrapper}>
      {clickhouseQueries.length > 0 && (
        <QueryList queries={clickhouseQueries} />
      )}
    </div>
  )
}

export default QuerySelector
