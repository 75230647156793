import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetContextMenuItems,
  GridApi,
  MenuItemDef
} from '@ag-grid-community/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BEST_BID_PRICE,
  BEST_BID_SPREAD,
  BEST_OFFER_PRICE,
  BEST_OFFER_SPREAD,
  CHECKBOX,
  ISSUER,
  mirrorColumnMapping
} from '../../containers/BondList/columnDefs'
import { useManageOneOrderType } from '../../helpers/hooks/useManageMyOrders'
import { updateColumnsOrder } from '../../store/grid/actions'
import { getColumnsOrder } from '../../store/grid/selectors'
import { isMyOrdersOpen } from '../../store/order/selectors'
import { Security } from '../../store/securities/reducer'
import { getWatchlistId } from '../../store/securities/selectors'
import {
  appendIssuerToWatchlist,
  appendSecurityToWatchlist
} from '../../store/watchList/actions'
import { getWatchList } from '../../store/watchList/selectors'
import { applyColumnsOrder } from './helpers'

type MinimalGridApi = {
  api: GridApi
  columnApi: ColumnApi
}
type UseGridControlsParams = {
  gridApi?: MinimalGridApi | null
  gridIndex: number
  canEditWatchlist: boolean
  setSelectedDetailSecurityId: (securityId: number) => void
  currentPage?: number
  onClickGridRow: (securityId: number) => void
  onDoubleClickGridRow: (securityId: number) => void
}

const getSelectedSecurityId = ({ api }: { api: GridApi }) => {
  const rowIndex = api?.getFocusedCell()?.rowIndex
  if (rowIndex !== undefined) {
    return api?.getDisplayedRowAtIndex(rowIndex)?.data?.id
  }
}

export const useGridControls = ({
  gridApi,
  gridIndex,
  canEditWatchlist,
  setSelectedDetailSecurityId,
  currentPage,
  onClickGridRow,
  onDoubleClickGridRow
}: UseGridControlsParams) => {
  const dispatch = useDispatch()
  const myOrdersOpen = useSelector(isMyOrdersOpen)(gridIndex)

  const handleColumnChange = useCallback(() => {
    const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      dispatch(updateColumnsOrder(gridIndex, displayed))
    }
  }, [gridApi])

  // ------------ Watch Lists ------------ //
  const watchlists = useSelector(getWatchList)
  const watchlistIdSelected = useSelector(getWatchlistId)(gridIndex)
  //
  // Action Dispatching
  const addBondToWatchlist = (data: any, id: number | undefined) => {
    if (id) {
      dispatch(appendSecurityToWatchlist(id, [data.id]))
    }
  }

  const addIssuerToWatchlist = (
    data: Security,
    id: number | undefined,
    append: boolean
  ) => {
    if (id) {
      dispatch(appendIssuerToWatchlist(id, data.issuerSymbol, append))
    }
  }
  const getBondWatchlistContextMenu = useCallback(
    (data: Security): MenuItemDef | string => {
      return watchlists?.length
        ? {
            name: 'Add bond to watchlist',
            subMenu: watchlists?.map((wl) => {
              return {
                name: wl.name,
                action() {
                  addBondToWatchlist(data, wl.id)
                }
              }
            })
          }
        : ''
    },
    [watchlists]
  )

  const getIssuerWatchlistContextMenu = useCallback(
    (data: Security) => {
      return watchlists?.length
        ? {
            name: 'Add issuer to watchlist',
            subMenu: watchlists?.map((wl) => {
              return {
                name: wl.name,
                action() {
                  addIssuerToWatchlist(data, wl.id, true)
                }
              }
            })
          }
        : ''
    },
    [watchlists]
  )

  const getRemoveIssuerContextMenu = useCallback(
    (data: Security) => {
      return canEditWatchlist
        ? {
            name: 'Remove issuer from watchlist',
            action() {
              addIssuerToWatchlist(data, watchlistIdSelected, false)
            }
          }
        : ''
    },
    [watchlistIdSelected, canEditWatchlist]
  )

  const getContextMenuItems: GetContextMenuItems<Security> = useCallback(
    (params) => {
      const data = params.node?.data
      if (!data) return []
      return [
        {
          name: 'View Security Details',
          action() {
            if (data?.id) {
              setSelectedDetailSecurityId(data.id)
            }
          }
        },
        getBondWatchlistContextMenu(data),
        getIssuerWatchlistContextMenu(data),
        getRemoveIssuerContextMenu(data)
      ].filter((item) => !!item)
    },
    [
      getBondWatchlistContextMenu,
      getIssuerWatchlistContextMenu,
      getRemoveIssuerContextMenu
    ]
  )

  // order managers

  const buyOrderManager = useManageOneOrderType('buy')
  const sellOrderManager = useManageOneOrderType('sell')

  const gridContext = useMemo(
    () => ({
      buyOrderManager,
      sellOrderManager
    }),
    [buyOrderManager, sellOrderManager]
  )

  // ------------ Columns ------------ //
  const columnsOrder = useSelector(getColumnsOrder)(gridIndex)

  const [columnDefs, setColumnDefs] = useState<
    ColDef[] | ColGroupDef[] | undefined
  >(undefined)

  //
  // Set Initial Columns Def
  useEffect(() => {
    setColumnDefs(applyColumnsOrder(gridIndex, columnsOrder))
  }, [columnsOrder?.join(',')])

  //
  // Handle My Orders group toggling
  useEffect(() => {
    if (gridApi) {
      const state = gridApi.columnApi.getColumnState()

      state.map((col) => {
        if (col.pinned === 'right') {
          col.hide = !myOrdersOpen
        }
        return col
      })

      gridApi.columnApi.applyColumnState({ state })
      gridApi.columnApi.setColumnGroupOpened('myOrders', myOrdersOpen)
    }
  }, [gridApi?.columnApi, myOrdersOpen])

  //
  // Toggle My Orders mirror columns
  const visibilities: Record<string, boolean | undefined> = {
    [BEST_BID_SPREAD]: gridApi?.columnApi
      .getColumn(BEST_BID_SPREAD)
      ?.isVisible(),
    [BEST_BID_PRICE]: gridApi?.columnApi.getColumn(BEST_BID_PRICE)?.isVisible(),
    [BEST_OFFER_SPREAD]: gridApi?.columnApi
      .getColumn(BEST_OFFER_SPREAD)
      ?.isVisible(),
    [BEST_OFFER_PRICE]: gridApi?.columnApi
      .getColumn(BEST_OFFER_PRICE)
      ?.isVisible()
  }

  useEffect(() => {
    if (!gridApi) return
    if (myOrdersOpen) {
      const state = gridApi.columnApi.getColumnState()
      state.map((col) => {
        if (!mirrorColumnMapping[col.colId]) return col
        // note this mutates the column, but replacing it screws up ag grid
        col.hide = true
        return col
      })
      gridApi.columnApi.applyColumnState({ state })
    }
  }, [
    myOrdersOpen,
    gridApi,
    visibilities[BEST_BID_SPREAD],
    visibilities[BEST_BID_PRICE],
    visibilities[BEST_OFFER_SPREAD],
    visibilities[BEST_OFFER_PRICE]
  ])

  //
  // Handle watchlist editing
  useEffect(() => {
    if (gridApi) {
      gridApi.columnApi.setColumnVisible(CHECKBOX, canEditWatchlist)
      gridApi.columnApi.setColumnWidth(ISSUER, canEditWatchlist ? 70 : 90)
    }
  }, [gridApi?.columnApi, canEditWatchlist])

  // ------------ interacting with grid rows ------------ //
  const handleGridRowClick = useCallback(
    (api: MinimalGridApi) => {
      onClickGridRow(getSelectedSecurityId(api))
    },
    [onClickGridRow]
  )
  const handleGridRowDoubleClick = useCallback(
    (api: MinimalGridApi) => {
      onDoubleClickGridRow(getSelectedSecurityId(api))
    },
    [onClickGridRow]
  )

  // other effects
  useEffect(() => {
    if (currentPage === 0 && gridApi) {
      gridApi.api.setFocusedCell(0, ISSUER)
    }
  }, [gridApi, currentPage])

  return {
    handleColumnChange,
    handleGridRowClick,
    handleGridRowDoubleClick,
    getContextMenuItems,
    columnDefs,
    gridContext
  }
}
