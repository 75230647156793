import React from 'react'

interface Props {
  fill: string
}

const Expand: React.FC<Props> = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="13"
    viewBox="0 0 11 13"
  >
    <path
      fill={fill}
      fillRule="nonzero"
      d="M.544 6.576a.499.499 0 0 1-.497-.496c0-.272.224-.496.497-.496h5.952l-1.6-1.6a.502.502 0 0 1 0-.704.502.502 0 0 1 .703 0l2.448 2.448a.495.495 0 0 1 .144.352.497.497 0 0 1-.144.352L5.6 8.88a.495.495 0 0 1-.352.144.497.497 0 0 1-.352-.144.502.502 0 0 1 0-.704l1.6-1.6H.544zM10.016.032H7.248a.499.499 0 0 0-.496.496c0 .272.223.497.496.497H9.52v10.111H7.248a.499.499 0 0 0-.496.497c0 .271.223.496.496.496h2.768a.499.499 0 0 0 .496-.496V.528a.499.499 0 0 0-.496-.496z"
    />
  </svg>
)

export default Expand
