import React from 'react'

const Cancel = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="2 2 10 10"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#B44F61"
        d="M3.333 2h7.334C11.403 2 12 2.597 12 3.333v7.334c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 0 1 2 10.667V3.333C2 2.597 2.597 2 3.333 2z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M9.792 8.861c.26.26.26.68 0 .94a.644.644 0 0 1-.47.199.625.625 0 0 1-.47-.198L7 7.945 5.134 9.802a.644.644 0 0 1-.47.198.625.625 0 0 1-.47-.198.666.666 0 0 1 0-.941l1.867-1.87-1.866-1.855a.666.666 0 0 1 .94-.94L7 6.063l1.866-1.869a.666.666 0 0 1 .94.94l-1.867 1.87L9.792 8.86z"
      />
    </g>
  </svg>
)

export default Cancel
