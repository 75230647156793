import cx from 'classnames'
import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFetchBondsSearchResults } from '../../helpers/hooks/useFetchBondsSearchResults'
import { getInvalidIdentifiers } from '../../store/upload/selectors'
import { OrderRowInfo } from '../../store/upload/types'
import { IssuerSecurities } from '../SearchBondsInput/selectors'
import styles from './uploadDropDownMenu.scss'

interface Props {
  addOrUpdateRows?: (newRow: OrderRowInfo) => void
  pasteRows?: (index: number, event: React.ClipboardEvent) => void
  index: number
  rowInfo: OrderRowInfo
  dropdownState: string
  gridIndex?: number
}

const mapToIssuer = (result: IssuerSecurities) => result.issuer

const mapAllToIssuer = (values: IssuerSecurities[]) => {
  return values.map(mapToIssuer)
}

const mapToIsins = (value: IssuerSecurities) => {
  return value.securities.map((security) => security.isin)
}

const mapAllToIsins = (values: IssuerSecurities[]) => {
  return values.flatMap(mapToIsins)
}

const UploadTableRow = forwardRef<HTMLInputElement, Props>(
  (
    {
      addOrUpdateRows,
      index,
      rowInfo,
      pasteRows,
      dropdownState,
      gridIndex = 0
    },
    ref
  ) => {
    const invalidIdentifiers = useSelector(getInvalidIdentifiers)

    const { watchListResults, notInWatchListResults } =
      useFetchBondsSearchResults(gridIndex, ref ? rowInfo.identifier : '')
    const [hideDatalist, setHideDatalist] = useState(false)

    const searchTermHasDigits = useMemo(() => {
      if (!rowInfo.identifier) return false
      const digits = /\d+/
      return digits.test(rowInfo.identifier)
    }, [rowInfo.identifier])

    /* When the user selects an option, it's annoying to see the open datalist
       with just that option in it. When they make a selection, it will trigger
       a keyboard event with no key, so we can hide the list until they start
       typing again.
       https://stackoverflow.com/a/65073572
     */
    const onSymbolInputKeyDown = useCallback((e: React.KeyboardEvent) => {
      setHideDatalist(!e.key)
    }, [])

    const update = useCallback(
      (field: keyof OrderRowInfo) =>
        (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value
          if (
            newValue.includes('\t') ||
            newValue.includes('\n') ||
            newValue.includes(' ')
          ) {
            return
          }
          const newRowInfo = {
            ...rowInfo,
            [field]: newValue
          }
          addOrUpdateRows!(newRowInfo)
        },
      [addOrUpdateRows, index, rowInfo]
    )

    const isValueInvalid = useCallback(
      () =>
        invalidIdentifiers?.some(
          (err) =>
            err.errorIndex === index && err.identifier === rowInfo.identifier
        ),
      [invalidIdentifiers, rowInfo.identifier, index]
    )

    const identifierDatalist = useMemo(() => {
      // TODO: consider splitting out invalidUpload into its own component?
      if (
        dropdownState !== 'invalidUpload' &&
        rowInfo.identifier?.length === 0
      ) {
        return undefined
      }

      const allResults = searchTermHasDigits
        ? [
            ...mapAllToIsins(watchListResults),
            ...mapAllToIsins(notInWatchListResults)
          ]
        : [
            ...mapAllToIssuer(watchListResults),
            ...mapAllToIssuer(notInWatchListResults)
          ]

      const noDupeIssuers = new Set(allResults)

      const sortedIssuers = Array.from(noDupeIssuers)
        .filter((symbol) => !!symbol)
        .sort()
      if (!sortedIssuers.length) return undefined
      return (
        <datalist id="matching-issuers">
          {sortedIssuers.map((issuer) => {
            return <option value={issuer} key={issuer} />
          })}
        </datalist>
      )
    }, [watchListResults, notInWatchListResults, rowInfo.identifier])

    return (
      <>
        {dropdownState === 'invalidUpload' && (
          <tr className={styles.invalidUploadRow}>
            <td className={styles.rowData}>{rowInfo.isin}</td>

            <td className={styles.rowData}>
              {rowInfo.orderType === 'buy' ? 'Bid' : 'Ofr'}
            </td>
            <td className={styles.rowData}>{rowInfo.error}</td>
          </tr>
        )}
        {dropdownState !== 'invalidUpload' && (
          <tr>
            <td className={cx(styles.rowData, styles.rightBorder)}>
              <input
                id={'uploadtableIdentifier' + index}
                type="text"
                onChange={update('identifier')}
                data-testid-value={'uploadtableIdentifier-' + index}
                className={isValueInvalid() ? styles.errorInput : ''}
                value={rowInfo.identifier}
                onPaste={(event) => pasteRows!(index, event)}
                ref={ref}
                list={
                  identifierDatalist && !hideDatalist ? 'matching-issuers' : ''
                }
                autoComplete="off"
                onKeyDown={onSymbolInputKeyDown}
              />
              {identifierDatalist}
            </td>
            <td className={styles.rowData}>
              <input
                type="text"
                data-testid-value={'uploadtableBidAmt-' + index}
                className={styles.numInput}
                onChange={update('bidAmt')}
                value={rowInfo.bidAmt}
              />
            </td>
            <td className={styles.rowData}>
              <input
                type="number"
                data-testid-value={'uploadtableBidPrice-' + index}
                className={styles.numInput}
                onChange={update('bidPrice')}
                value={rowInfo.bidPrice}
              />
            </td>
            <td className={cx(styles.rowData, styles.rightBorder)}>
              <input
                type="number"
                data-testid-value={'uploadtableBidSprd-' + index}
                className={styles.numInput}
                onChange={update('bidSprd')}
                value={rowInfo.bidSprd}
              />
            </td>
            <td className={styles.rowData}>
              <input
                type="number"
                data-testid-value={'uploadtableOfrSprd-' + index}
                className={styles.numInput}
                onChange={update('ofrSprd')}
                value={rowInfo.ofrSprd}
              />
            </td>
            <td className={styles.rowData}>
              <input
                type="number"
                data-testid-value={'uploadtableOfrPrice-' + index}
                className={styles.numInput}
                onChange={update('ofrPrice')}
                value={rowInfo.ofrPrice}
              />
            </td>
            <td className={styles.rowData}>
              <input
                type="text"
                data-testid-value={'uploadtableOfrAmt-' + index}
                className={styles.numInput}
                onChange={update('ofrAmt')}
                value={rowInfo.ofrAmt}
              />
            </td>
          </tr>
        )}
      </>
    )
  }
)

export default UploadTableRow
