import cx from 'classnames'
import React, { FC, useCallback, useEffect, useReducer, useRef } from 'react'
import { noop } from 'rxjs'
import { useDropDownMenu } from '../../helpers/hooks'
import { IGRatingValues, HYRatingValues } from '../../store/filter/types'
import { getAllOrNoneStatus } from '../../store/helpers'
import DropdownList from '../DropdownList/DropdownList'
import {
  ListItem,
  SelectAll
} from '../GenericDropdownMenu/GenericDropdownMenuMulti'
import styles from '../GenericDropdownMenu/GenericDropdownMenu.scss'

interface State {
  IGHYOptionsSelected: string[]
}

type Action =
  | { type: 'TOGGLE_IGHY'; payload: string }
  | { type: 'REMOVE_IGHY' }
  | { type: 'ADD_IGHY' }

const initialState: State = {
  IGHYOptionsSelected: ['IG', 'HY']
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'TOGGLE_IGHY':
      const updatedColumns = state.IGHYOptionsSelected.includes(action.payload)
        ? state.IGHYOptionsSelected.filter((col) => col !== action.payload)
        : [...state.IGHYOptionsSelected, action.payload]

      return { ...state, IGHYOptionsSelected: updatedColumns }
    case 'REMOVE_IGHY':
      return { ...state, IGHYOptionsSelected: [] }
    case 'ADD_IGHY':
      return initialState
    default:
      return state
  }
}

export interface Props {
  placeholder: string
  selectedItems: string[]
  setSelectedItems(items: string[]): void
  options: string[]
  selectId: string
  className: string
  openRight: boolean
}

const DropdownRatings: FC<Props> = ({
  placeholder,
  selectedItems,
  setSelectedItems,
  options,
  selectId,
  className,
  openRight = false
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const IGHY = ['IG', 'HY']

  const ref = useRef<HTMLDivElement>(null)
  const { displayMenu, setDisplayMenu } = useDropDownMenu(ref)

  const showOrHideDropdown = useCallback(() => {
    setDisplayMenu((value) => !value)
    setSelectedItems(selectedItems)
  }, [selectedItems])

  const isItemSelected = (colId: string) => selectedItems.includes(colId)

  const allSelectedStatus = getAllOrNoneStatus(options, (column) =>
    isItemSelected(column)
  )

  // This is the IG/HY version of checking the select all status
  useEffect(() => {
    IGHY.map((rating) => {
      const ratingValues = rating === 'IG' ? IGRatingValues : HYRatingValues
      const allItemsSelected = ratingValues.every((item) =>
        selectedItems.includes(item)
      )
      const isRatingSelected = state.IGHYOptionsSelected.includes(rating)

      if (allItemsSelected !== isRatingSelected) {
        dispatch({ type: 'TOGGLE_IGHY', payload: rating })
      }
    })
  }, [selectedItems])

  const changeSelectAllStatus = useCallback(() => {
    switch (allSelectedStatus) {
      case 'none':
        setSelectedItems(options)
        dispatch({
          type: 'REMOVE_IGHY'
        })
        break
      case 'some':
        setSelectedItems(options)
        break
      case 'all':
        dispatch({
          type: 'ADD_IGHY'
        })
        setSelectedItems([])
        break
    }
  }, [allSelectedStatus])

  const toggleIGHY = useCallback(
    (colId: string) => () => {
      const ratingValues = colId === 'IG' ? IGRatingValues : HYRatingValues
      const updatedItems = state.IGHYOptionsSelected.includes(colId)
        ? selectedItems.filter((c) => !ratingValues.includes(c))
        : [...ratingValues, ...selectedItems]
      dispatch({ type: 'TOGGLE_IGHY', payload: colId })
      setSelectedItems(updatedItems)
    },
    [state.IGHYOptionsSelected]
  )

  const toggleOptions = useCallback(
    (colId: string) => () => {
      setSelectedItems(
        isItemSelected(colId)
          ? selectedItems.filter((c) => c !== colId)
          : [...selectedItems, colId]
      )
    },
    [selectedItems]
  )

  return (
    <div ref={ref} className={cx(styles.dropdown, className)}>
      <div onClick={showOrHideDropdown}>
        <label>{placeholder}</label>
        <i className="fas fa-angle-down"></i>
      </div>
      {displayMenu && (
        <div>
          <div className={openRight ? styles.menuRight : styles.menu}>
            <div className={styles.selectAll}>
              <DropdownList
                onElementSelected={noop}
                elements={[
                  {
                    id: { selectId },
                    label: (
                      <SelectAll
                        status={allSelectedStatus}
                        changeStatus={changeSelectAllStatus}
                      />
                    )
                  }
                ]}
              />
              <DropdownList
                onElementSelected={noop}
                elements={IGHY.map((name) => ({
                  id: name,
                  label: (
                    <ListItem
                      id={name}
                      label={name}
                      selected={state.IGHYOptionsSelected.includes(name)}
                      toggleSelected={toggleIGHY(name)}
                    />
                  )
                }))}
              />
            </div>
            <DropdownList
              onElementSelected={noop}
              elements={options.map((name) => ({
                id: name,
                label: (
                  <ListItem
                    id={name}
                    label={name}
                    selected={isItemSelected(name)}
                    toggleSelected={toggleOptions(name)}
                  />
                )
              }))}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default DropdownRatings
