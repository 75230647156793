import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import {
  externalOrderStatsClear,
  externalOrderStatsGet
} from '../../../store/admin/externalOrderStats/actions'
import { getExternalOrderStatsPending } from '../../../store/admin/externalOrderStats/selectors'
import { Customer } from '../../../store/customers/types'
import { getUsers } from '../../../store/users/selectors'
import { User } from '../../../store/users/types'
import styles from '../HistoricalUserData/HistoricalData.scss'
import ExternalOrdersStatsGrid from './Grid/ExternalOrderStatsGrid'

const ExternalOrderStats: FC = () => {
  const dispatch = useDispatch()
  const today = new Date()
  const allUsers = useSelector(getUsers)
  const [endDate, setEndDate] = useState<Date>(today)
  const [startDate, setStartDate] = useState<Date>(today)
  const pending = useSelector(getExternalOrderStatsPending)
  const { fin } = useOpenFin()

  const runQuery = () => {
    dispatch(externalOrderStatsClear())
    dispatch(externalOrderStatsGet(0, 0, startDate, endDate))
  }

  useEffect(() => {
    const newUsersByUserId: Record<number, User> = {}
    const newUsersByCustId: Record<number, User[]> = {}
    const custList: Customer[] = []
    if (allUsers) {
      for (const usr of allUsers) {
        newUsersByUserId[usr.id] = usr
        if (!newUsersByCustId[usr.custId]) {
          newUsersByCustId[usr.custId] = []
        }
        newUsersByCustId[usr.custId].push(usr)
        if (!custList[usr.custId]) {
          custList.push({ id: usr.custId, shortName: usr.custName })
        }
      }
    }
  }, [allUsers])

  useEffect(() => {
    setTimeout(runQuery, 1000)
  }, [])

  return (
    <React.Fragment>
      <div className={fin ? styles.finDataWrapper : styles.tickerWrapper}>
        <div className={styles.topInputs}>
          <span>
            <DatePicker
              onChange={setStartDate}
              value={startDate}
              className={styles.datepicker}
              clearIcon={null}
              disabled={pending}
            />
            <DatePicker
              onChange={setEndDate}
              value={endDate}
              className={styles.datepicker}
              clearIcon={null}
              disabled={pending}
            />
            <button
              className={cx(styles.btn, pending && styles.disabled)}
              onClick={runQuery}
              disabled={pending}
            >
              Run query
            </button>
          </span>
          <span className={styles.title}>API User Data</span>
        </div>
        <ExternalOrdersStatsGrid />
      </div>
    </React.Fragment>
  )
}

export default ExternalOrderStats
