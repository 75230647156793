import React from 'react'
import AdvancedOrderEditor from '../../../components/OrderEntry/AdvancedOrderEditor'
import { OrderType } from '../../../store/order/types'
import { CellProps } from './helpers'

const MyOrderAdvancedEditor = (orderType: OrderType) => ({
  data
}: CellProps) => {
  return <AdvancedOrderEditor data={data} orderType={orderType} />
}

export default MyOrderAdvancedEditor
