import React, { FC, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { saveSystemParam } from '../../../store/admin/sysparam/actions'
import { getSystemParam } from '../../../store/admin/sysparam/selectors'
import styles from './SystemParameters.scss'

interface Props {
  displayModal: boolean
  closeFunc: () => void
  paramId: number
}
const SystemParameterModal: FC<Props> = (props: Props) => {
  const displayModal = props.displayModal
  const closeFunc = props.closeFunc
  const paramId = props.paramId
  const dispatch = useDispatch()
  const param = useSelector(getSystemParam)(paramId)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [value, setValue] = useState<string>('')

  const nameChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setName(evt.target.value)
  }

  const descriptionChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(evt.target.value)
  }

  const valueChanged = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(evt.target.value)
  }

  useEffect(() => {
    setName(param.name)
    setDescription(param.description)
    setValue(param.value)
  }, [])
  const handleValidateParam = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const paramToSave = param
    paramToSave.name = name
    paramToSave.description = description
    paramToSave.value = value

    dispatch(saveSystemParam(paramToSave))
    closeFunc()
  }
  return (
    <ReactModal isOpen={displayModal} onRequestClose={() => closeFunc()}>
      <form className={styles.sysParamForm} onSubmit={handleValidateParam}>
        <label>Name:</label>
        <input value={name} onChange={nameChanged} />
        <label>Description:</label>
        <input value={description} onChange={descriptionChanged} />
        <label>Value:</label>
        <textarea value={value} onChange={valueChanged} rows={10} />
        <footer>
          <button type="submit">Save</button>
          <button onClick={closeFunc}>Cancel</button>
        </footer>
      </form>
    </ReactModal>
  )
}
export default SystemParameterModal
