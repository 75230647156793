import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, forkJoin, from, merge, of } from 'rxjs'
import {
  bufferTime,
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  mergeMapTo,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { OrderCreationParams } from '../checkedOrders/types'
import { splitCallByChunks } from '../helpers'
import { addLogItem, addLogItems } from '../log/actions'
import { fetchSecuritiesByIds, RemoveGridAction } from '../securities/actions'
import {
  getSecurityOrderDataById,
  getSecurityStaticDataById
} from '../securities/selectors'
import { getUserPreferences } from '../userPreferences/selectors'
import { addLastLookWindow } from '../windows/actions'
import { logError } from '../ws/actions'
import {
  addOrUpdateOperatorOrders,
  AddOrUpdateOperatorOrdersAction,
  addOrUpdateUserOrders,
  AddOrUpdateUserOrdersAction,
  CancelOrderAction,
  cancelOrderFailure,
  CancelOrdersAction,
  clearMyOrders,
  CreateOrderAction,
  CreateOrdersAction,
  createTempOrder,
  fetchUserOrders,
  handleBulkErr,
  handleErr,
  loadResubmitOrders,
  mapFakeTransactionId,
  setMyOrdersOpen,
  SubmitOrderAction,
  submitOrderFailure,
  unsubscribeFetchUserOrders,
  UnsubscribeFetchUserOrdersAction,
  updateOrdersValidations,
  UpdateOrdersValidationsAction,
  updateOrderValidation
} from './actions'
import {
  createOrderFromResponse,
  createOrdersFromResubmitOrders,
  getOrderTypeForResponse,
  showBest
} from './helpers'
import { getOperatorOrders, getUserOrders } from './selectors'
import { Order, OrderResponse, ValidationResult } from './types'
import { validate } from './validation'

export let nextMessageId = 0

const submitOrderEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.submit'),
    mergeMap((action: SubmitOrderAction) => {
      const { orderId, size, transactionId, custId, spotCrossSelection } =
        action.payload
      const messageId = nextMessageId++
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return getHub()
        .invoke<number>(
          'HitOrLiftOrder',
          selUser,
          orderId,
          size,
          custId,
          messageId,
          spotCrossSelection
        )
        .pipe(
          /* mergeMap(orderTransactionId =>
            of(
              mapFakeTransactionId(transactionId, orderTransactionId),
              updateFakeTransactionIdForAggressWindow(
                transactionId,
                orderTransactionId
              )
            )
          ),*/
          map((orderTransactionId) =>
            mapFakeTransactionId(transactionId, orderTransactionId)
          ),
          catchError((err) =>
            of(
              submitOrderFailure(orderId, size, transactionId, err),
              logError(err)
            )
          )
        )
    })
  )

const cancelOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<CancelOrdersAction>('order.cancelOrders'),
    mergeMap((action) => {
      /* const { orderIds } = action.payload
      return splitCallByChunks(orderIds, 2000, (orderIdsChunk) =>
        forkJoin(
          orderIdsChunk.map((orderId) =>
            getHub().invoke('CancelOrder', orderId)
          )
        )
      ).pipe(
        mergeMapTo(EMPTY),
        catchError((err) => of(logError(err)))
      )*/
      const orderIds = action.payload.orderIds
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return getHub()
        .invoke('CancelOrders', selUser, orderIds)
        .pipe(
          mergeMapTo(EMPTY),
          catchError((err) => of(logError(err)))
        )
    }),
    mergeMapTo(EMPTY)
  )

const cancelOrderEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.cancel'),
    mergeMap((action: CancelOrderAction) => {
      const { orderId } = action.payload
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return getHub()
        .invoke('CancelOrder', selUser, orderId)
        .pipe(
          mergeMapTo(EMPTY),
          catchError((err) =>
            of(cancelOrderFailure(orderId, err), logError(err))
          )
        )
    })
  )

const createOrdersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<CreateOrdersAction>('order.createOrders'),
    mergeMap((action) => {
      const getSecurity = getSecurityOrderDataById(state$.value)
      const getStatic = getSecurityStaticDataById(state$.value)
      const { minimumTradeSize } = getUserPreferences(state$.value)
      const [ordersCreationParams, orderValidations] =
        action.payload.params.reduce(
          ([createParams, validation], params) => {
            const security = getSecurity(params.securityId)

            const error =
              security &&
              validate(
                security,
                params.orderType,
                params.price,
                params.isSpread,
                params.size,
                params.individualMin,
                minimumTradeSize,
                null
              )
            let formattedStack = ''
            if (error) {
              if (error.startsWith('Crossing')) {
                formattedStack = showBest(security)
              }
            }
            const staticSecurity = getStatic(params.securityId)
            return [
              error ? createParams : [...createParams, params],
              [
                ...validation,
                {
                  securityId: params.securityId,
                  orderType: params.orderType,
                  error,
                  isin: staticSecurity?.isin,
                  formattedError:
                    'Error creating order: ' + error + ' ' + formattedStack
                }
              ]
            ]
          },
          [[] as OrderCreationParams[], [] as ValidationResult[]]
        )

      const bulkDate = new Date()
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return merge(
        of(
          updateOrdersValidations(orderValidations),
          addLogItems(
            action.payload.params.map(
              (ord) =>
                'Creating bulk order: security: ' +
                ord.securityId +
                ' price: ' +
                ord.price +
                ' isSpread: ' +
                ord.isSpread +
                ' size: ' +
                ord.size +
                ' side: ' +
                ord.orderType +
                ' aon: ' +
                ord.allOrNone
            )
          )
        ),
        splitCallByChunks(ordersCreationParams, 2000, (chunk) =>
          forkJoin(
            chunk.map((orderCreationParams) =>
              getHub()
                .invoke(
                  'CreateOrder',
                  selUser,
                  orderCreationParams.securityId,
                  orderCreationParams.orderType,
                  orderCreationParams.price,
                  orderCreationParams.isSpread,
                  orderCreationParams.size,
                  orderCreationParams.allOrNone,
                  orderCreationParams.individualMin,
                  orderCreationParams.custId,
                  { tob: false, limitPrice: 0, floorPrice: 0 },
                  nextMessageId++
                )
                .pipe(
                  catchError((err) =>
                    of(
                      handleErr(
                        err,
                        orderCreationParams.securityId,
                        orderCreationParams.orderType
                      )
                    )
                  )
                )
            )
          )
        ).pipe(
          concatMap((d) => {
            const arr2 = d.filter((elmt: any) => {
              return elmt.type !== undefined
            })
            const arr = arr2.map((e) => {
              return { error: e, date: bulkDate }
            })
            return of(handleBulkErr(arr))
          })
        )
      )
    })
  )

const updateOrdersValidationsEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.updateValidations'),
    mergeMap((action: UpdateOrdersValidationsAction) => {
      const validationResults = action.payload
      const errors = validationResults
        .filter((vr) => vr.error !== undefined)
        .map((vr) => vr.formattedError!)
      return of(addLogItems(errors))
    })
  )
const createOrUpdateOrderOnSecurityEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.createOrder'),
    mergeMap((action: CreateOrderAction) => {
      const {
        securityId,
        orderType,
        price,
        isSpread,
        size,
        allOrNone,
        individualMin,
        custId,
        tob
      } = action.payload

      const security = getSecurityOrderDataById(state$.value)(securityId)
      const getStatic = getSecurityStaticDataById(state$.value)(securityId)
      const traderPrefMinimum = getUserPreferences(
        state$.value
      ).minimumTradeSize
      const error =
        security &&
        validate(
          security,
          orderType,
          price,
          isSpread,
          size,
          individualMin,
          traderPrefMinimum,
          tob
        )
      let formattedStack = ''
      if (error) {
        if (error.startsWith('Crossing')) {
          formattedStack = showBest(security)
        }
        const isin = getStatic?.isin
        return of(
          addLogItem('Error creating order: ' + error + ' ' + formattedStack),
          updateOrderValidation({ securityId, orderType, error, isin })
        )
      }

      const messageId = nextMessageId++
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return merge(
        of(updateOrderValidation({ securityId, orderType, error: undefined })),
        getHub()
          .invoke(
            'CreateOrder',
            selUser,
            securityId,
            getOrderTypeForResponse(orderType),
            price,
            isSpread,
            size,
            allOrNone,
            individualMin,
            custId,
            tob,
            messageId
          )
          .pipe(
            map((transactionId: number) =>
              createTempOrder(
                transactionId,
                securityId,
                orderType,
                price,
                isSpread,
                size,
                allOrNone,
                individualMin,
                tob
              )
            ),
            // catchError(err => of(logError(err)))
            catchError((err) => of(handleErr(err, securityId, orderType)))
          )
      )
    })
  )

const lastLookOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<AddOrUpdateUserOrdersAction>('order.addOrUpdateUserOrders'),
    withLatestFrom(state$),
    filter(([, state]) => getUserPreferences(state).lastLookInPopup),
    mergeMap(([action]) => {
      const orders = action.payload
      const orderIsLastLook = (order: Order) =>
        order.status === 'waitingForConfirmation' && order.aggressorOrder
      return from(
        orders
          .filter(orderIsLastLook)
          .map((order) => addLastLookWindow({ orderId: order.id }))
      )
    })
  )

const lastLookOperatorOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<AddOrUpdateOperatorOrdersAction>('order.addOrUpdateOperatorOrders'),
    withLatestFrom(state$),
    filter(([, state]) => getUserPreferences(state).lastLookInPopup),
    mergeMap(([action]) => {
      const orders = action.payload
      const orderIsLastLook = (order: Order) =>
        order.status === 'waitingForConfirmation' && order.aggressorOrder
      return from(
        orders
          .filter(orderIsLastLook)
          .map((order) => addLastLookWindow({ orderId: order.id }))
      )
    })
  )

const fetchSecuritiesForOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.addOrUpdateUserOrders'),
    mergeMap((action: AddOrUpdateUserOrdersAction) => {
      const getSecurity = getSecurityOrderDataById(state$.value)
      const ids = [
        ...new Set(action.payload.map((order) => order.securityId))
      ].filter((securityId) => getSecurity(securityId) === undefined)
      return ids.length > 0 ? of(fetchSecuritiesByIds(ids)) : EMPTY
    })
  )

const getUserOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.fetchUserOrders'),
    mergeMap(() => {
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return getHub()
        .stream('GetMyOrders', selUser)
        .pipe(
          map((responses: OrderResponse[]) => {
            const pendingOrders = getUserOrders(state$.value).filter(
              (f: Order) => f.id === ''
            )
            return responses.map((response) =>
              createOrderFromResponse(response, pendingOrders)
            )
          }),
          bufferTime(1000),
          map((ordersArrays) => {
            return ordersArrays.reduce((acc, orders) => [...acc, ...orders], [])
          }),
          filter((orders, index) => index === 0 || orders.length > 0),
          map(addOrUpdateUserOrders),
          takeUntil(
            action$.ofType<UnsubscribeFetchUserOrdersAction>(
              'order.unsubscribeFetchUserOrders'
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const getOperatorOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.fetchOperatorOrders'),
    mergeMap(() =>
      getHub()
        .stream('GetOperatorOrders')
        .pipe(
          map((responses: OrderResponse[]) => {
            const pendingOrders = getOperatorOrders(state$.value).filter(
              (f: Order) => f.id === ''
            )
            return responses.map((response) =>
              createOrderFromResponse(response, pendingOrders)
            )
          }),
          bufferTime(1000),
          map((ordersArrays) => {
            return ordersArrays.reduce((acc, orders) => [...acc, ...orders], [])
          }),
          filter((orders, index) => index === 0 || orders.length > 0),
          map(addOrUpdateOperatorOrders),
          catchError((err) => of(logError(err)))
        )
    )
  )

const setSelectedUserEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('users.setSelectedUser'),
    map(() => {
      return unsubscribeFetchUserOrders()
    })
  )

const unsubscribeFetchUserOrdersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('order.unsubscribeFetchUserOrders'),
    map(() => {
      return clearMyOrders()
    })
  )

const clearMyOrdersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('order.clearMyOrders'),
    map(() => {
      return fetchUserOrders()
    })
  )

const getResubmitOrders: Epic = (action$, state$) =>
  action$.pipe(
    ofType('order.fetchResubmitOrders'),
    mergeMap(() =>
      getHub()
        .invoke('GetResubmitOrders')
        .pipe(
          map((orders) =>
            loadResubmitOrders(createOrdersFromResubmitOrders(orders))
          )
        )
    )
  )

/* const resetOrderValidationOnFilterChangeEpic: Epic<Action> = action$ =>
  action$.pipe(
    ofType(
      'securities.setWatchListId',
      'securities.setSecuritiesFilter',
      'securities.setIssuerFilter',
      'securities.setIsMine'
    ),
    mapTo(resetOrdersValidation())
  )*/

const resetMyOrdersOpenForGridEpic: Epic = (action$) =>
  action$.pipe(
    ofType<RemoveGridAction>('securities.removeGrid'),
    map((action) => setMyOrdersOpen(action.payload.gridIndex, false))
  )

export default combineEpics(
  submitOrderEpic,
  cancelOrderEpic,
  cancelOrdersEpic,
  createOrdersEpic,
  createOrUpdateOrderOnSecurityEpic,
  getUserOrdersEpic,
  getOperatorOrdersEpic,
  lastLookOrdersEpic,
  lastLookOperatorOrdersEpic,
  fetchSecuritiesForOrdersEpic,
  // resetOrderValidationOnFilterChangeEpic,
  setSelectedUserEpic,
  unsubscribeFetchUserOrdersEpic,
  clearMyOrdersEpic,
  resetMyOrdersOpenForGridEpic,
  getResubmitOrders,
  updateOrdersValidationsEpic
)
