import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { concat, EMPTY, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { checkOrders } from '../checkedOrders/actions'
import { splitCallByChunksAndAggregateResult } from '../helpers'
import { setIsMine, setWatchListId } from '../securities/actions'
import { Security } from '../securities/reducer'
import { addOrUpdateStagedOrders } from '../stagedOrders/actions'
import {
  addSecuritiesToWatchlist,
  FetchWatchListsSuccessAction
} from '../watchList/actions'
import { getDetailsForCurrentWatchlist } from '../watchList/selectors'
import { logError } from '../ws/actions'
import {
  CreateNewWatchlistWithIdentifiersAction,
  createNewWatchlistWithSecurityIds,
  CreateNewWatchlistWithSecurityIdsAction,
  setNewWatchlistTransactionId,
  toggleDropwdownState,
  UploadOrdersAction,
  uploadOrdersFailure,
  UploadOrdersFromPreviousDate,
  uploadOrdersServerError,
  uploadOrdersToNewWatchlist,
  UploadOrdersToNewWatchlistAction
} from './actions'
import {
  areLevelsEmpty,
  getFailures,
  getOrdersToStage,
  getSecuritiesToAdd
} from './helpers'
import { getNewWatchlistTransactionId } from './selectors'
import { OrdersWithSecurityIds } from './types'

const GET_SECURITIES_IDS_FROM_IDENTIFIERS_CHUNK_SIZE = 1000

const getSecurityIdsFromIdentifier$ = (identifiers: string[]) =>
  splitCallByChunksAndAggregateResult(
    identifiers,
    GET_SECURITIES_IDS_FROM_IDENTIFIERS_CHUNK_SIZE,
    (identifiersChunk) =>
      getHub().invoke<Array<Array<Security['id']>>>(
        'GetSecurityIds',
        identifiersChunk
      )
  )

const uploadOrdersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('upload.uploadOrders'),
    mergeMap((action: UploadOrdersAction) => {
      const { gridIndex, identifiers, orders, book } = action.payload
      const watchlistDetails = getDetailsForCurrentWatchlist(state$.value)(
        gridIndex
      )
      return getSecurityIdsFromIdentifier$(identifiers).pipe(
        map((securityIdsArray) =>
          securityIdsArray.map((securityIds, i) => ({
            orderInfo: orders[i],
            securityIds
          }))
        ),
        mergeMap((ordersWithSecurityIds: OrdersWithSecurityIds) => {
          const failures = getFailures(ordersWithSecurityIds)
          const securitiesToAdd = watchlistDetails?.id
            ? getSecuritiesToAdd(ordersWithSecurityIds)
            : []
          const ordersToStage = getOrdersToStage(ordersWithSecurityIds, book.id)
          if (watchlistDetails?.id && securitiesToAdd.length !== 0) {
            if (failures.length > 0) {
              if (ordersToStage.length > 0) {
                return of(
                  uploadOrdersFailure(failures),
                  addSecuritiesToWatchlist(
                    gridIndex,
                    watchlistDetails.id,
                    securitiesToAdd,
                    identifiers
                  ),
                  addOrUpdateStagedOrders(ordersToStage, true),
                  toggleDropwdownState(gridIndex, 'upload'),
                  setIsMine(gridIndex, true)
                )
              } else {
                return of(
                  uploadOrdersFailure(failures),
                  addSecuritiesToWatchlist(
                    gridIndex,
                    watchlistDetails.id,
                    securitiesToAdd,
                    identifiers
                  ),
                  toggleDropwdownState(gridIndex, 'upload')
                )
              }
            } else {
              if (ordersToStage.length > 0) {
                return of(
                  addSecuritiesToWatchlist(
                    gridIndex,
                    watchlistDetails.id,
                    securitiesToAdd,
                    identifiers
                  ),
                  addOrUpdateStagedOrders(ordersToStage, true),
                  toggleDropwdownState(gridIndex, 'closed'),
                  setIsMine(gridIndex, true)
                )
              } else {
                return of(
                  addSecuritiesToWatchlist(
                    gridIndex,
                    watchlistDetails.id,
                    securitiesToAdd,
                    identifiers
                  ),
                  toggleDropwdownState(gridIndex, 'closed')
                )
              }
            }
          } else {
            if (failures.length > 0) {
              if (ordersToStage.length > 0) {
                return of(
                  uploadOrdersFailure(failures),
                  addOrUpdateStagedOrders(ordersToStage, true),
                  toggleDropwdownState(gridIndex, 'upload'),
                  setIsMine(gridIndex, true)
                )
              } else {
                return of(
                  uploadOrdersFailure(failures),
                  toggleDropwdownState(gridIndex, 'upload')
                )
              }
            } else {
              if (ordersToStage.length > 0) {
                return of(
                  addOrUpdateStagedOrders(ordersToStage, true),
                  toggleDropwdownState(gridIndex, 'closed'),
                  setIsMine(gridIndex, true)
                )
              } else {
                return of(toggleDropwdownState(gridIndex, 'closed'))
              }
            }
          }
        })
      )
    }),
    catchError((err) => of(logError(err)))
  )

const createNewWatchlistWithIdentifiersEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.createNewWatchlistWithIdentifiers'),
    mergeMap((action: CreateNewWatchlistWithIdentifiersAction) => {
      const {
        gridIndex,
        name,
        identifiers,
        permission,
        book,
        orders,
        filter,
        myFirmChecked,
        useSizeChecked,
        size,
        useAdvancedFilter
      } = action.payload

      return getSecurityIdsFromIdentifier$(identifiers).pipe(
        map((securityIdsArray) =>
          securityIdsArray.filter(
            (securityIds, i) =>
              !(securityIds.length > 0 && !areLevelsEmpty(orders[i])) ||
              securityIds.length === 1
          )
        ),
        mergeMap((secIds) =>
          of(
            createNewWatchlistWithSecurityIds(
              gridIndex,
              name,
              permission,
              book,
              orders,
              secIds,
              identifiers,
              filter,
              myFirmChecked,
              useSizeChecked,
              size,
              useAdvancedFilter
            )
          )
        )
      )
    })
  )

const createNewWatchlistWithSecurityIdsEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.createNewWatchlistWithSecurityIds'),
    mergeMap((action: CreateNewWatchlistWithSecurityIdsAction) => {
      const {
        gridIndex,
        name,
        permission,
        book,
        orders,
        securityIds,
        identifiers,
        filter,
        myFirmChecked,
        useSizeChecked,
        size,
        useAdvancedFilter
      } = action.payload
      const ordersWithSecurityIds = orders.reduce(
        (acc, order, i) => [
          ...acc,
          { orderInfo: order, securityIds: securityIds[i] }
        ],
        []
      )

      return getHub()
        .invoke(
          'CreateWatchlist',
          name,
          identifiers,
          permission,
          book.name,
          filter,
          myFirmChecked,
          useSizeChecked,
          size,
          useAdvancedFilter
        )
        .pipe(
          mergeMap((id: number) =>
            of(
              setNewWatchlistTransactionId(gridIndex, id),
              uploadOrdersToNewWatchlist(
                gridIndex,
                ordersWithSecurityIds,
                book.id
              ),
              setWatchListId(gridIndex, id)
            )
          ),
          catchError((err) => of(uploadOrdersServerError(`${err.message}`)))
        )
    })
  )

const uploadOrdersToNewWatchlistEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.uploadOrdersToNewWatchlist'),
    mergeMap((action: UploadOrdersToNewWatchlistAction) => {
      const { gridIndex, securityIds, bookId } = action.payload
      const failures = getFailures(securityIds)
      const ordersToStage = getOrdersToStage(securityIds, bookId)

      if (failures.length > 0) {
        if (ordersToStage.length > 0) {
          return of(
            uploadOrdersFailure(failures),
            addOrUpdateStagedOrders(ordersToStage, true),
            toggleDropwdownState(gridIndex, 'upload'),
            setIsMine(gridIndex, true)
          )
        } else {
          return of(
            uploadOrdersFailure(failures),
            toggleDropwdownState(gridIndex, 'upload')
          )
        }
      } else {
        if (ordersToStage.length > 0) {
          return of(
            addOrUpdateStagedOrders(ordersToStage, true),
            toggleDropwdownState(gridIndex, 'closed'),
            setIsMine(gridIndex, true)
          )
        } else {
          return of(toggleDropwdownState(gridIndex, 'closed'))
        }
      }
    }),
    catchError((err) => of(logError(err)))
  )

const setCreatedWatchlistAsCurrentWatchlistEpic: Epic<Action> = (
  action$,
  state$
) =>
  action$.pipe(
    ofType<FetchWatchListsSuccessAction>('watchList.fetchWatchListsSuccess'),
    mergeMap((action) => {
      const { gridIndex, watchlists } = action.payload
      const { transactionId, id: watchlistId } = watchlists[0]

      const newWatchlistTransactionId = getNewWatchlistTransactionId(
        state$.value
      )(gridIndex)

      if (newWatchlistTransactionId === transactionId) {
        return of(setWatchListId(gridIndex, watchlistId))
      }

      return EMPTY
    })
  )

const uploadOrdersFromPreviousDateEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.uploadOrdersFromPreviousDate'),
    mergeMap((action: UploadOrdersFromPreviousDate) => {
      const stagedOrders = action.payload
      return concat(
        of(addOrUpdateStagedOrders(stagedOrders, true)),
        of(
          checkOrders(
            stagedOrders.map((stagedOrder) => ({
              securityId: stagedOrder.securityId,
              orderType: stagedOrder.orderType
            }))
          )
        )
      )
    })
  )

export default combineEpics(
  uploadOrdersEpic,
  createNewWatchlistWithIdentifiersEpic,
  setCreatedWatchlistAsCurrentWatchlistEpic,
  uploadOrdersFromPreviousDateEpic,
  createNewWatchlistWithSecurityIdsEpic,
  uploadOrdersToNewWatchlistEpic
)
