import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ColumnApi,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { faDownload, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOpenFin } from '../../../app/openFinContext'
import { tradeBlotterFetch } from '../../../store/activityTradeBlotter/actions'
import {
  getActivityTradeBlotter,
  getActivityTradeBlotterError
} from '../../../store/activityTradeBlotter/selectors'
import { Trade } from '../../../store/activityTradeBlotter/types'
import { columnDefinitions } from './columnDefs'

interface Props {
  onToggle?: (value: boolean) => void
}

const getTradeRowId: GetRowIdFunc<Trade> = ({ data }) => {
  return `${data?.id ?? ''}`
}

const ActivityTradeBlotterGrid: FC<Props> = ({ onToggle }) => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const tradeData = useSelector(getActivityTradeBlotter)
  const tradeDataError = useSelector(getActivityTradeBlotterError)
  const modalStyle = fin ? 'finModal' : 'webModal'
  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  useEffect(() => {
    dispatch(tradeBlotterFetch())
  }, [])

  const onGridReady = useCallback(({ api, columnApi }: GridReadyEvent) => {
    if (!gridApi) {
      setGridApi({ api, columnApi })
      columnApi.autoSizeAllColumns()
    }
  }, [])

  if (tradeDataError) {
    return <div>{tradeDataError}</div>
  }

  return (
    <React.Fragment>
      <div className="activity-log-actions-row">
        <FontAwesomeIcon
          className="pointer"
          icon={faDownload}
          onClick={() => {
            gridApi?.api.exportDataAsCsv({})
          }}
        />
        {onToggle && (
          <FontAwesomeIcon
            className="pointer activity-log-close"
            icon={faTimes}
            onClick={() => onToggle(false)}
          />
        )}
      </div>
      <div className={`ag-theme-balham activity-log ${modalStyle}`}>
        <AgGridReact
          rowData={tradeData}
          columnDefs={columnDefinitions}
          onGridReady={onGridReady}
          overlayNoRowsTemplate={'No trades found.'}
          overlayLoadingTemplate="Loading Trades..."
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          rowClass="activity-log-grid-row"
          getRowId={getTradeRowId}
        />
      </div>
    </React.Fragment>
  )
}

export default ActivityTradeBlotterGrid
