import { addOrUpdateElement } from '../helpers'
import { Order } from '../order/types'
import { WindowsAction } from './actions'
import {
  AggressorWindowInfo,
  LastLookWindowInfo,
  SecurityModalInfo
} from './types'

export interface State {
  aggressorWindows: AggressorWindowInfo[]
  lastLookWindows: LastLookWindowInfo[]
  aggressorForegroundIndex: number | undefined
  lastLookForegroundIndex: number | undefined
  securitiesModalInfo: Record<Order['id'], SecurityModalInfo | undefined>
  windowFront: string
}

export const initialState: State = {
  aggressorWindows: [],
  lastLookWindows: [],
  aggressorForegroundIndex: undefined,
  lastLookForegroundIndex: undefined,
  securitiesModalInfo: {},
  windowFront: 'depth'
}

export const securityWindowsMatch = (
  windowOne: SecurityModalInfo,
  windowTwo: SecurityModalInfo
) => windowOne.orderId === windowTwo.orderId

const findLastLookWindowIndex = (
  orderId: Order['id'],
  windows: LastLookWindowInfo[],
  currentIndex: number | undefined
) => {
  const index = windows.findIndex((w) => w.orderId === orderId)
  return index === -1 ? currentIndex : index
}

const findAggressorWindowIndex = (
  initialOrderId: Order['id'],
  windows: AggressorWindowInfo[],
  currentIndex: number | undefined
) => {
  const index = windows.findIndex((w) => w.initialOrderId === initialOrderId)
  return index === -1 ? currentIndex : index
}

export default (state = initialState, action: WindowsAction): State => {
  switch (action.type) {
    case 'windows.foregroundAggressorWindow':
      return {
        ...state,
        aggressorForegroundIndex: findAggressorWindowIndex(
          action.payload,
          state.aggressorWindows,
          state.aggressorForegroundIndex
        )
      }
    case 'windows.foregroundAggressorWindowAtIndex':
      return {
        ...state,
        aggressorForegroundIndex: action.payload
      }
    case 'windows.foregroundLastLookWindow':
      return {
        ...state,
        lastLookForegroundIndex: findLastLookWindowIndex(
          action.payload,
          state.lastLookWindows,
          state.lastLookForegroundIndex
        )
      }
    case 'windows.foregroundLastLookWindowAtIndex':
      return {
        ...state,
        lastLookForegroundIndex: action.payload
      }
    case 'windows.updateDataForSecurityModal':
      return {
        ...state,
        securitiesModalInfo: {
          ...state.securitiesModalInfo,
          [action.payload.initialOrderId]: action.payload.securityModalInfo
        }
      }
    case 'windows.addAggressorWindow':
      return {
        ...state,
        aggressorWindows: [action.payload, ...state.aggressorWindows]
      }
    case 'windows.addLastLookWindow':
      return {
        ...state,
        lastLookWindows: addOrUpdateElement(
          state.lastLookWindows,
          action.payload,
          (w1, w2) => w1.orderId === w2.orderId
        )
      }
    case 'windows.removeAggressorWindow':
      return {
        ...state,
        aggressorWindows: state.aggressorWindows.filter(
          (window) => window !== action.payload
        )
      }
    case 'windows.removeLastLookFromWindows':
      return {
        ...state,
        lastLookWindows: state.lastLookWindows.filter(
          (window) => window !== action.payload
        )
      }
    case 'windows.setWindowFront':
      return {
        ...state,
        windowFront: action.payload
      }
    default:
      return state
  }
}
