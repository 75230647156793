import React, { FC, useState } from 'react'
// @ts-ignore
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useOpenFin } from '../../../app/openFinContext'
import openfinStyles from '../../../containers/Openfin/Admin/openfinAdmin.scss'
import ManagementReportWrapper from './ManagementReportWrapper'

const ManagementReportMainPage: FC = () => {
  const { fin } = useOpenFin()
  const [date, setDate] = useState(new Date())

  return (
    <div className={fin && openfinStyles.finWrapper}>
      <div style={{ display: 'flex' }}>
        <DatePicker selected={date} onChange={(d: Date) => setDate(d)} />
      </div>

      <ManagementReportWrapper date={date} />
    </div>
  )
}

export default ManagementReportMainPage
