import { ColDef } from '@ag-grid-community/core'
import { dateFormatter } from '../../../../helpers/formatting'

export const ORDER_TIME = 'orderTime'
export const SECURITY = 'security'
export const DESCRIPTION = 'description'
export const TRADER = 'trader'
export const OPERATOR = 'operator'
export const EXCEPTION = 'exception'

export const customizableColumns: ColDef[] = [
  {
    colId: ORDER_TIME,
    field: ORDER_TIME,
    headerName: 'Time',
    valueFormatter: dateFormatter,
    cellStyle: { border: '1px solid #e4e5e6' },
    width: 150,
    suppressSizeToFit: true
  },
  {
    colId: DESCRIPTION,
    field: DESCRIPTION,
    headerName: 'Description',
    cellStyle: { border: '1px solid #e4e5e6' },
    width: 700,
    tooltipField: DESCRIPTION,
    wrapText: true
  },
  {
    colId: SECURITY,
    field: SECURITY,
    headerName: 'Security',
    cellStyle: { border: '1px solid #e4e5e6' },
    suppressSizeToFit: true
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
