import cx from 'classnames'
import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { filtersEqual } from '../../store/filter/helpers'
import { defaultFilter } from '../../store/filter/types'
import {
  getAdvancedFilter,
  getMyBook,
  getMyFirm,
  getUseSize
} from '../../store/securities/selectors'
import styles from './AdvancedFilter.scss'
import AdvancedFilterIcon from './AdvancedFilterIcon'

export interface Props {
  active: boolean
  setActive: (b: boolean) => void
  gridIndex: number
}
const AdvancedFilter: FC<Props> = ({ active, setActive, gridIndex }) => {
  const filter = useSelector(getAdvancedFilter)(gridIndex)
  const myFirmChecked = useSelector(getMyFirm)(gridIndex)
  const myBookChecked = useSelector(getMyBook)(gridIndex)
  const useSizeChecked = useSelector(getUseSize)(gridIndex)
  const isDefault =
    filtersEqual(filter, defaultFilter) &&
    !myFirmChecked &&
    !useSizeChecked &&
    !myBookChecked

  return (
    <div>
      <span
        className={cx(
          styles.button,
          active ? styles.active : !isDefault ? styles.filterActive : false
        )}
        onClick={() => setActive(!active)}
        data-tip={active ? 'Hide Filters' : 'Apply Filters'}
        data-for="advancedFilter"
      >
        <AdvancedFilterIcon fill={active ? '#FFFFFF' : '#656C78'} />
      </span>
      <ReactTooltip
        id="advancedFilter"
        backgroundColor="#e9edf1"
        textColor="#656C78"
        effect="solid"
        className="iconTooltip"
      >
        {active ? 'Hide Filters' : 'Apply Filters'}
      </ReactTooltip>
    </div>
  )
}

export default memo(AdvancedFilter)
