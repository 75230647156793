import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { managementReportFetch } from '../../../store/admin/managementReports/actions'
import { getManagementReportsForDate } from '../../../store/admin/managementReports/selectors'
import ManagementReportComponent from './ManagementReport'

interface Props {
  date: Date
}

const ManagementReportWrapper: FC<Props> = (props: Props) => {
  const date = props.date
  const dispatch = useDispatch()
  const managementReport = useSelector(getManagementReportsForDate)(date)
  useEffect(() => {
    if (managementReport === undefined) {
      dispatch(managementReportFetch(date))
    }
  }, [managementReport])
  return (
    <React.Fragment>
      {managementReport !== undefined && (
        <ManagementReportComponent managementReport={managementReport} />
      )}
    </React.Fragment>
  )
}

export default ManagementReportWrapper
