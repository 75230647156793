import cx from 'classnames'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../components/Checkbox'
import { toggleOrder } from '../../../store/checkedOrders/actions'
import { isOrderChecked } from '../../../store/checkedOrders/selectors'
import { OrderType } from '../../../store/order/types'
import {
  CellProps,
  useSecurityStaticData,
  useStagedAndLiveOrders
} from './helpers'

const OrderCheckboxCell = (orderType: OrderType) => ({
  data
}: CellProps) => {
  const security = useSecurityStaticData(data)
  const dispatch = useDispatch()
  const isChecked = useSelector(isOrderChecked)
  const { stagedOrder, liveOrder } = useStagedAndLiveOrders(
    security?.id,
    orderType
  )

  const onChange = useCallback(() => {
    dispatch(toggleOrder(Number(security.id), orderType))
  }, [])

  if (!security) return <> </>

  const value = isChecked(Number(security.id), orderType)

  if (stagedOrder || liveOrder) {
    return (
      <div className={cx(!value && 'my-orders-edit')}>
        <Checkbox
          locator={`${security.id}-checkbox-my-${
            orderType === 'buy' ? 'bid' : 'offer'
          }`}
          onChange={onChange}
          fillColor="#43A980"
          checked={value}
        />
      </div>
    )
  }

  return <> </>
}

export default OrderCheckboxCell
