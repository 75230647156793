import { MarketAction } from './actions'

export interface State {
  closed: boolean
}

const initialState: State = {
  closed: false
}

export default (state = initialState, action: MarketAction): State => {
  switch (action.type) {
    case 'market.marketClosed':
      return {
        closed: action.payload.closed
      }
    default:
      return state
  }
}
