import { ColDef } from '@ag-grid-community/core'
import styles from '../AdminTradeBlotter.scss'

export const STP_STATUS = 'stpStatus'
export const TRADE_ID = 'tradeId'
export const FLASH_ID = 'flashId'
export const TICKET_ID = 'ticketId'
export const CUSTOMER = 'customer'
export const SECURITY = 'security'
export const AMOUNT = 'amount'
export const DELETED = 'deleted'
export const BUY_SELL = 'buySell'
export const ORDER_TYPE = 'orderType'
export const VENUE = 'venue'
export const PRICE_TRADE = 'priceTrade'
export const PRICE_MARK_UP_DOWN = 'priceMarkUpDown'
export const CHECKED_OUT = 'checkedOut'
export const BROKER = 'broker'
export const BROKERAGE = 'brokerage'
export const BRO_BASIS_PTS = 'broBasisPoints'
export const DATE_TIME = 'dateTime'
export const ACCRUED_INTEREST = 'accruedInterest'
export const ACCRUED_DAYS = 'accruedDays'
export const ISIN = 'isin'
export const CUSIP = 'cusip'
export const EXPECTED_PROCEEDS = 'expectedProceeds'
export const PRIN_TRADE_DATE = 'prinTradeDate'
export const SPREAD = 'spread'
export const BENCHMARK_PRICE = 'benchmarkPrice'
export const TRADER = 'trader'
export const CURRENCY = 'currency'
export const OPERATOR = 'operator'
export const CLEARING_ACCOUNT = 'clearingAccount'
export const CUSTOMER_REF = 'customerRef'

export const customizableColumns: ColDef[] = [
  {
    colId: STP_STATUS,
    field: STP_STATUS,
    headerName: 'STP Status'
  },
  {
    colId: TRADE_ID,
    field: TRADE_ID,
    headerName: 'Trade Id',
    cellClass: styles.blueBackground
  },
  {
    colId: FLASH_ID,
    field: FLASH_ID,
    headerName: 'Flash Id',
    cellClass: styles.blueBackground
  },
  {
    colId: TICKET_ID,
    field: TICKET_ID,
    headerName: 'Ticket Id'
  },
  {
    colId: CUSTOMER,
    field: CUSTOMER,
    headerName: 'Customer'
  },
  {
    colId: SECURITY,
    field: SECURITY,
    headerName: 'Security'
  },
  {
    colId: AMOUNT,
    field: AMOUNT,
    headerName: 'Amt'
  },
  {
    colId: DELETED,
    field: DELETED,
    headerName: 'Deleted'
  },
  {
    colId: BUY_SELL,
    field: BUY_SELL,
    headerName: 'Buy/Sell'
  },
  {
    colId: ORDER_TYPE,
    field: ORDER_TYPE,
    headerName: 'OrderType'
  },
  {
    colId: VENUE,
    field: VENUE,
    headerName: 'Venue'
  },
  {
    colId: PRICE_TRADE,
    field: PRICE_TRADE,
    headerName: 'Price (Trd)'
  },
  {
    colId: PRICE_MARK_UP_DOWN,
    field: PRICE_MARK_UP_DOWN,
    headerName: 'Price (Mark Up/Dn)'
  },
  {
    colId: CHECKED_OUT,
    field: CHECKED_OUT,
    headerName: 'Checked Out'
  },
  {
    colId: BROKER,
    field: BROKER,
    headerName: 'Broker'
  },
  {
    colId: BROKERAGE,
    field: BROKERAGE,
    headerName: 'Brokerage'
  },
  {
    colId: BRO_BASIS_PTS,
    field: BRO_BASIS_PTS,
    headerName: 'Bro Basis Pts'
  },
  {
    colId: DATE_TIME,
    field: DATE_TIME,
    headerName: 'Date/Time'
  },
  {
    colId: ACCRUED_INTEREST,
    field: ACCRUED_INTEREST,
    headerName: 'Accrued Interest'
  },
  {
    colId: ACCRUED_DAYS,
    field: ACCRUED_DAYS,
    headerName: 'Accrued Days'
  },
  {
    colId: ISIN,
    field: ISIN,
    headerName: 'ISIN'
  },
  {
    colId: CUSIP,
    field: CUSIP,
    headerName: 'Cusip'
  },
  {
    colId: EXPECTED_PROCEEDS,
    field: EXPECTED_PROCEEDS,
    headerName: 'Expected Proceeds'
  },
  {
    colId: PRIN_TRADE_DATE,
    field: PRIN_TRADE_DATE,
    headerName: 'Prin Trade Date'
  },
  {
    colId: SPREAD,
    field: SPREAD,
    headerName: 'Spread'
  },
  {
    colId: BENCHMARK_PRICE,
    field: BENCHMARK_PRICE,
    headerName: 'Benchmark Price'
  },
  {
    colId: TRADER,
    field: TRADER,
    headerName: 'Trader'
  },
  {
    colId: CURRENCY,
    field: CURRENCY,
    headerName: 'Currency'
  },
  {
    colId: OPERATOR,
    field: OPERATOR,
    headerName: 'Operator'
  },
  {
    colId: CLEARING_ACCOUNT,
    field: CLEARING_ACCOUNT,
    headerName: 'Bonds.com Clearing Account'
  },
  {
    colId: CUSTOMER_REF,
    field: CUSTOMER_REF,
    headerName: 'Customer Ref'
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
