import { createContext, useCallback, useContext, useEffect } from 'react'
import { openfinConfig } from '../containers/Openfin/WindowConfig'

// TODO: write or get some types for this
export const OpenFinContext = createContext<any>(undefined)

export const useOpenFin = (
  windowName?: string | undefined,
  offset?: number | undefined
) => {
  const fin = useContext(OpenFinContext)
  const mainWindow = fin?.desktop.Window.getCurrent()
  const allWindows = fin?.desktop.Application.getCurrent()

  // ------------------------WINDOW DIMENSIONS------------------------ //
  const changeWidth = (currentWindow: any) => {
    currentWindow.updateOptions({
      maxWidth: offset
    })
    currentWindow.getBounds((bounds: any) => {
      currentWindow.resizeTo(offset, bounds.height)
    })
  }
  // This is called for Aggressor/Last Look windows
  const changeHeight = (currentWindow: any) => {
    currentWindow.updateOptions({
      maxHeight: offset
    })
    currentWindow.getBounds((bounds: any) => {
      currentWindow.resizeTo(bounds.width, offset)
    })
  }

  const updateWindowDimensions = useCallback(() => {
    if (windowName === 'BondList') {
      changeWidth(mainWindow)
    } else {
      allWindows.getChildWindows((children: any) => {
        const focused = children.filter(
          (child: any) => child.name === windowName
        )[0]
        focused.name.includes('Watchlist')
          ? changeWidth(focused)
          : changeHeight(focused)
      })
    }
  }, [allWindows, offset])

  useEffect(() => {
    if (fin && windowName && offset) {
      updateWindowDimensions()
    }
  }, [allWindows, offset])

  // ------------------------WINDOW CONTROLS------------------------ //

  // TODO: this func will change when depth/userEditorModal use the new method of window creation
  const manageWindows = (target: string | null, action: string) => {
    allWindows.getChildWindows((children: any) => {
      children.forEach((childWindow: any) => {
        switch (action) {
          case 'minimize':
            if (target && childWindow.name.startsWith(target)) {
              childWindow.minimize()
            }
            if (!target) {
              childWindow.minimize()
            }
            break
          case 'hide':
            if (target && childWindow.name.startsWith(target)) {
              childWindow.hide()
            }
            if (!target) {
              childWindow.hide()
            }
            break
          case 'show':
            if (target && childWindow.name.startsWith(target)) {
              // used when user clicks popout icon for existing hidden depth
              childWindow.restore()
              childWindow.setAsForeground()
            }
            if (!target) {
              childWindow.restore()
              childWindow.setAsForeground()
            }
            break
          case 'resize':
            // resize admin depth popout windows
            if (target && childWindow.name.startsWith(target)) {
              childWindow.updateOptions({
                maxWidth: 700
              })
              childWindow.resizeTo(700, 177)
            }
            break
          case 'quit':
            // used to close only depths
            if (target && childWindow.name.startsWith(target)) {
              childWindow.close(true)
            }
            if (!target) {
              childWindow.close(true)
            }
        }
      })
    })
  }

  // ------------------------CREATE WINDOWS------------------------ //
  const setWindowsInLocalStorage = () => {
    const windowList: string[] = []
    allWindows.getChildWindows((children: any) => {
      children.forEach((child: any) => {
        windowList.push(child.name)
      })
      windowList.length > 0
        ? localStorage.setItem('openfinOpenWindows', windowList.toString())
        : localStorage.removeItem('openfinOpenWindows')
    })
  }

  const createOpenfinWindow = async (name: string) => {
    const {
      windowHeight = 500,
      windowWidth = 500,
      windowTop = 10,
      windowLeft = 10,
      isAdminWindow = false
    } = openfinConfig[name] || {}

    const winOption = {
      name: `${name}`,
      defaultWidth: windowWidth,
      defaultHeight: windowHeight,
      defaultTop: windowTop,
      defaultLeft: windowLeft,
      url: `/${isAdminWindow ? 'admin/' : ''}${name}`,
      frame: true,
      saveWindowState: true,
      contextMenu: true,
      maximizable: false,
      autoShow: true,
      showTaskbarIcon: true,
      cornerRounding: {
        height: 10,
        width: 10
      }
    }
    const newWindow = await fin.Window.create(winOption)
    newWindow.on('closed', () => {
      setWindowsInLocalStorage()
    })
    setWindowsInLocalStorage()
    return newWindow
  }

  const renderWindowCheck = (name: string) => {
    allWindows.getChildWindows((children: any) => {
      const focusedWindow = children.find((child: any) => child.name === name)
      focusedWindow
        ? focusedWindow.setAsForeground()
        : createOpenfinWindow(name)
    })
  }

  // ------------------------AUTOLAUNCH/WINDOW CONTROLS------------------------ //

  const autoLaunch = (
    startupParameters: any,
    interval: any,
    setStateInterval: any
  ) => {
    if (startupParameters.openfinAutoStart) {
      if (interval) {
        clearInterval(interval)
        setStateInterval(undefined)
      }
      setStateInterval(
        setInterval(() => {
          const now = new Date()
          const hour = now.getHours()
          const min = now.getMinutes()
          const sec = now.getSeconds()
          const startTime = startupParameters.openfinStartTime
          let startHour: number | undefined
          let startMin: number | undefined
          if (startTime) {
            const startTimeArr = startTime.split(':')
            if (startTimeArr.length === 2) {
              startHour = Number(startTimeArr[0])
              startMin = Number(startTimeArr[1])
            }
          }
          if (
            startHour &&
            startMin &&
            hour === startHour &&
            sec < 2 &&
            min === startMin
          ) {
            restoreWindows()
          }
        }, 1000)
      )
    } else {
      if (interval) {
        clearInterval(interval)
        setStateInterval(undefined)
      }
    }
  }

  const removeFrame = () => {
    mainWindow.updateOptions({
      frame: false
    })
  }

  const closeChildWindow = (name: string) => {
    allWindows.getChildWindows((children: any) => {
      const focusedWindow = children.find((child: any) => child.name === name)
      if (focusedWindow) {
        focusedWindow.close()
        setWindowsInLocalStorage()
      }
    })
  }

  const restoreWindows = () => {
    mainWindow.restore()
    mainWindow.setAsForeground()
    // also show child windows
    allWindows.getChildWindows((children: any) => {
      children.forEach((childWindow: any) => {
        childWindow.restore()
      })
    })
  }

  return {
    fin,
    autoLaunch,
    closeChildWindow,
    createOpenfinWindow,
    manageWindows,
    removeFrame,
    renderWindowCheck,
    restoreWindows
  }
}
