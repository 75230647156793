import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import {
  adminTradeBlotterFetch,
  unsubscribeFromBlotter
} from '../../../store/admin/adminTradeBlotter/actions'
import TradeBlotterModal from './AdminTradeBlotterModal'
import TradeBlotterGrid from './Grid/TradeBlotterGrid'

import styles from './AdminTradeBlotter.scss'

const TradeBlotter: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState<string>('')
  const [displayModal, toggleModal] = useState(false)

  useEffect(() => {
    dispatch(adminTradeBlotterFetch())
    return () => {
      dispatch(unsubscribeFromBlotter())
    }
  }, [])

  const gridDoubleClick = (row: any) => {
    handleShowModal(row.data.ticketId)
  }

  const handleShowModal = useCallback(
    (row: string) => {
      setSelectedRow(row)
      toggleModal(!displayModal)
    },
    [displayModal, selectedRow]
  )

  return (
    <React.Fragment>
      <div className={fin ? styles.finWrapper : styles.tradeBlotterWrapper}>
        <TradeBlotterGrid onDoubleClick={gridDoubleClick} />
      </div>
      {displayModal && (
        <TradeBlotterModal
          paramId={selectedRow}
          closeFunc={() => handleShowModal('')}
        />
      )}
    </React.Fragment>
  )
}

export default TradeBlotter
