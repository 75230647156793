import cx from 'classnames'
import React, { FC, ReactNode, useCallback, useState } from 'react'
import ExpandableComponent from '../ExpandableComponent/ExpandableComponent'
import ExpandableComponentIcon from '../ExpandableComponent/ExpandableComponentIcon'
import styles from './DropdownList.scss'

export interface Element {
  id: any
  label: ReactNode
  expanded?: boolean
  children?: Array<Pick<Element, 'id' | 'label'>>
}

export interface Props {
  elements: Element[]
  onElementSelected?: (id: any) => void
  onChildSelected?: (parentId: any, id: any) => void
  classOverride?: string
  fin?:boolean
}

const ElementChildren: FC<{
  element: Element
  onChildSelected?: Props['onChildSelected']
}> = ({ element, onChildSelected }) => {
  const [numberDisplayed, setNumberDisplayed] = useState(5)
  const totalNumber = element.children?.length || 0

  const handleShowMoreClick = useCallback(() => {
    // Using setTimeout is a small hack to prevent hiding the dropdown when
    // the button is clicked. Without it, as soon as we update the state the
    // button is removed of the DOM and therefore not considered as a child
    // of the dropdown DOM element.
    setTimeout(() => {
      setNumberDisplayed((n) => Math.min(n + 5, totalNumber))
    }, 100)
  }, [])

  return (
    <>
      {element.children?.slice(0, numberDisplayed).map((child) => (
        <div
          key={`${element.id}/${child.id}`}
          className={cx(
            styles.dropdownRaw,
            onChildSelected && styles.clickable
          )}
          onClick={() =>
            onChildSelected && onChildSelected(element.id, child.id)
          }
        >
          {child.label}
        </div>
      ))}
      {numberDisplayed < totalNumber && (
        <div
          key={`${element.id}/more`}
          className={cx(
            styles.dropdownRaw,
            styles.showMoreButton,
            styles.clickable
          )}
          onClick={handleShowMoreClick}
        >
          {`Show ${Math.min(5, totalNumber - numberDisplayed)} more…`}
        </div>
      )}
    </>
  )
}

const DropdownList: FC<Props> = ({
  elements,
  onElementSelected,
  onChildSelected,
  classOverride,
  fin
}) => {
  const renderElement = (element: Element) => {
    const title = (
      expanded: boolean | undefined,
      setExpanded?: (e: boolean) => void
    ) => (
      <div
        className={cx(
          styles.dropdownRaw,
          (expanded === undefined ? onElementSelected : setExpanded) &&
            styles.clickable
        )}
        data-iddropdown={element.id}
        data-testid={'watchlist-' + element.id}
        onClick={() =>
          expanded === undefined
            ? onElementSelected && onElementSelected(element.id)
            : setExpanded && setExpanded(!expanded)
        }
      >
        <div className={styles.label}>{element.label}</div>
        {expanded !== undefined && (
          <div className={styles.expandedIcon}>
            <ExpandableComponentIcon isExpanded={expanded} />
          </div>
        )}
      </div>
    )
    if (element.children) {
      return (
        <ExpandableComponent
          title={title}
          isInitiallyExpanded={element.expanded}
        >
          <ElementChildren
            element={element}
            onChildSelected={onChildSelected}
          />
        </ExpandableComponent>
      )
    } else {
      return title(undefined)
    }
  }

  return (
    <div
      className={
        classOverride
          ? styles[classOverride]
          : fin
          ? styles.finDropdownMenu
          : styles.dropdownMenu
      }
    >
      {elements.map((element) => (
        <div key={`${element.id}`}>{renderElement(element)}</div>
      ))}
    </div>
  )
}

export default DropdownList
