import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../../../helpers/formatting'
import { getTotalSecondsBeforeExpiration } from '../../../helpers/orders'
import { Styles } from '../../../helpers/types'
import { Order, OrderStatus, OrderType } from '../../../store/order/types'
import { SecurityModalInfo } from '../../../store/windows/types'
import { getServerTimeDelayMs } from '../../../store/ws/selectors'
import GenericDropdownMenu from '../../GenericDropdownMenu/GenericDropdownMenu'
import TimerLeftComponent from '../../Timer/Timer'

interface Props {
  order: Order
  type: OrderType
  securityModalInfo: SecurityModalInfo
  tradeAmt: string | undefined
  isLastLook: boolean
  // FIXME: define style classname types
  styles: Styles
  price: number
  orderStatusComp: OrderStatus | undefined
  shouldAlertPriceChange?: boolean
  onChangeTradeAmt?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCloseModal: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleRejectButtonClick?: () => void
  handleConfirmButtonClick?: (tradeAmt?: number) => () => void
  selectedAccount?: string
  setSelectedAccount?: (st: string) => void
  currency: string
}

const LastLookModalContent: FC<Props> = (props) => {
  const {
    order,
    type,
    securityModalInfo,
    tradeAmt,
    isLastLook,
    price,
    styles,
    onChangeTradeAmt,
    handleCloseModal,
    handleConfirmButtonClick,
    handleRejectButtonClick,
    orderStatusComp,
    shouldAlertPriceChange,
    selectedAccount,
    setSelectedAccount,
    currency
  } = props

  const [prc, setPrc] = useState(0)
  const [ytw, setYtw] = useState(0)
  const [bmPrice, setBmPrice] = useState<number | undefined>(0)

  useEffect(() => {
    setPrc(price)
    setYtw(securityModalInfo.yieldToWorst)
    setBmPrice(securityModalInfo.benchmarkPrice)
  }, [])

  const serverTimeDelayMs = useSelector(getServerTimeDelayMs)

  const fontClassName =
    type === 'buy' ? styles.buySecondColor : styles.sellSecondColor
  const borderColorBottom =
    type === 'buy' ? styles.buyBorderColorBottom : styles.sellBorderColorBottom

  const isActive = orderStatusComp === 'waitingForConfirmation'

  const rightSideInfoClasses = cx(
    styles.rightInfos,
    orderStatusComp !== 'accepted' && !isActive && isLastLook && styles.timedOut
  )

  const alertValueStyles = cx(
    styles.value,
    shouldAlertPriceChange && styles.warning
  )
  const accountOptions = Object.values(securityModalInfo.accountIdToName).sort()
  const getRightSideContent = () => {
    if (isLastLook) {
      const timerColors =
        isActive || orderStatusComp === 'accepted' ? '#466992' : '#ffffff'

      const { totalSeconds } = getTotalSecondsBeforeExpiration(
        order.aggressorOrder?.expiration,
        order.aggressorOrder?.submitTime,
        serverTimeDelayMs
      )

      return (
        <>
          <div className={styles.timerWrapper}>
            <TimerLeftComponent
              valueColor={timerColors}
              timerInit={totalSeconds}
              isPlaying={isActive}
              valueSize="big"
              totalSecondsBeforeExpiration={{
                expiration: order.aggressorOrder?.expiration,
                submitTime: order.aggressorOrder?.submitTime,
                serverTimeDelayMs
              }}
            />
          </div>
          <div className={styles.timerActionWrapper}>
            {(orderStatusComp === 'accepted' ||
              orderStatusComp === 'rejected' ||
              orderStatusComp === 'cancelled') && (
              <button
                className={
                  orderStatusComp === 'accepted'
                    ? styles.timerActionCloseButtonAccepted
                    : styles.timerActionCloseButton
                }
                data-testid="lastlook-close"
                onClick={handleCloseModal}
              >
                Close
              </button>
            )}
            {orderStatusComp === 'waitingForConfirmation' && (
              <>
                <button
                  data-testid="lastlook-reject"
                  className={styles.timerActionRejectButton}
                  onClick={handleRejectButtonClick}
                >
                  Reject
                </button>
                <button
                  data-testid="lastlook-confirm"
                  className={styles.timerActionMainButton}
                  onClick={
                    handleConfirmButtonClick &&
                    handleConfirmButtonClick(Number(tradeAmt))
                  }
                >
                  CONFIRM
                </button>
              </>
            )}
          </div>
        </>
      )
    } else {
      return (
        <div className={styles.allInPrice}>
          <p>All-in Price</p>
          <p data-testid="aggressor-all-in-price" className={alertValueStyles}>
            {formatCurrency(securityModalInfo.allInPrice, currency)}{' '}
          </p>
        </div>
      )
    }
  }
  return (
    <div
      className={cx(
        styles.content,
        setSelectedAccount ? styles.aggressorContent : styles.lastLookContent
      )}
    >
      <div className={styles.leftInfos}>
        <div className={styles.firstLine}>
          <div className={styles.inputContent}>
            <label>Trade Amt</label>
            {isLastLook ? (
              <p data-testid="lastlook-amt" className={styles.value}>
                {tradeAmt}
              </p>
            ) : (
              <>
                <input
                  value={tradeAmt}
                  data-testid="aggressor-trade-amt"
                  onChange={onChangeTradeAmt}
                  disabled={
                    orderStatusComp !== 'creationPending' &&
                    orderStatusComp !== 'cancelled' &&
                    orderStatusComp !== 'error'
                  }
                  type="number"
                  autoFocus
                  className={`${fontClassName} ${borderColorBottom} `}
                />
                <span className={styles.minQty}>
                  Minimum Qty:{' '}
                  <span data-testid="aggressor-minimumquantity">
                    {securityModalInfo.minimumSize}{' '}
                  </span>
                </span>
              </>
            )}
          </div>
          <div className={styles.inputContent}>
            <p>Spread</p>
            <p className={styles.value}>{securityModalInfo.spread}</p>
          </div>
        </div>
        {selectedAccount && setSelectedAccount && (
          <div className={styles.accountLine}>
            Account:
            <GenericDropdownMenu
              selectedItem={selectedAccount}
              setSelectedItem={setSelectedAccount}
              options={accountOptions}
              selectId={''}
              openRight={true}
              className={''}
              dropDownClassname={'dropdownMenuAggressor'}
            />
          </div>
        )}
        <div className={styles.secondLine}>
          <div className={styles.inputContent}>
            <p>Available Amt</p>
            <p data-testid="aggressor-amt" className={styles.value}>
              {securityModalInfo.maximumSize}
            </p>
          </div>
          <div className={styles.inputContent}>
            <p>Price</p>
            <p data-testid="aggressor-price" className={alertValueStyles}>
              {formatCurrency(prc, currency)}
            </p>
          </div>
        </div>

        <div className={styles.thirdLine}>
          <div className={styles.inputContent}>
            <p>Bench Price</p>
            <p data-testid="aggressor-bench-price" className={styles.value}>
              {bmPrice}
            </p>
          </div>
          <div className={styles.inputContent}>
            <p>YTW</p>
            <p data-testid="aggressor-YTW" className={alertValueStyles}>
              {ytw}
            </p>
          </div>
        </div>

        <div className={styles.fourthLine}>
          <div className={styles.inputContent}>
            <p>Hedge Notional</p>
            <p data-testid="aggressor-hedge-notional" className={styles.value}>
              {securityModalInfo.hedgeNotional}
            </p>
          </div>
        </div>
      </div>
      <div className={rightSideInfoClasses}>{getRightSideContent()}</div>
    </div>
  )
}

export default LastLookModalContent
