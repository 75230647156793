import React, { FC } from 'react'
import styles from './ComponentHeader.scss'

export interface Props {
  title: string
}

const ComponentHeader: FC<Props> = ({ title }) => {
  return (
    <header className={styles.header}>
      <h2>{title}</h2>
    </header>
  )
}

export default ComponentHeader
