import React from 'react'
import { RadioProps } from '.'

const Checkbox: React.FC<RadioProps> = ({ className, fillColor }) => (
  <span className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 14 14"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={fillColor}
          d="M7 14c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7z"
        />
        <circle cx="7" cy="7" r="3" fill="#FFF" />
      </g>
    </svg>
  </span>
)

export default Checkbox
