import { Action } from 'redux'
import { WebSettings } from './types'

export interface SetWebSettingsAction extends Action {
  type: 'webSettings.setWebSettings'
  payload: {
    settings: WebSettings
  }
}

export const setWebSettings = (
  settings: WebSettings
): SetWebSettingsAction => ({
  type: 'webSettings.setWebSettings',
  payload: { settings }
})

export type WebSettingsAction = SetWebSettingsAction
