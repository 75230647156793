import { ColumnApi, GetRowIdFunc, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useOpenFin } from '../../../../app/openFinContext'
import { getUserDashboard } from '../../../../store/admin/userDashboard/selectors'
import { UserDashboardEntry } from '../../../../store/admin/userDashboard/types'
import { columnDefinitions } from './columnDefs'

const defaultColumnDefinitions = {
  flex: 1,
  resizable: true,
  menuTabs: ['filterMenuTab' as const, 'columnsMenuTab' as const],
  filterParams: {
    buttons: ['clear']
  },
  sortable: true,
  suppressMovable: false,
  wrapText: true
}

const getRowId: GetRowIdFunc<UserDashboardEntry> = ({ data }) => {
  return `${data.id}`
}

const UserDashboardGrid: FC = () => {
  const { fin } = useOpenFin()
  const userDashboard = useSelector(getUserDashboard)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const columnState = localStorage.getItem('columnState')
        if (columnState) {
          const parsedState = JSON.parse(columnState)
          columnApi.applyColumnState(parsedState)
        }
      }
    },
    []
  )
  useEffect(() => {
    if (fin) {
      defaultColumnDefinitions.suppressMovable = true
    }
  }, [])

  return (
    <div className="ag-theme-balham activity-log">
      <AgGridReact
        rowData={userDashboard}
        columnDefs={columnDefinitions}
        overlayNoRowsTemplate="No users connected."
        overlayLoadingTemplate="Loading Active User Dashboard…"
        alwaysShowVerticalScroll={true}
        suppressDragLeaveHidesColumns={true}
        defaultColDef={defaultColumnDefinitions}
        rowClass="tiers-grid-row"
        onGridReady={onGridReady}
        rowSelection={'single'}
        groupIncludeTotalFooter={true}
        getRowId={getRowId}
        rowHeight={50}
      />
    </div>
  )
}

export default UserDashboardGrid
