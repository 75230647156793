import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { faExternalLinkAlt } from '@fortawesome/pro-duotone-svg-icons'
import {
  faCompressAlt,
  faTimes,
  faWindowMinimize
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import ReactTooltip from 'react-tooltip'

import Card from '../../components/Card/Card'
import ModalFooter from '../../components/Modal/ModalFooter/ModalFooter'
import MyOrdersFooter from '../../components/MyOrdersFooter/MyOrdersFooter'

import { useOpenFin } from '../../app/openFinContext'
import {
  closeAllPopouts,
  closePopout,
  newSubscription,
  popoutSecurity,
  removeSubscription
} from '../../store/depthOfMarket/actions'
import {
  getRows,
  getSelectedSecurityId
} from '../../store/depthOfMarket/selectors'
import { getColumnsOrder } from '../../store/grid/selectors'
import { setValidationOpen } from '../../store/order/actions'
import {
  getErrorForOrder,
  getLastErrorDateForDisplayedOrders,
  getSelectedValidation,
  getValidationOpen
} from '../../store/order/selectors'
import {
  getCurrentPage,
  getQuoteReliability,
  getSecuritiesForPage,
  getSecurityOrderDataById,
  getSecurityStaticDataById
} from '../../store/securities/selectors'
import { getIsAdmin } from '../../store/webSettings/selectors'
import { customizableColumnsSpec } from '../BondList/columnDefs'
import { IsInPopupContext } from '../popoutContext'
import styles from './depthOfMarket.scss'
import SecurityDetailsModal from './DetailsModal/SecurityDetailsModal'
import ErrorComponent from './Error'
import SingleError from './Error/SingleError'
import StaticForm from './Form'
import Table from './Table'
import Title from './Title'

interface Props {
  gridIndex: number
  securityId: number
  popout?: boolean
  isOpen?: boolean
}

interface ContainerProps {
  gridIndex: number
  isOpen: boolean
}

const DepthOfMarket: React.FC<Props> = ({
  gridIndex,
  popout = false,
  securityId,
  isOpen
}) => {
  const { fin, manageWindows } = useOpenFin()
  const dispatch = useDispatch()
  const securityOrderData = useSelector(getSecurityOrderDataById)(securityId)
  const securityStaticData = useSelector(getSecurityStaticDataById)(securityId)
  const rows = useSelector(getRows)(gridIndex, securityId)
  const isAdmin = useSelector(getIsAdmin)
  const lastErrorDateForDisplayedOrders = useSelector(
    getLastErrorDateForDisplayedOrders
  )(gridIndex)
  const validationOpen = useSelector(getValidationOpen)(gridIndex)
  const validation = useSelector(getSelectedValidation)(gridIndex)
  /* const errorsForDate = useSelector(getErrorsForDate)(
    gridIndex,
    lastErrorDateForDisplayedOrders
  )*/
  const [replaceSearch, setReplaceSearch] = useState(false)
  const [error, setDepthError] = useState('')
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const displayedColumns =
    useSelector(getColumnsOrder)(gridIndex) ||
    customizableColumnsSpec
      .filter((column) => column.selectedByDefault)
      .map((c) => c.id)

  const getServerError = useSelector(getErrorForOrder)

  const quoteReliability = useSelector(getQuoteReliability)(gridIndex)

  const onPopout = useCallback(() => {
    if (securityStaticData) {
      dispatch(popoutSecurity(securityStaticData.id))
      if (fin) {
        manageWindows(`Depth-${securityId}`, 'show')
      }
    }
  }, [securityStaticData])

  const setOpen = (open: boolean) => {
    dispatch(setValidationOpen(open))
  }

  const onClose = useCallback(() => {
    if (securityStaticData) {
      dispatch(closePopout(securityStaticData.id))
      if (fin) {
        manageWindows(`Depth-${securityId}`, 'quit')
      }
    }
  }, [securityStaticData])

  useEffect(() => {
    dispatch(
      // subscribeToDepthOfMarket(gridIndex, securityId, popout, quoteReliability)
      newSubscription(gridIndex, securityId, quoteReliability, popout)
    )
    return () => {
      // dispatch(unsubscribeFromDepthOfMarket(securityId, popout))
      dispatch(removeSubscription(gridIndex, securityId, popout))
    }
  }, [securityId, quoteReliability, popout])

  if (!securityStaticData || !securityOrderData) {
    return null
  }

  const bidSideError = getServerError(securityStaticData.id, 'buy')
  const offerSideError = getServerError(securityStaticData.id, 'sell')

  const errorText = !isEmpty(error)
    ? error
    : !isEmpty(bidSideError)
    ? bidSideError!
    : !isEmpty(offerSideError)
    ? offerSideError!
    : ''

  const closeAll = () => {
    dispatch(closeAllPopouts())
    manageWindows(`Depth`, 'quit')
  }

  const showDepthIcons = () => {
    // TODO: something about openfin prevents tooltips from showing in child windows
    if (fin) {
      return (
        <>
          <div
            onClick={onMinimizeClick}
            data-tip="Minimize All Windows"
            data-for="minimizeAll"
          >
            <FontAwesomeIcon icon={faWindowMinimize} color={'#656c78'} />
          </div>
          <ReactTooltip
            id="minimizeAll"
            backgroundColor="#e9edf1"
            textColor="#656C78"
            effect="solid"
            className="iconTooltip"
          >
            Minimize All Windows
          </ReactTooltip>
          <FontAwesomeIcon
            icon={faCompressAlt}
            onClick={closeAll}
            className="pointer"
            color={'#656c78'}
            style={{ margin: '0 1rem' }}
            data-tip="Close all depths"
            data-for="closeDepths"
          />
          <ReactTooltip
            id="closeDepths"
            backgroundColor="#e9edf1"
            textColor="#656C78"
            effect="solid"
            className="iconTooltip"
          >
            Close All Depths
          </ReactTooltip>
        </>
      )
    }
  }

  const onMinimizeClick = () => {
    manageWindows(`Depth-${securityId}`, 'minimize')
  }

  return (
    <IsInPopupContext.Provider value={popout}>
      <Card style={popout ? 'static' : 'dynamic'}>
        <div
          className={cx(
            fin ? styles.finContent : styles.depthContent,
            isOpen ? styles.rightBorderAdjust : ''
          )}
        >
          <div
            className={cx(styles.container, isAdmin && styles.containerPopout)}
          >
            <ErrorComponent error={errorText} />
            <div
              className={cx(
                styles.securityTitle,
                popout && styles.popoutSecurityTitle,
                isAdmin && styles.popoutSecurityTitle
              )}
            >
              <Title
                issuer={securityStaticData.issuerSymbol}
                coupon={securityStaticData.coupon}
                maturityDate={securityStaticData.maturityDate}
                isin={securityStaticData.isin}
                cusip={securityStaticData.cusip}
                benchmarkName={securityStaticData.benchmarkName}
                bidBenchmarkPrice={securityStaticData.bidBenchmarkPrice}
                offerBenchmarkPrice={securityStaticData.offerBenchmarkPrice}
                isPopout={popout}
                setReplaceSearch={setReplaceSearch}
                replaceSearch={replaceSearch}
                securityId={securityId}
              />
              {isAdmin && !popout && (
                <React.Fragment>
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    onClick={onPopout}
                    color={'#656c78'}
                    className={cx('pointer', styles.title)}
                    data-tip="Popout Depth for this Security"
                    data-for="popoutDepth"
                  />
                  <ReactTooltip
                    id="popoutDepth"
                    backgroundColor="#e9edf1"
                    textColor="#656C78"
                    effect="solid"
                    className="iconTooltip"
                  >
                    Popout Depth for this Security
                  </ReactTooltip>
                </React.Fragment>
              )}
              <div
                className={replaceSearch ? styles.replaceSearch : ''}
                onClick={() => setReplaceSearch(false)}
              />
              {popout && (
                <span className={fin && styles.flex}>
                  {showDepthIcons()}
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={onClose}
                    color={'#656c78'}
                    className="pointer"
                  />
                </span>
              )}
            </div>
            <StaticForm
              security={securityStaticData}
              err={errorText}
              setError={setDepthError}
              displayedColumns={displayedColumns}
              isAdmin={isAdmin}
            />
            {!isAdmin && !popout && (
              <React.Fragment>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  onClick={onPopout}
                  color={'#656c78'}
                  className={cx('pointer', styles.title)}
                  data-tip="Popout Depth for this Security"
                  data-for="popoutDepth"
                />
                <ReactTooltip
                  id="popoutDepth"
                  backgroundColor="#e9edf1"
                  textColor="#656C78"
                  effect="solid"
                  className="iconTooltip"
                >
                  Popout Depth for this Security
                </ReactTooltip>
              </React.Fragment>
            )}
          </div>
          <div className={styles.tableContainer}>
            {!popout && !isAdmin && (
              <div className={styles.mainTitle}>Market Depth</div>
            )}
            <Table
              isPopout={popout}
              rows={rows}
              security={securityOrderData}
              displayedColumns={displayedColumns}
              isAdmin={isAdmin}
              currency={securityStaticData?.currency ?? ''}
            />
          </div>
        </div>
        {isOpen && (
          <div className={styles.expandable}>
            <ErrorComponent
              key={String(lastErrorDateForDisplayedOrders)}
              error={
                lastErrorDateForDisplayedOrders !== undefined
                  ? 'Some orders were not submitted.'
                  : ''
              }
              autoDismissDelay={3000}
            />
            {validation && (
              <SingleError
                securityId={validation.securityId}
                orderType={validation.orderType}
                open={validationOpen}
                setOpen={setOpen}
              />
            )}
            {/* FIXME: We shouldn’t need to include this in depth of market component */}
            {!popout && isOpen && <MyOrdersFooter />}
          </div>
        )}
        {detailsModalOpen && (
          <SecurityDetailsModal
            security={securityStaticData}
            isOpen={detailsModalOpen}
            toggleIsOpen={() => setDetailsModalOpen(!detailsModalOpen)}
          />
        )}
        {fin && popout && <ModalFooter dataTestId="depth" />}
      </Card>
    </IsInPopupContext.Provider>
  )
}

const isEmpty = (str: string | undefined) => {
  return !str || 0 === str.length
}

export const DepthOfMarketContainer: React.FC<ContainerProps> = ({
  gridIndex,
  isOpen
}) => {
  // when the grid loads with the "no security found" message and resubmit orders is applied,
  // we need to check the first element in the securities table
  // so that DOM is populated and user can hit clear/submit buttons
  const securityId = useSelector(getSelectedSecurityId)(gridIndex)
  const currentPage = useSelector(getCurrentPage)(gridIndex)
  const securitiesForPage = useSelector(getSecuritiesForPage)
  const security =
    currentPage !== undefined ? securitiesForPage(gridIndex, currentPage) : []
  if (!securityId && !security[0]?.id) {
    return null
  }
  return (
    <DepthOfMarket
      gridIndex={gridIndex}
      isOpen={isOpen}
      securityId={securityId || security[0].id}
    />
  )
}

export default DepthOfMarket
