import { Action } from 'redux'

export interface RejectOrderAction extends Action {
  type: 'lastLook.rejectOrder'
  payload: string
}

export const rejectOrder = (payload: string): RejectOrderAction => ({
  type: 'lastLook.rejectOrder',
  payload
})

export interface ConfirmOrderAction extends Action {
  type: 'lastLook.confirmOrder'
  payload: { id: string; canCross: boolean }
}

export const confirmOrder = (
  id: string,
  canCross: boolean
): ConfirmOrderAction => ({
  type: 'lastLook.confirmOrder',
  payload: { id, canCross }
})

export interface RejectOrderFailureAction extends Action {
  type: 'lastLook.rejectOrderFailure'
  error: true
  payload: number
}

export const rejectOrderFailure = (
  payload: number
): RejectOrderFailureAction => ({
  type: 'lastLook.rejectOrderFailure',
  error: true,
  payload
})

export interface ConfirmOrderFailureAction extends Action {
  type: 'lastLook.confirmOrderFailure'
  error: true
  payload: number
}

export const confirmOrderFailure = (
  payload: number
): ConfirmOrderFailureAction => ({
  type: 'lastLook.confirmOrderFailure',
  error: true,
  payload
})

export type LastLookAction =
  | ConfirmOrderAction
  | ConfirmOrderFailureAction
  | RejectOrderAction
  | RejectOrderFailureAction
