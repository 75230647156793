import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import { systemParamFetch } from '../../../store/admin/sysparam/actions'
import SystemParametersGrid from './Grid/SystemParametersGrid'
import SystemParameterModal from './SystemParameterModal'
import styles from './SystemParameters.scss'

const SystemParameters: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState<number>(0)
  const [displayModal, toggleModal] = useState(false)
  const fetchParameters = useCallback(() => {
    dispatch(systemParamFetch())
  }, [])
  useEffect(() => {
    fetchParameters()
  }, [])

  const gridDoubleClick = (row: any) => {
    handleShowModal(row.data.id)
  }

  const handleShowModal = useCallback(
    (row: number) => {
      setSelectedRow(row)
      toggleModal(!displayModal)
    },
    [displayModal, selectedRow]
  )

  return (
    <React.Fragment>
      <div
        className={fin ? styles.finParamsWrapper : styles.systemParamWrapper}
      >
        <button onClick={() => handleShowModal(0)}>New</button>
        <button onClick={fetchParameters}>Refresh</button>
        <SystemParametersGrid onDoubleClick={gridDoubleClick} />
      </div>
      {displayModal && (
        <SystemParameterModal
          paramId={selectedRow}
          displayModal={displayModal}
          closeFunc={() => handleShowModal(0)}
        />
      )}
    </React.Fragment>
  )
}

export default SystemParameters
