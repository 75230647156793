import { SetWebSettingsAction } from './actions'
import { WebSettings } from './types'

export interface State {
  settings: WebSettings
}

const initialState: State = {
  settings: {
    isLoading: true,
    maxGrids: 5,
    showTsy: false,
    showTOB: false,
    benchmarkRefresh: 5000,
    isAdmin: false,
    canEditTiers: false,
    canSeeSystemParamEditor: false,
    canMarkRestricted: false,
    canForceCancel: false
  }
}

export default (state = initialState, action: SetWebSettingsAction): State => {
  switch (action.type) {
    case 'webSettings.setWebSettings':
      return {
        settings: { ...action.payload.settings, isLoading: false }
      }
    default:
      return state
  }
}
