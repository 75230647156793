import cx from 'classnames'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getOrderValidations } from '../../../store/order/selectors'
import { OrderType } from '../../../store/order/types'
import { getSecurityStaticDataById } from '../../../store/securities/selectors'
import styles from './error.scss'

interface Props {
  securityId: number
  orderType: OrderType
  open: boolean
  setOpen: (b: boolean) => void
}

const SingleError: React.FC<Props> = ({
  securityId,
  orderType,
  open,
  setOpen
}) => {
  const security = useSelector(getSecurityStaticDataById)(securityId)
  const allValidations = useSelector(getOrderValidations)
  const thisValidationArr = allValidations.filter(
    (v) => v.securityId === securityId && v.orderType === orderType
  )
  useEffect(() => {
    if (open) {
      const validationExists = allValidations.filter(
        (v) => v.orderType === orderType && v.securityId === securityId
      )
      if (validationExists.length === 0) {
        setOpen(false)
      }
    }
  }, [allValidations])
  const dismiss = useCallback(() => {
    setOpen(false)
  }, [])
  if (thisValidationArr.length === 0) {
    return <> </>
  }
  const thisValidation = thisValidationArr[0]
  let errorMessage
  if (security) {
    const prefix =
      (security.cusip && security.cusip.length > 0)
        ? security.cusip
        : security.isin
    errorMessage =
      prefix +
      ' ' +
      (orderType === 'buy' ? 'Bid' : 'Offer') +
      ': ' +
      thisValidation.error
  }

  const hidden = !securityId || !open

  return (
    <div
      className={cx(
        styles.singleError,
        hidden ? styles.hidden : styles.displayed
      )}
    >
      {!hidden && (
        <>
          <span className={styles.leftWrapper}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span
              data-testid="marketdepth-errormessage"
              className={styles.errorText}
            >
              {errorMessage}
            </span>
          </span>
          <FontAwesomeIcon
            icon={faTimesCircle}
            data-testid="marketdepth-closeerror"
            onClick={dismiss}
          />
        </>
      )}
    </div>
  )
}

export default SingleError
