import {
  cancelOrder,
  CancelOrderAction,
  createOrder,
  CreateOrderAction
} from '../../../store/order/actions'
import { Order, OrderType, TobParameters } from '../../../store/order/types'
import { Security } from '../../../store/securities/reducer'

export const DEFAULT_ORDER_SIZE = 100

export interface ErrMsg {
  orderType: OrderType
  errMsg: string
}

interface UpdateOrCancelOrderResult {
  action: CancelOrderAction | CreateOrderAction | undefined
  price: string
  size: string
  individualMin: string
}

export const updateOrCancelOrder = (
  security: Security,
  existingOrder: Order | undefined,
  orderType: OrderType,
  price: number | undefined,
  isSpread: boolean,
  size: number,
  allOrNone: boolean,
  individualMin: number,
  book: number,
  tob: TobParameters
): UpdateOrCancelOrderResult => {
  if (!isNaN(size)) {
    size = size || security.defaultOrderSize || DEFAULT_ORDER_SIZE
  }
  if ((!isSpread && price === 0) || price === undefined) {
    return {
      action: existingOrder ? cancelOrder(existingOrder.id) : undefined,
      price: '',
      size: '',
      individualMin: ''
    }
  }
  const custId = existingOrder ? existingOrder.custId : book
  if (
    (!isSpread && existingOrder?.price !== price) ||
    (isSpread && existingOrder?.spread !== price) ||
    existingOrder?.size !== size ||
    allOrNone !== existingOrder?.allOrNone ||
    individualMin !== existingOrder?.individualMin
  ) {
    return {
      action: createOrder(
        security.id,
        orderType,
        price,
        isSpread,
        size,
        allOrNone,
        individualMin,
        custId,
        tob
      ),
      price: String(price),
      size: String(size),
      individualMin: String(individualMin)
    }
  }
  return {
    action: undefined,
    price: String(price),
    size: String(size),
    individualMin: String(individualMin)
  }
}
