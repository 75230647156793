import { Order } from '../order/types'
import { Security } from '../securities/reducer'
import { AddOrUpdateDepthOfMarketAction } from './actions'
import { DepthOfMarketRow } from './types'

type DepthOfMarketKeys = Record<keyof Order, keyof DepthOfMarketRow>
type DepthOfMarketRowKeys = Pick<
  DepthOfMarketKeys,
  | 'canAggress'
  | 'size'
  | 'price'
  | 'displayPrice'
  | 'spread'
  | 'id'
  | 'qr'
  | 'myFirm'
  | 'allOrNone'
  | 'displayName'
  | 'isSpreadOrder'
  | 'status'
>

export function depthOfMarketRowData(data: Order[]): DepthOfMarketRow[] {
  const buys = [...data]
    .filter(
      (item) =>
        (item.status === 'pending' || item.status === 'notAvailable') &&
        item.type === 'buy'
    )
    .sort((a: Order, b: Order) => b.markedPrice - a.markedPrice)

  const sells = [...data]
    .filter(
      (item) =>
        (item.status === 'pending' || item.status === 'notAvailable') &&
        item.type === 'sell'
    )
    .sort((a: Order, b: Order) => a.markedPrice - b.markedPrice)

  const rows = [...buys, ...sells]

  return rows.reduce((obj: DepthOfMarketRow[], item: Order) => {
    const keys: DepthOfMarketRowKeys =
      item.type === 'sell'
        ? {
            canAggress: 'offerAggress',
            size: 'offerSize',
            price: 'offerPrice',
            displayPrice: 'offerDisplayPrice',
            spread: 'offerSpread',
            id: 'offerId',
            qr: 'offerQr',
            myFirm: 'offerIsMyFirm',
            allOrNone: 'offerAon',
            displayName: 'offerDisplayName',
            isSpreadOrder: 'offerIsSpread',
            status: 'offerStatus'
          }
        : {
            canAggress: 'bidAggress',
            size: 'bidSize',
            price: 'bidPrice',
            displayPrice: 'bidDisplayPrice',
            spread: 'bidSpread',
            id: 'bidId',
            qr: 'bidQr',
            myFirm: 'bidIsMyFirm',
            allOrNone: 'bidAon',
            displayName: 'bidDisplayName',
            isSpreadOrder: 'bidIsSpread',
            status: 'bidStatus'
          }
    const idx = obj.filter((t: DepthOfMarketRow) => Boolean(t[keys.price]))
      .length

    const newItem = Object.keys(keys).reduce(
      (o: any, key: keyof DepthOfMarketRowKeys) => ({
        ...o,
        [keys[key]]: item[key]
      }),
      {}
    )

    obj[idx] = { ...obj[idx], ...newItem }
    return obj
  }, [])
}

export const updateDepthOfMarketOrderState = (
  oldOrderState: Record<number, Order[]> | undefined,
  action: AddOrUpdateDepthOfMarketAction
): Record<number, Order[]> => {
  const newState: Record<number, Order[]> = oldOrderState
    ? { ...oldOrderState }
    : {}
  newState[action.payload.securityId] = action.payload.orders
  return newState
}

export const resetDepthForGridIndex = (
  stateRecords: Record<
    number,
    {
      securityId: Security['id'] | undefined
      error: boolean
      orders: Order[]
      hoveredOrders: Order[]
    }
  >,
  gridIndex: number
) => {
  const newRecords = { ...stateRecords }
  delete newRecords[gridIndex]
  return newRecords
}

export const setGridDOMs = (
  payloadRecord: Record<number, Order[]>,
  stateRecord: Record<
    number,
    {
      securityId: Security['id'] | undefined
      error: boolean
      orders: Order[]
      hoveredOrders: Order[]
    }
  >
) => {
  const newRecords: Record<
    number,
    {
      securityId: Security['id'] | undefined
      error: boolean
      orders: Order[]
      hoveredOrders: Order[]
    }
  > = {}
  for (const r in payloadRecord) {
    if (payloadRecord.hasOwnProperty(r)) {
      newRecords[r] = { ...stateRecord[r], orders: payloadRecord[r] }
    }
  }
  return newRecords
}
