import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../components/Checkbox'
import { checkOrUncheckAllSecurities } from '../../store/watchList/actions'
import {
  getCheckedSecuritiesStatus,
  getDetailsForCurrentWatchlist
} from '../../store/watchList/selectors'

export interface Props {
  gridIndex: number
}

const HeaderSecurityCheckbox = (gridIndex: number) => () => {
  const dispatch = useDispatch()
  const watchlist = useSelector(getDetailsForCurrentWatchlist)(gridIndex)
  const getStatus = useSelector(getCheckedSecuritiesStatus)

  const onChange = useCallback(() => {
    dispatch(checkOrUncheckAllSecurities(gridIndex))
  }, [])
  const status = watchlist && getStatus(watchlist.id!)

  return (
    <Checkbox
      locator="checkbox-all-securities"
      onChange={onChange}
      fillColor="#43A980"
      checked={status === 'all'}
      indeterminate={status === 'some'}
    />
  )
}

HeaderSecurityCheckbox.displayName = 'HeaderSecurityCheckbox'

export default HeaderSecurityCheckbox
