import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useClickAway, useDebounce } from 'react-use'
import styles from '../SearchBondsInput/SearchBondsInput.scss'
import { IssuerSecurities } from '../SearchBondsInput/selectors'
import SearchInput from '../SearchInput/SearchInput'
import GenericBondsSearchDropdown from './GenericBondsSearchDropdown'

interface Props {
  onSearchChanged: (searchTerm: string) => void
  onClear: () => void
  onSearchCommit?: (search: string) => void
  search?: string
  formattedSearch?: string
  onIssuerAddAllClicked?: (issuerId: string) => void
  onIssuerShowAllClicked?: (issuerId: string) => void
  onSecurityClicked?: (securityId: number, isInWatchList: boolean) => void
  watchListResults?: IssuerSecurities[]
  notInWatchListResults?: IssuerSecurities[]
  showLiveChecked?: boolean
  menuStyle?: string
}

// regular expressions are expensive to make, just create it once
const fixSearchRegEx = /[^a-zA-Z0-9\s./]/g

const fixSearchString = (search: string) => {
  // we could be reusing this regular expression, so reset it
  fixSearchRegEx.lastIndex = 0
  return search.replace(fixSearchRegEx, '')
}

const GenericBondsSearch = ({
  onSearchChanged,
  search = '',
  formattedSearch = '',
  onSearchCommit,
  onClear,
  onIssuerAddAllClicked,
  onIssuerShowAllClicked,
  onSecurityClicked,
  watchListResults,
  notInWatchListResults,
  showLiveChecked,
  menuStyle
}: Props) => {
  const [pendingSearchTerm, setPendingSearchTerm] = useState(search)
  const [showDropdown, setShowDropdown] = useState(false)
  const [compareToFormatted, setCompareToFormatted] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const handleSearchChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const searchInputValue = e.target.value
      setPendingSearchTerm(fixSearchString(searchInputValue))
      setShowDropdown(searchInputValue.length > 0)
      setCompareToFormatted(false)
    },
    []
  )

  const [, cancelDebounce] = useDebounce(
    () => {
      if (pendingSearchTerm !== search) {
        onSearchChanged(pendingSearchTerm)
      }
    },
    300,
    [search, pendingSearchTerm, onSearchChanged]
  )
  useEffect(() => {
    if (!formattedSearch) return
    cancelDebounce()
    setCompareToFormatted(true)
  }, [formattedSearch])

  useClickAway(ref, () => {
    setShowDropdown(false)
  })

  const handleFocus = useCallback(() => {
    if (search) setShowDropdown(true)
  }, [search])

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSearchCommit && onSearchCommit(pendingSearchTerm)
        setShowDropdown(false)
      }
    },
    [onSearchCommit, pendingSearchTerm]
  )

  const handleClear = useCallback(() => {
    onClear()
    setShowDropdown(false)
    setPendingSearchTerm('')
  }, [onClear])

  const handleSecurityClicked = useCallback(
    (issuer: string, security: number) => {
      // TODO: since we care about this, we should be providing it on props to the drop down
      const isInWatchList = !issuer.startsWith('not-in-watchlist-')
      onSecurityClicked && onSecurityClicked(security, isInWatchList)
      if (!isInWatchList) setShowDropdown(false)
    },
    [onSecurityClicked]
  )

  const handleOnIssuerShowAllClicked = useCallback(
    (issuer: string) => {
      onIssuerShowAllClicked && onIssuerShowAllClicked(issuer)
      setShowDropdown(false)
    },
    [onIssuerShowAllClicked]
  )
  const handleOnIssuerAddAllClicked = useCallback(
    (issuer: string) => {
      onIssuerAddAllClicked && onIssuerAddAllClicked(issuer)
      setShowDropdown(false)
    },
    [onIssuerAddAllClicked]
  )

  // ---------------------------------------------------------------------------------------------------------------- //

  return (
    <div ref={ref} className={styles.dropdown}>
      <SearchInput
        onChange={handleSearchChanged}
        value={compareToFormatted ? formattedSearch : pendingSearchTerm}
        className={styles.searchBondsInput}
        placeholder="Search Bonds"
        onFocus={handleFocus}
        onKeyUp={onKeyUp}
        onClear={handleClear}
      />
      {showDropdown && (
        <GenericBondsSearchDropdown
          isShowLiveChecked={showLiveChecked ?? false}
          searchString={search}
          watchListResults={watchListResults}
          notInWatchListResults={notInWatchListResults}
          onIssuerShowAllClicked={handleOnIssuerShowAllClicked}
          onIssuerAddAllClicked={handleOnIssuerAddAllClicked}
          onSecurityClicked={handleSecurityClicked}
          menuStyle={menuStyle}
        />
      )}
    </div>
  )
}

export default GenericBondsSearch
