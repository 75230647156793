import cx from 'classnames'
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetRowIdFunc,
  GridApi
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import moment from 'moment'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../../../app/openFinContext'
import {
  getExternalOrderStats,
  getExternalOrderStatsError,
  getExternalOrderStatsPending
} from '../../../../store/admin/externalOrderStats/selectors'
import { ExternalOrderStatsTableRow } from '../../../../store/admin/externalOrderStats/types'
import { externalOrderColumnModelChanged } from '../../../../store/settings/actions'
import { getExternalOrderColumnOrder } from '../../../../store/settings/selectors'
import SearchInput from '../../../SearchInput/SearchInput'
import { applyColumnsOrder } from '../../helpers'
import styles from '../../HistoricalUserData/HistoricalData.scss'
import { columnDefinitions } from './columnDefs'

export const defaultColumnDefinitions = {
  resizable: true,
  filter: true,
  filterParams: {
    buttons: ['clear']
  },
  sortable: true,
  suppressMovable: false
}

const getRowId: GetRowIdFunc<
  ExternalOrderStatsTableRow & { id: string | number }
> = ({ data }) => {
  return `${data.id}`
}
const ExternalOrderStatsGrid: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const pending = useSelector(getExternalOrderStatsPending)
  const error = useSelector(getExternalOrderStatsError)
  const externalOrderStats = useSelector(getExternalOrderStats)
  const [dataInitialized, setDataInitialized] = useState(false)

  const onDataInitialized = () => {
    setDataInitialized(true)
  }
  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)
  const [externalOrderStatsRowData, setexternalOrderStatsRowData] = useState<
    ExternalOrderStatsTableRow[]
  >([])

  const columnsOrder = useSelector(getExternalOrderColumnOrder)
  const [columnDefs, setColumnDefs] = useState<
    ColDef[] | ColGroupDef[] | undefined
  >(undefined)

  useEffect(() => {
    if (fin) {
      defaultColumnDefinitions.suppressMovable = true
    }
  }, [])

  //
  // Set Initial Columns Def
  useEffect(() => {
    setColumnDefs(applyColumnsOrder(columnsOrder, columnDefinitions))
  }, [columnsOrder?.join(',')])

  useEffect(() => {
    if (externalOrderStats) {
      setexternalOrderStatsRowData(externalOrderStats.rows)
    } else {
      setexternalOrderStatsRowData([])
    }
  }, [externalOrderStats])

  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
    },
    []
  )

  useEffect(() => {
    if (gridApi) {
      if (pending) {
        gridApi.api.showLoadingOverlay()
      } else if (error) {
        gridApi.api.showNoRowsOverlay()
      } else {
        if (externalOrderStatsRowData.length === 0) {
          gridApi.api.showNoRowsOverlay()
        } else {
          gridApi.api.hideOverlay()
        }
      }
    }
  }, [pending, error, externalOrderStatsRowData, gridApi])

  const onBtExport = useCallback(() => {
    if (gridApi) {
      const time = moment(new Date())
      gridApi.api.exportDataAsCsv({
        fileName: `External Order Data ${time.format('YYYY-MM-DD HH_mm_ss')}`
      })
    }
  }, [gridApi])

  const handleColumnChange = useCallback(() => {
    if (dataInitialized) {
      const displayed = gridApi?.columnApi
        .getAllDisplayedColumns()
        .map((col) => {
          return col.getColId()
        })
      if (displayed) {
        dispatch(externalOrderColumnModelChanged(displayed))
      }
    }
  }, [dataInitialized])

  return (
    <div className={styles.dataGridWrapper}>
      <div className={styles.dataSearchWrapper}>
        {!pending && !error && (
          <SearchInput
            value={search}
            onChange={handleSearchChange}
            onClear={onClear}
            placeholder="Filter data"
            className="tiers-search"
          />
        )}
      </div>
      {!pending && !error && externalOrderStats && (
        <button
          className={styles.exportBtn}
          onClick={onBtExport}
          style={{
            marginBottom: '5px',
            fontWeight: 'bold'
          }}
        >
          Export to Csv
        </button>
      )}
      <div
        className={cx(
          'ag-theme-balham activity-log',
          fin ? styles.finWrapper : styles.tickerWrapper
        )}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={externalOrderStatsRowData}
          quickFilterText={search}
          defaultColDef={defaultColumnDefinitions}
          overlayNoRowsTemplate="No user data."
          overlayLoadingTemplate="Loading API user data. This may take up to several minutes depending on server load."
          suppressDragLeaveHidesColumns={true}
          onColumnMoved={handleColumnChange}
          onDisplayedColumnsChanged={handleColumnChange}
          onFirstDataRendered={onDataInitialized}
          alwaysShowVerticalScroll={true}
          onGridReady={onGridReady}
          rowClass="activity-log-grid-row"
          enableBrowserTooltips={true}
          rowSelection={'single'}
          groupIncludeTotalFooter={true}
          getRowId={getRowId}
        />
      </div>
    </div>
  )
}

export default ExternalOrderStatsGrid
