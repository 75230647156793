export const flatten = (thing: Record<string, any>, prefix = ''): any =>
  Object.entries(thing).reduce((obj: any, [key, value]: any) => {
    if (value && typeof value === 'object' && !(value instanceof Date)) {
      return { ...obj, [key]: value, ...flatten(value, prefix + key) }
    }
    const firstLetter = key[0].toUpperCase()
    const newKey = prefix ? firstLetter + key.slice(1) : key
    obj[prefix + newKey] = value
    return obj
  }, {})

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate()
