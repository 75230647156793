import cx from 'classnames'
import React, { FC, useCallback, useState } from 'react'
import { AlertFilter } from '../../store/alertFilter/types'
import DeleteLarge from '../ActionIcon/deleteLarge'
import Button from '../Button/Button'
import styles from './AlertFilter.scss'

export interface Props {
  onSelect: () => void
  onDelete: () => void
  onMute: (b: boolean) => void
  filter: AlertFilter
  isSelected: boolean
}
const AlertFilterListItem: FC<Props> = ({
  filter,
  onSelect,
  onDelete,
  onMute,
  isSelected
}) => {
  const deleteAlertFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onDelete()
  }

  const muteAlertFilterToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    onMute(filter.muted)
  }
  const [confirmation, setConfirmation] = useState(false)
  const handleCallConfirmation = useCallback(() => {
    setConfirmation(true)
  }, [])

  const unconfirmedDelete = useCallback(() => {
    setConfirmation(false)
  }, [])
  const bellClass = 'fas ' + (filter.muted ? 'fa-bell-slash' : 'fa-bell')
  const title = filter.muted ? 'Unmute Rule' : 'Mute Rule'

  return (
    <div
      className={cx(
        styles.AlertFilterListItem,
        confirmation && styles.handleConfirmation,
        isSelected && styles.selected
      )}
    >
      <span
        className={cx(
          styles.AlertFilterListItemName,
          confirmation && styles.none
        )}
        onClick={onSelect}
      >
        {filter.name}
      </span>
      <span className={confirmation ? styles.ConfirmDelete : styles.none}>
        Are you sure?
      </span>
      <span className={cx(styles.bellContainer, confirmation && styles.none)}>
        <i
          className={cx(bellClass, styles.AlertFilterListItemIcon)}
          onClick={muteAlertFilterToggle}
          title={title}
        />
      </span>
      <span
        className={cx(styles.DeleteIcon, confirmation ? styles.none : '')}
        title={'Delete'}
        onClick={handleCallConfirmation}
      >
        <DeleteLarge />
      </span>
      <span
        className={cx(
          styles.btnGrpDeleteWatchlist,
          !confirmation && styles.none
        )}
      >
        <Button
          data-testid="filter-deleteyes"
          className={styles.deleteWatchlistConfirmation}
          onClick={deleteAlertFilter}
        >
          Yes
        </Button>
        <Button
          data-testid="filter-deleteno"
          className={styles.deleteWatchlistConfirmation}
          onClick={() => unconfirmedDelete()}
        >
          No
        </Button>
      </span>
    </div>
  )
}

export default AlertFilterListItem
