import { ColumnApi, GetRowIdFunc, GridApi } from '@ag-grid-community/core'
import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { AgGridReact } from '@ag-grid-community/react'
import {
  // getHighQueueStats,
  getQueueStats
} from '../../../../store/admin/queueStats/selectors'
import { QueueStats } from '../../../../store/admin/queueStats/types'
import SearchInput from '../../../SearchInput/SearchInput'
import styles from '../QueueStats.scss'
import { columnDefinitions } from './columnDefs'

import { useOpenFin } from '../../../../app/openFinContext'

const getRowId: GetRowIdFunc<QueueStats> = ({ data }) => {
  return `${data.id}`
}
const QueueStatsGrid: FC = () => {
  const { fin } = useOpenFin()
  const rowData = useSelector(getQueueStats)
  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const sortModel = [{ colId: 'queueSize', sort: 'desc' } as const]
        columnApi.applyColumnState({ state: sortModel })
      }
    },
    []
  )

  const rowStyle = (params: any) => {
    if (params.data.isMDQueue && params.data.queueSize > 500000) {
      return { 'background-color': 'red' }
    }
    if (params.data.isMDQueue && params.data.queueSize > 250000) {
      return { 'background-color': 'orange' }
    }
    if (!params.data.isMDQueue && params.data.queueSize > 50000) {
      return { 'background-color': 'red' }
    }
    if (!params.data.isMDQueue && params.data.queueSize > 25000) {
      return { 'background-color': 'orange' }
    }
  }

  return (
    <div className={fin && styles.queueStatsWrapper}>
      <div className={styles.tiersSearchWrapper}>
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          onClear={onClear}
          placeholder="Search Queue Stats"
          className="tiers-search"
        />
      </div>
      <div className="ag-theme-balham tiers">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefinitions}
          quickFilterText={search}
          overlayNoRowsTemplate="No CSRMS with high queue found."
          overlayLoadingTemplate="Loading queue stats"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          onGridReady={onGridReady}
          getRowStyle={rowStyle}
          rowClass="tiers-grid-row"
          rowSelection={'single'}
          getRowId={getRowId}
        />
      </div>
    </div>
  )
}

export default QueueStatsGrid
