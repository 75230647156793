import { ColDef } from '@ag-grid-community/core'
import { diffDateFormatter } from '../../../helpers/formatting'

export const EVENT_TIME = 'eventTime'
export const SECURITY = 'security'
export const DESCRIPTION = 'description'

export const customizableColumns: ColDef[] = [
  {
    colId: EVENT_TIME,
    field: EVENT_TIME,
    headerName: 'Order Time',
    width: 115,
    suppressAutoSize: true,
    sortable: true,
    valueFormatter: diffDateFormatter,
    sort: 'desc'
  },
  {
    colId: SECURITY,
    field: SECURITY,
    headerName: 'Security',
    width: 150,
    suppressAutoSize: true,
    sortable: true,
    resizable: true
  },
  {
    colId: DESCRIPTION,
    field: DESCRIPTION,
    headerName: 'Description',
    width: 450,
    suppressAutoSize: true,
    sortable: true,
    resizable: true
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
