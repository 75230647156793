window.config = {
  api: {
    url: 'http://localhost:55555/mts',
    reconnectDelay: 5000,
    refreshTokenTimeout: 25000
  },
  okta: {
    issuer: 'https://bondspro-dev.oktapreview.com/oauth2/default',
    redirectUri: 'http://localhost:1234/implicit/callback',
    clientId: '0oaizsvf5pILTFgkF1d6',
    deactivate: false
  }
};