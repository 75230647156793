import { ColumnApi, GetRowIdFunc, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useOpenFin } from '../../../../app/openFinContext'
import {
  getSystemParamError,
  getSystemParams
} from '../../../../store/admin/sysparam/selectors'
import { SystemParam } from '../../../../store/admin/sysparam/types'
import SearchInput from '../../../SearchInput/SearchInput'
import styles from '../SystemParameters.scss'
import { columnDefinitions } from './columnDefs'

export const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  menuTabs: [],
  suppressMovable: false
}

interface Props {
  onDoubleClick: (row: any) => void
}

const getRowId: GetRowIdFunc<SystemParam> = ({ data }) => {
  return `${data.id}`
}
const SystemParametersGrid: FC<Props> = (props: Props) => {
  const { fin } = useOpenFin()
  const onDoubleClick = props.onDoubleClick
  const systemParameters = useSelector(getSystemParams)
  const systemParametersError = useSelector(getSystemParamError)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  useEffect(() => {
    if (fin) {
      defaultColumnDefinitions.suppressMovable = true
    }
  }, [])

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const sortModel = [{ colId: 'name', sort: 'desc' } as const]
        columnApi.applyColumnState({ state: sortModel })
      }
    },
    []
  )

  if (systemParametersError) {
    return <div>{systemParametersError}</div>
  }

  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  return (
    <div className={fin && styles.finWrapper}>
      <div className={styles.systemParamSearchWrapper}>
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          onClear={onClear}
          placeholder="Search System Parameters"
          className="tiers-search"
        />
      </div>
      <div className="ag-theme-balham aggress-attempts">
        <AgGridReact
          rowData={systemParameters}
          columnDefs={columnDefinitions}
          overlayNoRowsTemplate="No system parameters found."
          overlayLoadingTemplate="Loading System Parameters…"
          alwaysShowVerticalScroll={true}
          maintainColumnOrder={true}
          defaultColDef={defaultColumnDefinitions}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns={true}
          onRowDoubleClicked={onDoubleClick}
          rowClass="aggress-attempts-grid-row"
          getRowId={getRowId}
          quickFilterText={search}
        />
      </div>
    </div>
  )
}

export default SystemParametersGrid
