import React, { FC, useState } from 'react'
// @ts-ignore
import DateTimePicker from 'react-datetime-picker'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { saveRestrictedTrades } from '../../../store/admin/restrictedTrades/actions'
import { getRestrictedTrade } from '../../../store/admin/restrictedTrades/selectors'
import styles from './RestrictedTrades.scss'

interface Props {
  displayModal: boolean
  closeFunc: () => void
  isPermanent: boolean
}
const RestrictedTradesModal: FC<Props> = (props: Props) => {
  const displayModal = props.displayModal
  const closeFunc = props.closeFunc
  const dispatch = useDispatch()
  const param = useSelector(getRestrictedTrade)(0)
  const [criteria, setCriteria] = useState<string>('')
  const [rationale, setRationale] = useState<string>('')
  const time = new Date().setHours(17, 0)
  const [restrictUntil, setRestrictUntil] = useState(new Date(time))

  const criteriaChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCriteria(evt.target.value)
  }

  const rationaleChanged = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRationale(evt.target.value)
  }

  const handleValidateParam = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (criteria) {
      const paramToSave = param
      paramToSave.criteria = criteria
      paramToSave.rationale = rationale
      paramToSave.isDeleted = false
      paramToSave.restrictUntil = props.isPermanent
        ? new Date(0)
        : restrictUntil
      paramToSave.dateTimeRestricted = new Date()
      dispatch(saveRestrictedTrades(paramToSave))
      closeFunc()
    }
  }

  return (
    <ReactModal isOpen={displayModal} onRequestClose={() => closeFunc()}>
      <form className={styles.sysParamForm} onSubmit={handleValidateParam}>
        <label>Criteria:</label>
        <input
          className={styles.criteria}
          value={criteria}
          onChange={criteriaChanged}
        />
        <label>Rationale:</label>
        <textarea value={rationale} onChange={rationaleChanged} rows={5} />
        {!props.isPermanent && (
          <>
            <label>Restrict Until:</label>
            <div className={styles.DatePickerWrapper}>
              <DateTimePicker
                value={restrictUntil}
                onChange={(d: Date) => setRestrictUntil(d)}
                clearIcon={null}
              />
            </div>
          </>
        )}

        <footer>
          <button type="submit">Save</button>
          <button onClick={closeFunc}>Cancel</button>
        </footer>
      </form>
    </ReactModal>
  )
}
export default RestrictedTradesModal
