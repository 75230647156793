import { combineEpics, Epic, ofType } from 'redux-observable'
import { map, mergeMap } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { marketClosed } from './actions'

const marketClosedEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('settings.handleWindowSettings'),
    mergeMap(() => {
      return getHub()
        .stream<boolean>('MarketClosed')
        .pipe(map(marketClosed))
    })
  )

export default combineEpics(marketClosedEpic)
