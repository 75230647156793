import {
  faArchive,
  faArrows,
  faBan,
  faBookOpen,
  faCalendar,
  faChartBar,
  faChartLine,
  faDatabase,
  faFileSpreadsheet,
  faFlask,
  faLayerGroup,
  faMagnifyingGlassChart,
  faMessagesDollar,
  faSignalAlt3,
  faUsers
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { useOpenFin } from '../../app/openFinContext'
import {
  getCanMarkRestricted,
  getCanSeeSystemParamEditor
} from '../../store/webSettings/selectors'
import styles from './header.scss'

export interface Props {
  setSelectedTab?: (st: string) => void
}

const AdminTabSelect: FC<Props> = ({ setSelectedTab }) => {
  const { fin } = useOpenFin()
  const canSeeSystemParameterEditor = useSelector(getCanSeeSystemParamEditor)
  const canMarkRestricted = useSelector(getCanMarkRestricted)

  return (
    <>
      <div className={fin ? styles.finAlert : styles.alertButton}>
        <span
          className={fin ? styles.finAdminbutton : styles.adminButton}
          onClick={() => setSelectedTab!('AggressAttempts')}
          data-tip="Aggress Attempts"
          data-for="aggressAttempts"
        >
          <FontAwesomeIcon icon={faMessagesDollar} />
        </span>
        <ReactTooltip
          id="aggressAttempts"
          backgroundColor="#e9edf1"
          textColor="#656C78"
          effect="solid"
          className="iconTooltip"
        >
          Aggress Attempts
        </ReactTooltip>
      </div>
      <div className={styles.dropdownContainer}>
        <span className={cx(fin ? styles.finTitle : styles.dropdownTitle)}>
          Admin
        </span>
        <div
          className={cx(
            styles.dropdownWrapper,
            fin && styles.finDropdownWrapper
          )}
        >
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('TradeBlotter')}
          >
            <FontAwesomeIcon icon={faArchive} /> Trade Blotter
          </div>
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('ActivityTicker')}
          >
            <FontAwesomeIcon icon={faChartLine} /> Activity Ticker
          </div>
          {canSeeSystemParameterEditor && (
            <div
              className={styles.dropdownButton}
              onClick={() => setSelectedTab!('SystemParameters')}
            >
              <FontAwesomeIcon icon={faBookOpen} /> System Parameters
            </div>
          )}
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('Tiers')}
          >
            <FontAwesomeIcon icon={faLayerGroup} /> Tiers
          </div>
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('QueueStats')}
          >
            <FontAwesomeIcon icon={faSignalAlt3} /> Queue Stats
          </div>
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('QuerySelector')}
          >
            <FontAwesomeIcon icon={faDatabase} /> Order/Trade History
          </div>
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('UserDashboard')}
          >
            <FontAwesomeIcon icon={faUsers} /> Active User Dashboard
          </div>
          {canMarkRestricted && (
            <>
              <div
                className={styles.dropdownButton}
                onClick={() => setSelectedTab!('RestrictedTrading')}
              >
                <FontAwesomeIcon icon={faBan} /> Restrict Trading
              </div>
              <div
                className={styles.dropdownButton}
                onClick={() => setSelectedTab!('RestrictedTradingPermanent')}
              >
                <FontAwesomeIcon icon={faBan} /> Restrict Trading Permanent
              </div>
            </>
          )}
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('HistoricalUserData')}
          >
            <FontAwesomeIcon icon={faCalendar} /> GUI User Data
          </div>
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('ExternalOrderStats')}
          >
            <FontAwesomeIcon icon={faArrows} /> API User Data
          </div>
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('ExternalLiquidityStats')}
          >
            <FontAwesomeIcon icon={faFlask} /> External Liquidity Stats
          </div>
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('WatchlistManager')}
          >
            <FontAwesomeIcon icon={faMagnifyingGlassChart} /> Manage watchlists
          </div>
        </div>
      </div>
      <div className={styles.dropdownContainer}>
        <span className={cx(fin ? styles.finTitle : styles.dropdownTitle)}>
          Ops
        </span>
        <div
          className={cx(
            styles.dropdownWrapper,
            fin && styles.finDropdownWrapper
          )}
        >
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('BBMISIN')}
          >
            <FontAwesomeIcon icon={faFileSpreadsheet} /> B-BMISIN
          </div>
          <div
            className={styles.dropdownButton}
            onClick={() => setSelectedTab!('ManagementReports')}
          >
            <FontAwesomeIcon icon={faChartBar} /> Management Reports
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminTabSelect
