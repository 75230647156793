import cx from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import GenericBondsSearch from '../../../components/GenericBondsSearch/GenericBondsSearch'
import { useFetchBondsSearchResults } from '../../../helpers/hooks/useFetchBondsSearchResults'
import { updatePopouts } from '../../../store/depthOfMarket/actions'
import { getIssuerFilter } from '../../../store/securities/selectors'

import styles from '../../../components/SearchBondsInput/SearchBondsResultsDropdown.scss'

interface DepthOfMarketTitleBondsSearchProps {
  onSecurityClicked?: (previousId: number, securityId: number) => void
  gridIndex: number
  previousId: number
}

// NOTE there's a wrapper component at the bottom of this file that adds Openfin functionality before export
const DepthOfMarketTitleBondsSearch = ({
  gridIndex,
  onSecurityClicked,
  previousId
}: DepthOfMarketTitleBondsSearchProps) => {
  const { fin } = useOpenFin()
  // we separate this out into two variables so calls to set the search term that are just about formatting
  // don't rerun the search
  const [displayedSearch, setDisplayedSearch] = useState('')
  const [searchString, setSearchString] = useState('')

  const dispatch = useDispatch()

  const {
    watchlist,
    watchListResults,
    notInWatchListResults,
    showLiveChecked
  } = useFetchBondsSearchResults(gridIndex, searchString)

  // when user clicks "show all" in the bonds list associated with the same grid index, it sets an issuer filter
  // get that filter so we can format the search results
  const issuerFilter = useSelector(getIssuerFilter)(gridIndex)

  useEffect(() => {
    // we already hit clear and redux hasn't caught up
    if (!displayedSearch) return
    if (issuerFilter) {
      setDisplayedSearch(`${issuerFilter} `)
    }
  }, [watchlist, issuerFilter])

  const onClear = useCallback(() => {
    setSearchString('')
    setDisplayedSearch('')
  }, [])

  const handleSearchChanged = useCallback((search: string) => {
    setDisplayedSearch('')
    setSearchString(search)
  }, [])

  const handleSecurityClicked = useCallback(
    (securityId: number) => {
      dispatch(updatePopouts(previousId, securityId))
      onSecurityClicked && onSecurityClicked(previousId, securityId)
    },
    [previousId, dispatch]
  )

  // ---------------------------------------------------------------------------------------------------------------- //
  return (
    <GenericBondsSearch
      search={searchString}
      formattedSearch={displayedSearch}
      onSearchChanged={handleSearchChanged}
      watchListResults={watchListResults}
      notInWatchListResults={notInWatchListResults}
      showLiveChecked={showLiveChecked}
      onClear={onClear}
      onSecurityClicked={handleSecurityClicked}
      menuStyle={cx(styles.contentDropDownMenu, fin && styles.finMenu)}
    />
  )
}

const OpenfinAwareDepthOfMarketTitleBondsSearch = (
  props: Omit<DepthOfMarketTitleBondsSearchProps, 'onSecurityClicked'>
) => {
  const openfinApi = useOpenFin()
  const onSecurityClicked = useCallback(
    (securityId: number) => {
      if (!openfinApi) return
      const previousBounds = localStorage.getItem(
        `depthPrevBounds-${props.previousId}`
      )
      if (previousBounds) {
        const { left, top } = JSON.parse(previousBounds) as {
          left: number
          top: number
        }
        localStorage.setItem(
          `depthPrevBounds-${securityId}`,
          JSON.stringify({ left, top })
        )
      }
    },
    [props.previousId]
  )
  return (
    <DepthOfMarketTitleBondsSearch
      {...props}
      onSecurityClicked={onSecurityClicked}
    />
  )
}

export default OpenfinAwareDepthOfMarketTitleBondsSearch
