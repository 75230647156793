import React from 'react'

const Play = () => (
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="my-orders---my-trades-"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="watchlist--my-orders-v1"
        transform="translate(-2228.000000, -272.000000)"
      >
        <g id="play-icon" transform="translate(2227.000000, 269.000000)">
          <rect
            id="Rectangle"
            fill="#46678D"
            fillRule="evenodd"
            x="1"
            y="3"
            width="10"
            height="10"
            rx="2"
          ></rect>
          <polygon
            id="Path"
            fill="#FFFFFF"
            fillRule="nonzero"
            points="4 4 4 11.9527778 8.98742222 7.97646667"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
)

export default Play
