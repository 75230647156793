import cx from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './error.scss'

interface Props {
  error: string
  autoDismissDelay?: number
  setError?: (s: string) => void
}

const ErrorComponent: React.FC<Props> = ({
  error,
  autoDismissDelay,
  setError
}) => {
  const [dismissed, setDismissed] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined

    if (!dismissed && autoDismissDelay) {
      timeout = setTimeout(dismiss, autoDismissDelay)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [dismissed])

  useEffect(() => {
    if (dismissed) {
      setDismissed(false)
    }
  }, [error])

  const dismiss = useCallback(() => {
    setDismissed(true)
    if (setError) {
      setError('')
    }
  }, [])

  const hidden = !error || dismissed

  return (
    <div
      className={cx(styles.error, hidden ? styles.hidden : styles.displayed)}
    >
      {!hidden && (
        <>
          <span className={styles.leftWrapper}>
            <i className="fas fa-exclamation-triangle" />
            <span
              data-testid="marketdepth-errormessage"
              className={styles.errorText}
            >
              {error}
            </span>
          </span>
          <i
            className="far fa-times-circle"
            data-testid="marketdepth-closeerror"
            onClick={dismiss}
          />
        </>
      )}
    </div>
  )
}

export default ErrorComponent
