import { ColDef } from '@ag-grid-community/core'

export const ORDER_ID = 'id'
export const SECURITY_ID = 'securityId'
export const BOARD_LABEL = 'boardLabel'
export const SECTOR = 'sector'
export const PRODUCT_TYPE = 'productType'
export const ISIN = 'ISIN'
export const CUSIP = 'cusip'
export const SUBMIT_TIME = 'submitTime'
export const VENUE_NAME = 'venueName'
export const VENUE_SUPPRESSED = 'venueSuppressed'
export const PRICE = 'price'
export const ORDER_AMOUNT = 'orderAmount'
export const MINIMUM_AMOUNT = 'minimumAmount'
export const BBG_PRICING_NUMBER = 'bbgPricingNumber'
export const BBG_ORDER_BOOK = 'bbgOrderBook'
export const BID_OFFER = 'bidOffer'
export const CURRENCY = 'currency'
export const SHOW_AS_USER = 'showAsUser'
export const SHOW_AS_CUSTOMER = 'showAsCustomer'
export const RESTRICTED = 'restricted'
export const IS_LIVE = 'isLive'
export const PARAMETERS = 'parameters'

export const customizableColumns: ColDef[] = [
  {
    colId: ORDER_ID,
    field: ORDER_ID,
    headerName: 'Order Id',
    width: 100,
    suppressAutoSize: true
  },
  {
    colId: SECURITY_ID,
    field: SECURITY_ID,
    headerName: 'Security Id',
    width: 100,
    suppressAutoSize: true
  },
  {
    colId: BOARD_LABEL,
    field: BOARD_LABEL,
    headerName: 'Board Label',
    width: 100,
    suppressAutoSize: true
  },
  {
    colId: SECTOR,
    field: SECTOR,
    headerName: 'Sector',
    width: 100,
    suppressAutoSize: true
  },
  {
    colId: PRODUCT_TYPE,
    field: PRODUCT_TYPE,
    headerName: 'Product Type',
    width: 100,
    suppressAutoSize: true
  },
  {
    colId: ISIN,
    field: ISIN,
    headerName: 'ISIN',
    width: 120,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: CUSIP,
    field: CUSIP,
    headerName: 'CUSIP',
    width: 130,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: SUBMIT_TIME,
    field: SUBMIT_TIME,
    headerName: 'Submit Time',
    width: 100,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: VENUE_NAME,
    field: VENUE_NAME,
    headerName: 'Venue Name',
    width: 100,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: VENUE_SUPPRESSED,
    field: VENUE_SUPPRESSED,
    headerName: 'Venue Suppressed',
    width: 100,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: PRICE,
    field: PRICE,
    headerName: 'Price',
    width: 100,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: ORDER_AMOUNT,
    field: ORDER_AMOUNT,
    headerName: 'Amount',
    width: 100,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: MINIMUM_AMOUNT,
    field: MINIMUM_AMOUNT,
    headerName: 'Minimum',
    width: 130,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BBG_PRICING_NUMBER,
    field: BBG_PRICING_NUMBER,
    headerName: 'BBG Pricing Number',
    width: 100,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BBG_ORDER_BOOK,
    field: BBG_ORDER_BOOK,
    headerName: 'BBG Order Book',
    width: 100,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BID_OFFER,
    field: BID_OFFER,
    headerName: 'Bid/Offer',
    width: 130,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: CURRENCY,
    field: CURRENCY,
    headerName: 'Currency',
    width: 150,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: SHOW_AS_USER,
    field: SHOW_AS_USER,
    headerName: 'Show as User',
    width: 140,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: SHOW_AS_CUSTOMER,
    field: SHOW_AS_CUSTOMER,
    headerName: 'Show as Customer',
    width: 150,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: RESTRICTED,
    field: RESTRICTED,
    headerName: 'Restricted',
    width: 150,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: IS_LIVE,
    field: IS_LIVE,
    headerName: 'Live',
    width: 130,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: PARAMETERS,
    field: PARAMETERS,
    headerName: 'Parameters',
    width: 180,
    suppressAutoSize: true,
    cellStyle: { justifyContent: 'center' }
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
